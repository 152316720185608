import React, {Component} from "react";
import {eqOptions, FILTER, FILTERING, intOptions, SAVE, strOptions} from '../../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Add, SkipNext, SkipPrevious} from "@material-ui/icons";
import {createTypeOption, getLabel, handleErr, showFilterSpinner} from "../../utils/MiscellaniousUtils";
import Select from "react-select";
import ResourceAPIs from "../../utils/ResourceAPI";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class InboundFeeds extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                clientId: {
                    condition: "eq",
                    value: "0",
                    label: "",
                },
                inboundFeedName: {
                    condition: "eq",
                    value: "",
                },
                updateFrequency: {
                    condition: "eq",
                    value: "",
                },
                feedType: {
                    condition: "eq",
                    value: "",
                },
                modifierType: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            clientts: []
        };

        this.onChangeWarehouseIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.warehouseId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeClientIdValue = (selected) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeInboundFeedNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.inboundFeedName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeUpdateFrequencyValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.updateFrequency.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeFeedTypeValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.feedType.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeModifierTypeValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.modifierType.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeQty = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].locationSize = event.target.value;
            this.setState(stateCopy);
        };
    }

    onChangeWarehouseIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouseId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInboundFeedNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.inboundFeedName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeUpdateFrequencyFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.updateFrequency.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFeedTypeFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.feedType.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeModifierTypeFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.modifierType.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchInboundFeeds();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchInboundFeeds();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchInboundFeeds();
    };


    clearFilters = () => {
        this.setState({
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                clientId: {
                    condition: "eq",
                    value: "0",
                    label: "",
                },
                inboundFeedName: {
                    condition: "eq",
                    value: "",
                },
                updateFrequency: {
                    condition: "eq",
                    value: "",
                },
                feedType: {
                    condition: "eq",
                    value: "",
                },
                modifierType: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchInboundFeeds() {
        if (this.validateInputs()) {
            this.setState({
                filterBtnText: FILTERING,
                isProcessing: true,
            });
            new ResourceAPIs().searchInboundFeeds(this.state.filters)
                .then(res => {
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: res.data,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: error,
                        });
                    });
        } else {
            console.log("invalid inputs");
        }
    }

    validateInputs = () => {
        // validate warehouse
        if (parseInt(this.state.filters.warehouseId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = parseInt(this.state.filters.warehouseId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid Warehouse ID"
            });
            return false;
        }

        // validate client id
        if (parseInt(this.state.filters.clientId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = parseInt(this.state.filters.clientId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid Client ID"
            });
            return false;
        }

        return true;
    };

    handleAddNew = () => {
        new ResourceAPIs().addInboundFeed()
            .then(res => {
                    window.open("/ifeeds/" + res.data);
                })
            .catch(error => {
                    handleErr(error);
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={3}>
                                        <Form.Label style={filterLabel}>Warehouse ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={intOptions}
                                                                handleOnChange={this.onChangeWarehouseIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeWarehouseIdValue}
                                                              value={this.state.filters.warehouseId.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Client ID</Form.Label>
                                        {this.props.id === undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Select
                                                        value={createTypeOption(this.state.filters.clientId.label)}
                                                        onChange={(e) => this.onChangeClientIdValue(e)}
                                                        options={this.state.clients}
                                                        styles={this.customStyles}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {this.props.id !== undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={eqOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Form.Control size="sm" type="number"
                                                                  value={this.props.id}
                                                                  onChange={this.onChangeClientIdValue}
                                                                  onKeyPress={this.handleFilter}
                                                                  disabled={true}/>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Label style={filterLabel}>Inbound Feed Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeInboundFeedNameFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              value={this.state.filters.inboundFeedName.value}
                                                              onChange={this.onChangeInboundFeedNameValue}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Update Frequency</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeUpdateFrequencyFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeUpdateFrequencyValue}
                                                              value={this.state.filters.updateFrequency.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Label style={filterLabel}>Feed Type</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeFeedTypeFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              value={this.state.filters.feedType.value}
                                                              onChange={this.onChangeFeedTypeValue}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Modifier Type</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeModifierTypeFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeModifierTypeValue}
                                                              value={this.state.filters.modifierType.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={1}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <p>Add New Feed</p>
                                            <Button variant="primary" onClick={this.handleAddNew}><Add/> Add New</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Warehouse ID</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Inb Feed Name</TableCell>
                                            <TableCell align="center">updateFrequency</TableCell>
                                            <TableCell align="center">feedType</TableCell>
                                            <TableCell align="center">modifierType</TableCell>
                                            <TableCell align="center">modifier</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/ifeeds/" + row.id} target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="right">{row.warehouseId}</TableCell>
                                                <TableCell align="right">{row.clientId}</TableCell>
                                                <TableCell align="left">{row.inboundFeedName}</TableCell>
                                                <TableCell align="left">{row.updateFrequency}</TableCell>
                                                <TableCell align="left">{row.feedType}</TableCell>
                                                <TableCell align="left">{row.modifierType}</TableCell>
                                                <TableCell align="right">{row.modifier}</TableCell>
                                                <TableCell align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default InboundFeeds;
