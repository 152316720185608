import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {
    CALCULATE, 
    SAVE, 
    SAVING, 
    ADDING_CONTENTS, 
    ADD_CONTENTS, 
    DELETE_CONTENTS, 
    DELETING_CONTENTS
} from "../../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../../utils/DateUtils";
import {createTypeOption, getIdFromUrl, getLabel, getOptionUsingValue, handleErr, setEmptyIfNull, handleError} 
    from "../../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Clear, ExpandMore, PlayCircleOutline, Save, Delete} from "@material-ui/icons";
import Select from "react-select";
import InboundFeedContents from "./InboundFeedContents";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import Accordion from "@material-ui/core/Accordion/Accordion";
import ContentsManualImporter from "./ContentsManualImporter";
import ResourceAPIs from "../../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const EXECUTE = "Execute";
const EXECUTING = "Executing";

const feedTypeOptions = [
    {value: "warehouse", label: "warehouse"},
    {value: "manual", label: "manual"},
];

const modifierTypeOptions = [
    {value: "add", label: "add"},
    {value: "subtract", label: "subtract"},
    {value: "multiply", label: "multiply"},
    {value: "divide", label: "divide"},
    {value: "none", label: "none"},
];

class InboundFeedDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            feed: {
                id: "",
                clientId: "",
                warehouseId: 0,
                inboundFeedName: "",
                updateFrequency: "",
                feedType: "",
                modifierType: "",
                modifier: "",
                barcodeFieldNo: "",
                qtyFieldNo: "",
                hasHeaders: false,
                createdAt: "",
                updatedAt: "",
                errMsg: "",
            },
            addContent: {
                qty: "",
                barcodes: "",
            },
            isLocationLoaded: false,
            isProcessing: false,
            addContentBtnText: ADD_CONTENTS,
            deleteContentBtnText: DELETE_CONTENTS,
            editLocationBtnText: SAVE,
            executeBtnText: EXECUTE,
            calculateBtnText: CALCULATE,
            errMsg: "",
            childLoad: false,
            isDeleted: false,
            isOpen: false,
            clientLabel: "",
            clients: []
        };

        // Edit location

        this.onChangeClientIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.feed.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeWarehouseId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.feed.warehouseId = event.target.value;
            this.setState(newState);
        };

        this.onChangeInboundFeedName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.feed.inboundFeedName = event.target.value;
            this.setState(newState);
        };

        this.onChangeUpdateFrequency = (event) => {
            let newState = Object.assign({}, this.state);
            newState.feed.updateFrequency = event.target.value;
            this.setState(newState);
        };

        this.onChangeFeedType = selected => {
            let newState = Object.assign({}, this.state);
            newState.feed.feedType = selected;
            if (selected === feedTypeOptions[1]) {
                newState.feed.warehouseId = 0;
            }
            this.setState(newState);
        };

        this.onChangeModifierType = selected => {
            let newState = Object.assign({}, this.state);
            newState.feed.modifierType = selected;
            this.setState(newState);
        };

        this.onChangeModifier = (event) => {
            let newState = Object.assign({}, this.state);
            newState.feed.modifier = event.target.value;
            this.setState(newState);
        };

        // Add contents

        this.onChangeAddContentQty = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.qty = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentBarcodes = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.barcodes = event.target.value;
            this.setState(newState);
        };

        this.changeIsOpen = (event) => {
            let newState = Object.assign({}, this.state);
            newState.isOpen = !this.state.isOpen;
            this.setState(newState);
        }

        this.handleEditInboundFeed = () => {
            if (this.validateEditInboundFeed()) {
                this.setState({
                    editLocationBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPIs().updateInboundFeed(this.getLocationId(), this.state.feed.clientId,
                    this.state.feed.warehouseId, this.state.feed.inboundFeedName, this.state.feed.updateFrequency,
                    this.state.feed.feedType.value, this.state.feed.modifierType.value,
                    this.state.feed.modifier, this.state.addContent)
                    .then(res => {
                            this.setState({
                                editLocationBtnText: SAVE,
                                isProcessing: false,
                            });
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                editLocationBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.executeInboundFeed = () => {
            this.setState({
                executeBtnText: EXECUTING,
                isProcessing: true,
            });
            new ResourceAPIs().executeInboundFeed(this.getLocationId())
                .then(res => {
                        this.setState({
                            executeBtnText: EXECUTE,
                            isProcessing: false,
                        });
                    })
                .catch(error => {
                        handleError(error);
                        this.setState({
                            executeBtnText: EXECUTE,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleCancelEditLocation = () => {
            this.getInboundFeedById();
        };

        this.handleAddContent = () => {
            if (this.validateAddContentInputs()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });
                new ResourceAPIs().updateWHContentsByLocationId(getIdFromUrl(), this.state.addContent)
                    .then(res => {
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                childLoad: false,
                            });
                            this.restAddContentFrom();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleDeleteAllContents = () => {
            MySwal.fire({
                title: 'You want to Delete all the contents of this location?',
                text: "You won't be able to revert this!",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    // Delete happens here
                    this.setState({
                        deleteContentBtnText: DELETING_CONTENTS,
                        isProcessing: true,
                    });
                    new ResourceAPIs().deleteWHContentsByLocationId(getIdFromUrl())
                        .then(res => {
                                this.setState({
                                    childLoad: true,
                                });
                                this.setState({
                                    deleteContentBtnText: DELETE_CONTENTS,
                                    isProcessing: false,
                                    childLoad: false,
                                });
                                MySwal.fire(
                                    'Deleted!',
                                    'All contents of this location has been deleted.',
                                    'success'
                                );
                            })
                        .catch(error => {
                                handleErr(error);
                                this.setState({
                                    deleteContentBtnText: DELETE_CONTENTS,
                                    isProcessing: false,
                                    results: error,
                                });
                            });
                }
            });
        };
    }

    setStateIsDeleted = (val) =>{
        this.setState({
            isDeleted: val
        })
    };

    validateAddContentInputs = () => {
        // validate qty
        if (parseInt(this.state.addContent.qty, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.addContent.qty = parseInt(this.state.addContent.qty, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid Quantity"
            });
            return false;
        }

        // validate barcodes
        if (this.state.addContent.barcodes === "") {
            this.setState({
                errMsg: "Invalid Barcodes"
            });
            return false;
        }

        return true;
    };

    validateEditInboundFeed = () => {
        // validate client id
        if (parseInt(this.state.feed.clientId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.feed.clientId = parseInt(this.state.feed.clientId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Client ID");
        }

        // validate warehouse id
        if (parseInt(this.state.feed.warehouseId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.feed.warehouseId = parseInt(this.state.feed.warehouseId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Warehouse ID");
        }

        // validate inbound feed name
        if (this.state.feed.inboundFeedName === "") {
            return this.setLocationEditErr("Invalid Inbound Feed Name");
        }

        // validate update frequency
        if (this.state.feed.updateFrequency === "") {
            return this.setLocationEditErr("Invalid Update Frequency");
        }

        // validate feed type
        if (this.state.feed.feedType === "") {
            return this.setLocationEditErr("Invalid Feed Type");
        }

        // validate modifier type
        if (this.state.feed.modifierType === "") {
            return this.setLocationEditErr("Invalid Modifier Type");
        }

        // validate modifier
        if (parseInt(this.state.feed.modifier, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.feed.modifier = parseInt(this.state.feed.modifier, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Modifier");
        }

        if (this.state.feed.feedType === feedTypeOptions[0]) {
            if (parseInt(this.state.feed.warehouseId, 10) === 0) {
                return this.setLocationEditErr("Warehouse ID should be set for feed type: warehouse");
            }
        }

        return true;
    };

    setLocationEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.feed.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getLocationId = () => {
        let url = window.location.href;
        return url.substring(url.lastIndexOf('/') + 1);
    };

    getInboundFeedById = () => {
        new ResourceAPIs().getInboundFeedsById(getIdFromUrl())
            .then(res => {
                    const result = res.data;
                    this.setState({
                        feed: {
                            id: result.id,
                            clientId: result.clientId,
                            warehouseId: result.warehouseId,
                            inboundFeedName: setEmptyIfNull(result.inboundFeedName),
                            updateFrequency: setEmptyIfNull(result.updateFrequency),
                            feedType: getOptionUsingValue(feedTypeOptions, result.feedType),
                            modifierType: getOptionUsingValue(modifierTypeOptions, result.modifierType),
                            modifier: setEmptyIfNull(result.modifier),
                            barcodeFieldNo: setEmptyIfNull(result.barcodeFieldNo),
                            qtyFieldNo: setEmptyIfNull(result.qtyFieldNo),
                            hasHeaders: result.ignoreHeaders,
                            createdAt: result.createdAt,
                            updatedAt: result.updatedAt,
                        },
                        isLocationLoaded: true,
                        clientLabel: getLabel(this.state.clients, result.clientId)
                    });
                })
            .catch(error => {
                    this.setState({
                        isLocationLoaded: false,
                        error
                    });
                }
            );
    };

    deleteContents = () => {
        MySwal.fire({
            title: 'You want to Delete All Inbound Feed Contents?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPIs().deleteAllIFContentsById(getIdFromUrl())
                    .then(res => {
                            MySwal.fire(
                                'Deleted!',
                                'All Contents have been deleted.',
                                'success'
                            );
                            this.setStateIsDeleted(true);
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                deleteContentBtnText: DELETE_CONTENTS,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        })
    };

    restAddContentFrom = () => {
        this.setState({
            addContent: {
                qty: "",
                barcodes: "",
            }
        });
    };

    loadInboundFeedContents = () => {
        this.setState({
            childLoad: true,
        });
        this.setState({
            childLoad: false,
        });
        this.getInboundFeedById();
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
            this.getInboundFeedById();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <Row>
                        <Col xs={12}>
                            <Card border="secondary">
                                <Card.Header as="h5">
                                    <span style={{marginTop: 60}}>Feed Details</span>

                                    <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.feed.errMsg}
                                    </span>
                                        <Button variant="success" style={{width: 200}} size="sm"
                                                disabled={this.state.isProcessing}
                                                onClick={this.handleEditInboundFeed}>
                                            {showEditLocationSpinner(this.state.editLocationBtnText)}
                                        </Button>
                                        <Button variant="secondary" style={{marginLeft: 10, width: 200}} size="sm"
                                                onClick={this.handleCancelEditLocation}
                                                disabled={this.state.isProcessing}>
                                            <Clear/> Cancel
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.feed.id} disabled={true}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Client ID</Form.Label>
                                                        </Col>
                                                        {this.props.id === undefined && (
                                                            <Col xs={8}>
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientIdValue(e)}
                                                                options={this.state.clients}
                                                                styles={this.customStyles}
                                                            />
                                                            </Col>
                                                        )}
                                                        {this.props.id !== undefined && (
                                                            <Col xs={8}>
                                                                <Form.Control type="number"
                                                                            value={this.state.feed.clientId}
                                                                            onChange={this.onChangeClientId}
                                                                            disabled = {true}/>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Warehouse ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.feed.warehouseId}
                                                                          onChange={this.onChangeWarehouseId}
                                                                          disabled={this.state.feed.warehouseId === 0
                                                                            && this.state.feed.feedType === feedTypeOptions[1]}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Inb Feed Name</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.feed.inboundFeedName}
                                                                          onChange={this.onChangeInboundFeedName}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Update Frequency</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.feed.updateFrequency}
                                                                          onChange={this.onChangeUpdateFrequency}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Feed Type</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Select
                                                                value={this.state.feed.feedType}
                                                                onChange={this.onChangeFeedType}
                                                                options={feedTypeOptions}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Modifier Type</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Select value={this.state.feed.modifierType}
                                                                    onChange={this.onChangeModifierType}
                                                                    options={modifierTypeOptions}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Modifier</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"  min="0"
                                                                          value={this.state.feed.modifier}
                                                                          onChange={this.onChangeModifier}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Created At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.feed.createdAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Updated At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.feed.updatedAt)}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row style={{marginLeft: 5}}>
                        <Button variant="primary" style={{width: 200, marginRight: 10}}
                                disabled={this.state.isProcessing || this.state.feed.feedType === feedTypeOptions[1]}
                                onClick={this.executeInboundFeed}>
                            {showExecuteSpinner(this.state.executeBtnText)}
                        </Button>
                        
                        <Button variant="danger" style={{marginRight: 10, width: 200}} size="sm"
                                onClick={this.deleteContents}
                                disabled={this.state.isProcessing} 
                                className="btn btn-primary small">
                                <Delete/> Delete all contents
                        </Button>
                    </Row>
                    <br/><br/>

                    <Accordion expanded={this.state.isOpen}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            onClick={this.changeIsOpen}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <span style={{color: '#32a2fe'}}>Manual Import</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row style={{width:'100%'}}>
                                <ContentsManualImporter
                                    parent={this.state.feed}
                                    childLoad={this.loadInboundFeedContents}/>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                    <br/><br/>
                    <InboundFeedContents parent={this.state.feed} 
                                        isLoad={this.state.childLoad} 
                                        isDeleted={this.state.isDeleted}/>
                </div>
            );
        }
    }
}

function showEditLocationSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showExecuteSpinner(btnText) {
    if (btnText === EXECUTE) {
        return <span><PlayCircleOutline/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default InboundFeedDetails;
