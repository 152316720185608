import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import DeletedStores from "./DeletedStores";
import Stores from "./Stores";

class StoresHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: "",
        }
    }

    componentDidMount() {
        let url = window.location.href;
        this.setState({
            activeKey: url.substring(url.lastIndexOf('/') + 1)
        });
    }

    render() {
        return (
            <div style={{ marginLeft: '1%', marginRight: '1%' }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="stores">
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="stores">Stores</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="deleted">Deleted</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="stores">
                            <Stores />
                        </Tab.Pane>
                        <Tab.Pane eventKey="deleted">
                            <DeletedStores />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }
}

export default StoresHome;