import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import * as Constants from '../../constants';
import {FaFileImport} from "react-icons/fa";
import Select from "react-select";
import {createStoreOptions} from "./AmazonNewOrders";
import {handleError, handleFileSizeExceedsError} from "../../utils/MiscellaniousUtils";
import {IMPORT, IMPORTING} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";

const amazonNewDiv = {
    marginTop: '25px',
};

class AmazonAllOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            stores: [],
            selectedFile: null,
            selectedStore: {value: 1, label: "Shoe Annex Amazon"},
            fileErr: "",
            storeErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importStatus: "",
        };
    }

    componentDidMount() {
        new ResourceAPIs().getStores()
            .then(res => {
                const result = res.data;
                    this.setState({
                        isLoaded: true,
                        stores: result
                    });
                })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    onChangeHandler = event => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].size > Constants.MAX_FILE_SIZE) {
                handleFileSizeExceedsError(Constants.MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                });
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                });
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true,
            });
            new ResourceAPIs().importAmazonAllOrdersByStoreId(this.state.selectedStore.value, data)
                .then(res => {
                    this.setState({
                        importBtnText: IMPORT,
                        isProcessing: false,
                        importStatus: res.data,
                    });
                    this.clearFrom();
                })
                .catch(error => {
                    this.setState({
                        importBtnText: IMPORT,
                        isProcessing: false,
                    });
                    handleError(error);
                });
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }

        if (this.state.selectedStore <= 0) {
            this.setState({
                storeErr: "Pls select a Store!",
            });
            return false;
        } else {
            this.setState({
                storeErr: "",
            });
        }

        return true;
    };

    handleChange = selectedOption => {
        this.setState({
            selectedStore: selectedOption,
        })
    };

    clearFrom = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            selectedStore: {value: 1, label: "Shoe Annex Amazon"},
            selectedFile: null,
            theInputField: randomString
        });
    };

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <div style={amazonNewDiv}>
                        <Form.Group as={Col} controlId="stores">
                            <Select
                                value={this.state.selectedStore}
                                onChange={this.handleChange}
                                options={createStoreOptions(this.state.stores)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Select File</Form.Label>
                            <br/>
                            <input 
                                type="file" 
                                name="file" 
                                onChange={this.onChangeHandler}
                                key={this.state.theInputField}/>
                            <Form.Text className="text-muted">
                                {this.state.fileErr}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState">
                            <Row>
                                <Col>
                                    <Button variant="success" onClick={this.onClickHandler}
                                            disabled={this.state.isProcessing}>
                                        <FaFileImport/> {this.state.importBtnText}
                                    </Button>
                                </Col>
                                <Col>
                                    {this.state.importStatus}
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>
                </div>
            );
        }
    }
}

export default AmazonAllOrders;
