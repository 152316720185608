// we access protected resources, the HTTP request needs Authorization header
// this method adds auth header
export function AuthorizationHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.Authorization) {
        return { Authorization: 'Bearer ' + user.Authorization };
    } else {
        return {};
    }
}

export default function AuthHeader() {
    return {headers: AuthorizationHeader()}
}
