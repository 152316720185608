import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import {PlaylistAdd} from "@material-ui/icons";
import {
    alertSuccess,
    alertSuccessfullyUpdated, createTypeOption,
    getIdFromUrl, getLabel,
    handleErr, handleError,
    showFilterSpinner
} from "../utils/MiscellaniousUtils";
import {EXPORT, FILTER, FILTERING, intOptions, SAVE, strOptions} from "../constants";
import Spinner from "react-bootstrap/Spinner";
import ResourceAPI from "../utils/ResourceAPI";
import withStyles from "@material-ui/core/styles/withStyles";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Select from "react-select";

const MySwal = withReactContent(Swal);

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30,
};

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
        height: 440,
    },
});

const ADD_PO_CONTENTS = "Add PO Contents";
const ADDING_PO_CONTENTS = "Adding PO Contents...";
const ADD_PO_CONTENTS_TASK = "Add PO Contents Task";
const ADDING_PO_CONTENTS_TASK = "Adding PO Contents Task...";

class POContentsAdder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                supplier: {
                    condition: "eq",
                    value: "",
                },
                supplierGroup: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            exportBtnText: EXPORT,
            errMsg: "",
            results: [],
            resultsSize: 0,
            saveQtyBtnText: SAVE,
            addPOContentsBtnText: ADD_PO_CONTENTS,
            addPOContentsTaskBtnText: ADD_PO_CONTENTS_TASK,
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeBuyingModelValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.buyingModel.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInventoryModelValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.inventoryModel.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeFulfilmentValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.fulfilment.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSupplierValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.supplier.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSupplierGroupValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.supplierGroup.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            // this.handleFilter();
        };

        this.onChangeCombinedStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = getLabel(this.state.stores, selected.value);
            this.setState(stateCopy);
            // this.handleFilter();
        };
    }

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeBuyingModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.buyingModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInventoryModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.inventoryModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFulfilmentFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.fulfilment.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSupplierFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.supplier.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSupplierGroupFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.supplierGroup.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    // handleFilterOnEnter = (event) => {
    //     if (event.keyCode === 13) {
    //         this.handleFilter();
    //     }
    // };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                storeId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                supplier: {
                    condition: "eq",
                    value: "",
                },
                supplierGroup: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    componentDidMount() {
        this.getClients();
    }

    getClients = () => {
        new ResourceAPI().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    getStores = () => {
        new ResourceAPI().getStores().then(res => {
                const stores = res.data.map(store => {
                    return {value: store.id, label: store.id + " - " + store.name, name: store.name}
                });
                this.setState({
                    stores: stores,
                });
            },
            (error) => {
                console.log(error);
                this.setState({
                    results: error,
                });
            });
    };

    searchPullRequests() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPI().getSearchListingsToAddContents(this.state.filters)
            .then(res => {
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: res.data.listings,
                    resultsSize: res.data.size
                });
            })
            .catch(error => {
                handleErr(error);
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: error,
                });
            });
    }

    addPOContents = (isTask) => {
        if (this.state.resultsSize > 3000) {
            MySwal.fire({
                title: 'Results size is more than 3000, [' + this.state.resultsSize + ' results]',
                text: 'Do you want to add all ' + this.state.resultsSize + ' results?',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Add all!'
            }).then((result) => {
                if (result.value) {
                    if (isTask) {
                        this.executeAddPOContentsAsTask();
                    } else {
                        this.executeAddPOContents();
                    }
                }
            });
        } else {
            if (isTask) {
                this.executeAddPOContentsAsTask();
            } else {
                this.executeAddPOContents();
            }
        }
    };

    executeAddPOContents = () => {
        this.setState({
            addPOContentsBtnText: ADDING_PO_CONTENTS,
            isProcessing: true,
        });
        new ResourceAPI().addPOContents(getIdFromUrl(), this.state.filters)
            .then(res => {
                this.props.childLoadSetter(true);
                this.setState({
                    addPOContentsBtnText: ADD_PO_CONTENTS,
                    isProcessing: false,
                    addContent: {
                        sku: "",
                    }
                });
                this.props.childLoadSetter(false);
                alertSuccessfullyUpdated("Purchase order contents");
            }).catch(error => {
                handleError(error);
                this.setState({
                    addPOContentsBtnText: ADD_PO_CONTENTS,
                    isProcessing: false,
                    results: error,
                });
            });
    };

    executeAddPOContentsAsTask = () => {
        this.setState({
            addPOContentsTaskBtnText: ADDING_PO_CONTENTS_TASK,
            isProcessing: true,
        });
        new ResourceAPI().addPOContentsTask(getIdFromUrl(), this.state.filters)
            .then(res => {
                this.props.childLoadSetter(true);
                this.setState({
                    addPOContentsTaskBtnText: ADD_PO_CONTENTS_TASK,
                    isProcessing: false,
                    addContent: {
                        sku: "",
                    }
                });
                this.props.childLoadSetter(false);
                alertSuccess("'Add PO Contents' task added successfully!");
            }).catch(error => {
                handleError(error);
                this.setState({
                    addPOContentsTaskBtnText: ADD_PO_CONTENTS_TASK,
                    isProcessing: false,
                    results: error,
                });
            });
    };

    render() {
        const {classes} = this.props;

        return (
            <Row>
                <Row style={{width: '100%'}}>
                    <Col xs={12} style={{width: '100%'}}>
                        <h6 style={{marginLeft: 10}}>Search Listings</h6>
                        <Card style={{marginLeft: '1%', marginRight: '1%', width: '98%',}}>
                            <Card.Body>
                                <Row xs={12}>
                                    <Col xs={5}>
                                        <Form.Label style={filterLabel}>Client</Form.Label>
                                        {this.props.id === undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Select
                                                        value={createTypeOption(this.state.filters.clientId.label)}
                                                        onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                        options={this.state.clients}
                                                        styles={this.customStyles}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {this.props.id !== undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Form.Control size="sm" type="number"
                                                                value={this.props.id}
                                                                onKeyDown={this.handleFilterOnEnter}
                                                                onChange={this.onChangeClientIdValue}
                                                                disabled={true}/>
                                                </Col>
                                            </Row>
                                        )}


                                        <Form.Label style={filterLabel}>Store</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={intOptions}
                                                                handleOnChange={this.onChangeStoreIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Select
                                                    value={createTypeOption(this.state.filters.storeId.label)}
                                                    onChange={(e) => this.onChangeCombinedStoreIdValue(e)}
                                                    options={this.state.stores}
                                                    styles={this.customStyles}
                                                />
                                            </Col>
                                        </Row>

                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.status.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Fulfilment</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeFulfilmentFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeFulfilmentValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.fulfilment.value}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label style={filterLabel}>Inventory model</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeInventoryModelFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeInventoryModelValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.inventoryModel.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Buying model</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeBuyingModelFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeBuyingModelValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.buyingModel.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Supplier</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeSupplierFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSupplierValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.supplier.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Supplier Group</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeSupplierGroupFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSupplierGroupValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.supplierGroup.value}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row align="center"
                                             style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary"
                                                    onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                        <br/>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <span style={resultsDescStyle}>
                                                Showing {this.state.results.length} of
                                                <b style={this.state.resultsSize > 3000 ? {'color': 'red'} : {}}> {this.state.resultsSize}</b> results
                                            </span>
                                        </Row>
                                        <br/>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="primary"
                                                    disabled={this.state.isProcessing
                                                        || this.state.resultsSize === 0}
                                                    onClick={() => this.addPOContents(false)}>
                                                {showAddPOContentsSpinner(this.state.addPOContentsBtnText)}
                                            </Button>
                                            <br/>
                                            <Button variant="warning"
                                                    disabled={this.state.isProcessing
                                                    || this.state.resultsSize === 0}
                                                    onClick={() => this.addPOContents(true)}
                                                    style={{fontSize: 12, marginTop: 10}}>
                                                {showAddPOContentsTaskSpinner(this.state.addPOContentsTaskBtnText)}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <Paper className={classes.root}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">SKU</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Store Name</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Fulfilment</TableCell>
                                            <TableCell align="center">Inventory Model</TableCell>
                                            <TableCell align="center">Buying Model</TableCell>
                                            <TableCell align="center">Supplier</TableCell>
                                            <TableCell align="center">Supplier Group</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                        <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align="right">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">{row.sku}</TableCell>
                                                <TableCell align="right">{row.clientId}</TableCell>
                                                <TableCell align="left">{row.storeName}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>
                                                <TableCell align="left">{row.fulfilment}</TableCell>
                                                <TableCell
                                                    align="left">{row.inventoryModel}</TableCell>
                                                <TableCell align="left">{row.buyingModel}</TableCell>
                                                <TableCell align="left">{row.supplier}</TableCell>
                                                <TableCell align="left">{row.supplierGroup}</TableCell>
                                                <TableCell
                                                    align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell
                                                    align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </Paper>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </Row>
        );
    }
}

function showAddPOContentsSpinner(btnText) {
    if (btnText === ADD_PO_CONTENTS) {
        return <span><PlaylistAdd/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showAddPOContentsTaskSpinner(btnText) {
    if (btnText === ADD_PO_CONTENTS_TASK) {
        return <span><PlaylistAdd/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default withStyles(useStyles)(POContentsAdder);
