import React, {Component} from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import ResourceAPIs from "../utils/ResourceAPI";
import {getLabel, getName} from "../utils/MiscellaniousUtils";

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 455,
        height: 455,
    },
});

class UnbilledWholesaleOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            results: [],
            clients: [],
            stores: [],
        };
    }

    get = () => {
        new ResourceAPIs().getUnbilledWholesaleOrders()
            .then(res => {
                const result = res.data;
                this.setState({
                    result: result,
                    isLoaded: true,
                })
            })
            .catch(error => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            );
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return {value: store.id, label: store.id + " - " + store.name, name: store.name}
                    });
                    this.setState({
                        stores: storesIds,
                    });
                    this.get();
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return {value: client.id, label: client.id + " - " + client.name, name: client.name}
            });
            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        const {classes} = this.props;

        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <h5>Unbilled Wholesale Orders</h5>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{width: '30%'}}>Client</TableCell>
                                        <TableCell align="center" style={{width: '15%'}}>WO ID</TableCell>
                                        <TableCell align="center" style={{width: '25%'}}>PO</TableCell>
                                        <TableCell align="center" style={{width: '30%'}}>Store</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.result.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align="left">
                                                    <a href={"/clients/" + row.clientId} target="_blank">
                                                        {row.clientId} - {getName(this.state.clients, row.clientId)}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <a href={"/wholesale-order/" + row.id} target="_blank">
                                                        {row.id}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="left">{row.purchaseOrder}</TableCell>
                                                <TableCell align="left">
                                                    {getLabel(this.state.stores, row.storeId)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            );
        }
    }
}

export default withStyles(useStyles)(UnbilledWholesaleOrders);
