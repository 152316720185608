import React, {Component} from "react";
import {format2NiceDate} from "../../utils/DateUtils";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import {Clear, Delete, Save} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {alertError, getIdFromUrl, handleErr} from "../../utils/MiscellaniousUtils";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import ResourceAPIs from "../../utils/ResourceAPI";
import BarcodeGenerator from "../../listings/BarcodeGenerator";

const MySwal = withReactContent(Swal);

const barcodeBadge = {
    width: 50,
};

class PullRequestContents extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            locationId: this.props.locationId,
            contents: [],
            isLoaded: false,
            deleteBtnText: "Delete",
            isProcessing: false,
        };

        this.onChangeQtyDesired = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].qtyDesired = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSku = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].sku = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInvoice = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].invoice = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = false;
            stateCopy.contents[index].qtyDesired = this.state.contents[index].untouchedQtyDesired;
            stateCopy.contents[index].sku = this.state.contents[index].untouchedSku;
            stateCopy.contents[index].invoice = this.state.contents[index].untouchedInvoice;
            this.setState(stateCopy);
        };
    }

    getPullRequestContents = () => {
        new ResourceAPIs().getPRContentsById(getIdFromUrl())
            .then(res => {
                    const result = res.data
                    this.setState({
                        contents: this.addUntouchedQty(result),
                        isLoaded: true,
                    })
                })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            );
    };

    addUntouchedQty = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedSku = arr[i].sku;
            arr[i].untouchedQtyDesired = arr[i].qtyDesired;
            arr[i].untouchedInvoice = arr[i].invoice;
        }
        return arr;
    };

    saveQty = (index) => {
        this.setState({
            isProcessing: true,
        });
        let content = this.state.contents[index];
        let id = parseInt(content.id, 10);
        let sku = content.sku;
        let invoice = content.invoice;
        let qtyDesired = parseInt(content.qtyDesired, 10);
        if (id > 0 && qtyDesired >= 0) {
            new ResourceAPIs().updatePullRequestContent(id, sku, qtyDesired, invoice)
                .then(res => {
                        console.log(res);
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.isProcessing = false;
                        stateCopy.contents[index].edited = false;
                        stateCopy.contents[index].untouchedQtyDesired = this.state.contents[index].qtyDesired;
                        stateCopy.contents[index].untouchedSku = this.state.contents[index].sku;
                        stateCopy.contents[index].untouchedInvoice = this.state.contents[index].invoice;
                        this.setState(stateCopy);
                    })
                .catch(error => {
                        console.log(error);
                        this.setState({
                            isProcessing: false,
                        });
                        handleErr(error);
                    });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        } else {
            alertError("qty desired should be greater than or equal to 0");
        }
        this.setState({
            saveQtyBtnText: "Save",
            isProcessing: false,
        });
    };

    deleteContent = (index) => {
        let contentId = this.state.contents[index].id;
        MySwal.fire({
            title: 'You want to Delete Content with ID ' + contentId + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                // Delete happens here
                new ResourceAPIs().deletePullRequestContent(contentId)
                    .then(res => {
                            MySwal.fire(
                                'Deleted!',
                                'Content ' + contentId + ' has been deleted.',
                                'success'
                            );
                            this.getPullRequestContents();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                deleteContentBtnText: "Delete All Contents",
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        })
    };

    componentDidMount() {
        this.getPullRequestContents();
    }

    componentWillReceiveProps({someProp}) {
        // console.log(this.props);
        if (this.props.isLoad) {
            this.getPullRequestContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">Pull Request Contents</Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{width: "5%"}}>Content ID</TableCell>
                                            <TableCell align="center" style={{width: "22%"}}>SKU</TableCell>
                                            <TableCell align="center" style={{width: "8%"}}>Qty Desired</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Qty Pulled</TableCell>
                                            <TableCell align="center" style={{width: "25%"}}>Barcode Fields</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>Product Name</TableCell>
                                            <TableCell align="center" style={{width: "10%"}}>Invoice</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Created At</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Updated At</TableCell>
                                            <TableCell style={{width: "10%"}}>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="right">{row.id}</TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={{width: 300,}}
                                                                  value={row.sku}
                                                                  onChange={(e) => this.onChangeSku(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="number" style={{width: 100,}}
                                                                  value={row.qtyDesired}
                                                                  onChange={(e) => this.onChangeQtyDesired(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">{row.qtyPulled}</TableCell>
                                                <TableCell align="left">
                                                    <Badge variant="secondary" style={barcodeBadge}>ASIN</Badge> {row.asin}
                                                    <BarcodeGenerator value={row.asin} SKU={row.sku} title={row.productName} width={1} />
                                                    <br/>
                                                    <Badge variant="primary" style={barcodeBadge}>FNSKU</Badge> {row.fnsku}
                                                    <BarcodeGenerator value={row.fnsku} SKU={row.sku} title={row.productName} width={1} />
                                                    <br/>
                                                    <Badge variant="success" style={barcodeBadge}>UPC</Badge> {row.upc}
                                                    <BarcodeGenerator value={row.upc} SKU={row.sku} title={row.productName} width={1} />
                                                    <br/>
                                                    <Badge variant="info" style={barcodeBadge}>Co UPC</Badge> {row.componentUpc}
                                                    <BarcodeGenerator value={row.componentUpc} SKU={row.sku} title={row.productName} width={1} />
                                                </TableCell>
                                                <TableCell align="left">{row.productName}</TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={{width: 150,}}
                                                                  value={row.invoice}
                                                                  onChange={(e) => this.onChangeInvoice(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.createdAt)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton color="primary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.saveQty(index)}>
                                                        <Save/>
                                                    </IconButton>
                                                    <IconButton color="default" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.cancelSaveQty(index)}>
                                                        <Clear/>
                                                    </IconButton>
                                                    <IconButton color="secondary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || row.edited}
                                                                onClick={() => this.deleteContent(index)}>
                                                        <Delete/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default PullRequestContents;
