import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import fileDownload from 'js-file-download';
import {EXPORT, EXPORTING} from '../constants';
import {getFormattedDate} from "../orders/OrdersExporter";
import {handleErr, showExportSpinner} from "../utils/MiscellaniousUtils";
import ResourceAPI from "../utils/ResourceAPI";


const exportBtn = {
    marginTop: '25px',
    marginBottom: '35px',
    marginLeft: '15px',
};

class ListingsExporter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            exportBtnText: EXPORT,
            err: "",
        };
    }

    onClickHandler = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPI().exportListings().then(response => {
                this.setState({
                    exportBtnText: EXPORT,
                    isProcessing: false,
                    err: "",
                });
                const dd = getFormattedDate(new Date());
                fileDownload(response.data, "listings-export-" + dd + ".csv");
            }).catch(error => {
                this.setState({
                    exportBtnText: EXPORT,
                    isProcessing: false,
                    err: error,
                });
                handleErr(error);
            });
    };

    render() {
        return (
            <div>
                <h4>Listings Exporter</h4>
                <Button style={exportBtn} variant="success" onClick={this.onClickHandler}
                        disabled={this.state.isProcessing}>
                    {showExportSpinner(this.state.exportBtnText)}
                </Button>
                <p>{this.state.err}</p>
            </div>
        );
    }
}

export default ListingsExporter;
