import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
    alertSuccess, 
    showSpinner, 
    handleFileSizeExceedsError,
    handleErr,
} from "../utils/MiscellaniousUtils";
import {IMPORT, IMPORTING, MAX_FILE_SIZE, MAX_FILE_SIZE_STRING} from "../constants";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {ExpandMore} from "@material-ui/icons";
import ResourceAPIs from "../utils/ResourceAPI";

class SplitContentsImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importStatus: "",
        };
    }

    onChangeHandler = event => {
        if( event.target.files[0] !== undefined){
            if(event.target.files[0].size > MAX_FILE_SIZE){
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                })
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                })
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true,
            });
            new ResourceAPIs().importSplitContents(this.getSplitId(), data)
                .then(res => {
                        this.props.setIsSplitContentsImported(true);
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                        });
                        this.clearFrom();
                        alertSuccess(res.data);
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                        });
                    }).then(
                        this.props.setIsSplitContentsImported(false)
                    );
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    getSplitId = () => {
        let url = window.location.href;
        return url.substring(url.lastIndexOf('/') + 1);
    };

    render() {
        return (
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <span style={{color: '#32a2fe'}}>Split Contents Importer</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row>           
                            <div>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Select File</Form.Label>
                                    <br/>
                                    <input type="file" name="file" onChange={this.onChangeHandler}/>
                                    <Form.Text className="text-muted">
                                        {this.state.fileErr}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Row>
                                        <Col>
                                            <Button variant="success" onClick={this.onClickHandler}
                                                    disabled={this.state.isProcessing}>
                                                {showSpinner(this.state.importBtnText)}
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.importStatus}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                        </Row>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

export default SplitContentsImporter;
