import React, { Component } from "react";
import { Default, Mobile } from "../../utils/MediaUtils";
import { Col, Row } from "react-bootstrap";
import UpcPullsSearch from "./UpcPullsSearch";

class UpcPulls extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Mobile>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            <UpcPullsSearch />
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Mobile >
                <Default>
                    <UpcPullsSearch />
                </Default>
            </div >
        );
    }
}

export default UpcPulls;
