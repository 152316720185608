import React, { Component } from 'react';
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClientListings from "./ClientListings";
import ClientWHLocations from "./ClientWHLocations";
import ClientWHContents from "./ClientWHContents";
import ClientOPRContents from "./ClientOPRContents";
import ClientPRContents from './ClientPRContents';
import ClientDetails from "./ClientDetails";

class Client extends Component {
    constructor(props) {
        super(props);
    }
    render() { 
        return ( 
            <div style={{marginLeft: '1%', marginRight: '1%'}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                    <Row>
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="details">Client Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="listings">Listings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="wh-locations">Warehouse Locations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="wh-contents">Warehouse Contents</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="order-pr-contents">Order Request Contents</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="pull-request-contents">Pull Request Contents</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content>
                                <Tab.Pane eventKey="details">
                                    <br/>
                                    <ClientDetails/>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="listings">
                                    <br/>
                                    <ClientListings/>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="wh-locations">
                                    <br/>
                                    <ClientWHLocations/>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="wh-contents">
                                    <br/>
                                    <ClientWHContents/>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="order-pr-contents">
                                    <br/>
                                    <ClientOPRContents />
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="pull-request-contents">
                                    <br/>
                                    <ClientPRContents/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}
 
export default Client;