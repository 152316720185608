import React, {Component} from "react";
import SplitsSearch from "./SplitsSearch";

class Splits extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <SplitsSearch id={this.props.id}/> 
            </div>
        );
    }
}

export default Splits;
