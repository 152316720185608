import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Add, Clear, Save, SkipNext, SkipPrevious} from "@material-ui/icons";
import Select from "react-select";
import {
    alertError, 
    handleErr, 
    showExportSpinner, 
    showFilterSpinner,
    createTypeOption, 
    getLabel
} from "../../utils/MiscellaniousUtils";
import {FILTER} from "../../constants";
import {SAVE} from "../../constants";
import {FILTERING} from "../../constants";
import {SAVING} from "../../constants";
import {strOptions} from "../../constants";
import {eqOptions} from "../../constants";
import {intOptions} from "../../constants";
import {EXPORT} from "../../constants";
import {getFormattedDate} from "../../orders/OrdersExporter";
import {EXPORTING} from "../../constants";
import fileDownload from "js-file-download";
import ResourceAPIs from "../../utils/ResourceAPI";
import { Default, Mobile } from "../../utils/MediaUtils";
import Badge from "react-bootstrap/Badge";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const qtyInput = {
    width: 60,
};

const locationNameInput = {
    width: 150,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const qtySaveBtn = {
    fontSize: 13,
    height: 30,
    padding: '0px 3px 0px 3px',
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class LocationsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            exportBtnText: EXPORT,
            clients: [],
        };

        this.onChangeWarehouseIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.warehouseId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeLocationIdValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationId.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeLocationNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);

            this.handleFilter();
        };

        this.onChangeQty = (event, index) => {
            // console.log(row);
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].locationSize = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeLocationName = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].locationName = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = false;
            stateCopy.results[index].locationSize = this.state.results[index].untouchedQty;
            stateCopy.results[index].locationName = this.state.results[index].untouchedLocationName;
            this.setState(stateCopy);
        };
    }

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    } 

    componentDidMount() {
        this.getClients();
    }

    onChangeWarehouseIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouseId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeLocationIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeLocationNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationName.condition = selectedValue;
        this.setState(stateCopy);
    };


    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchWhLocations();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchWhLocations();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchWhLocations();
    };

    addUntouchedQty = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedQty = arr[i].locationSize;
            arr[i].untouchedLocationName = arr[i].locationName;
        }
        return arr;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchWhLocations() {
        if (this.validateInputs()) {
            this.setState({
                filterBtnText: FILTERING,
                isProcessing: true,
            });

            new ResourceAPIs().getSearchWHLocation(this.state.filters)
                .then(res => {
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: this.addUntouchedQty(res.data),
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: error,
                        });
                    });
        } else {
            console.log("invalid inputs");
        }
    }

    saveQty = (index) => {
        this.setState({
            saveQtyBtnText: SAVING,
            isProcessing: true,
        });
        let content = this.state.results[index];
        console.log(content);
        let id = parseInt(content.id, 10);
        let locationSize = content.locationSize;
        let locationName = content.locationName;
        let clientId = parseInt(content.clientId, 10);
        let warehouseId = parseInt(content.warehouseId,10);

        if (id > 0 && clientId > 0 && warehouseId > 0) {
            new ResourceAPIs().updateWHLocationByQuery(id, locationSize, locationName, clientId, warehouseId, this.state.filters)
                .then(res => {
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.saveQtyBtnText = SAVE;
                        stateCopy.isProcessing = false;
                        stateCopy.results[index].edited = false;
                        stateCopy.results[index].untouchedQty = this.state.results[index].locationSize;
                        stateCopy.results[index].untouchedLocationName = this.state.results[index].locationName;
                        this.setState(stateCopy);
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            saveQtyBtnText: SAVE,
                            isProcessing: false,
                        });
                    });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        }

        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    validateInputs = () => {
        // validate warehouse
        if (parseInt(this.state.filters.warehouseId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = parseInt(this.state.filters.warehouseId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid warehouse ID"
            });
            return false;
        }

        // validate location id
        if (parseInt(this.state.filters.locationId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationId.value = parseInt(this.state.filters.locationId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid location ID"
            });
            return false;
        }

        return true;
    };

    handleAddNew = () => {
        new ResourceAPIs().addNewWHLocation()
            .then(res => {
                    window.open("/wh-locations/" + res.data);
                })
            .catch(error => {
                    handleErr(error);
                });
    };

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().getSearchExportWHLocations(this.state.filters)
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "warehouse-locations-search-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Label style={filterLabel}>Location Name</Form.Label>
                                        <Row>
                                            <Col xs={4} style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeLocationNameFilter}/>
                                            </Col>
                                            <Col xs={8} style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              value={this.state.filters.locationName.value}
                                                              onChange={this.onChangeLocationNameValue}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Location ID</Form.Label>
                                        <Row>
                                            <Col xs={4} style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeLocationIdFilter}/>
                                            </Col>
                                            <Col xs={8} style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeLocationIdValue}
                                                              value={this.state.filters.locationId.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Label style={filterLabel}>Warehouse ID</Form.Label>
                                        <Row>
                                            <Col xs={4} style={dropdownCol}>
                                                <WHFilterSelect options={intOptions}
                                                                handleOnChange={this.onChangeWarehouseIdFilter}/>
                                            </Col>
                                            <Col xs={8} style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeWarehouseIdValue}
                                                              value={this.state.filters.warehouseId.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        {this.props.id === undefined && (
                                            <div>
                                            <Form.Label style={filterLabel}>Client ID</Form.Label>
                                            <Row>
                                                <Col xs={4} style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col xs={8} style={inputCol}>
                                                        <Select
                                                            value={createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                            onKeyDown={this.handleFilterOnEnter}
                                                        />
                                                </Col>
                                            </Row>
                                            </div>
                                        )}
                                        {this.props.id !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col xs={4} style={dropdownCol}>
                                                        <WHFilterSelect options={eqOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col xs={8} style={inputCol}>
                                                        <Form.Control size="sm" type="number"
                                                                      value={this.props.id}
                                                                      onChange={this.onChangeClientIdValue}
                                                                      onKeyDown={this.handleFilterOnEnter}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Col>
                                    <Mobile>
                                        <Col xs={12}>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Form.Text className="text-muted">
                                                    {this.state.errMsg}
                                                </Form.Text>
                                            </Row>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                    <SkipPrevious />
                                                </IconButton>
                                                <span style={resultsDescStyle}>{this.state.results.length} results
                                                    (Page {(this.state.filters.from / 100) + 1})</span>
                                                <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                    <SkipNext />
                                                </IconButton>
                                            </Row>
                                            <Row align="center" style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                    {showFilterSpinner(this.state.filterBtnText)}
                                                </Button>
                                                <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                            </Row>
                                        </Col>
                                        <Col xs={12} style={{ marginTop: 25 }}>
                                            <Row align="center" style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="primary" onClick={this.handleAddNew} 
                                                style={filterBtn}>
                                                    <Add /> Add New</Button>
                                                <Button variant="warning" onClick={this.onClickExport}
                                                    disabled={this.state.isProcessing}
                                                    style={{ width: '120px' }}>
                                                    {showExportSpinner(this.state.exportBtnText)}
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Mobile>
                                    <Default>
                                        <Col xs={2}>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Form.Text className="text-muted">
                                                    {this.state.errMsg}
                                                </Form.Text>
                                            </Row>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                    <SkipPrevious />
                                                </IconButton>
                                                <span style={resultsDescStyle}>{this.state.results.length} results
                                                    (Page {(this.state.filters.from / 100) + 1})</span>
                                                <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                    <SkipNext />
                                                </IconButton>
                                            </Row>
                                            <Row align="center" style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                    {showFilterSpinner(this.state.filterBtnText)}
                                                </Button>
                                                <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                            </Row>
                                        </Col>
                                        <Col xs={2}>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="primary" onClick={this.handleAddNew}
                                                    style={{ width: '100px' }}>
                                                    <Add /> Add New</Button>
                                            </Row>
                                            <br />
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="warning" onClick={this.onClickExport}
                                                    disabled={this.state.isProcessing}
                                                    style={{ width: '100px' }}>
                                                    {showExportSpinner(this.state.exportBtnText)}
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Default>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Mobile>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">ID</TableCell>
                                                <TableCell align="right">Location Name / Size</TableCell>
                                                <TableCell>Controls</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell align="left">
                                                        <b><a href={"/wh-locations/" + row.id} target="_blank">{row.id}</a></b>
                                                        <br />
                                                        <Badge variant="primary" style={{ width: 50 }}>Client</Badge> {row.clientId}<br />
                                                        <Badge variant="secondary" style={{ width: 50 }}>WH</Badge> {row.warehouseId}<br />
                                                        <Badge variant="warning" style={{ width: 50 }}>Qty</Badge> {row.qtyInLocation}<br />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="primary" style={{ width: 50 }}>Name</Badge>
                                                        <Form.Control size="sm" type="text" 
                                                            style={{width: 120, fontSize: 12}}
                                                            value={row.locationName}
                                                            onChange={(e) => this.onChangeLocationName(e, index)} />
                                                        <Badge variant="secondary" style={{ width: 50 }}>Size</Badge>
                                                        <Form.Control size="sm" type="text" 
                                                            style={{ width: 120, fontSize: 12 }}
                                                            value={row.locationSize}
                                                            onChange={(e) => this.onChangeQty(e, index)} />
                                                        <i style={{ fontSize: 9 }}>Created: {format2NiceDate(row.createdAt)}</i>
                                                        <br/>
                                                        <i style={{ fontSize: 9 }}>Updated: {format2NiceDate(row.updatedAt)}</i>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton color="primary" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            onClick={() => this.saveQty(index)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton color="default" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            onClick={() => this.cancelSaveQty(index)}>
                                                            <Clear />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Mobile>
                    <Default>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">Location ID</TableCell>
                                                <TableCell align="right">Location Name</TableCell>
                                                <TableCell align="right">Qty In Location</TableCell>
                                                <TableCell align="right">Warehouse ID</TableCell>
                                                <TableCell align="right">Client ID</TableCell>
                                                <TableCell align="right">Location Size</TableCell>
                                                <TableCell align="right">Created At</TableCell>
                                                <TableCell align="right">Updated At</TableCell>
                                                <TableCell>Controls</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <a href={"/wh-locations/" + row.id} target="_blank">{row.id}</a>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Form.Control size="sm" type="text" style={locationNameInput}
                                                            value={row.locationName}
                                                            onChange={(e) => this.onChangeLocationName(e, index)} />
                                                    </TableCell>
                                                    <TableCell align="right">{row.qtyInLocation}</TableCell>
                                                    <TableCell align="right">{row.warehouseId}</TableCell>
                                                    <TableCell align="right">{row.clientId}</TableCell>
                                                    <TableCell align="right">
                                                        <Form.Control size="sm" type="text" style={qtyInput}
                                                            value={row.locationSize}
                                                            onChange={(e) => this.onChangeQty(e, index)} />
                                                    </TableCell>
                                                    <TableCell
                                                        align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                    <TableCell align="right">
                                                        {format2NiceDate(row.updatedAt)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant="success"
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            style={{
                                                                fontSize: 13,
                                                                height: 30,
                                                                padding: '0px 3px 0px 3px',
                                                                marginRight: 5
                                                            }}
                                                            onClick={() => this.saveQty(index)}>
                                                            <Save /> {this.state.saveQtyBtnText}
                                                        </Button>
                                                        <Button variant="secondary"
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            style={qtySaveBtn}
                                                            onClick={() => this.cancelSaveQty(index)}>
                                                            <Clear /> Clear
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Default>
                </Row>
            </div>
        );
    }
}

export default LocationsSearch;
