import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Add, SkipNext, SkipPrevious} from "@material-ui/icons";
import {eqOptions, FILTER, FILTERING, intOptions, strOptions} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";
import {createTypeOption, getLabel, handleErr, showFilterSpinner} from "../../utils/MiscellaniousUtils";
import {Default, Mobile} from "../../utils/MediaUtils";
import Badge from "react-bootstrap/Badge";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class UpcPullsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                status: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                warehouseId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            clients: [],
            warehouses: [],
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeClientIdValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeWarehouseIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = selected.value;
            stateCopy.filters.warehouseId.label = getLabel(this.state.warehouses, selected.value)
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeStatus = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].status = event.target.value;
            this.setState(stateCopy);
        };
    }

    componentDidMount() {
        this.getWarehouses();
    }

    getWarehouses = () => {
        new ResourceAPIs().getAllWarehouses()
            .then(res => {
                    const warehouses = res.data.map(warehouse => {
                        return {
                            value: warehouse.id,
                            label: warehouse.name !== null
                                ? warehouse.id + " - " + warehouse.name
                                : warehouse.id + " - ",
                            name: warehouse.name
                        }
                    });
                    this.setState({
                        warehouses: warehouses,
                    });
                    this.getClients();
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return {
                    value: client.id,
                    label: client.id + " - " + client.name,
                    name: client.name
                }
            });
            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    onChangeWarehouseIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };


    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchUpcPulls();
    };

    handleFilterOnEnter = (event) => {
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchUpcPulls();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchUpcPulls();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                status: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                warehouseId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchUpcPulls() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchUpcPulls(this.state.filters)
            .then(res => {
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: res.data,
                });
            })
            .catch(error => {
                handleErr(error);
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: error,
                });
            });
    }

    validateInputs = () => {
        // validate client ID
        if (parseInt(this.state.filters.clientId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = parseInt(this.state.filters.clientId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid client ID"
            });
            return false;
        }

        // validate warehouse ID
        if (parseInt(this.state.filters.warehouseId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = parseInt(this.state.filters.warehouseId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid warehouse ID"
            });
            return false;
        }

        return true;
    };

    handleAddNew = () => {
        new ResourceAPIs().addNewUpcPull()
            .then(res => {
                window.open("/upc-pull/" + res.data);
            })
            .catch(error => {
                handleErr(error);
            });
    };

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={6} lg={4}>
                                        {this.props.id === undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client</Form.Label>
                                                <Row>
                                                    <Col xs={4} style={dropdownCol}>
                                                        <WHFilterSelect options={intOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col xs={8} style={inputCol}>
                                                        <Select
                                                            value={createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                            onKeyDown={this.handleFilterOnEnter}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.props.id !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col xs={4} style={dropdownCol}>
                                                        <WHFilterSelect options={eqOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col xs={8} style={inputCol}>
                                                        <Form.Control size="sm" type="number"
                                                                      value={this.props.id}
                                                                      onChange={this.onChangeClientIdValue}
                                                                      onKeyDown={this.handleFilterOnEnter}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        <Form.Label style={filterLabel}>Warehouse</Form.Label>
                                        <Row>
                                            <Col xs={4} style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeWarehouseIdFilter}/>
                                            </Col>
                                            <Col xs={8} style={inputCol}>
                                                <Select
                                                    value={createTypeOption(this.state.filters.warehouseId.label)}
                                                    onChange={(e) => this.onChangeWarehouseIdValue(e)}
                                                    options={this.state.warehouses}
                                                    styles={this.customStyles}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col xs={4} style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col xs={8} style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              value={this.state.filters.status.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Mobile>
                                        <Col xs={12}>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Form.Text className="text-muted">
                                                    {this.state.errMsg}
                                                </Form.Text>
                                            </Row>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                    <SkipPrevious />
                                                </IconButton>
                                                <span style={resultsDescStyle}>{this.state.results.length} results
                                                    (Page {(this.state.filters.from / 100) + 1})</span>
                                                <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                    <SkipNext />
                                                </IconButton>
                                            </Row>
                                            <Row align="center" style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                    {showFilterSpinner(this.state.filterBtnText)}
                                                </Button>
                                                <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                            </Row>
                                        </Col>
                                        <Col xs={12} style={{ marginTop: 25 }}>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="primary" onClick={this.handleAddNew}><Add /> Add
                                                    New</Button>
                                            </Row>
                                        </Col>
                                    </Mobile>
                                    <Default>
                                        <Col xs={2}>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <Form.Text className="text-muted">
                                                    {this.state.errMsg}
                                                </Form.Text>
                                            </Row>
                                            <Row style={{ display: 'block', textAlign: 'center' }}>
                                                <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                    <SkipPrevious />
                                                </IconButton>
                                                <span style={resultsDescStyle}>{this.state.results.length} results
                                                    (Page {(this.state.filters.from / 100) + 1})</span>
                                                <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                    <SkipNext />
                                                </IconButton>
                                            </Row>
                                            <Row align="center" style={{ display: 'block', textAlign: 'center' }}>
                                                <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                    {showFilterSpinner(this.state.filterBtnText)}
                                                </Button>
                                                <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                            </Row>
                                        </Col>
                                        <Col xs={2}>
                                            <Row style={{ display: 'block', textAlign: 'center', marginTop: 20 }}>
                                                <p style={{ fontSize: 12 }}>Add New Pull Request</p>
                                                <Button variant="primary" onClick={this.handleAddNew}><Add /> Add
                                                    New</Button>
                                            </Row>
                                        </Col>
                                    </Default>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Mobile>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">Client / Warehouse</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <a href={"/upc-pull/" + row.id} target="_blank">{row.id}</a>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="primary" style={{ width: 50 }}>Client</Badge> {getLabel(this.state.clients, row.clientId)}<br />
                                                        <Badge variant="secondary" style={{ width: 50 }}>WH</Badge> {getLabel(this.state.warehouses, row.warehouseId)}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.status}
                                                        <br />
                                                        <i style={{ fontSize: 9 }}>Created: {format2NiceDate(row.createdAt)}</i>
                                                        <br />
                                                        <i style={{ fontSize: 9 }}>Updated: {format2NiceDate(row.updatedAt)}</i>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Mobile>
                    <Default>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">Client</TableCell>
                                                <TableCell align="center">Warehouse</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="center">Created At</TableCell>
                                                <TableCell align="center">Updated At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <a href={"/upc-pull/" + row.id} target="_blank">{row.id}</a>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {getLabel(this.state.clients, row.clientId)}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {getLabel(this.state.warehouses, row.warehouseId)}
                                                    </TableCell>
                                                    <TableCell>{row.status}</TableCell>
                                                    <TableCell align="right">
                                                        {format2NiceDate(row.createdAt)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {format2NiceDate(row.updatedAt)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Default>
                </Row>
            </div>
        );
    }
}

export default UpcPullsSearch;
