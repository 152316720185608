import React, {Component} from "react";
import {
    IMPORT, 
    IMPORTING,
    IMPORT_TASK, 
    IMPORTING_TASK, 
    INTERNAL_SERVER_ERROR, 
    MAX_FILE_SIZE, 
    MAX_FILE_SIZE_STRING
} from "../../constants";
import {
    alertError,
    alertSuccess,
    getIdFromUrl,
    handleFileSizeExceedsError,
    showSpinner,
    showImportTaskSpinner
} from "../../utils/MiscellaniousUtils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ResourceAPIs from "../../utils/ResourceAPI";


class ContentsManualImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            barcodeFieldNo: this.props.parent.barcodeFieldNo,
            qtyFieldNo: this.props.parent.qtyFieldNo,
            hasHeaders: this.props.parent.hasHeaders,
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importTaskBtnText: IMPORT_TASK,
            importStatus: "",
        };

        this.onChangeBarcode = (event) => {
            let newState = Object.assign({}, this.state);
            newState.barcodeFieldNo = event.target.value;
            this.setState(newState);
        };

        this.onChangeQty = (event) => {
            let newState = Object.assign({}, this.state);
            newState.qtyFieldNo = event.target.value;
            this.setState(newState);
        };

        this.toggleCheckbox = (event) => {
            let newState = Object.assign({}, this.state);
            newState.hasHeaders = event.target.checked;
            this.setState(newState);
            console.log(this.state.hasHeaders);
        };
    }

    onChangeFile = event => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].size > MAX_FILE_SIZE) {
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                });
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                });
            }
        }
    };

    handleImport = (task) => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('barcodeFieldNo', this.state.barcodeFieldNo);
        data.append('qtyFieldNo', this.state.qtyFieldNo);
        data.append('hasHeaders', this.state.hasHeaders);
        data.append('feedId', getIdFromUrl());

        if (this.validateInputs()) {
            if (task === undefined || task === null) { // normal import
                this.setState({
                    importBtnText: IMPORTING,
                    isProcessing: true,
                });
                new ResourceAPIs().importIFContents(data)
                .then(res => {
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                        });
                        alertSuccess(res.data);
                        this.props.childLoad();
                    })
                .catch(error => {
                        let err = INTERNAL_SERVER_ERROR;
                        if (error.response) {
                            err = error.response.data;
                            alertError(err);
                        } else {
                            alertError(err + ": " + error.error + " (" + error.message + ")");
                        }
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                        });
                    });
            } else { // task import
                this.setState({
                    importTaskBtnText: IMPORTING_TASK,
                    isProcessing: true,
                });
                new ResourceAPIs().taskImportIFContents(data)
                .then(res => {
                        this.setState({
                            importTaskBtnText: IMPORT_TASK,
                            isProcessing: false,
                        });
                        alertSuccess(res.data);
                        this.props.childLoad();
                    })
                .catch(error => {
                        let err = INTERNAL_SERVER_ERROR;
                        if (error.response) {
                            err = error.response.data;
                            alertError(err);
                        } else {
                            alertError(err + ": " + error.error + " (" + error.message + ")");
                        }
                        this.setState({
                            importTaskBtnText: IMPORT_TASK,
                            isProcessing: false,
                        });
                    });
            }
            
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        }

        if (this.props.parent.modifierType === undefined) {
            return this.setValidationErr("Invalid modifier type");
        }

        // validate barcode field
        if (parseInt(this.state.barcodeFieldNo, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.barcodeFieldNo = parseInt(this.state.barcodeFieldNo, 10);
            this.setState(stateCopy);
        } else {
            return this.setValidationErr("Invalid Barcode Field");
        }

        // validate qty field
        if (parseInt(this.state.qtyFieldNo, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.qtyFieldNo = parseInt(this.state.qtyFieldNo, 10);
            this.setState(stateCopy);
        } else {
            return this.setValidationErr("Invalid Qty Field");
        }

        this.setState({
            fileErr: "",
        });
        return true;
    };

    setValidationErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.fileErr = err;
        this.setState(stateCopy);
        return false;
    };

    clearForm = () => {
        let newState = Object.assign({}, this.state);
        newState.barcodeFieldNo = "";
        newState.qtyFieldNo = "";
        newState.hasHeaders = false;
        newState.selectedFile = null;
        this.setState(newState);
    };

    render() {
        return (
            <div style={{width: '100%', marginLeft: '5%', marginRight: '5%'}}>
                <Form>
                    <Row xs={12}>
                        <Col xs={6}>
                            <h6>Column number in file</h6>
                            <Form.Group controlId="formBasicPassword">
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label>Barcode</Form.Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Form.Control type="number"
                                                      value={this.state.barcodeFieldNo}
                                                      onChange={this.onChangeBarcode}/>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label>Quantity</Form.Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Form.Control type="number"
                                                      value={this.state.qtyFieldNo}
                                                      onChange={this.onChangeQty}/>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Check type="checkbox" label="Ignore Headers (First Row)"
                                            checked={this.state.hasHeaders}
                                            onChange={this.toggleCheckbox}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Select File</Form.Label>
                                <br/>
                                <input type="file" name="file" onChange={this.onChangeFile}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Text style={{color: 'red'}}>
                            {this.state.fileErr}
                        </Form.Text>
                    </Row>
                    <br/>
                    <Row>
                        <Form.Group as={Col} controlId="formGridState">
                            <Row>
                                <Col>
                                    <Button variant="success" onClick={() => this.handleImport(null)}
                                            disabled={this.state.isProcessing}>
                                        {showSpinner(this.state.importBtnText)}
                                    </Button>
                                    <Button variant="warning" style={{marginLeft: '20px'}}
                                            onClick={() => this.handleImport(true)}
                                            disabled={this.state.isProcessing}>
                                        {showImportTaskSpinner(this.state.importTaskBtnText)}
                                    </Button>
                                </Col>
                                <Col>
                                    {this.state.importStatus}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default ContentsManualImporter;
