import React, {Component} from "react";
import {format2NiceDate} from "../utils/DateUtils";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import {getFormattedDate} from "../orders/OrdersExporter";
import {getIdFromUrl, handleErr, showExportSpinner} from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";
import {EXPORT, EXPORTING} from "../constants";
import fileDownload from "js-file-download";
import Button from "react-bootstrap/Button";

class SettlementReportContents extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            breport: this.props.breport,
            contents: [],
            isLoaded: false,
            isProcessing: false,
            exportBtnText: EXPORT,
        };
    }

    getContents = () => {
        new ResourceAPIs().getSettlementReportContents(getIdFromUrl()).then(response => {
                this.setState({
                    contents: this.addBReportData(response.data),
                    isLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    onExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().exportSettlementReportContents(getIdFromUrl()).then(response => {
            this.setState({
                exportBtnText: EXPORT,
                isProcessing: false,
                err: "",
            });
            const dd = getFormattedDate(new Date());
            fileDownload(response.data, "settlement-report-contents-export-" + dd + ".csv");
        }).catch(error => {
            this.setState({
                exportBtnText: EXPORT,
                isProcessing: false,
                err: error,
            });
            handleErr(error);
        });
    };

    addBReportData = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].businessReportId = this.state.breport.id;
            arr[i].clientId = this.state.breport.clientId;
            arr[i].storeName = this.state.breport.storeName;
            arr[i].startDate = this.state.breport.startDate;
            arr[i].endDate = this.state.breport.endDate;
        }
        return arr;
    };

    componentDidMount() {
        this.getContents();
    }

    componentWillReceiveProps({someProp}) {
        if (this.props.isLoad) {
            this.getContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">
                            <span style={{marginTop: 20}}>Settlement Report Contents</span>
                            <Button style={{float: "right"}} variant="success" onClick={this.onExport}
                                    disabled={this.state.isProcessing}>
                                {showExportSpinner(this.state.exportBtnText)}
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Content ID</TableCell>
                                            <TableCell align="center">SKU</TableCell>
                                            <TableCell align="center">Qty Purchased</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="right">{row.id}</TableCell>
                                                <TableCell align="left">{row.sku}</TableCell>
                                                <TableCell align="right">{row.qtyPurchased}</TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.createdAt)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default SettlementReportContents;
