import React, {Component} from "react";
import {CLIENT, NO_ACCESS_OPTION, userRoles} from "../../constants";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {format2NiceDate} from "../../utils/DateUtils";
import TableContainer from "@material-ui/core/TableContainer";
import Select from "react-select";
import {capitalizeFirstLetter} from "../../utils/TypeUtils";
import {alertError, handleError} from "../../utils/MiscellaniousUtils";
import {Clear, Info, Save} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Form from "react-bootstrap/Form";
import Tooltip from "@material-ui/core/Tooltip";
import TableFooter from "@material-ui/core/TableFooter";
import ResourceAPIs from "../../utils/ResourceAPI";


class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            isLoaded: false,
            isProcessing: false,
        };

        this.customStyles = {
            control: (base, state) => ({
                ...base,
                minHeight: '1px',
                height: '33px',
                'font-size': '13px',

            }),
            dropdownIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
        };
    }

    onChangeType = (index, selected) => {
        let newState = Object.assign({}, this.state);
        newState.users[index].edited = true;
        newState.users[index].type = selected.value;
        this.setState(newState);
    };

    onChangeClientId = (index, event) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.users[index].edited = true;
        stateCopy.users[index].clientId = event.target.value;
        this.setState(stateCopy);
    };

    createTypeOption = (type) => {
        if (type === null || type === NO_ACCESS_OPTION.value) {
            return NO_ACCESS_OPTION;
        } else {
            const label = capitalizeFirstLetter(type.toLowerCase());
            return {value: type, label: label};
        }
    };

    addUntouchedEditableValues = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedType = arr[i].type;
            arr[i].untouchedClientId = arr[i].clientId;
        }
        return arr;
    };

    getUsers = () => {
        new ResourceAPIs().getUsers().then(response => {
                let result = response.data;
                this.setState({
                    users: this.addUntouchedEditableValues(result),
                    isLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    updateRole = index => {
        this.setState({
            isProcessing: true,
        });
        let user = this.state.users[index];
        let id = parseInt(user.id, 10);
        let type = user.type;
        let clientId = user.clientId;

        // client id applies only for clients
        if (type !== CLIENT.value) {
            clientId = 0;
        }

        if (id > 0) {
            new ResourceAPIs().updateUser(id, type, clientId).then(response => {
                    let stateCopy = Object.assign({}, this.state);
                    stateCopy.isProcessing = false;
                    stateCopy.users[index].edited = false;
                    stateCopy.users[index].untouchedType = this.state.users[index].type;
                    stateCopy.users[index].untouchedClientId = this.state.users[index].clientId;
                    this.setState(stateCopy);
                }).catch(error => {
                    this.setState({
                        isProcessing: false,
                    });
                    handleError(error);
                });
        } else {
            alertError("invalid id: " + id);
        }
    };

    cancelupdateRole = (index) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.users[index].edited = false;
        stateCopy.users[index].type = this.state.users[index].untouchedType;
        stateCopy.users[index].clientId = this.state.users[index].untouchedClientId;
        this.setState(stateCopy);
    };

    componentDidMount() {
        this.getUsers();
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <h1>Users</h1>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{width: "10%"}}>Username</TableCell>
                                    <TableCell align="center" style={{width: "20%"}}>Email</TableCell>
                                    <TableCell align="center" style={{width: "15%"}}>Type</TableCell>
                                    <TableCell align="center" style={{width: "10%"}}>
                                        Client ID
                                        <Tooltip title="Client ID is only applicable for Client Users">
                                            <IconButton aria-label="info" size="small">
                                                <Info/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center" style={{width: "15%"}}>Created At</TableCell>
                                    <TableCell align="center" style={{width: "15%"}}>Updated At</TableCell>
                                    <TableCell align="center" style={{width: "15%"}}>Controls</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.users.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">{row.username}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                                            <Select
                                                value={this.createTypeOption(row.type)}
                                                onChange={(e) => this.onChangeType(index, e)}
                                                options={userRoles}
                                                styles={this.customStyles}
                                            />

                                        </TableCell>
                                        <TableCell align="left" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                                            {row.type === CLIENT.value && (
                                                <Form.Control
                                                    type="number" size="sm"
                                                    value={row.clientId}
                                                    onChange={(e) => this.onChangeClientId(index, e)}/>
                                            )}
                                        </TableCell>
                                        <TableCell align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                        <TableCell align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                        <TableCell style={{padding: "0px"}}>
                                            <IconButton color="primary" style={{padding: 6}}
                                                        disabled={this.state.isProcessing || !row.edited}
                                                        onClick={() => this.updateRole(index)}>
                                                <Save/>
                                            </IconButton>
                                            <IconButton color="default" style={{padding: 6}}
                                                        disabled={this.state.isProcessing || !row.edited}
                                                        onClick={() => this.cancelupdateRole(index)}>
                                                <Clear/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter style={{height: 30,}}>
                                <center>
                                    <i>
                                        <br/>
                                        Admins: {this.state.users.length}
                                        <br/>
                                        Clients: {this.state.users.length}
                                        <br/>
                                        All: {this.state.users.length}
                                        <br/>
                                    </i>
                                </center>
                                <br/>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            );
        }
    }
}

export default Users;
