import React, {Component} from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import OrderPullRequestsSearch from "./OrderPullRequestsSearch";
import OrderPullRequestContentsSearch from "./OrderPullRequestContentsSearch";
import {BULK_ACTIONS} from "../../constants";

class OrderPullRequests extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="search">Search Order Pull Requests</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="searchPRContents">Search Order Pull Request Contents</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="search">
                            <br/>
                            <OrderPullRequestsSearch id={this.props.clientId}/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                        <Tab.Pane eventKey="searchPRContents">
                            <br/>
                            <OrderPullRequestContentsSearch id={this.props.clientId}/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Pane eventKey="importAndExport">
                    </Tab.Pane>
                </Tab.Container>
            </div>
        );
    }
}

export default OrderPullRequests;
