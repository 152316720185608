import React from "react";
import TableRow from "@material-ui/core/TableRow";
import {Box, Collapse, TableCell} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Clear, Delete, Save} from "@material-ui/icons";
import Highlight from "react-highlight.js";
import {format2NiceDate} from "../../utils/DateUtils";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const barcodeBadge = {
    width: 50,
};

const barcodeBadgeBasic = {
    width: 55,
};

const barcodeBadgePkg = {
    width: 65,
};

export function OPRContentsTableRow(props) {
    const {row, index, isProcessing, onChangeSku, onChangeInvoice, onChangeQtyDesired, saveQty, cancelSaveQty,
        deleteContent, getStoreName} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell align="right">
                    {row.id} <br/>
                    {row.listingType && row.listingType === "complex" &&
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                    }
                </TableCell>
                <TableCell align="left">
                    <Badge variant="secondary" style={barcodeBadgeBasic}>SKU</Badge>
                    <Form.Control size="sm" type="text" style={{width: 300,}}
                                  value={row.sku}
                                  onChange={(e) => onChangeSku(e, index)}/>
                    <Badge variant="primary" style={barcodeBadgeBasic}>Invoice</Badge>
                    <Form.Control size="sm" type="text" style={{width: 300,}}
                                  value={row.invoice}
                                  onChange={(e) => onChangeInvoice(e, index)}/>
                    <Badge variant="success" style={barcodeBadgeBasic}>
                        Store</Badge> {row.storeId + ' - ' + getStoreName(row.storeId)}
                    <br/>
                    <Badge variant="info" style={barcodeBadgeBasic}>
                        Order No</Badge> {row.orderNumber}
                </TableCell>
                <TableCell align="right">{row.unitCount}</TableCell>
                <TableCell align="right">{row.unitsOrdered}</TableCell>
                <TableCell align="right">
                    <Form.Control size="sm" type="number" style={{width: 100,}}
                                  value={row.qtyDesired}
                                  onChange={(e) => onChangeQtyDesired(e, index)}/>
                </TableCell>
                <TableCell align="right">{row.qtyPulled}</TableCell>
                <TableCell align="left">
                    <Badge variant="secondary"
                           style={barcodeBadge}>ASIN</Badge> {row.asin}<br/>
                    <Badge variant="primary"
                           style={barcodeBadge}>FNSKU</Badge> {row.fnsku}<br/>
                    <Badge variant="success"
                           style={barcodeBadge}>UPC</Badge> {row.upc}<br/>
                    <Badge variant="info"
                           style={barcodeBadge}>Co UPC</Badge> {row.componentUpc}
                </TableCell>
                <TableCell align="left">
                    <Badge variant="secondary"
                           style={barcodeBadgePkg}>Weight Lb</Badge> {row.packageWeightLb}<br/>
                    <Badge variant="primary"
                           style={barcodeBadgePkg}>Length In</Badge> {row.packageLengthIn}<br/>
                    <Badge variant="success"
                           style={barcodeBadgePkg}>Width In</Badge> {row.packageWidthIn}<br/>
                    <Badge variant="info"
                           style={barcodeBadgePkg}>Heigh In</Badge> {row.packageHeightIn}
                </TableCell>
                <TableCell align="left">{row.productName}</TableCell>
                <TableCell align="right">
                    {format2NiceDate(row.createdAt)}
                </TableCell>
                <TableCell align="right">
                    {format2NiceDate(row.updatedAt)}
                </TableCell>
                <TableCell>
                    <IconButton color="primary" style={{padding: 6}}
                                disabled={isProcessing || !row.edited}
                                onClick={() => saveQty(index)}>
                        <Save/>
                    </IconButton>
                    <IconButton color="default" style={{padding: 6}}
                                disabled={isProcessing || !row.edited}
                                onClick={() => cancelSaveQty(index)}>
                        <Clear/>
                    </IconButton>
                    <IconButton color="secondary" style={{padding: 6}}
                                disabled={isProcessing || row.edited}
                                onClick={() => deleteContent(index)}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Highlight laguage="json">
                                {JSON.stringify(JSON.parse(row.compUpcPull), null, 4)}
                            </Highlight>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
