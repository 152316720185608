import React, {Component} from "react";
import axios from 'axios';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {Add, CallSplit, Clear, Delete, ExpandMore, Save, SkipNext, SkipPrevious} from "@material-ui/icons";
import {alertError, handleErr, handleError, showExportSpinner, showFilterSpinner, showDeleteSpinner} 
    from "../utils/MiscellaniousUtils";
import {
    eqOptions,
    EXPORT,
    EXPORTING,
    FILTER,
    FILTERING,
    HOST_NAME,
    intOptions,
    NO_ACCESS_OPTION,
    SAVE,
    SAVING,
    DELETE,
    DELETING,
    strOptions
} from "../constants";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ListingLastId from "./ListingLastId";
import Badge from "react-bootstrap/Badge";
import {Link} from "react-router-dom";
import AuthHeader from "../user/services/AuthHeader";
import BarcodeGenerator from './BarcodeGenerator';
import Select from "react-select";
import ResourceAPI from "../utils/ResourceAPI";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Checkbox from "@material-ui/core/Checkbox";

const MySwal = withReactContent(Swal);

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

const displayRow = {
    marginLeft: 12,
    width: '99%'
};

const barcodeBadge = {
    width: 50,
};


const UPDATE_QTY_IN_WH = "Update Qty in Warehouse";
const UNDEFINED = "Undefined";

class ListingsSearch extends Component {

    constructor(props) {
        super(props);

        this.customStyles = {
            control: (base, state) => ({
                ...base,
                minHeight: '1px',
                height: '33px',
                'font-size': '13px',

            }),
            dropdownIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
        };

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                supplier: {
                    condition: "eq",
                    value: "",
                },
                supplierGroup: {
                    condition: "eq",
                    value: "",
                },
                asin: {
                    condition: "eq",
                    value: "",
                },
                sku: {
                    condition: "eq",
                    value: "",
                },
                upc: {
                    condition: "eq",
                    value: "",
                },
                componentUpc: {
                    condition: "eq",
                    value: "",
                },
                barcode: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                fnsku: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            exportBtnText: EXPORT,
            deleteBtnText: DELETE,
            errMsg: "",
            results: [],
            selectedListings: [],
            saveQtyBtnText: SAVE,
            updateQtyInWHBtnText: UPDATE_QTY_IN_WH,
            storesIds: [],
            clients: [],
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeSkuValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.sku.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeAsinValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.asin.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeUpcValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.upc.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeComponentUpcValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.componentUpc.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeBarcodeValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.barcode.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = this.getStoreLabel(selected.value)
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = this.getClientLabel(selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.getStoreLabel = (value) => {
            let data = "";
            this.state.storesIds.forEach((store) => {
                if(store.value === value) {
                    data = store.label;
                }
            });
            return data;
        };

        this.getClientLabel = value => {
            let data = "";
            this.state.clients.forEach((client) => {
                if(client.value === value) {
                    data = client.label;
                }
            });
            return data;
        };

        this.onChangeSupplierValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.supplier.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSupplierGroupValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.supplierGroup.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeBuyingModelValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.buyingModel.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeFnskuValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.fnsku.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInventoryModelValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.inventoryModel.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeFulfilmentValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.fulfilment.value = event.target.value;
            this.setState(stateCopy);
        };


        this.onChangePullName = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].pullName = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStoreName = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].storeName = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangePlanId = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].planId = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatus = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].status = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInvoice = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].invoice = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = false;
            stateCopy.results[index].pullName = this.state.results[index].untouchedPullName;
            stateCopy.results[index].storeName = this.state.results[index].untouchedStoreName;
            stateCopy.results[index].planId = this.state.results[index].untouchedPlanId;
            stateCopy.results[index].status = this.state.results[index].untouchedStatus;
            stateCopy.results[index].invoice = this.state.results[index].untouchedInvoice;
            this.setState(stateCopy);
        };

        this.onChangeStatus = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].status = event.target.value;
            this.setState(stateCopy);
        };

    }

    componentDidMount() {
        if (this.props.barcode !== undefined) {
            let newState = Object.assign({}, this.state);
            newState.filters.barcode.value = this.props.barcode;
            this.setState(newState,() => this.handleFilter());
        }

        this.getStores();
        this.getClients();
    }

    getStores = () => {
        new ResourceAPI().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPI().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    getStoreName = (storeId) => {
        for (let store of this.state.storesIds) {
            if (store.value === storeId) {
                return store.name;
            }
        }
        return UNDEFINED;
    };

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSupplierFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.supplier.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSupplierGroupFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.supplierGroup.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSkuFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.sku.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeAsinFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.asin.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeUpcFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.upc.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeComponentUpcFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.componentUpc.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeBarcodeFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.barcode.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFnskuFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.fnsku.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeBuyingModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.buyingModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInventoryModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.inventoryModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFulfilmentFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.fulfilment.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchListings();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchListings();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchListings();
    };

    addUntouchedValues = (arr) => {
        for (const listing of arr) {
            listing.untouchedPullName = listing.pullName;
            listing.untouchedStoreName = this.getStoreName(listing.storeId);
            listing.untouchedPlanId = listing.planId;
            listing.untouchedStatus = listing.status;
            listing.untouchedInvoice = listing.invoice;
        }
        return arr;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                supplier: {
                    condition: "eq",
                    value: "",
                },
                supplierGroup: {
                    condition: "eq",
                    value: "",
                },
                asin: {
                    condition: "eq",
                    value: "",
                },
                sku: {
                    condition: "eq",
                    value: "",
                },
                upc: {
                    condition: "eq",
                    value: "",
                },
                componentUpc: {
                    condition: "eq",
                    value: "",
                },
                barcode: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                },
                fnsku: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    createTypeOption = (type) => {
        if (type === null || type === NO_ACCESS_OPTION.value) {
            return NO_ACCESS_OPTION;
        } else {
            return {value: type, label: type};
        }
    };

    handleSelectAllClick = (event) => {
        let listings = [];
        if (event.target.checked) {
            listings = this.state.results;
        }

        this.setState({
            selectedListings: listings
        });
    };

    handleSelectClick = (event, row, index) => {
        let selectedListings = this.state.selectedListings;
        let listings = this.state.results;
        if (event.target.checked) {
            selectedListings.push(row);
        } else {
            if (index > -1) {
                selectedListings.splice(index, 1);
            }
        }

        this.setState({
            selectedListings: selectedListings,
            results: listings
        });
    };

    searchListings() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });

        new ResourceAPI().getSearchListings(this.state.filters).then(response => {
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: this.addUntouchedValues(response.data),
                });
            }).catch(error => {
                handleErr(error);
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: error,
                });
            });
    }

    saveQty = (index) => {
        this.setState({
            saveQtyBtnText: SAVING,
            isProcessing: true,
        });
        let content = this.state.results[index];
        console.log(content);
        let id = parseInt(content.id, 10);
        let pullName = content.pullName;
        let storeName = content.storeName;
        let planId = content.planId;
        let status = content.status;
        let invoice = content.invoice;

        if (id > 0) {
            axios.post(HOST_NAME + "/update/pull-request?id=" + id
                + "&client-id=" + content.clientId
                + "&pull-name=" + pullName
                + "&store-name=" + storeName
                + "&plan-id=" + planId
                + "&status=" + status
                + "&warehouse=" + content.warehouse
                + "&invoice=" + invoice, this.state.filters, AuthHeader())
                .then(res => {
                        console.log(res);
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.saveQtyBtnText = SAVE;
                        stateCopy.isProcessing = false;
                        stateCopy.results[index].edited = false;
                        stateCopy.results[index].untouchedPullName = this.state.results[index].pullName;
                        stateCopy.results[index].untouchedStoreName = this.state.results[index].storeName;
                        stateCopy.results[index].untouchedPlanId = this.state.results[index].planId;
                        stateCopy.results[index].untouchedStatus = this.state.results[index].status;
                        stateCopy.results[index].untouchedInvoice = this.state.results[index].invoice;
                        this.setState(stateCopy);
                    },
                    (error) => {
                        console.log(error);
                        handleErr(error);
                        this.setState({
                            saveQtyBtnText: SAVE,
                            isProcessing: false,
                        });
                    });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        }

        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };


    handleAddNew = () => {
        new ResourceAPI().addListing().then(response => {
                window.open("/listings/" + response.data);
            }).catch(error => {
                handleErr(error);
            });
    };

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPI().getSearchExportListings(this.state.filters).then(response => {
                this.setState({
                    exportBtnText: EXPORT,
                    isProcessing: false,
                    err: "",
                });
                const dd = getFormattedDate(new Date());
                fileDownload(response.data, "listings-search-export-" + dd + ".csv");
            }).catch(error => {
                this.setState({
                    exportBtnText: EXPORT,
                    isProcessing: false,
                    err: error,
                });
                handleErr(error);
            });
    };

    saveListing = (index) => {
        this.setState({
            isProcessing: true,
        });
        let listing = this.state.results[index];
        let id = parseInt(listing.id, 10);

        if (id > 0) {
            new ResourceAPI().updateListingById(id, listing)
                .then(res => {
                    let stateCopy = Object.assign({}, this.state);
                    stateCopy.isProcessing = false;
                    stateCopy.results[index].edited = false;
                    stateCopy.results[index].untouchedStatus = this.state.results[index].status;
                    this.setState(stateCopy);
                }, (error) => {
                    this.setState({
                        isProcessing: false,
                    });
                    handleError(error);
                });
        } else {
            alertError("invalid id: " + id);
        }
        this.setState({
            isProcessing: false,
        });
    };

    cloneListing = (index) => {
        this.setState({
            isProcessing: true,
        });
        let listing = this.state.results[index];
        let id = parseInt(listing.id, 10);

        if (id > 0) {
            new ResourceAPI().cloneListingById(id)
                .then(res => {
                    let stateCopy = Object.assign({}, this.state);
                    stateCopy.isProcessing = false;
                    stateCopy.results[index].edited = false;
                    stateCopy.results[index].untouchedStatus = this.state.results[index].status;
                    this.setState(stateCopy);
                    window.open("/listings/" + res.data);
                }, (error) => {
                    this.setState({
                        isProcessing: false,
                    });
                    handleError(error);
                });
        } else {
            alertError("invalid id: " + id);
        }
        this.setState({
            isProcessing: false,
        });
    }

    cancelSaveListing = (index) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.results[index].edited = false;
        stateCopy.results[index].status = this.state.results[index].untouchedStatus;
        this.setState(stateCopy);
    };

    deleteListing = (index) => {
        let id = this.state.results[index].id;
        MySwal.fire({
            title: 'You want to Delete Listing with ID ' + id + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPI().deleteListingById(id).then(response => {
                    MySwal.fire(
                        'Deleted!',
                        'Listing ' + id + ' has been deleted.',
                        'success'
                    );
                    this.searchListings();
                    this.getStores();
                    this.getClients();
                }).catch(error => {
                    handleErr(error);
                    this.setState({
                        isProcessing: false,
                        results: error,
                    });
                });
            }
        });
    };

    deleteSelectedListings = () => {
        MySwal.fire({
            title: 'You want to Delete selected Listings?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete!'
        }).then(result => {
            if (result.value) {
                this.setState({
                    deleteBtnText: DELETING,
                    isProcessing: true,
                });
                
                new ResourceAPI().deleteListings(this.state.selectedListings).then(() => {
                    this.searchListings();
                }).catch(error => {
                    handleError(error);
                });

                this.setState({
                    deleteBtnText: DELETE,
                    isProcessing: false,
                    selectedFiles: [],
                });
            }
        });
    };

    render() {
        let numSelected = this.state.selectedListings.length;
        let rowCount = this.state.results.length;

        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col lg={3} md={6} xs={12}>
                                        {this.props.id === undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={intOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Select
                                                            value={this.createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.props.id !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={eqOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Form.Control size="sm" type="number"
                                                                      value={this.props.id}
                                                                      onChange={this.onChangeClientIdValue}
                                                                      onKeyDown={this.handleFilterOnEnter}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        <Form.Label style={filterLabel}>Store ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeStoreIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Select
                                                    value={this.createTypeOption(this.state.filters.storeId.label)}
                                                    onChange={(e) => this.onChangeStoreIdValue(e)}
                                                    options={this.state.storesIds}
                                                    styles={this.customStyles}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Supplier</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.supplier.condition}
                                                                handleOnChange={this.onChangeSupplierFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSupplierValue}
                                                              value={this.state.filters.supplier.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>ASIN</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.asin.condition}
                                                                handleOnChange={this.onChangeAsinFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeAsinValue}
                                                              value={this.state.filters.asin.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              value={this.state.filters.status.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={6} xs={12}>
                                        <Form.Label style={filterLabel}>Fulfilment</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeFulfilmentFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeFulfilmentValue}
                                                              value={this.state.filters.fulfilment.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Supplier Group</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.supplier.condition}
                                                                handleOnChange={this.onChangeSupplierGroupFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSupplierGroupValue}
                                                              value={this.state.filters.supplierGroup.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>SKU</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.sku.condition}
                                                                handleOnChange={this.onChangeSkuFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSkuValue}
                                                              value={this.state.filters.sku.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Inventory model</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeInventoryModelFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeInventoryModelValue}
                                                              value={this.state.filters.inventoryModel.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Buying model</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeBuyingModelFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeBuyingModelValue}
                                                              value={this.state.filters.buyingModel.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={6} xs={12}>
                                        <Form.Label style={filterLabel}>FNSKU</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.fnsku.condition}
                                                                handleOnChange={this.onChangeFnskuFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeFnskuValue}
                                                              value={this.state.filters.fnsku.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>UPC</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.upc.condition}
                                                                handleOnChange={this.onChangeUpcFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeUpcValue}
                                                              value={this.state.filters.upc.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Component-UPC</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.componentUpc.condition}
                                                                handleOnChange={this.onChangeComponentUpcFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeComponentUpcValue}
                                                              value={this.state.filters.componentUpc.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Barcode</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                value={this.state.filters.barcode.condition}
                                                                handleOnChange={this.onChangeBarcodeFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeBarcodeValue}
                                                              value={this.state.filters.barcode.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={6} xs={12}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                        <br/>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" onClick={this.onClickExport}
                                                    disabled={this.state.isProcessing}>
                                                {showExportSpinner(this.state.exportBtnText)}
                                            </Button>
                                        </Row>
                                        <br/>
                                        <Row style={{ display: 'block', textAlign: 'center' }}>
                                            <Button variant="primary" onClick={this.handleAddNew}>
                                                <Add /> Add New
                                            </Button>
                                            <Button variant="danger" onClick={this.deleteSelectedListings}
                                                style={{ marginLeft: 10 }}
                                                disabled={this.state.isProcessing
                                                    || this.state.selectedListings.length === 0
                                                    || this.state.selectedListings.length > 100}>
                                                {showDeleteSpinner(this.state.deleteBtnText)}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>

                    <Col>
                        <Row>
                            <Accordion style={displayRow}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <span style={{color: '#32a2fe'}}>Last Listing Id</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Row>
                                        <ListingLastId/>
                                    </Row>
                                </AccordionDetails>
                            </Accordion>
                        </Row>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                                    checked={rowCount > 0 && numSelected === rowCount}
                                                    onChange={this.handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" style={{ width: "5%" }}>ID</TableCell>
                                            <TableCell align="center" style={{ width: "20%" }}>Basic Fields</TableCell>
                                            <TableCell align="center" style={{ width: "15%" }}>Other Fields</TableCell>
                                            <TableCell align="center" style={{ width: "40%" }}>Barcode Fields</TableCell>
                                            <TableCell align="center" style={{ width: "5%" }}>Supplier</TableCell>
                                            <TableCell align="center" style={{ width: "5%" }}>Unit Count</TableCell>
                                            <TableCell align="center" style={{ width: "10%" }}>Supplier Group</TableCell>
                                            <TableCell align="center" style={{ width: "25%" }}>Product Title</TableCell>
                                            <TableCell>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => {
                                            const isItemSelected = this.state.selectedListings.includes(row);
                                            const labelId = `enhanced-table-checkbox-${index}`;
        
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            onChange={(event) => this.handleSelectClick(event, row, index)}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        <Link to={{
                                                            pathname: "/listings/" + row.id,
                                                            state: { foo: 'bar' }
                                                        }}>{row.id}</Link>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="primary" style={barcodeBadge}>Client</Badge> {row.clientId}
                                                        <br />
                                                        <Badge variant="warning" style={barcodeBadge}>Store</Badge> {row.storeId} - {this.getStoreName(row.storeId)}
                                                        <br />
                                                        <Badge variant="secondary" style={barcodeBadge}>Status</Badge>
                                                        <Form.Control size="sm" type="text" style={{ width: 150, }}
                                                            value={row.status}
                                                            onChange={(e) => this.onChangeStatus(e, index)} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="success" style={{ width: 90, marginTop: 14 }}>Fulfilment</Badge> {row.fulfilment}
                                                        <br />
                                                        <Badge variant="info" style={{ width: 90, marginTop: 14 }}>Inventory Model</Badge> {row.inventoryModel}
                                                        <br />
                                                        <Badge variant="warning" style={{ width: 90, marginTop: 14 }}>Buying Model</Badge> {row.buyingModel}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="primary" style={barcodeBadge}>SKU</Badge> {row.sku}
                                                        <BarcodeGenerator value={row.sku} SKU={row.sku} title={row.name} width={1} />
                                                        <br />
                                                        <Badge variant="secondary" style={barcodeBadge}>ASIN</Badge>
                                                        <Link to={{ pathname: "/warehouses/contents?barcode=" + row.asin }} target={"_blank"}>{" "}{row.asin}</Link>
                                                        <BarcodeGenerator value={row.asin} SKU={row.sku} title={row.name} width={2} />
                                                        <br />
                                                        <Badge variant="success" style={barcodeBadge}>UPC</Badge>
                                                        <Link to={{ pathname: "/warehouses/contents?barcode=" + row.upc }} target={"_blank"}>{" "}{row.upc}</Link>
                                                        <BarcodeGenerator value={row.upc} SKU={row.sku} title={row.name} width={2} />
                                                        <br />
                                                        <Badge variant="info" style={barcodeBadge}>Co UPC</Badge>
                                                        <Link to={{ pathname: "/warehouses/contents?barcode=" + row.componentUpc }} target={"_blank"}>{" "}{row.componentUpc}</Link>
                                                        <BarcodeGenerator value={row.componentUpc} SKU={row.sku} title={row.name} width={2} />
                                                        <br />
                                                        <Badge variant="warning" style={barcodeBadge}>FNSKU</Badge>
                                                        <Link to={{ pathname: "/warehouses/contents?barcode=" + row.fnsku }} target={"_blank"}>{" "}{row.fnsku}</Link>
                                                        <BarcodeGenerator value={row.fnsku} SKU={row.sku} title={row.name} width={2} />
                                                        <br />
                                                    </TableCell>
                                                    <TableCell align="left">{row.supplier}</TableCell>
                                                    <TableCell align="left">{row.unitCount}</TableCell>
                                                    <TableCell align="left">{row.supplierGroup}</TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell>
                                                        <IconButton color="primary" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            onClick={() => this.saveListing(index)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton color="default" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            onClick={() => this.cancelSaveListing(index)}>
                                                            <Clear />
                                                        </IconButton>
                                                        <IconButton color="secondary" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing}
                                                            onClick={() => this.deleteListing(index)}>
                                                            <Delete />
                                                        </IconButton>
                                                        <IconButton color="primary" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing}
                                                            onClick={() => this.cloneListing(index)}>
                                                            <CallSplit />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ListingsSearch;
