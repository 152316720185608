import React, {Component} from 'react';
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../utils/DateUtils";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {HOST_NAME, listingTypes, SAVE, SAVING} from "../constants";
import {Clear, Save} from "@material-ui/icons";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import {createTypeOption, getIdFromUrl, handleErr} from "../utils/MiscellaniousUtils";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {withRouter} from "react-router-dom";
import AuthHeader from "../user/services/AuthHeader";
import Select from "react-select";
import ResourceAPIs from "../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

class ListingDetails extends Component {

    constructor(props) {
        super(props);
        this.customStyles = {
            control: (base, state) => ({
                ...base,
                minHeight: '1px',
                height: '33px',
                'font-size': '13px',

            }),
            dropdownIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0,
            }),
        };

        this.state = {
            listing: {
                asin: "",
                buyingModel: "",
                caseQty: "",
                channel: "",
                clientId: "",
                componentUpc: "",
                createdAt: "",
                fbaFulfilmentCost: "",
                fnsku: "",
                fulfilment: "",
                id: "",
                inboundShippingCost: "",
                inventoryModel: "",
                mfnFulfilmentCost: "",
                minStock: "",
                mpn: "",
                name: "",
                productCost: "",
                qtyAtAmazon: "",
                qtyInWarehouse: "",
                sku: "",
                status: "",
                stocked: "",
                storeName: "",
                storeId: "",
                supplier: "",
                supplierGroup: "",
                upc: "",
                unitCount: 1,
                updatedAt: "",
                leadTime: "",
                minPrice: "",
                maxPrice: "",
                type: "",
                packageWeightLb: "",
                packageLengthIn: "",
                packageWidthIn: "",
                packageHeightIn: "",
            },
            storeLabel: "",
            clientLabel: "",
            errMsg: "",
            isLocationLoaded: false,
            getListingUrl: "/listings?id=",
            updateListingUrl: "/update/listings?id=",
            backUrl: "/listings",
            storesIds: [],
            clients: [],
        };

    }

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });
                    this.setState({
                        storesIds: storesIds,
                    });
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getStoreLabel = (value) => {
        let data = "";
        this.state.storesIds.forEach((store) => {
            if(store.value === value) {
                data = store.label;
            }
        });
        return data;
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            }, () => {
                this.getListingById();
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    getClientLabel = value => {
        let data = "";
        this.state.clients.forEach((client) => {
            if(client.value === value) {
                data = client.label;
            }
        });
        return data;
    };

    onChangeClientId = (selected) => {
        let newState = Object.assign({}, this.state);
        newState.listing.clientId = selected.value;
        newState.clientLabel = this.getStoreLabel(selected.value);
        this.setState(newState);
    };

    onChangeStatus = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.status = event.target.value;
        this.setState(newState);
    };

    onChangeStoreName = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.storeName = event.target.value;
        this.setState(newState);
    };

    onChangeUnitCount = event => {
        let newState = Object.assign({}, this.state);
        newState.listing.unitCount = event.target.value;
        this.setState(newState);
    }

    onChangeBuyingModel = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.buyingModel = event.target.value;
        this.setState(newState);
    };

    onChangeInventoryModel = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.inventoryModel = event.target.value;
        this.setState(newState);
    };

    onChangeFulfilment = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.fulfilment = event.target.value;
        this.setState(newState);
    };

    onChangeChannel = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.channel = event.target.value;
        this.setState(newState);
    };

    onChangeSKU = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.sku = event.target.value;
        this.setState(newState);
    };

    onChangeName = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.name = event.target.value;
        this.setState(newState);
    };

    onChangeSupplier = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.supplier = event.target.value;
        this.setState(newState);
    };

    onChangeSupplierGroup = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.supplierGroup = event.target.value;
        this.setState(newState);
    };

    onChangeMPN = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.mpn = event.target.value;
        this.setState(newState);
    };

    onChangeASIN = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.asin = event.target.value;
        this.setState(newState);
    };

    onChangeFNSKU = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.fnsku = event.target.value;
        this.setState(newState);
    };

    onChangeUPC = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.upc = event.target.value;
        this.setState(newState);
    };

    onChangeComponentUPC = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.componentUpc = event.target.value;
        this.setState(newState);
    };

    onChangeProductCost= (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.productCost = event.target.value;
        this.setState(newState);
    };

    onChangeInboundShippingCost = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.inboundShippingCost = event.target.value;
        this.setState(newState);
    };

    onChangeFbaFulfilmentCost= (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.fbaFulfilmentCost = event.target.value;
        this.setState(newState);
    };

    onChangeMfnFulfilmentCost = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.mfnFulfilmentCost = event.target.value;
        this.setState(newState);
    };

    onChangeCaseQty= (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.caseQty = event.target.value;
        this.setState(newState);
    };

    onChangeMinStock = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.minStock = event.target.value;
        this.setState(newState);
    };

    onChangeStocked = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.stocked = event.target.value;
        this.setState(newState);
    };

    onChangeQtyInWarehouse = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.qtyInWarehouse = event.target.value;
        this.setState(newState);
    };

    onChangeQtyAtAmazon = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.qtyAtAmazon = event.target.value;
        this.setState(newState);
    };

    onChangeLeadTime = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.leadTime = event.target.value;
        this.setState(newState);
    };

    onChangeMinPrice = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.minPrice = event.target.value;
        this.setState(newState);
    };

    onChangeMaxPrice = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.maxPrice = event.target.value;
        this.setState(newState);
    };

    onChangePackageWeightLb = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.packageWeightLb = event.target.value;
        this.setState(newState);
    };

    onChangePackageLengthIn = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.packageLengthIn = event.target.value;
        this.setState(newState);
    };

    onChangePackageWidthIn = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.packageWidthIn = event.target.value;
        this.setState(newState);
    };

    onChangePackageHeightIn = (event) => {
        let newState = Object.assign({}, this.state);
        newState.listing.packageHeightIn = event.target.value;
        this.setState(newState);
    };

    onChangeStoreIdValue = selected => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.listing.storeId = selected.value;
        stateCopy.storeLabel = this.getStoreLabel(selected.value);
        this.setState(stateCopy);
    };

    onChangeListingType = selected => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.listing.type = selected.value;
        this.setState(stateCopy);
    };

    getListingById = () => {
        axios
            .get(HOST_NAME + this.state.getListingUrl + getIdFromUrl(), AuthHeader())
            .then(response => {
                let result = response.data;
                console.log(result);
                this.setState({
                    listing: {
                        asin: result.asin,
                        buyingModel: result.buyingModel,
                        caseQty: result.caseQty,
                        channel: result.channel,
                        clientId: result.clientId,
                        componentUpc: result.componentUpc,
                        createdAt: result.createdAt,
                        fbaFulfilmentCost: result.fbaFulfilmentCost,
                        fnsku: result.fnsku,
                        fulfilment: result.fulfilment,
                        id: result.id,
                        inboundShippingCost: result.inboundShippingCost,
                        inventoryModel: result.inventoryModel,
                        mfnFulfilmentCost: result.mfnFulfilmentCost,
                        minStock: result.minStock,
                        mpn: result.mpn,
                        name: result.name,
                        productCost: result.productCost,
                        qtyAtAmazon: result.qtyAtAmazon,
                        qtyInWarehouse: result.qtyInWarehouse,
                        sku: result.sku,
                        status: result.status,
                        stocked: result.stocked,
                        storeName: result.storeName,
                        storeId: result.storeId,
                        supplier: result.supplier,
                        unitCount: result.unitCount,
                        supplierGroup: result.supplierGroup,
                        upc: result.upc,
                        updatedAt: result.updatedAt,
                        leadTime: result.leadTime,
                        minPrice: result.minPrice,
                        maxPrice: result.maxPrice,
                        packageWeightLb: result.packageWeightLb,
                        packageLengthIn: result.packageLengthIn,
                        packageWidthIn: result.packageWidthIn,
                        packageHeightIn: result.packageHeightIn,
                        type: result.type,
                    },
                    storeLabel: this.getStoreLabel(result.storeId),
                    clientLabel: this.getClientLabel(result.clientId),
                    errMsg: "",
                    isLocationLoaded: true,
                    editListingsBtnText: SAVE,
                    isProcessing: false,
                })
            }, error => {
                console.log(this.state.backUrl);
                if (error.response.status === 401) {
                    MySwal.fire({
                        title: 'Unauthorized',
                        text: "You are not allowed",
                        icon: 'error',
                        confirmButtonColor: '#0000FF'
                    }).then((result) => {
                        this.props.history.push(this.state.backUrl)
                    })
                } else {
                    MySwal.fire({
                        title: 'Server Error',
                        text: "Something went wrong",
                        icon: 'error',
                        confirmButtonColor: '#0000FF'
                    }).then((result) => {
                        this.props.history.push(this.state.backUrl)
                    })
                }
            })
    };

    handleEditListing = () => {
        if (this.validateListingDetails()) {
            this.setState({
                editListingsBtnText: SAVING,
                isProcessing: true,
            });
            axios.post(HOST_NAME
                + this.state.updateListingUrl + getIdFromUrl(),
                this.state.listing, AuthHeader())
                .then(res => { // then print response status
                        MySwal.fire(
                            'Updated!',
                            'Listing ' + this.state.listing.id + ' has been Updated.',
                            'success'
                        );
                        console.log(res);
                        this.setState({
                            editListingsBtnText: SAVE,
                            isProcessing: false,
                        });
                    },
                    (error) => {
                        handleErr(error);
                        this.setState({
                                editListingsBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            }
                        );
                    }
                );
        }
    };

    validateListingDetails = () => {
        // validate unit count
        if (parseInt(this.state.listing.unitCount, 10) > 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.listing.unitCount = parseInt(this.state.listing.unitCount, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Unit Count");
        }
        return true;
    }

    setLocationEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.listing.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    handleCancelEditListing = () => {
        this.getListingById();
    };

    componentDidMount(){
        this.getStores();
        this.getClients();
    }

    getFormComponent = (componentName, inputType, value, changeFunction) => {
        return (
        <Form.Group controlId="formBasicPassword">
            <Row>
                <Col>
                    <Form.Label>{componentName}</Form.Label>
                </Col>
                <Col>
                    <Form.Control type={inputType} size="sm"
                                value={value}
                                onChange={changeFunction}/>
                </Col>
            </Row>
        </Form.Group>
        );
    };

    getDisabledFormComponent = (componentName, inputType, value) => {
        return (
        <Form.Group controlId="formBasicPassword">
            <Row>
                <Col>
                    <Form.Label>{componentName}</Form.Label>
                </Col>
                <Col>
                    <Form.Control type={inputType} size="sm"
                                value={value}
                                disabled={true}/>
                </Col>
            </Row>
        </Form.Group>
        );
    };

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <div>
                        <Card border="secondary">
                            <Card.Header as="h5">
                                <span style={{marginTop: 60}}>Listing Details</span>

                                <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.listing.errMsg}
                                    </span>
                                    <Button variant="success" size="sm" style={{width: 100,}}
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleEditListing}>
                                        {showSaveListingSpinner(this.state.editListingsBtnText)}
                                    </Button>
                                    <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                            onClick={this.handleCancelEditListing}
                                            disabled={this.state.isProcessing}>
                                        <Clear/> Cancel
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col xs={3}>
                                            {this.getDisabledFormComponent("ID", "number", this.state.listing.id)}
                                            {this.props.id === undefined && (
                                                <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Client ID</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Select
                                                            value={createTypeOption(this.state.clientLabel)}
                                                            onChange={(e) => this.onChangeClientId(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            )}
                                            {this.props.id !== undefined && (
                                                this.getDisabledFormComponent("Client ID", "number", this.state.listing.clientId)
                                            )}

                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Store ID</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Select
                                                            value={createTypeOption(this.state.storeLabel)}
                                                            onChange={(e) => this.onChangeStoreIdValue(e)}
                                                            options={this.state.storesIds}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            {this.getFormComponent("Store Name", "text", this.state.listing.storeName, this.onChangeStoreName)}
                                            {this.getFormComponent("Status", "text", this.state.listing.status, this.onChangeStatus)}
                                            {this.getFormComponent("Buying Model", "text", this.state.listing.buyingModel, this.onChangeBuyingModel)}
                                            {this.getFormComponent("Inventory Model", "text", this.state.listing.inventoryModel, this.onChangeInventoryModel)}
                                            {this.getFormComponent("Fulfilment", "text", this.state.listing.fulfilment, this.onChangeFulfilment)}
                                            {this.getFormComponent("Channel", "text", this.state.listing.channel, this.onChangeChannel)}
                                            <Row>
                                                <Col>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.listing.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={3}>
                                            {this.getFormComponent("SKU", "text", this.state.listing.sku, this.onChangeSKU)}
                                            {this.getFormComponent("Name", "text", this.state.listing.name, this.onChangeName)}
                                            {this.getFormComponent("Supplier", "text", this.state.listing.supplier, this.onChangeSupplier)}
                                            {this.getFormComponent("Supplier Group", "text", this.state.listing.supplierGroup, this.onChangeSupplierGroup)}
                                            {this.getFormComponent("MPN", "text", this.state.listing.mpn, this.onChangeMPN)}
                                            {this.getFormComponent("ASIN", "text", this.state.listing.asin, this.onChangeASIN)}
                                            {this.getFormComponent("FNSKU", "text", this.state.listing.fnsku, this.onChangeFNSKU)}
                                            {this.getFormComponent("UPC", "text", this.state.listing.upc, this.onChangeUPC)}
                                            {this.getFormComponent("Unit Count", "number", this.state.listing.unitCount, this.onChangeUnitCount)}
                                            <Row>
                                                <Col>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.listing.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={3}>
                                            {this.getFormComponent("Component UPC", "text", this.state.listing.componentUpc, this.onChangeComponentUPC)}
                                            {this.getFormComponent("Product Cost", "number", this.state.listing.productCost, this.onChangeProductCost)}
                                            {this.getFormComponent("Inbound Ship Cost", "number", this.state.listing.inboundShippingCost, this.onChangeInboundShippingCost)}
                                            {this.getFormComponent("Fba Fulfilment Cost", "number", this.state.listing.fbaFulfilmentCost, this.onChangeFbaFulfilmentCost)}
                                            {this.getFormComponent("Mfn Fulfilment Cost", "number", this.state.listing.mfnFulfilmentCost, this.onChangeMfnFulfilmentCost)}
                                            {this.getFormComponent("Case Qty", "number", this.state.listing.caseQty, this.onChangeCaseQty)}
                                            {this.getFormComponent("Min Stock", "number", this.state.listing.minStock, this.onChangeMinStock)}
                                            {this.getFormComponent("Stocked", "text", this.state.listing.stocked, this.onChangeStocked)}
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Listing Type</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Select
                                                            value={createTypeOption(this.state.listing.type)}
                                                            onChange={(e) => this.onChangeListingType(e)}
                                                            options={listingTypes}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3}>
                                            {this.getFormComponent("Qty In Warehouse", "number", this.state.listing.qtyInWarehouse, this.onChangeQtyInWarehouse)}
                                            {this.getFormComponent("Qty In Amazon", "number", this.state.listing.qtyAtAmazon, this.onChangeQtyAtAmazon)}
                                            {this.getFormComponent("Lead Time", "number", this.state.listing.leadTime, this.onChangeLeadTime)}
                                            {this.getFormComponent("Min Price", "number", this.state.listing.minPrice, this.onChangeMinPrice)}
                                            {this.getFormComponent("Max Price", "number", this.state.listing.maxPrice, this.onChangeMaxPrice)}
                                            {this.getFormComponent("Packge Weight Lb", "number", this.state.listing.packageWeightLb, this.onChangePackageWeightLb)}
                                            {this.getFormComponent("Packge Length In", "number", this.state.listing.packageLengthIn, this.onChangePackageLengthIn)}
                                            {this.getFormComponent("Packge Width In", "number", this.state.listing.packageWidthIn, this.onChangePackageWidthIn)}
                                            {this.getFormComponent("Packge Height In", "number", this.state.listing.packageHeightIn, this.onChangePackageHeightIn)}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            );
        }
    }

    getClientId= () => {
        return this.props.match.params.ClientId;
    };

}

function showSaveListingSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default withRouter(ListingDetails);
