// Tasks
export const INBOUND_FEED_EXECUTOR = "inbound feed executor";
export const INBOUND_CONTENTS_IMPORTER = "inbound contents importer";
export const LISTING_WH_CONTENTS_PULLER = "listing warehouse contents puller";
export const AMAZON_LISTINGS_IMPORTER = "amazon listings importer";
export const OUTBOUND_CONTENTS_QTY_UPDATER = "outbound contents qty updater";
export const PURCHASE_ORDER_CONTENTS_INSERTER = "purchase order contents inserter";
export const MANUAL_PURCHASE_ORDER_CONTENTS_INSERTER = "manual purchase order contents inserter";
export const PURCHASE_ORDER_WH_CONTENTS_PULLER = "purchase order warehouse contents puller";
export const PURCHASE_ORDER_FEED_CONTENTS_PULLER = "purchase order inbound feed contents puller";
export const PURCHASE_ORDER_LISTING_PULLER = "purchase order listing data puller";
export const PURCHASE_ORDER_FBA_INV_PULLER = "purchase order FBA inventory puller";
export const PURCHASE_ORDER_BR_SALES_PULLER = "purchase order business report sales puller";
export const PURCHASE_ORDER_BUY_RECOMMEND_MAKER = "purchase order buy recommendation maker";
export const ORDER_PULL_REQUEST_LISTINGS_PULLER = "order pull request listings puller";
export const ORDER_PULL_REQUEST_WAREHOUSE_PULLER = "order pull request warehouse puller";

