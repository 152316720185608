import React, {Component} from "react";
import {EXPORT, EXPORTING, FILTER, FILTERING, intOptions, eqOptions, SAVE} from '../../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {ExpandMore, SkipNext, SkipPrevious} from "@material-ui/icons";
import {
    handleErr, 
    showExportSpinner, 
    showFilterSpinner, 
    createTypeOption,
    getLabel
} from "../../utils/MiscellaniousUtils";
import {getFormattedDate} from "../OrdersExporter";
import OrderPullRequestsImporter from "./OrderPullRequestImporter";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import fileDownload from "js-file-download";
import {strOptions, strWithNilOptions} from "../../constants";
import Select from "react-select";
import ResourceAPIs from "../../utils/ResourceAPI";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

const displayRow = {
    width: '98%'
};

class OrderPullRequestContentsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                invoice: {
                    condition: "con",
                    value: "",
                },
                sku: {
                    condition: "eq",
                    value: "",
                },
                upc: {
                    condition: "eq",
                    value: "",
                },
                compUpc: {
                    condition: "eq",
                    value: "",
                },
                orderPullRequestId: {
                    condition: "eq",
                    value: "0",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            exportBtnText: EXPORT,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            storeIds: [],
            clients: [],
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeStoreNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = getLabel(this.state.storesIds, selected.value)
            this.setState(stateCopy);
            this.handleFilter();
        };


        this.onChangeInvoiceValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.invoice.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeSkuValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.sku.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeUpcValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.upc.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeCompUpcValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.compUpc.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeOrderPullRequestIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.orderPullRequestId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };
    }

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInvoiceFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.invoice.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSkuFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.sku.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeUpcFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.upc.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeCompUpcFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.compUpc.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeOrderPullRequestIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.orderPullRequestId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchOrderPullRequests();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    }

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchOrderPullRequests();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchOrderPullRequests();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                invoice: {
                    condition: "con",
                    value: "",
                },
                sku: {
                    condition: "eq",
                    value: "",
                },
                upc: {
                    condition: "eq",
                    value: "",
                },
                compUpc: {
                    condition: "eq",
                    value: "",
                },
                orderPullRequestId: {
                    condition: "eq",
                    value: "0",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchOrderPullRequests() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchOrderPRContents(this.state.filters)
            .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: res.data,
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                });
    }

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().searchExportOrderPRContents(this.state.filters)
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "order-pull-request-contents-search-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    }, () => {
                        this.getClients();
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    componentDidMount() {
        this.getStores();
    }

    render() {
        return (
            <div>
                <Row>
                    <Card style={displayRow}>
                        <Card.Body>
                            <Row>
                                <Col xs={5}>
                                    {this.props.id === undefined && (
                                        <div>
                                            <Form.Label style={filterLabel}>Client ID</Form.Label>
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                        <Select
                                                            value={createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                        />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    {this.props.id !== undefined && (
                                        <div>
                                            <Form.Label style={filterLabel}>Client ID</Form.Label>
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={eqOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Form.Control size="sm" type="number"
                                                                  value={this.props.id}
                                                                  onChange={this.onChangeClientIdValue}
                                                                  onKeyDown={this.handleFilterOnEnter}
                                                                  disabled={true}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    <Form.Label style={filterLabel}>Invoice</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strWithNilOptions}
                                                            handleOnChange={this.onChangeInvoiceFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          value={this.state.filters.invoice.value}
                                                          onChange={this.onChangeInvoiceValue}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>UPC</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeUpcFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          value={this.state.filters.upc.value}
                                                          onChange={this.onChangeUpcValue}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Order Pull Request ID</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={intOptions}
                                                            handleOnChange={this.onChangeOrderPullRequestIdFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          value={this.state.filters.orderPullRequestId.value}
                                                          onChange={this.onChangeOrderPullRequestIdValue}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Store Name</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeStoreNameFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeStoreNameValue}
                                                          value={this.state.filters.storeName.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={5}>
                                    <Form.Label style={filterLabel}>Store ID</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={eqOptions}
                                                            handleOnChange={this.onChangeStoreIdFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Select
                                                value={createTypeOption(this.state.filters.storeId.label)}
                                                onChange={(e) => this.onChangeStoreIdValue(e)}
                                                options={this.state.storesIds}
                                                styles={this.customStyles}
                                            />
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>SKU</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeSkuFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeSkuValue}
                                                          value={this.state.filters.sku.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Component UPC</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeCompUpcFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeCompUpcValue}
                                                          value={this.state.filters.compUpc.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Status</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeStatusFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeStatusValue}
                                                          value={this.state.filters.status.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={2}>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <Form.Text className="text-muted">
                                            {this.state.errMsg}
                                        </Form.Text>
                                    </Row>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                            <SkipPrevious/>
                                        </IconButton>
                                        <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                        <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                            <SkipNext/>
                                        </IconButton>
                                    </Row>
                                    <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                        <Button variant="success" style={filterBtn}
                                                onClick={this.handleFilter}
                                                disabled={this.state.isProcessing}>
                                            {showFilterSpinner(this.state.filterBtnText)}
                                        </Button>
                                        <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                    </Row>

                                    <hr/>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <span style={{fontSize: 12}}>Export Search Result</span><br/>
                                        <Button variant="success" onClick={this.onClickExport}
                                                disabled={this.state.isProcessing}>
                                            {showExportSpinner(this.state.exportBtnText)}
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
                <br/>

                <Row>
                    <Accordion style={displayRow}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <span style={{color: '#32a2fe'}}>Import</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Row>
                                <OrderPullRequestsImporter/>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                </Row>
                <br/>

                <Row>
                    {this.state.results.length > 0 ?
                        <TableContainer component={Paper} style={displayRow}>
                            <Table aria-label="simple table" size="small">
                                <TableHead style={{backgroundColor: '#bdbdbd'}}>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Order PR ID</TableCell>
                                        <TableCell align="center">Client ID</TableCell>
                                        <TableCell align="center">Store Name</TableCell>
                                        <TableCell align="center">Store Id</TableCell>
                                        <TableCell align="center">Order No</TableCell>
                                        <TableCell align="center">SKU</TableCell>
                                        <TableCell align="center">Qty Desired</TableCell>
                                        <TableCell align="center">Qty Pulled</TableCell>
                                        <TableCell align="center">Unit Count</TableCell>
                                        <TableCell align="center">Invoice</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Created At</TableCell>
                                        <TableCell align="center">Updated At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.results.map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row" align="right">
                                                {row.id}
                                            </TableCell>
                                            <TableCell>{row.orderPullRequestId}</TableCell>
                                            <TableCell>{row.clientId}</TableCell>
                                            <TableCell>{row.storeName}</TableCell>
                                            <TableCell>{row.storeId}</TableCell>
                                            <TableCell>{row.orderNumber}</TableCell>
                                            <TableCell>{row.sku}</TableCell>
                                            <TableCell>{row.qtyDesired}</TableCell>
                                            <TableCell>{row.qtyPulled}</TableCell>
                                            <TableCell>{row.unitCount}</TableCell>
                                            <TableCell>{row.invoice}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                            <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : <p></p>
                    }
                </Row>
            </div>
        );
    }
}

export default OrderPullRequestContentsSearch;
