import React, {Component} from 'react';
import {ADD_CONTENTS, ADDING_CONTENTS, INTERNAL_SERVER_ERROR, SAVE, SAVING} from "../../constants";
import {
    alertError,
    alertSuccess,
    alertSuccessfullyUpdated,
    createTypeOption,
    getIdFromUrl,
    getLabel,
    handleErr,
    isEmptyOrUndefined,
    setEmptyIfNull
} from "../../utils/MiscellaniousUtils";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {format2NiceDate} from "../../utils/DateUtils";
import {Clear, GetApp, HomeWork, LowPriority, Save} from "@material-ui/icons";
import {getDisabledFormComponent, getFormComponent} from "../../utils/FormUtils";
import {getFormattedDate} from "../../orders/OrdersExporter";
import fileDownload from "js-file-download";
import OutboundFeedContents from "./OutboundFeedContents";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import OFContentsAdder from "./OFContentsAdder";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FieldsIncluder from "./FieldsIncluder";
import ResourceAPIs from "../../utils/ResourceAPI";
import OutboundFeedScheduler from "./OutboundFeedScheduler";
import Select from "react-select";
const MySwal = withReactContent(Swal);

const pullBtnStyle = {
    width: '100%',
    fontSize: 12,
};

const PULL_LISTING_DATA = "Pull Listing Data";
const PULLING_LISTING_DATA = "Pulling Listing Data";
const DOWNLOAD_FEEDS = "Download Feeds";
const DOWNLOADING_FEEDS = "Downloading Feeds";
const DOWNLOAD_CONTENTS = "Download Contents";
const DOWNLOADING_CONTENTS = "Downloading Contents";
const UPDATE_QUANTITIES = "Update Quantities";
const UPDATING_QUANTITIES = "Updating Quantities";
const ADD_TASK_UPDATE_QUANTITIES = "Update Quantities as a Task";
const ADDING_TASK_UPDATE_QUANTITIES = "Updating Quantities as a Task";

class OutboundFeedDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            outboundFeed: {
                id: "",
                createdAt: "",
                updatedAt: "",
                clientId: "",
                storeId: "",
                warehouseId: "",
                storeName: "",
                comments: "",
                status: "",
                format: "",
                fieldIncluded: "",
                name: "",
                inboundFeedIds: "",
            },
            addContent: {
                sku: "",
            },
            addContentBtnText: ADD_CONTENTS,
            updateQuantitiesBtnText: UPDATE_QUANTITIES,
            updateQuantitiesAsATaskBtnText: ADD_TASK_UPDATE_QUANTITIES,
            downloadOFCBtnText: DOWNLOAD_CONTENTS,
            downloadOFBtnText: DOWNLOAD_FEEDS,
            pullListingBtnText: PULL_LISTING_DATA,
            isProcessing: false,
            editOutboundFeedBtnText: SAVE,
            isLocationLoaded: false,
            childLoad: false,
            errMsg: "",
            clientLabel: "",
            storeLabel: "",
            clients: [],
            storesIds: []
        };

        this.setChildLoad = (bool) => {
            this.setState({
                childLoad: bool,
            });
        };
    }

    includeFields = (fieldsIncluded) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.fieldIncluded = JSON.stringify(fieldsIncluded);
        this.setState(newState);
    };

    onChangeComments = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.comments = event.target.value;
        this.setState(newState);
    };

    onChangeClientId = (selected) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.clientId = selected.value;
        newState.clientLabel = getLabel(this.state.clients, selected.value);
        this.setState(newState);
    };

    onChangeWarehouseId = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.warehouseId = event.target.value;
        this.setState(newState);
    };

    onChangeStoreId = (selected) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.storeId = selected.value;
        newState.storeLabel = getLabel(this.state.storesIds, selected.value);
        this.setState(newState);
    };

    onChangeStoreName = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.storeName = event.target.value;
        this.setState(newState);
    };

    onChangeStatus = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.status = event.target.value;
        this.setState(newState);
    };

    onChangeName = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.name = event.target.value;
        this.setState(newState);
    };

    onChangeInboundFeedIds = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.inboundFeedIds = event.target.value;
        this.setState(newState);
    };

    onChangeFormat = (event) => {
        let newState = Object.assign({}, this.state);
        newState.outboundFeed.format = event.target.id;
        this.setState(newState);
    };

    onChangeAddContentSku = (event) => {
        let newState = Object.assign({}, this.state);
        newState.addContent.sku = event.target.value;
        this.setState(newState);
    };

    getOutboundFeedById = () => {
        new ResourceAPIs().getOutboundFeedsById(getIdFromUrl())
            .then(res => {
                const result = res.data;
                this.setState({
                    outboundFeed: {
                        id: result.id,
                        createdAt: result.createdAt,
                        updatedAt: result.updatedAt,
                        clientId: result.clientId,
                        storeId: result.storeId,
                        warehouseId: result.warehouseId,
                        storeName: result.storeName,
                        comments: result.comments,
                        status: result.status,
                        format: result.format,
                        fieldIncluded: result.fieldIncluded,
                        name: result.name,
                        inboundFeedIds: result.inboundFeedIds,
                    },
                    errMsg: "",
                    isLocationLoaded: true,
                    clientLabel: getLabel(this.state.clients, result.clientId),
                    storeLabel: getLabel(this.state.storesIds, result.storeId)
                });
            })
            .catch(error => {
                    this.setState({
                        isLocationLoaded: false,
                        error
                    });
                }
            );
    };

    handleAddContents = () => {
        if (this.validateAddContentInputs()) {
            this.setState({
                addContentBtnText: ADDING_CONTENTS,
                isProcessing: true,
            });
            new ResourceAPIs().addOFContents(getIdFromUrl(), this.state.addContent)
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        addContentBtnText: ADD_CONTENTS,
                        isProcessing: false,
                        childLoad: false,
                        addContent: {
                            sku: "",
                        }
                    });
                    this.restAddContentFrom();
                    alertSuccessfullyUpdated("Updated feed contents")
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        addContentBtnText: ADD_CONTENTS,
                        isProcessing: false,
                        results: error,
                    });
                });
        }
    };

    restAddContentFrom = () => {
        this.setState({
            addContent: {
                sku: "",
            }
        });
    };

    setOutboundFeedEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    validateEditOutboundFeed = () => {
        // validate client id
        if (!isEmptyOrUndefined(this.state.outboundFeed.clientId)) {
            if (parseInt(this.state.outboundFeed.clientId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.outboundFeed.clientId = parseInt(this.state.outboundFeed.clientId, 10);
                this.setState(stateCopy);
            } else {
                return this.setOutboundFeedEditErr("Invalid Client ID!");
            }
        } else {
            return this.setOutboundFeedEditErr("Client ID field is required!");
        }

        // validate warehouse id
        if (!isEmptyOrUndefined(this.state.outboundFeed.warehouseId)) {
            if (parseInt(this.state.outboundFeed.warehouseId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.outboundFeed.warehouseId = parseInt(this.state.outboundFeed.warehouseId, 10);
                this.setState(stateCopy);
            } else {
                return this.setOutboundFeedEditErr("Invalid Warehouse ID!");
            }
        } else {
            return this.setOutboundFeedEditErr("Warehouse ID field is required!");
        }

        // validate inbound feed Ids
        if (!isEmptyOrUndefined(this.state.outboundFeed.inboundFeedIds)) {
            if (this.checkIntArray(this.state.outboundFeed.inboundFeedIds)) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.outboundFeed.inboundFeedIds = this.state.outboundFeed.inboundFeedIds;
                this.setState(stateCopy);
            } else {
                return this.setOutboundFeedEditErr("Invalid Inbound feed ID!");
            }
        } else {
            return this.setOutboundFeedEditErr("Invalid feed ids field is required!");
        }

        return this.validateFieldsIncluded();
    };

    validateFieldsIncluded = () => {
        let fieldsArray = JSON.parse(this.state.outboundFeed.fieldIncluded);
        let orderArray = [];

        for (let fieldIndex = 0; fieldIndex < fieldsArray.length; fieldIndex++) {
            if (fieldsArray[fieldIndex].included === true) {
                if (fieldsArray[fieldIndex].outboundFieldName === "") {
                    return this.setOutboundFeedEditErr(fieldsArray[fieldIndex].tableField
                        + " should have outbound field name");
                } else if (fieldsArray[fieldIndex].order === 0) {
                    return this.setOutboundFeedEditErr(fieldsArray[fieldIndex].tableField + " order should not be 0");
                }
                orderArray.push(parseInt(fieldsArray[fieldIndex].order));
            }
        }

        orderArray.sort();
        for (let orderValue = 0; orderValue < orderArray.length; orderValue++) {
            if (orderArray[orderValue] !== orderValue + 1) {
                return this.setOutboundFeedEditErr("Please insert a valid order");
            }
        }
        return true;
    }

    validateAddContentInputs = () => {
        if (this.state.addContent.sku.trim() === "") {
            this.setState({
                errMsg: "SKUs field cannot be empty!"
            });
            return false;
        }
        return true;
    };

    checkIntArray = (inboundFeedIds) => {
        let isNumeric = true;
        if(inboundFeedIds == null) {
            return isNumeric;
        } else {
            let inboundFeedIdArray = inboundFeedIds.split(",");
            inboundFeedIdArray.map(element => {
                if(isNaN(element.trim())){
                    isNumeric = false;
                }
            });
            return isNumeric;
        }
    };

    handleCancelEditOutboundFeed = () => {
        this.getOutboundFeedById();
    };

    componentDidMount(){
        this.getClients();
    }

    handleEditOutboundFeed = () => {
        if (this.validateEditOutboundFeed()) {
            this.setState({
                errMsg: "",
                editOutboundFeedBtnText: SAVING,
                isProcessing: true,
            });
            new ResourceAPIs().updateOutboundFeed(getIdFromUrl(), this.state.outboundFeed)
                .then(res => {
                    this.setState({
                        editOutboundFeedBtnText: SAVE,
                        isProcessing: false,
                    });
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        editOutboundFeedBtnText: SAVE,
                        isProcessing: false,
                        results: error,
                    });
                });
        }
    };

    handlePullListings = () => {
        this.setState({
            pullListingBtnText: PULLING_LISTING_DATA,
            isProcessing: true,
        });
        new ResourceAPIs().handlePullListingsOFContents(getIdFromUrl())
            .then(res => {
                this.setState({
                    childLoad: true,
                });
                this.setState({
                    pullListingBtnText: PULL_LISTING_DATA,
                    isProcessing: false,
                    childLoad: false,
                });
                this.getOutboundFeedById();
                MySwal.fire(
                    'Updated!',
                    'All contents are updated based on Listings.',
                    'success'
                );
            })
            .catch(error => {
                handleErr(error);
                this.setState({
                    pullListingBtnText: PULL_LISTING_DATA,
                    isProcessing: false,
                    results: error,
                });
            });
    };

    handleUpdateQuantities = () => {
        this.setState({
            updateQuantitiesBtnText: UPDATING_QUANTITIES,
            isProcessing: true,
        });
        new ResourceAPIs().handleUpdateQuantitiesOFContents(getIdFromUrl())
            .then(res => {
                this.setState({
                    childLoad: true,
                });
                this.setState({
                    updateQuantitiesBtnText: UPDATE_QUANTITIES,
                    isProcessing: false,
                    childLoad: false,
                });
                this.getOutboundFeedById();
                MySwal.fire(
                    'Updated!',
                    'All contents are updated based on quantities.',
                    'success'
                );
            })
            .catch(error => {
                handleErr(error);
                this.setState({
                    updateQuantitiesBtnText: UPDATE_QUANTITIES,
                    isProcessing: false,
                    results: error,
                });
            });
    };

    handleUpdateQuantitiesTask = () => {
        const data = new FormData();
        data.append('outboundFeedId', getIdFromUrl());

        this.setState({
            updateQuantitiesAsATaskBtnText: ADDING_TASK_UPDATE_QUANTITIES,
            isProcessing: true,
        });
        new ResourceAPIs().taskUpdateOFQty(data)
            .then(res => {
                this.setState({
                    updateQuantitiesAsATaskBtnText: ADD_TASK_UPDATE_QUANTITIES,
                    isProcessing: false,
                });
                alertSuccess(res.data);
            })
            .catch(error => {
                let err = INTERNAL_SERVER_ERROR;
                if (error.response) {
                    err = error.response.data;
                    alertError(err);
                } else {
                    alertError(err + ": " + error.error + " (" + error.message + ")");
                }
                this.setState({
                    updateQuantitiesAsATaskBtnText: ADD_TASK_UPDATE_QUANTITIES,
                    isProcessing: false,
                });
            });
    };

    handleDownloadOFContents = () =>{
        this.setState({
            downloadOFCBtnText: DOWNLOADING_CONTENTS,
            isProcessing: true,
        });
        new ResourceAPIs().exportOFContents(getIdFromUrl())
            .then(res => {
                this.setState({
                    childLoad: true,
                });
                this.setState({
                    downloadOFCBtnText: DOWNLOAD_CONTENTS,
                    isProcessing: false,
                    childLoad: false,
                });
                const dd = getFormattedDate(new Date());
                fileDownload(res.data, "outbound-feed-contents" + dd + ".csv");
            })
            .catch(error => {
                handleErr(error);
                this.setState({
                    downloadPRBtnText: DOWNLOAD_CONTENTS,
                    isProcessing: false,
                    results: error,
                });
            });
    };

    handleDownloadOFeeds = () => {
        this.setState({
            downloadOFBtnText: DOWNLOADING_FEEDS,
            isProcessing: true,
        });
        new ResourceAPIs().exportOutboundFeed(getIdFromUrl())
            .then(res => {
                this.setState({
                    childLoad: true,
                });
                this.setState({
                    downloadOFBtnText: DOWNLOAD_FEEDS,
                    isProcessing: false,
                    childLoad: false,
                });
                const dd = getFormattedDate(new Date());
                if (this.state.outboundFeed.format === ".csv") {
                    fileDownload(res.data, "outbound-feed" + dd + ".csv");
                } else {
                    fileDownload(res.data, "outbound-feed" + dd + ".txt");
                }
            })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        downloadOFBtnText: DOWNLOAD_FEEDS,
                        isProcessing: false,
                        results: error,
                    });
                }
            );
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                    this.getOutboundFeedById();

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });

            this.getStores();

        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            console.log(this.state);
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <div>
                        <Card border="secondary">
                            <Card.Header as="h5">
                                <span style={{marginTop: 60}}>Outbound Feed Details</span>

                                <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.errMsg}
                                    </span>
                                    <Button variant="success" size="sm" style={{width: 100,}}
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleEditOutboundFeed}>
                                        {showSavePRSpinner(this.state.editOutboundFeedBtnText)}
                                    </Button>
                                    <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                            onClick={this.handleCancelEditOutboundFeed}
                                            disabled={this.state.isProcessing}>
                                        <Clear/> Cancel
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col xs={12} md={8} lg={4}>
                                            {getDisabledFormComponent("ID", "number", this.state.outboundFeed.id)}

                                            {this.props.id === undefined &&
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Client ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientId(e)}
                                                                options={this.state.clients}
                                                                styles={this.customStyles}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            }
                                            {this.props.id !== undefined && (
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Client ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                        <Select
                                                            value={createTypeOption(this.state.clientLabel)}
                                                            onChange={(e) => this.onChangeClientId(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                            isDisabled={true}
                                                        />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            )}
                                            <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Store ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                        <Select
                                                            value={createTypeOption(this.state.storeLabel)}
                                                            onChange={(e) => this.onChangeStoreId(e)}
                                                            options={this.state.storesIds}
                                                            styles={this.customStyles}
                                                        />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            {getFormComponent("Warehouse ID", "number", this.state.outboundFeed.warehouseId, this.onChangeWarehouseId)}
                                            {getFormComponent("Name", "text", this.state.outboundFeed.name, this.onChangeName)}
                                            {getFormComponent("Inbound Feed IDs", "text", this.state.outboundFeed.inboundFeedIds, this.onChangeInboundFeedIds)}
                                            {getFormComponent("Store Name", "text", this.state.outboundFeed.storeName, this.onChangeStoreName)}
                                            {getFormComponent("Status", "text", this.state.outboundFeed.status, this.onChangeStatus)}
                                        </Col>
                                        <Col xs={12} md={4} lg={3}>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Comments</Form.Label>
                                                        <Form.Control type="text" size="sm" as="textarea" rows="5"
                                                                      value={setEmptyIfNull(this.state.outboundFeed.comments)}
                                                                      onChange={this.onChangeComments}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label as="legend" column>Format</Form.Label>
                                                <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label=".csv"
                                                        checked={".csv" === this.state.outboundFeed.format}
                                                        onChange={this.onChangeFormat}
                                                        name="format"
                                                        id=".csv"
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label=".txt"
                                                        checked={".txt" === this.state.outboundFeed.format}
                                                        onChange={this.onChangeFormat}
                                                        name="format"
                                                        id=".txt"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.outboundFeed.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.outboundFeed.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={12} lg={5}>
                                            <FieldsIncluder
                                                fieldsIncluded={this.state.outboundFeed.fieldIncluded}
                                                fieldsIncluder={this.includeFields}/>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br/>
                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="manual">
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="manual">Add Outbound Feed Contents Manually</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="listings">Add Outbound Feed Contents using Listings</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <br/>
                            <Tab.Content>
                                <Tab.Pane eventKey="manual">
                                    <Card>
                                        <Card.Body>
                                            <Form.Group as={Col} controlId="formGridState">
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Form.Label>SKUs</Form.Label>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control as="textarea" rows="4"
                                                                          value={this.state.addContent.sku}
                                                                          onChange={this.onChangeAddContentSku}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form.Group>
                                            <div>
                                                <span style={{fontSize: 12, color: "red",}}>{this.state.errMsg}</span>
                                                <br/>
                                                <Button variant="success" style={{width: 200}}
                                                        disabled={this.state.isProcessing}
                                                        onClick={this.handleAddContents}>
                                                    {showSpinner(this.state.addContentBtnText)}
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="listings">
                                    <OFContentsAdder childLoadSetter={this.setChildLoad} id={this.props.id}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    <br/>
                    <div>
                        <Row>
                            <Col xs={3}>
                                <Button variant="info" style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullListings}>
                                    {showPullListingsSpinner(this.state.pullListingBtnText)}
                                </Button>
                            </Col>
                            <Col xs={3}>
                                <Button variant="secondary"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleUpdateQuantities}>
                                    {showUpdateQuantitiesSpinner(this.state.updateQuantitiesBtnText)}
                                </Button>
                                <Button variant="secondary"
                                        style={{width: '100%', fontSize: 12, marginTop: 10, marginBottom: 10}}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleUpdateQuantitiesTask}>
                                    {showUpdateQuantitiesAsATaskSpinner(this.state.updateQuantitiesAsATaskBtnText)}
                                </Button>
                                <OutboundFeedScheduler
                                    style={{width: '100%', fontSize: 12}} />
                            </Col>
                            <Col xs={3}>
                                <Button variant="primary"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleDownloadOFContents}>
                                    {showPullDownloadSpinner(this.state.downloadOFCBtnText)}
                                </Button>
                            </Col>
                            <Col xs={3}>
                                <Button variant="warning"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleDownloadOFeeds}>
                                    {showPullDownloadSpinner(this.state.downloadOFBtnText)}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                    <OutboundFeedContents isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

function showSavePRSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showSpinner(btnText) {
    if (btnText === ADD_CONTENTS) {
        return <span><AddToPhotosIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}


function showPullListingsSpinner(btnText) {
    if (btnText === PULL_LISTING_DATA) {
        return <span><LowPriority/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showUpdateQuantitiesSpinner(btnText) {
    if (btnText === UPDATE_QUANTITIES) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showUpdateQuantitiesAsATaskSpinner(btnText) {
    if (btnText === ADD_TASK_UPDATE_QUANTITIES) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadSpinner(btnText) {
    if (btnText === DOWNLOAD_CONTENTS || btnText === DOWNLOAD_FEEDS) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default OutboundFeedDetails;
