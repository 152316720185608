import React, { Component } from 'react';
import {getIdFromUrl, getShowingResultsPhrase, handleErr} from '../../utils/MiscellaniousUtils';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import Paper from "@material-ui/core/Paper/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import ResourceAPIs from "../../utils/ResourceAPI";

class OutboundFeedContents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contents: [],
            contentsCount: null,
            isLoaded: false,
            isProcessing: false,
        };
    }

    getOutboundFeedContents = () => {
        new ResourceAPIs().getOFContentsByIdAndSize(getIdFromUrl(), 10)
            .then(result => {
                    this.setState({
                        contents: result.data.contents,
                        contentsCount: result.data.count,
                        isLoaded: true,
                    })
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            );
    };

    componentDidMount(){
        this.getOutboundFeedContents();
    }

    componentWillReceiveProps(){
        this.getOutboundFeedContents();
    }

    render() { 
        return ( 
            <div>
                <Card border="secondary">
                    <Card.Header as="h5">
                    <span style={{marginTop: 20}}>Outbound Feed Contents</span>
                    </Card.Header>
                    <Card.Body>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" >Content ID</TableCell>
                                        <TableCell align="center" >Sku</TableCell>
                                        <TableCell align="center" >ASIN</TableCell>
                                        <TableCell align="center" >FNSKU</TableCell>
                                        <TableCell align="center" >Upc</TableCell>
                                        <TableCell align="center" >Component Upc</TableCell>
                                        <TableCell align="center" >Mpn</TableCell>
                                        <TableCell align="center" >Case Quantity</TableCell>
                                        <TableCell align="center" >Quantity</TableCell>
                                        <TableCell align="center">Created At</TableCell>
                                        <TableCell align="center">Updated At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.contents.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">{row.id}</TableCell>
                                        <TableCell align="center">{row.sku}</TableCell>
                                        <TableCell align="center">{row.asin}</TableCell>
                                        <TableCell align="center">{row.fnsku}</TableCell>
                                        <TableCell align="center">{row.upc}</TableCell>
                                        <TableCell align="center">{row.componentUpc}</TableCell>
                                        <TableCell align="center">{row.mpn}</TableCell>
                                        <TableCell align="center">{row.caseQty}</TableCell>
                                        <TableCell align="center">{row.quantity}</TableCell>
                                        <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                        <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        <br/>
                        <p style={{fontSize: 13, float: 'right'}}>
                            {getShowingResultsPhrase(10, this.state.contentsCount)}
                        </p>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
 
export default OutboundFeedContents;
