import React from "react";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from '@material-ui/core/styles';
import {Clear, Delete, Save} from "@material-ui/icons";
import {format2NiceDate} from "../../utils/DateUtils";
import Form from "react-bootstrap/Form";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export function UpcPullContentsTableRow(props) {
    const {row, index, isProcessing, onChangeUpc, onChangeQtyDesired, saveQty, cancelSaveQty,
        deleteContent} = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell align="right">
                    {row.id}
                </TableCell>
                <TableCell align="left">
                    <Form.Control size="sm" type="text" style={{width: 300,}}
                                  value={row.upc}
                                  onChange={(e) => onChangeUpc(e, index)}/>
                </TableCell>
                <TableCell align="right">{row.unitsOrdered}</TableCell>
                <TableCell align="right">
                    <Form.Control size="sm" type="number" style={{width: 100,}}
                                  value={row.qtyDesired}
                                  onChange={(e) => onChangeQtyDesired(e, index)}/>
                </TableCell>
                <TableCell align="right">{row.qtyPulled}</TableCell>
                <TableCell align="right">
                    {format2NiceDate(row.createdAt)}
                </TableCell>
                <TableCell align="right">
                    {format2NiceDate(row.updatedAt)}
                </TableCell>
                <TableCell>
                    <IconButton color="primary" style={{padding: 6}}
                                disabled={isProcessing || !row.edited}
                                onClick={() => saveQty(index)}>
                        <Save/>
                    </IconButton>
                    <IconButton color="default" style={{padding: 6}}
                                disabled={isProcessing || !row.edited}
                                onClick={() => cancelSaveQty(index)}>
                        <Clear/>
                    </IconButton>
                    <IconButton color="secondary" style={{padding: 6}}
                                disabled={isProcessing || row.edited}
                                onClick={() => deleteContent(index)}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
