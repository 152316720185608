import React, {Component} from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {alertError, getIdFromUrl, handleErr} from "../../utils/MiscellaniousUtils";
import ResourceAPIs from "../../utils/ResourceAPI";
import {DELETE, SAVE, UNDEFINED} from "../../constants";
import {WholesaleOrderContentsTableRow} from "./WholesaleOrderContentsTableRow";

const MySwal = withReactContent(Swal);

class WholesaleOrderContents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            wholesaleOrderId: this.props.wholesaleOrderId,
            contents: [],
            isLoaded: false,
            deleteBtnText: DELETE,
            isProcessing: false,
        };

        this.onChangeQtyDesired = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].qtyDesired = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSku = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].sku = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInvoice = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].invoice = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = false;
            stateCopy.contents[index].qtyDesired = this.state.contents[index].untouchedQtyDesired;
            stateCopy.contents[index].sku = this.state.contents[index].untouchedSku;
            stateCopy.contents[index].invoice = this.state.contents[index].untouchedInvoice;
            this.setState(stateCopy);
        };
    }

    getWholesaleOrderContents = () => {
        new ResourceAPIs().getWholesaleOrderContentsById(getIdFromUrl())
            .then(res=> {
                    const result = res.data;
                    this.setState({
                        contents: this.addUntouchedQty(result),
                        isLoaded: true,
                    });
                })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    };

    addUntouchedQty = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            // set empty for null
            if (arr[i].sku === null) {
                arr[i].sku = "";
            }
            if (arr[i].invoice === null) {
                arr[i].invoice = "";
            }

            arr[i].untouchedSku = arr[i].sku;
            arr[i].untouchedInvoice = arr[i].invoice;
            arr[i].untouchedQtyDesired = arr[i].qtyDesired;
        }
        return arr;
    };

    saveWholesaleOrderContent = index => {
        this.setState({
            isProcessing: true,
        });
        let content = this.state.contents[index];
        let id = parseInt(content.id, 10);
        let sku = content.sku;
        let invoice = content.invoice;
        let qtyDesired = parseInt(content.qtyDesired, 10);
        if (id > 0 && qtyDesired >= 0) {
            new ResourceAPIs().updateWholesaleOrderContent(id, sku, qtyDesired, invoice)
                .then(res => {
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.isProcessing = false;
                        stateCopy.contents[index].edited = false;
                        stateCopy.contents[index].untouchedQtyDesired = this.state.contents[index].qtyDesired;
                        stateCopy.contents[index].untouchedSku = this.state.contents[index].sku;
                        stateCopy.contents[index].untouchedInvoice = this.state.contents[index].invoice;
                        this.setState(stateCopy);
                    })
                .catch(error => {
                        this.setState({
                            isProcessing: false,
                        });
                        handleErr(error);
                    });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        } else {
            alertError("qty desired should be greater than or equal to 0");
        }
        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    deleteContent = (index) => {
        let contentId = this.state.contents[index].id;
        MySwal.fire({
            title: 'You want to Delete Content with ID ' + contentId + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPIs().deleteWholesaleOrderContent(contentId)
                    .then(res => {
                            MySwal.fire(
                                'Deleted!',
                                'Content ' + contentId + ' has been deleted.',
                                'success'
                            );
                            this.getWholesaleOrderContents();
                        })
                    .catch(error => {
                        this.setState({
                                deleteContentBtnText: "Delete All Contents",
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        })
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return {value: store.id, label: store.id + " - " + store.name, name: store.name}
                    });

                    this.setState({
                        stores: storesIds,
                    });

                }, (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getStoreName = (storeId) => {
        for (let store of this.state.stores) {
            if (store.value === storeId) {
                return store.name;
            }
        }
        return UNDEFINED;
    };

    componentDidMount() {
        this.getWholesaleOrderContents();
        this.getStores();
    }

    componentWillReceiveProps({someProp}) {
        if (this.props.isLoad) {
            this.getWholesaleOrderContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">Wholesale Order Contents</Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{width: "5%"}}>Content ID</TableCell>
                                            <TableCell align="center" style={{width: "25%"}}>Basic Fields</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Unit Count</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Units Ordered</TableCell>
                                            <TableCell align="center" style={{width: "10%"}}>Qty Desired</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Qty Pulled</TableCell>
                                            <TableCell align="center" style={{width: "20%"}}>Barcode Fields</TableCell>
                                            <TableCell align="center" style={{width: "20%"}}>Product Name</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Created At</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Updated At</TableCell>
                                            <TableCell style={{width: "10%"}}>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <WholesaleOrderContentsTableRow key={row.id}
                                                   row={row}
                                                   index={index}
                                                   isProcessing={this.state.isProcessing}
                                                                  onChangeSku={this.onChangeSku}
                                                                 onChangeInvoice={this.onChangeInvoice}
                                                                 onChangeQtyDesired={this.onChangeQtyDesired}
                                                                 saveQty={this.saveWholesaleOrderContent}
                                                                 cancelSaveQty={this.cancelSaveQty}
                                                                 deleteContent={this.deleteContent}
                                                                 getStoreName={this.getStoreName}/>

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default WholesaleOrderContents;
