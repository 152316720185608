import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { format2NiceDate } from "../utils/DateUtils";
import { Add } from "@material-ui/icons";
import { handleError } from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconButton from "@material-ui/core/IconButton";
import { Delete } from "@material-ui/icons";

const MySwal = withReactContent(Swal);

export const STORES = "stores";
const STORE = "store";

class Stores extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            results: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.fetchStores();
    }

    fetchStores() {
        new ResourceAPIs().getStores().then(response => {
            this.setState({
                results: response.data,
                isLoaded: true,
            });
        }).catch(error => {
            handleError(error);
            this.setState({
                results: error,
                isLoaded: true,
            });
        });
    }

    handleAddNew = () => {
        new ResourceAPIs().addStores().then(response => {
            window.open("/" + STORE + "/" + response.data);
        }).catch(error => {
            handleError(error);
        });
    };

    deleteStore = (index) => {
        let id = this.state.results[index].id;
        MySwal.fire({
            title: 'You want to Delete this Store with ID ' + id + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPIs().deleteStore(id).then(response => {
                    MySwal.fire(
                        'Deleted!',
                        'Store ' + id + ' has been deleted.',
                        'success'
                    );
                    this.fetchStores();
                }).catch(error => {
                    handleError(error);
                    this.setState({
                        isProcessing: false,
                        results: error,
                    });
                });
            }
        });
    };

    render() {
        return (
            <div>
                <Row style={{ display: 'block', textAlign: 'right', marginRight: '2%' }}>
                    <Button variant="outline-primary" onClick={this.handleAddNew}><Add /> Add New Store</Button>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Type</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                            <TableCell align="center">Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/" + STORE + "/" + row.id} target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="right">{row.clientId}</TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.type}</TableCell>
                                                <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton color="secondary" style={{ padding: 6 }}
                                                        disabled={this.state.isProcessing}
                                                        onClick={() => this.deleteStore(index)}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Stores;
