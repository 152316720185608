import React, {Component} from "react";
import {format2NiceDate} from "../utils/DateUtils";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import {Clear, Delete, Save} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {alertError, getIdFromUrl, handleErr} from "../utils/MiscellaniousUtils";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import {SAVE} from "../constants";
import ResourceAPI from "../utils/ResourceAPI";
import { DOWNLOAD } from "./POConstants";
import fileDownload from "js-file-download";
import {getFormattedDate} from "../orders/OrdersExporter";
import Button from "react-bootstrap/Button";
import { showPullDownloadPRSpinner } from "./POUtils";

const MySwal = withReactContent(Swal);

const barcodeBadge = {
    width: 50,
};

const barcodeBadge2 = {
    width: 65,
};

const barcodeBadge3 = {
    width: 65,
};

class PurchaseOrderContents extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            purchaseOrder: this.props.purchaseOrder,
            contents: [],
            isLoaded: false,
            deleteBtnText: "Delete",
            isProcessing: false,
            downloadPRBtnText: DOWNLOAD,
        };

        this.onChangeQtyDesired = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].qtyDesired = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSku = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].sku = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInvoice = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].invoice = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = false;
            stateCopy.contents[index].sku = this.state.contents[index].untouchedSku;
            this.setState(stateCopy);
        };

        this.handleDownloadPullReport = () => {
            this.setState({
                downloadPRBtnText: "Downloading",
                isProcessing: true,
            });
            new ResourceAPI().exportPOPullReports(getIdFromUrl())
                .then(res => {
                        this.setState({
                            downloadPRBtnText: DOWNLOAD,
                            isProcessing: false,
                        });
                        const dd = getFormattedDate(new Date());
                        fileDownload(res.data, "po-pull-report-" + dd + ".csv");
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            downloadPRBtnText: DOWNLOAD,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };
    }

    getPullRequestContents = () => {
        new ResourceAPI().getPOContentsById(getIdFromUrl())
            .then(res=> {
                const result = res.data;
                    this.setState({
                        contents: this.addPOData(result),
                        isLoaded: true,
                    })
                })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            );
    };

    addPOData = (arr) => {
        // console.log("***update contents****");
        for (let i = 0; i < arr.length; i++) {
            arr[i].clientId = this.state.purchaseOrder.clientId;
            arr[i].storeName = this.state.purchaseOrder.storeName;
            arr[i].status = this.state.purchaseOrder.status;
            arr[i].supplier = this.state.purchaseOrder.supplier;
            arr[i].comments = this.state.purchaseOrder.comments;
            arr[i].businessReportId = this.state.purchaseOrder.businessReportId;
            arr[i].fbaInventoryId = this.state.purchaseOrder.fbaInventoryId;
            arr[i].warehouseId = this.state.purchaseOrder.warehouseId;
            arr[i].replenishmentId = this.state.purchaseOrder.replenishmentId;
            arr[i].untouchedSku = arr[i].sku;
        }
        return arr;
    };

    saveQty = (index) => {
        this.setState({
            isProcessing: true,
        });
        let content = this.state.contents[index];
        let id = parseInt(content.id, 10);
        let sku = content.sku;

        if (id > 0) {
            new ResourceAPI().updatePOContentsById(id, sku)
                .then(res => {
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.isProcessing = false;
                        stateCopy.contents[index].edited = false;
                        stateCopy.contents[index].untouchedSku = this.state.contents[index].sku;
                        this.setState(stateCopy);
                    })
                .catch(error => {
                        this.setState({
                            isProcessing: false,
                        });
                        handleErr(error);
                    });
        } else {
            alertError("invalid id: " + id);
        }
        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    deleteContent = (index) => {
        let contentId = this.state.contents[index].id;
        MySwal.fire({
            title: 'You want to Delete Content with ID ' + contentId + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPI().deletePOContentById(contentId)
                    .then(res => {
                            MySwal.fire(
                                'Deleted!',
                                'Content ' + contentId + ' has been deleted.',
                                'success'
                            );
                            this.getPullRequestContents();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                deleteContentBtnText: "Delete All Contents",
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        })
    };

    componentDidMount() {
        this.getPullRequestContents();
    }

    componentWillReceiveProps({someProp}) {
        // console.log(this.props);
        if (this.props.isLoad) {
            this.getPullRequestContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">
                            <span>Purchase Order Contents</span>
                            <div style={{float: 'right'}}>
                                <Button variant="light"
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleDownloadPullReport}
                                        size="sm">
                                    {showPullDownloadPRSpinner(this.state.downloadPRBtnText)}
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{width: "5%"}}>Content ID</TableCell>
                                            <TableCell align="center" style={{width: "20%"}}>SKU</TableCell>
                                            <TableCell align="center" style={{width: "20%"}}>Listing Details</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>Product Name</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>Repurchasing Values</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>Buy Recommendation</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Created At</TableCell>
                                            <TableCell align="center" style={{width: "5%"}}>Updated At</TableCell>
                                            <TableCell style={{width: "10%"}}>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="right">{row.id}</TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={{width: 300,}}
                                                                  value={row.sku}
                                                                  onChange={(e) => this.onChangeSku(e, index)}/>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Badge variant="secondary" style={barcodeBadge}>ASIN</Badge> {row.asin}<br/>
                                                    <Badge variant="primary" style={barcodeBadge}>FNSKU</Badge> {row.fnsku}<br/>
                                                    <Badge variant="success" style={barcodeBadge}>UPC</Badge> {row.upc}<br/>
                                                    <Badge variant="info" style={barcodeBadge}>Co UPC</Badge> {row.coUpc}<br/>
                                                    <Badge variant="dark" style={barcodeBadge}>MPN</Badge> {row.mpn}

                                                </TableCell>
                                                <TableCell>{row.productName}</TableCell>
                                                <TableCell align="left">
                                                    <Badge variant="secondary" style={barcodeBadge2}>WHSE</Badge> {row.whse}<br/>
                                                    <Badge variant="success" style={barcodeBadge2}>Feed</Badge> {row.feed}<br/>
                                                    <Badge variant="primary" style={barcodeBadge2}>FBA</Badge> {row.fba}<br/>
                                                    <Badge variant="info" style={barcodeBadge2}>BUS RPT</Badge> {row.busRpt}<br/>
                                                    <Badge variant="dark" style={barcodeBadge2}>Case Qty</Badge> {row.caseQty}<br/>
                                                    <Badge variant="warning" style={barcodeBadge2}>Min Stock</Badge> {row.minStock}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Badge variant="secondary" style={barcodeBadge3}>Fulfilment</Badge> {row.fulfilment}<br/>
                                                    <Badge variant="primary" style={barcodeBadge3}>Cost</Badge> {row.cost}<br/>
                                                    <Badge variant="success" style={barcodeBadge3}>Needed</Badge> {row.needed}<br/>
                                                    <Badge variant="info" style={barcodeBadge3}>Cases</Badge> {row.cases}<br/>
                                                    <Badge variant="dark" style={barcodeBadge3}>Total</Badge> {row.total}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.createdAt)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton color="primary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.saveQty(index)}>
                                                        <Save/>
                                                    </IconButton>
                                                    <IconButton color="default" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.cancelSaveQty(index)}>
                                                        <Clear/>
                                                    </IconButton>
                                                    <IconButton color="secondary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || row.edited}
                                                                onClick={() => this.deleteContent(index)}>
                                                        <Delete/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default PurchaseOrderContents;
