import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {SAVE, SAVING} from "../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate, isValidDate, YYYY_MM_DD} from "../utils/DateUtils";
import {createTypeOption, getIdFromUrl, getLabel, handleErr, showSaveSpinner} from "../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import {Clear, Info} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettlementReportContents from "./SettlementReportContents";
import ResourceAPIs from "../utils/ResourceAPI";
import Select from "react-select";

class SettlementReportDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            breport: {
                id: "",
                clientId: "",
                storeId: "",
                storeName: "",
                startDate: "",
                endDate: "",
                createdAt: "",
                updatedAt: "",
                errMsg: "",
            },
            clientLabel: "",
            storeLabel: "",
            selectedFile: null,
            isLocationLoaded: false,
            isProcessing: false,
            addContentBtnText: "Add Contents",
            deleteContentBtnText: "Delete All Contents",
            editLocationBtnText: SAVE,
            errMsg: "",
            childLoad: false,
            clients : [],
            storesIds : []
        };

        // Edit breport

        this.onChangeClientIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.breport.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeStoreIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.breport.storeId = selected.value;
            newState.storeLabel = getLabel(this.state.storesIds, selected.value);
            this.setState(newState);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.breport.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeStartDate = (event) => {
            let newState = Object.assign({}, this.state);
            newState.breport.startDate = event.target.value;
            this.setState(newState);
        };

        this.onChangeEndDate = (event) => {
            let newState = Object.assign({}, this.state);
            newState.breport.endDate = event.target.value;
            this.setState(newState);
        };

        // Add contents

        this.onChangeAddContentQty = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.qty = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentBarcodes = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.barcodes = event.target.value;
            this.setState(newState);
        };

        this.handleEditSettlementReport = () => {
            if (this.validateEditLocation()) {
                this.setState({
                    editLocationBtnText: SAVING,
                    isProcessing: true,
                });

                new ResourceAPIs().updateSettlementReport(
                    getIdFromUrl(),
                    this.state.breport.clientId, 
                    this.state.breport.storeId,
                    this.state.breport.storeName,
                    this.state.breport.startDate,
                    this.state.breport.endDate, 
                    this.state.addContent).then(response => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            clientLabel : getLabel(this.state.clients, this.state.breport.clientId),
                            editLocationBtnText: SAVE,
                            isProcessing: false,
                            childLoad: false,
                        });
                    }).catch(error => {
                        handleErr(error);
                        this.setState({
                            editLocationBtnText: SAVE,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };

        this.handleCancelEditLocation = () => {
            this.getSettlementReportById();
        };

        this.onChangeSelectedFile = event => {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
            })
        };

        this.handleAddContents = () => {
            const data = new FormData();
            data.append('file', this.state.selectedFile);

            if (this.validateAddContentsInputs()) {
                this.setState({
                    addContentBtnText: "Adding Contents",
                    isProcessing: true,
                });
                new ResourceAPIs().addSettlementReportContents(
                    getIdFromUrl(), data).then(response => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            addContentBtnText: "Add Contents",
                            isProcessing: false,
                            childLoad: false,
                        });
                        this.resetAddContentFrom();
                    }).catch(error => {
                        handleErr(error);
                        this.setState({
                            addContentBtnText: "Add Contents",
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };
    }

    validateAddContentsInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                errMsg: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                errMsg: "",
            });
        }
        return true;
    };

    validateEditDetails = () => {
        // validate qty
        if (parseInt(this.state.addContent.qty, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.addContent.qty = parseInt(this.state.addContent.qty, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid Quantity"
            });
            return false;
        }

        // validate barcodes
        if (this.state.addContent.barcodes === "") {
            this.setState({
                errMsg: "Invalid Barcodes"
            });
            return false;
        }

        // start date
        if (!isValidDate(this.state.startDate, YYYY_MM_DD)) {
            this.setState({
                errMsg: "Invalid Start Date, Correct format: yyyy-MM-dd"
            });
            return false;
        }

        // end date
        if (!isValidDate(this.state.endDate, YYYY_MM_DD)) {
            this.setState({
                errMsg: "Invalid End Date, Correct format: yyyy-MM-dd"
            });
            return false;
        }

        return true;
    };

    validateEditLocation = () => {
        // validate client id
        if (parseInt(this.state.breport.clientId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.breport.clientId = parseInt(this.state.breport.clientId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Client ID");
        }

        // validate store name
        if (this.state.breport.storeName === "") {
            return this.setLocationEditErr("Invalid Location Name");
        }


        return true;
    };

    setLocationEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.breport.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getSettlementReportById = () => {
        new ResourceAPIs().getSettlementReportById(getIdFromUrl()).then(response => {
                let result = response.data;
                this.setState({
                    breport: {
                        id: result.id,
                        clientId: result.clientId,
                        storeId: result.storeId,
                        storeName: result.storeName,
                        startDate: result.startDate,
                        endDate: result.endDate,
                        createdAt: result.createdAt,
                        updatedAt: result.updatedAt,
                    },
                    clientLabel : getLabel(this.state.clients, result.clientId),
                    storeLabel : getLabel(this.state.storesIds, result.storeId),
                    isLocationLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLocationLoaded: false,
                    error
                });
            });
    };

    resetAddContentFrom = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            selectedFile: null,
            theInputField: randomString
        });
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });
                    this.getSettlementReportById();
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <Row>
                        <Col xs={8}>
                            <Card border="secondary">
                                <Card.Header as="h5">Settlement Report Details</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number"
                                                                      value={this.state.breport.id}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Client ID</Form.Label>
                                                    </Col>
                                                    {this.props.id !== undefined &&
                                                        <Col xs={8}>
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientIdValue(e)}
                                                                options={this.state.clients}
                                                                isDisabled={true}
                                                            />
                                                        </Col>
                                                    }
                                                    {this.props.id === undefined &&
                                                        <Col xs={8}>
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientIdValue(e)}
                                                                options={this.state.clients}
                                                            />
                                                        </Col>
                                                    }
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Store ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Select
                                                            value={createTypeOption(this.state.storeLabel)}
                                                            onChange={(e) => this.onChangeStoreIdValue(e)}
                                                            options={this.state.storesIds}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Store Name</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text"
                                                                      value={this.state.breport.storeName}
                                                                      onChange={this.onChangeStoreName}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>


                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>
                                                            Start Date
                                                            <Tooltip title="Format: yyyy-MM-dd">
                                                                <IconButton aria-label="delete" size="small">
                                                                    <Info/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text"
                                                                      value={this.state.breport.startDate}
                                                                      onChange={this.onChangeStartDate}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>
                                                            End Date
                                                            <Tooltip title="Format: yyyy-MM-dd">
                                                                <IconButton aria-label="delete" size="small">
                                                                    <Info/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text" size="sm"
                                                                      value={this.state.breport.endDate}
                                                                      onChange={this.onChangeEndDate}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.breport.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.breport.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div style={{textAlign: "center",}}>
                                        <Form.Text className="text-muted">
                                            {this.state.breport.errMsg}
                                        </Form.Text>
                                        <br/>
                                        <Button variant="success" style={{width: 200}}
                                                disabled={this.state.isProcessing}
                                                onClick={this.handleEditSettlementReport}>
                                            {showSaveSpinner(this.state.editLocationBtnText)}
                                        </Button>
                                        <Button variant="secondary" style={{marginLeft: 10, width: 200}}
                                                onClick={this.handleCancelEditLocation}
                                                disabled={this.state.isProcessing}>
                                            <Clear/> Cancel
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Card border="secondary">
                                <Card.Header as="h5">Add Settlement Report Contents</Card.Header>
                                <Card.Body style={{height: 265}}>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <Form.Label>Select File</Form.Label>
                                                    <br/>
                                                    <input type="file" name="file"
                                                           onChange={this.onChangeSelectedFile}
                                                           key={this.state.theInputField}/>
                                                </Form.Group>
                                                <span style={{fontSize: 12, color: "red",}}>
                                                        {this.state.errMsg}
                                                    </span>
                                                <br/>
                                                <Button variant="success" style={{width: 200}}
                                                        disabled={this.state.isProcessing}
                                                        onClick={this.handleAddContents}>
                                                    {showSpinner(this.state.addContentBtnText)}
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <SettlementReportContents breport={this.state.breport} isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

function showSpinner(btnText) {
    if (btnText === "Add Contents") {
        return <span><AddToPhotosIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default SettlementReportDetails;
