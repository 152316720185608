import React, {Component} from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import {handleErr} from "../utils/MiscellaniousUtils";
import Spinner from "react-bootstrap/Spinner";
import {Description} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import ResourceAPIs from "../utils/ResourceAPI";
import OrderPullRequestImporter from "../orders/order-pull-requests/OrderPullRequestImporter";

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
        height: 440,
    },
});

class UnbilledOrderFulfilment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            results: [],
            isProcessing: false,
        };
    }

    get = () => {
        new ResourceAPIs().getUnbilledOrderFulfilments()
            .then(res => {
                const result = res.data;
                    this.setState({
                        result: result,
                        isLoaded: true,
                    });
                })
            .catch(error => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            );
    };

    onClickExport = (clientId) => {
        this.setState({
            isProcessing: true,
        });
        new ResourceAPIs().exportUnbilledOrderFulfilments(clientId)
            .then(res => {
                    this.setState({
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "clientId-" + clientId + "-order-pr-contents" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    componentDidMount() {
        this.get();
    }

    render() {
        const {classes} = this.props;

        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <h5>Unbilled Order Fulfilment</h5>
                    <br/>
                    <OrderPullRequestImporter miniWidget={true}/>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Client ID</TableCell>
                                        <TableCell align="center">Client Name</TableCell>
                                        <TableCell align="center">Qty Unbilled</TableCell>
                                        <TableCell align="center">Unbilled Orders</TableCell>
                                        <TableCell align="center">Export</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.result.map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                <TableCell align="right">
                                                    <a href={"/clients/" + row.clientId} target="_blank">
                                                        {row.clientId}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="left">{row.clientName}</TableCell>
                                                <TableCell align="right">{row.qtyUnbilled}</TableCell>
                                                <TableCell align="right">{row.unbilledOrders}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton style={{padding: 6}}
                                                                disabled={this.state.isProcessing}
                                                                onClick={() => this.onClickExport(row.clientId)}
                                                                color="primary">
                                                        {showExportSpinner(this.state.isProcessing)}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            );
        }
    }
}

export function showExportSpinner(isProcessing) {
    if (isProcessing === false) {
        return <span><Description/></span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/></span>;
    }
}

export default withStyles(useStyles)(UnbilledOrderFulfilment);
