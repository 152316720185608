import React, {Component} from "react";
import ListingsExporter from "./ListingsExporter";
import ListingsImporter from "./ListingsImporter";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ListingsSearch from "./ListingsSearch";
import {BULK_ACTIONS} from "../constants";
import AmazonListingsImporter from "./AmazonListingsImporter";
import ListingWarehouseContentsPuller from "./QtyInWarehouseUpdator";


class Listings extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    hasProps = () => {
        if (this.props.location.search) {
            const query = new URLSearchParams(this.props.location.search);
            const barcode = query.get('barcode');
            return <ListingsSearch barcode={barcode} id={this.props.id}/>;
        } else {
            return <ListingsSearch id={this.props.id}/>
        }
    };

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="search">Search Listings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="amazonListings">Import Amazon Listings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="listingWhContentsPuller">Listing Warehouse Contents Puller</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="search">
                            <br/>
                            {this.hasProps()}
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                        <Tab.Pane eventKey="amazonListings">
                            <br/>
                            <AmazonListingsImporter/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                        <Tab.Pane eventKey="listingWhContentsPuller">
                            <br/>
                            <ListingWarehouseContentsPuller/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                        <Tab.Pane eventKey="importAndExport">
                            <br/>
                            <ListingsImporter/>
                            <br/>
                            <ListingsExporter/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }
}

export default Listings;
