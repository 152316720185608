import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { format2NiceDate } from "../../utils/DateUtils";
import { FaFilter } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import { Clear, Save, SkipNext, SkipPrevious } from "@material-ui/icons";
import { alertError, createTypeOption, getLabel, handleErr, showExportSpinner } from "../../utils/MiscellaniousUtils";
import { eqOptions, EXPORT, EXPORTING, FILTER, FILTERING, intOptions, SAVE, SAVING } from "../../constants";
import { Link } from "react-router-dom";
import { getFormattedDate } from "../../orders/OrdersExporter";
import fileDownload from "js-file-download";
import ResourceAPIs from "../../utils/ResourceAPI";
import Select from "react-select";
import { Mobile, Default } from "../../utils/MediaUtils";
import Badge from "react-bootstrap/Badge";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const qtyInput = {
    width: 60,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const qtySaveBtn = {
    fontSize: 13,
    height: 30,
    padding: '0px 3px 0px 3px',
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class ContentsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                barcode: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            exportBtnText: EXPORT,
            clients: [],
        };

        this.onChangeWHValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.warehouseId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeLocationIdValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationId.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeLocationNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeBarcodeValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.barcode.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeClientIdValue = (selected) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeQty = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].quantity = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeLot = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].lot = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSave = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = false;
            stateCopy.results[index].quantity = this.state.results[index].untouchedQty;
            stateCopy.results[index].lot = this.state.results[index].untouchedLot;
            this.setState(stateCopy);
        };
    }

    componentDidMount() {
        this.getClients();
        if (this.props.barcode !== undefined) {
            let newState = Object.assign({}, this.state);
            newState.filters.barcode.value = this.props.barcode;
            this.setState(newState, () => this.handleFilter());
        }
    }

    onChangeWHFilter = selectedValue => {
        this.setState(prevState => ({
            filters: {
                warehouseId: {
                    condition: selectedValue,
                    value: prevState.filters.warehouseId.value,
                },
                locationId: prevState.filters.locationId,
                locationName: prevState.filters.locationName,
                barcode: prevState.filters.barcode,
                size: prevState.filters.size,
                from: prevState.filters.from,
            }
        }));
    };

    onChangeLocationIdFilter = selectedValue => {
        this.setState(prevState => ({
            filters: {
                warehouseId: prevState.filters.warehouseId,
                locationId: {
                    condition: selectedValue,
                    value: prevState.filters.locationId.value,
                },
                locationName: prevState.filters.locationName,
                barcode: prevState.filters.barcode,
                size: prevState.filters.size,
                from: prevState.filters.from,
            }
        }));
    };

    onChangeLocationNameFilter = selectedValue => {
        this.setState(prevState => ({
            filters: {
                warehouseId: prevState.filters.warehouseId,
                locationId: prevState.filters.locationId,
                locationName: {
                    condition: selectedValue,
                    value: prevState.filters.locationName.value,
                },
                barcode: prevState.filters.barcode,
                size: prevState.filters.size,
                from: prevState.filters.from,
            }
        }));
    };


    onChangeBarcodeFilter = selectedValue => {
        this.setState(prevState => ({
            filters: {
                warehouseId: prevState.filters.warehouseId,
                locationId: prevState.filters.locationId,
                locationName: prevState.filters.locationName,
                barcode: {
                    condition: selectedValue,
                    value: prevState.filters.barcode.value,
                    size: prevState.filters.size,
                    from: prevState.filters.from,
                },
            }
        }));
    };

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilterOnEnter = (event) => {
        console.log(event.keyCode);
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchWarehouseContents();
    };

    addUntouchedQty = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedQty = arr[i].quantity;
            arr[i].untouchedLot = arr[i].lot;
        }
        return arr;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                barcode: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchWarehouseContents();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchWarehouseContents();
    };

    searchWarehouseContents = () => {
        if (this.validateInputs()) {
            this.setState({
                filterBtnText: FILTERING,
                isProcessing: true,
            });
            new ResourceAPIs().getSearchWHContents(this.state.filters)
                .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: this.addUntouchedQty(res.data),
                    });
                }).catch(error => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                });
        } else {
            alertError("invalid inputs");
        }
    };

    saveQty = (index) => {
        this.setState({
            saveQtyBtnText: SAVING,
            isProcessing: true,
        });
        let content = this.state.results[index];
        let id = parseInt(content.id, 10);
        let qty = parseInt(content.quantity, 10);
        let lot = content.lot;
        if (id > 0) {
            new ResourceAPIs().updateWHContentQty(id, qty, lot, this.state.filters)
                .then(res => {
                    let stateCopy = Object.assign({}, this.state);
                    stateCopy.saveQtyBtnText = SAVE;
                    stateCopy.isProcessing = false;
                    stateCopy.results[index].edited = false;
                    stateCopy.results[index].untouchedQty = this.state.results[index].quantity;
                    stateCopy.results[index].untouchedLot = this.state.results[index].lot;
                    this.setState(stateCopy);
                }).catch(error => {
                    this.setState({
                        saveQtyBtnText: SAVE,
                        isProcessing: false,
                    });
                    handleErr(error);
                });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        }
        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };


    validateInputs = () => {
        // validate warehouse
        if (parseInt(this.state.filters.warehouseId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = parseInt(this.state.filters.warehouseId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid warehouse ID"
            });
            return false;
        }

        // validate location id
        if (parseInt(this.state.filters.locationId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationId.value = parseInt(this.state.filters.locationId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid location ID"
            });
            return false;
        }

        return true;
    };

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().getSearchExportWHContents(this.state.filters)
            .then(res => {
                this.setState({
                    exportBtnText: EXPORT,
                    isProcessing: false,
                    err: "",
                });
                const dd = getFormattedDate(new Date());
                fileDownload(res.data, "warehouse-content-search-export-" + dd + ".csv");
            }).catch(error => {
                this.setState({
                    exportBtnText: EXPORT,
                    isProcessing: false,
                    err: error,
                });
                handleErr(error);
            });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Label style={filterLabel}>Warehouse</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol} xs={4}>
                                                <WHFilterSelect options={intOptions}
                                                    handleOnChange={this.onChangeWHFilter} />
                                            </Col>
                                            <Col style={inputCol} xs={8}>
                                                <Form.Control size="sm" type="number"
                                                    value={this.state.filters.warehouseId.value}
                                                    onChange={this.onChangeWHValue}
                                                    onKeyDown={this.handleFilterOnEnter} />
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Location ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol} xs={4}>
                                                <WHFilterSelect options={intOptions}
                                                    handleOnChange={this.onChangeLocationIdFilter} />
                                            </Col>
                                            <Col style={inputCol} xs={8}>
                                                <Form.Control size="sm" type="number"
                                                    onChange={this.onChangeLocationIdValue}
                                                    value={this.state.filters.locationId.value}
                                                    onKeyDown={this.handleFilterOnEnter} />
                                            </Col>
                                        </Row>
                                        {this.props.clientId === undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol} xs={4}>
                                                        <WHFilterSelect options={eqOptions}
                                                            handleOnChange={this.onChangeClientIdFilter} />
                                                    </Col>
                                                    <Col style={inputCol} xs={8}>
                                                        <Select
                                                            value={createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.props.clientId !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol} xs={4}>
                                                        <WHFilterSelect options={eqOptions}
                                                            handleOnChange={this.onChangeClientIdFilter} />
                                                    </Col>
                                                    <Col style={inputCol} xs={8}>
                                                        <Form.Control size="sm" type="text"
                                                            value={this.props.clientId}
                                                            disabled={true} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Label style={filterLabel}>Location Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol} xs={4}>
                                                <WHFilterSelect options={eqOptions}
                                                    handleOnChange={this.onChangeLocationNameFilter} />
                                            </Col>
                                            <Col style={inputCol} xs={8}>
                                                <Form.Control size="sm" type="text"
                                                    onChange={this.onChangeLocationNameValue}
                                                    value={this.state.filters.locationName.value}
                                                    onKeyDown={this.handleFilterOnEnter} />
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Barcode</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol} xs={4}>
                                                <WHFilterSelect options={eqOptions}
                                                    handleOnChange={this.onChangeBarcodeFilter} />
                                            </Col>
                                            <Col style={inputCol} xs={8}>
                                                <Form.Control size="sm" type="text"
                                                    value={this.state.filters.barcode.value}
                                                    onChange={this.onChangeBarcodeValue}
                                                    onKeyDown={this.handleFilterOnEnter} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Text className="text-muted">
                                            {this.state.errMsg}
                                        </Form.Text>
                                        <Row style={{ display: 'block', textAlign: 'center' }}>
                                            <IconButton onClick={this.handlePrev}
                                                disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious />
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                                (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext />
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{ display: 'block', textAlign: 'center' }}>
                                            <Button variant="success" style={filterBtn}
                                                onClick={this.handleFilter}
                                                disabled={this.state.isProcessing}>
                                                <FaFilter /> {this.state.filterBtnText}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>

                                        <Row style={{ display: 'block', textAlign: 'center', paddingTop: '10px' }}>
                                            <br />
                                            <Button variant="warning" onClick={this.onClickExport}
                                                disabled={this.state.isProcessing}
                                                style={{ width: '150px' }}>
                                                {showExportSpinner(this.state.exportBtnText)}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </Col>
                    <Mobile>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '60%' }}>ID</TableCell>
                                                <TableCell align="center" style={{ width: '40%' }}>Lot / Qty</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell align="left">
                                                        <b>{row.id}</b>
                                                        <br />
                                                        <Badge variant="primary" style={{ width: 50 }}>Client</Badge> {getLabel(this.state.clients, row.clientId)}<br />
                                                        <Badge variant="secondary" style={{ width: 50 }}>WH</Badge> {row.warehouseId}<br />
                                                        <Badge variant="warning" style={{ width: 50 }}>Location</Badge> <a href={"/wh-locations/" + row.locationId}
                                                            target="_blank">{row.locationName}</a><br />
                                                        <Badge variant="success" style={{ width: 50 }}>Barcode</Badge> <Link to={"/listings?barcode=" + row.barcode}
                                                            target={"_blank"}>{row.barcode}</Link><br />
                                                        <i style={{ fontSize: 9 }}>Created: {format2NiceDate(row.createdAt)}</i>
                                                        <br />
                                                        <i style={{ fontSize: 9 }}>Updated: {format2NiceDate(row.updatedAt)}</i>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="primary" style={{ width: 50 }}>Lot</Badge>
                                                        <Form.Control size="sm" type="text" style={{width: 100}}
                                                            value={row.lot}
                                                            onChange={(e) => this.onChangeLot(e, index)} />
                                                        <Badge variant="secondary" style={{ width: 50 }}>Qty</Badge>
                                                        <Form.Control size="sm" type="number" style={{width: 100}}
                                                            value={row.quantity}
                                                            onChange={(e) => this.onChangeQty(e, index)} />
                                                        <IconButton color="primary"
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            style={{
                                                                fontSize: 13,
                                                                height: 30,
                                                                padding: '0px 3px 0px 3px',
                                                                marginRight: 5
                                                            }}
                                                            onClick={() => this.saveQty(index)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton color="default"
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            style={qtySaveBtn}
                                                            onClick={() => this.cancelSave(index)}>
                                                            <Clear />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Mobile>
                    <Default>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">Client</TableCell>
                                                <TableCell align="center">Location</TableCell>
                                                <TableCell align="center">Warehouse</TableCell>
                                                <TableCell align="center">Quantity</TableCell>
                                                <TableCell align="center">Barcode</TableCell>
                                                <TableCell align="center">Lot</TableCell>
                                                <TableCell align="center">Created At</TableCell>
                                                <TableCell align="center">Updated At</TableCell>
                                                <TableCell align="center">Controls</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getLabel(this.state.clients, row.clientId)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <a href={"/wh-locations/" + row.locationId}
                                                            target="_blank">{row.locationName}</a>
                                                    </TableCell>
                                                    <TableCell align="right">{row.warehouseId}</TableCell>
                                                    <TableCell>
                                                        <Form.Control size="sm" type="number" style={qtyInput}
                                                            value={row.quantity}
                                                            onChange={(e) => this.onChangeQty(e, index)} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={"/listings?barcode=" + row.barcode}
                                                            target={"_blank"}
                                                        >{row.barcode}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Form.Control size="sm" type="text" style={qtyInput}
                                                            value={row.lot}
                                                            onChange={(e) => this.onChangeLot(e, index)} />
                                                    </TableCell>
                                                    <TableCell>{format2NiceDate(row.createdAt)}</TableCell>
                                                    <TableCell>{format2NiceDate(row.updatedAt)}</TableCell>
                                                    <TableCell>
                                                        <IconButton color="primary"
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            style={{
                                                                fontSize: 13,
                                                                height: 30,
                                                                padding: '0px 3px 0px 3px',
                                                                marginRight: 5
                                                            }}
                                                            onClick={() => this.saveQty(index)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton color="default"
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            style={qtySaveBtn}
                                                            onClick={() => this.cancelSave(index)}>
                                                            <Clear />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Default>
                </Row>
            </div>
        );
    }
}

export default ContentsSearch;
