import React, { Component } from 'react';
import OutboundFeedsSearch from "./OutboundFeedsSearch";

class OutboundFeeds extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <OutboundFeedsSearch id={this.props.id}/>
            </div>
        );
    }
}
 
export default OutboundFeeds;
