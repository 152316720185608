import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {ExpandMore, SkipNext, SkipPrevious} from "@material-ui/icons";
import WHFilterSelect from "../components/WHFilterSelect";
import {getIdFromUrl, handleErr, showExportSpinner, showFilterSpinner} from "../utils/MiscellaniousUtils";
import {EXPORT, FILTER, FILTERING, strOptions} from "../constants";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import ClientListingImporter from "./ClientListingsImporter";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {EXPORTING} from "../constants";
import ResourceAPIs from "../utils/ResourceAPI";


const filterBtn = {
    marginRight: 10,
    width: 120,
    marginTop: 10,
    marginBottom: 10
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

const displayRow = { 
    marginLeft: 15,
    width: '98%'
};

class ClientListings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            exportBtnText: EXPORT,
            errMsg: "",
            results: []
        };
        
    }

    onChangeStatusValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        console.log(selectedValue);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFulfilmentFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.fulfilment.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFulfilmentValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.fulfilment.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        console.log(selectedValue);
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeBuyingModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.buyingModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeBuyingModelValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.buyingModel.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeInventoryModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.inventoryModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInventoryModelValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.inventoryModel.value = event.target.value;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    }

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    getListings = () => {
        new ResourceAPIs().getClientListings(this.state.clientId)
            .then(res => {
                    this.setState({
                        results: res.data,
                        resultType: "default"
                    });
                })
            .catch(error => {
                    handleErr(error);
                }
            );
    };

    searchPullRequests() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().getSearchListings(this.state.filters)
            .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: res.data,
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                }   
            );
    }

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().getSearchExportListings(this.state.filters)
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "listings-search-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    render() { 
        return ( 
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label style={filterLabel}>Store Name</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeStoreNameFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                        onChange={this.onChangeStoreNameValue}
                                                        value={this.state.filters.storeName.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Buying model</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeBuyingModelFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                        onChange={this.onChangeBuyingModelValue}
                                                        value={this.state.filters.buyingModel.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Inventory model</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeInventoryModelFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                        onChange={this.onChangeInventoryModelValue}
                                                        value={this.state.filters.inventoryModel.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label style={filterLabel}>Status</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeStatusFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                        onChange={this.onChangeStatusValue}
                                                        value={this.state.filters.status.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Fulfilment</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeFulfilmentFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                        onChange={this.onChangeFulfilmentValue}
                                                        value={this.state.filters.fulfilment.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <Form.Text className="text-muted">
                                            {this.state.errMsg}
                                        </Form.Text>
                                    </Row>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                            <SkipPrevious/>
                                        </IconButton>
                                        <span style={resultsDescStyle}>{this.state.results.length} results
                                        (Page {(this.state.filters.from / 100) + 1})</span>
                                        <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                            <SkipNext/>
                                        </IconButton>
                                    </Row>
                                    <Row align="center" style={{display: 'block', textAlign: 'center' }}>
                                        <Button variant="success" style={filterBtn}
                                                onClick={this.handleFilter}
                                                disabled={this.state.isProcessing}>
                                            {showFilterSpinner(this.state.filterBtnText)}
                                        </Button>
                                        <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <p style={{fontSize: 12, marginTop: 17}}>Export Result</p>
                                        <Button variant="success" onClick={this.onClickExport}
                                                disabled={this.state.isProcessing}>
                                            {showExportSpinner(this.state.exportBtnText)}
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br/>
                </Col>
                <Accordion style={displayRow}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <span style={{color: '#32a2fe'}}>Import</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Row>
                            <ClientListingImporter/>
                        </Row>
                    </AccordionDetails>
                </Accordion>
                <br/><br/>
                <Col xs={12}>
                {this.state.results.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">ID</TableCell>
                                    <TableCell align="right">Store Name</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Fulfilment</TableCell>
                                    <TableCell align="right">Inventory Model</TableCell>
                                    <TableCell align="right">Buying Model</TableCell>
                                    <TableCell align="right">Qty in WH</TableCell>
                                    <TableCell align="right">Created At</TableCell>
                                    <TableCell align="right">Updated At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.results.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" align="right">
                                            <a href={"/clients/" + getIdFromUrl()
                                            + "/listings/" + row.id} target="_blank">{row.id}</a>
                                        </TableCell>
                                        <TableCell align="center">{row.storeName}</TableCell>
                                        <TableCell align="left">{row.status}</TableCell>
                                        <TableCell align="left">{row.fulfilment}</TableCell>
                                        <TableCell align="left">{row.inventoryModel}</TableCell>
                                        <TableCell align="left">{row.buyingModel}</TableCell>
                                        <TableCell align="right">{row.qtyInWarehouse}</TableCell>
                                        <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                        <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <p></p>
                }
            </Col>
            </Row>
    
        );
    }
}
 
export default ClientListings;