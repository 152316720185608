import React, {Component} from 'react';
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {format2NiceDate} from "../utils/DateUtils";
import {SAVE, SAVING} from "../constants";
import {Clear, Save} from "@material-ui/icons";
import {createTypeOption, getIdFromUrl, getLabel, handleError} from "../utils/MiscellaniousUtils";
import {getDisabledFormComponent, getFormComponent} from "../utils/FormUtils";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SplitContents from "./SplitContents";
import SplitContentsImporter from './SplitContentsImporter';
import AmazonShipmentImporter from "./AmazonShipmentImporter";
import ResourceAPIs from "../utils/ResourceAPI";

import Select from "react-select";

const MySwal = withReactContent(Swal);

class SplitDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            split: {
                id: "",
                clientId: "",
                storeId: "",
                storeName: "",
                splitPlanName: "",
                status: "",
                createdAt: "",
                updatedAt: "",
            },
            clientLabel: "",
            storeLabel: "",
            errMsg: "",
            isProcessing: false,
            isSplitLoaded: true,
            editSplitBtnText: SAVE,
            isSplitContentsImported: false,
            clients: [],
            storesIds: []
        };

        this.setIsSplitContentsImported = (val) => {
            this.setState({
                isSplitContentsImported: val,
            });
        };

        this.onChangeClientIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.split.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeStoreIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.split.storeId = selected.value;
            newState.storeLabel = getLabel(this.state.storesIds, selected.value);
            this.setState(newState);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.split.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeSplitPlaneName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.split.splitPlanName = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.split.status = event.target.value;
            this.setState(newState);
        };
    }

    getSplitById = () => {
        new ResourceAPIs().getSplitById(getIdFromUrl())
            .then(result => {
                let splitObj = result.data;
                console.log(splitObj);
                this.setState({
                    split: {
                        id: splitObj.id,
                        clientId: splitObj.clientId,
                        storeId : splitObj.storeId,
                        storeName: splitObj.storeName,
                        splitPlanName: splitObj.splitPlanName,
                        status: splitObj.status,
                        createdAt: splitObj.createdAt,
                        updatedAt: splitObj.updatedAt,
                    },
                    isSplitContentsImported: false,
                    clientLabel : getLabel(this.state.clients, splitObj.clientId),
                    storeLabel: getLabel(this.state.storesIds, splitObj.storeId)
                });
            })
            .catch(error => {
                handleError(error);
                this.setState({
                    isLocationLoaded: false,
                    error
                });
            })
    };

    handleEditSplit = () => {
        this.setState({
            editSplitBtnText: SAVING,
            isProcessing: true,
        });
        new ResourceAPIs().updateSplit(getIdFromUrl(), this.state.split)
            .then(res => {
                MySwal.fire(
                    'Updated!',
                    'Split ' + this.state.split.id + ' has been Updated.',
                    'success'
                );
                this.getSplitById();
                this.setState({
                    editSplitBtnText: SAVE,
                    isProcessing: false,
                });
            })
            .catch(error => {
                    handleError(error);
                    this.setState({
                        editSplitBtnText: SAVE,
                        isProcessing: false,
                        results: error,
                    });
                }
            );
    };

    componentDidMount() {
        this.getClients();
    }

    componentDidUpdate(prevProps, preState, snapshot) {
        if (preState.isSplitContentsImported !== this.state.isSplitContentsImported) {
            this.getSplitById();
        }
    }

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });
                    this.setState({
                        storesIds: storesIds,
                    });
                    this.getSplitById();
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    render() {
        if (!this.state.isSplitLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <Row>
                        <Col xs={8}>
                            <div>
                                <Card border="secondary">
                                    <Card.Header as="h5">
                                        <span style={{marginTop: 60}}>Split Details</span>

                                        <div style={{float: "right"}}>
                                         <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                            {this.state.errMsg}
                                        </span>
                                            <Button variant="success" size="sm" style={{width: 100,}}
                                                    disabled={this.state.isProcessing}
                                                    onClick={this.handleEditSplit}>
                                                {showSaveOrderPRSpinner(this.state.editSplitBtnText)}
                                            </Button>
                                            <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                                    onClick={this.handleCancelEditLocation}
                                                    disabled={this.state.isProcessing}>
                                                <Clear/> Cancel
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <Col xs={6}>
                                                    {getDisabledFormComponent("ID", "number", this.state.split.id)}
                                                    <Form.Group controlId="formBasicPassword">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <Form.Label>Store ID</Form.Label>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <Select
                                                                    value={createTypeOption(this.state.storeLabel)}
                                                                    onChange={(e) => this.onChangeStoreIdValue(e)}
                                                                    options={this.state.storesIds}
                                                                    styles={this.customStyles}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    {getFormComponent("Store Name", "text", this.state.split.storeName, this.onChangeStoreName)}
                                                    {getFormComponent("Split Plan Name", "text", this.state.split.splitPlanName, this.onChangeSplitPlaneName)}
                                                </Col>
                                                <Col xs={6}>
                                                    {this.props.id !== undefined && <Form.Group controlId="formBasicPassword">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <Form.Label>Client ID</Form.Label>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <Select
                                                                    value={createTypeOption(this.state.clientLabel)}
                                                                    onChange={(e) => this.onChangeClientIdValue(e)}
                                                                    options={this.state.clients}
                                                                    styles={this.customStyles}
                                                                    isDisabled={true}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>}

                                                    {this.props.id === undefined && <Form.Group controlId="formBasicPassword">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <Form.Label>Client ID</Form.Label>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <Select
                                                                    value={createTypeOption(this.state.clientLabel)}
                                                                    onChange={(e) => this.onChangeClientIdValue(e)}
                                                                    options={this.state.clients}
                                                                    styles={this.customStyles}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>}

                                                    {getFormComponent("Status", "text", this.state.split.status, this.onChangeStatus)}
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Created At</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Badge variant="secondary">
                                                                {format2NiceDate(this.state.split.createdAt)}</Badge>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Updated At</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Badge variant="secondary">
                                                                {format2NiceDate(this.state.split.updatedAt)}</Badge>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <SplitContentsImporter setIsSplitContentsImported={this.setIsSplitContentsImported}/>
                            <br/>
                            <AmazonShipmentImporter setIsSplitContentsImported={this.setIsSplitContentsImported}/>
                        </Col>
                    </Row>
                    <br/>
                    <SplitContents isSplitContentsImported={this.state.isSplitContentsImported}/>
                </div>
            );
        }
    }
}


function showSaveOrderPRSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default SplitDetails;
