import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {CLONE, CLONING, SAVE, SAVING} from "../../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../../utils/DateUtils";
import { createTypeOption, getLabel, handleErr, handleError, showSaveSpinner, setEmptyIfNull, showAddContentsSpinner, 
    getIdFromUrl, showCloneSpinner } from "../../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Clear, GetApp, HomeWork, LowPriority} from "@material-ui/icons";
import PullRequestContents from "./PullRequestContents";
import {getFormattedDate} from "../../orders/OrdersExporter";
import fileDownload from "js-file-download";
import Select from "react-select";
import ResourceAPIs from "../../utils/ResourceAPI";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { ADD_CONTENTS, ADDING_CONTENTS } from "../../constants";

const MySwal = withReactContent(Swal);

const locationInput = {
    width: 300,
};

const PULL_WH_CONTENTS = "Pull Warehouse Contents";
const DOWNLOAD_PULL_REPORT = "Download Pull Report";
const DOWNLOAD_PULL_CONTENT = "Download Pull Contents";

const OLDEST_LOCATION_FIRST = "oldest_location_first";
const LARGEST_LOCATION_FIRST = "largest_location_first";

class PullRequestDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pullRequest: {
                id: "",
                createdAt: "",
                updatedAt: "",
                clientId: "",
                pullName: "",
                status: "",
                storeName: "",
                storeId: "",
                planId: "",
                warehouse: "",
                invoice: "",
                errMsg: "",
            },
            storeLabel: "",
            clientLabel: "",
            addContent: {
                qty: "",
                barcodes: "",
            },
            selectedFile: null,
            isLocationLoaded: false,
            isProcessing: false,
            addContentBtnText: "Add Contents",
            deleteContentBtnText: "Delete All Contents",
            editPullRequestBtnText: SAVE,
            clonePullRequestBtnText: CLONE,
            pullListingBtnText: "Pull Listing Data",
            pullWhContentsBtnText: PULL_WH_CONTENTS,
            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
            downloadPCBtnText: DOWNLOAD_PULL_CONTENT,
            contentsText: "",
            errMsg: "",
            errMsgText: "",
            childLoad: false,
            storesIds: [],
            clients: [],
        };

        // Edit pull requests

        this.onChangeClientId = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeWarehouse = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.warehouse = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreIdValue = selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.pullRequest.storeId = selected.value;
            stateCopy.storeLabel = getLabel(this.state.storesIds, selected.value);
            this.setState(stateCopy);
        };

        this.onChangePullName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.pullName = event.target.value;
            this.setState(newState);
        };

        this.onChangePlanId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.planId = event.target.value;
            this.setState(newState);
        };

        this.onChangeInvoice = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.invoice = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.status = event.target.value;
            this.setState(newState);
        };

        this.onChangeSelectedFile = event => {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
            })
        };

        this.onChangeContentsText = (event) => {
            let newState = Object.assign({}, this.state);
            newState.contentsText = event.target.value;
            this.setState(newState);
        };

        this.handleEditPullRequest = () => {
            if (this.validateEditPullRequest()) {
                this.setState({
                    editPullRequestBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPIs().updatePullRequestContentFields(this.getPullRequestId(),
                    this.state.pullRequest.clientId, this.state.pullRequest.pullName, this.state.pullRequest.status,
                    this.state.pullRequest.storeName, this.state.pullRequest.storeId, this.state.pullRequest.planId,
                    this.state.pullRequest.warehouse, this.state.pullRequest.invoice)
                    .then(res => {
                        this.setState({
                            editPullRequestBtnText: SAVE,
                            isProcessing: false,
                        });
                    })
                    .catch(error => {
                        handleError(error);
                        this.setState({
                            editPullRequestBtnText: SAVE,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };

        this.handleClonePullRequest = () => {
            if (this.validateEditPullRequest()) {
                this.setState({
                    clonePullRequestBtnText: CLONING,
                    isProcessing: true,
                });
                new ResourceAPIs().clonePullRequest(this.getPullRequestId())
                    .then(res => {
                        window.open("/pull-requests/" + res.data);
                        this.setState({
                            clonePullRequestBtnText: CLONE,
                            isProcessing: false,
                        });
                    })
                    .catch(error => {
                        handleError(error);
                        this.setState({
                            clonePullRequestBtnText: CLONE,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };

        this.handleCancelEditLocation = () => {
            this.getPullRequestById();
        };

        this.handleAddContents = () => {
            const data = new FormData();
            data.append('file', this.state.selectedFile);

            if (this.validateAddContentsInputs()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });
                new ResourceAPIs().importPRById(this.getPullRequestId(), data)
                    .then(res => { // then print response status
                            // console.log(res);
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                childLoad: false,
                            });
                            this.restAddContentFrom();
                        })
                    .catch(error => {
                            console.log(error);
                            handleErr(error);
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleAddContentsFromText = () => {
            if (this.validateAddContentsInputsAsText()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });
                new ResourceAPIs().addWarehousePullContents(getIdFromUrl(), {contents: this.state.contentsText})
                    .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            addContentBtnText: ADD_CONTENTS,
                            contentsText: "",
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        handleError(error);
                        this.setState({
                            addContentBtnText: ADD_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };

        this.handlePullListings = () => {
            this.setState({
                pullListingBtnText: "Pulling Listing Data",
                isProcessing: true,
            });
            new ResourceAPIs().pullListingDataById(this.getPullRequestId())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullListingBtnText: "Pull Listing Data",
                            isProcessing: false,
                            childLoad: false,
                        });
                        this.getPullRequestById();
                    })
                .catch(error => {
                        console.log(error);
                        handleErr(error);
                        this.setState({
                            pullListingBtnText: "Pull Listing Data",
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullWhContents = (pullWarehouseType) => {
            this.setState({
                pullWhContentsBtnText: "Pulling Warehouse Contents",
                isProcessing: true,
            });
            new ResourceAPIs().pullWHContentsById(this.getPullRequestId(), pullWarehouseType)
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullWhContentsBtnText: PULL_WH_CONTENTS,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullWhContentsBtnText: PULL_WH_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleDownloadPullReport = () => {
            this.setState({
                downloadPRBtnText: "Downloading Pull Report",
                isProcessing: true,
            });
            new ResourceAPIs().exportPRById(this.getPullRequestId())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
                            isProcessing: false,
                            childLoad: false,
                        });
                        const dd = getFormattedDate(new Date());
                        fileDownload(res.data, "pull-report-" + dd + ".csv");
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleDownloadPullContent = () => {
            this.setState({
                downloadPCBtnText: "Downloading Pull Content",
                isProcessing: true,
            });
            new ResourceAPIs().exportPRContentsById(this.getPullRequestId())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            downloadPCBtnText: DOWNLOAD_PULL_CONTENT,
                            isProcessing: false,
                            childLoad: false,
                        });
                        const dd = getFormattedDate(new Date());
                        fileDownload(res.data, "pull-content-" + dd + ".csv");
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            downloadPCBtnText: DOWNLOAD_PULL_CONTENT,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleDeleteAllContents = () => {
            MySwal.fire({
                // title: 'Are you sure?',
                title: 'You want to Delete all the contents of this location?',
                text: "You won't be able to revert this!",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    // Delete happens here
                    this.setState({
                        deleteContentBtnText: "Deleting All Contents",
                        isProcessing: true,
                    });
                    new ResourceAPIs().deleteAllWHContentsById(this.getPullRequestId())
                        .then(res => {
                                this.setState({
                                    childLoad: true,
                                });
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    childLoad: false,
                                });
                                MySwal.fire(
                                    'Deleted!',
                                    'All contents of this location has been deleted.',
                                    'success'
                                )
                            })
                        .catch(error => {
                                console.log(error);
                                handleErr(error);
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    results: error,
                                });
                            });
                }
            });
        };
    }

    validateAddContentsInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                errMsg: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                errMsg: "",
            });
        }
        return true;
    };

    validateAddContentsInputsAsText = () => {
        if (this.state.contentsText === undefined || this.state.contentsText === "") {
            this.setState({
                errMsgText: "Pls add contents you want to add!",
            });
            return false;
        } else {
            this.setState({
                errMsgText: "",
            });
        }
        return true;
    };

    validateEditPullRequest = () => {
        // validate client id
        if (parseInt(this.state.pullRequest.clientId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.pullRequest.clientId = parseInt(this.state.pullRequest.clientId, 10);
            this.setState(stateCopy);
        } else {
            return this.setPullRequestEditErr("Invalid Client ID");
        }

        // validate warehouse id
        if (parseInt(this.state.pullRequest.warehouse, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.pullRequest.warehouse = parseInt(this.state.pullRequest.warehouse, 10);
            this.setState(stateCopy);
        } else {
            return this.setPullRequestEditErr("Invalid Warehouse ID");
        }

        // validate store name
        if (this.state.pullRequest.storeName === "") {
            return this.setPullRequestEditErr("Invalid Store Name");
        }

        // validate pull name
        if (this.state.pullRequest.pullName === "") {
            return this.setPullRequestEditErr("Invalid Pull Name");
        }

        // validate plan id
        if (this.state.pullRequest.planId === "") {
            return this.setPullRequestEditErr("Invalid Plan ID");
        }

        // validate invoice
        if (this.state.pullRequest.invoice === "") {
            return this.setPullRequestEditErr("Invalid Invoice");
        }

        // validate status
        if (this.state.pullRequest.status === "") {
            return this.setPullRequestEditErr("Invalid Status");
        }

        return true;
    };

    setPullRequestEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.pullRequest.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getPullRequestId = () => {
        let url = window.location.href;
        return url.substring(url.lastIndexOf('/') + 1);
    };

    getPullRequestById = () => {
        new ResourceAPIs().getPRById(this.getPullRequestId())
            .then(res => {
                const result = res.data;
                this.setState({
                    pullRequest: {
                        id: result.id,
                        createdAt: result.createdAt,
                        updatedAt: result.updatedAt,
                        clientId: result.clientId,
                        pullName: result.pullName,
                        status: result.status,
                        storeName: result.storeName,
                        storeId: result.storeId,
                        planId: result.planId,
                        warehouse: result.warehouse,
                        invoice: result.invoice,
                        errMsg: ""
                    },
                    storeLabel: getLabel(this.state.storesIds, result.storeId),
                    clientLabel: getLabel(this.state.clients, result.clientId),
                    isLocationLoaded: true,
                })
            })
            .catch(error => {
                    this.setState({
                        isLocationLoaded: false,
                        error
                    });
                    handleError(error);
                }
            );
    };

    restAddContentFrom = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            addContent: {
                qty: "",
                barcodes: "",
                theInputField: randomString
            }
        });
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });
                    this.setState({
                        storesIds: storesIds,
                    });
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            }, () => {
                this.getPullRequestById();
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    componentDidMount() {
        this.getStores();
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <div>
                        <Card border="secondary">
                            <Card.Header as="h5">
                                <span style={{marginTop: 60}}>Pull Request Details</span>

                                <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.pullRequest.errMsg}
                                    </span>
                                    <Button variant="success" size="sm" style={{width: 100,}}
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleEditPullRequest}>
                                        {showSaveSpinner(this.state.editPullRequestBtnText)}
                                    </Button>
                                    <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                            onClick={this.handleCancelEditLocation}
                                            disabled={this.state.isProcessing}>
                                        <Clear/> Cancel
                                    </Button>
                                    <Button variant="primary" size="sm" style={{width: 100, marginLeft: 10}}
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleClonePullRequest}>
                                        {showCloneSpinner(this.state.clonePullRequestBtnText)}
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>ID</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.pullRequest.id}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            {this.props.id === undefined && (
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Form.Label>Client ID</Form.Label>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientId(e)}
                                                                options={this.state.clients}
                                                                styles={this.customStyles}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            )}
                                            {this.props.id !== undefined && (
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Form.Label>Client ID</Form.Label>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control type="number" size="sm"
                                                                          value={this.state.pullRequest.clientId}
                                                                          onChange={this.onChangeClientId}
                                                                          disabled={true}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            )}
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>Store Name</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Form.Control type="text" size="sm"
                                                                      value={this.state.pullRequest.storeName}
                                                                      onChange={this.onChangeStoreName}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>Store ID</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Select
                                                            value={createTypeOption(this.state.storeLabel)}
                                                            onChange={(e) => this.onChangeStoreIdValue(e)}
                                                            options={this.state.storesIds}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Warehouse ID</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="number" size="sm" style={locationInput}
                                                                      value={this.state.pullRequest.warehouse}
                                                                      onChange={this.onChangeWarehouse}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Pull Name</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                                      value={this.state.pullRequest.pullName}
                                                                      onChange={this.onChangePullName}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Plan ID</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                                      value={this.state.pullRequest.planId}
                                                                      onChange={this.onChangePlanId}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Invoice</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                                      value={this.state.pullRequest.invoice}
                                                                      onChange={this.onChangeInvoice}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Status</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                                      value={this.state.pullRequest.status}
                                                                      onChange={this.onChangeStatus}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.pullRequest.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.pullRequest.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br/>
                    <div>
                        <Card border="secondary">
                            {/*<Card.Header as="h5">Add Pull Request Contents</Card.Header>*/}
                            <Card.Body style={{ backgroundColor: "#FAFAFA"}}>
                                <Form>
                                    <Row>
                                        <Col>
                                            <b style={{marginBottom: 5}}>Add Pull Request Contents</b>
                                            <Card>
                                                <Card.Body>
                                                    <Tab.Container id="left-tabs-example" defaultActiveKey="file">
                                                        <Nav variant="tabs" style={{fontSize: 11}}>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="file">Using File</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="text">Using Text</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <br/>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="file" style={{height: 165}}>
                                                                <Form.Group as={Col} controlId="formGridState">
                                                                    <Form.Label>Select File</Form.Label>
                                                                    <br />
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        onChange={this.onChangeSelectedFile}
                                                                        key={this.state.theInputField} />
                                                                </Form.Group>
                                                                <span style={{ fontSize: 12, color: "red", }}>
                                                                    {this.state.errMsg}
                                                                </span>
                                                                <Button variant="success" 
                                                                    style={{ width: 200, float: 'right', marginTop: 53 }}
                                                                    disabled={this.state.isProcessing} size="sm" 
                                                                    onClick={this.handleAddContents}>
                                                                    {showSpinner(this.state.addContentBtnText)}
                                                                </Button>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="text">
                                                                <Form>
                                                                    <Form.Group controlId="contents">
                                                                        <Row>
                                                                            <Col>
                                                                                <Form.Control type="text" size="sm" 
                                                                                    as="textarea" rows="5"
                                                                                    value={setEmptyIfNull(this.state.contentsText)}
                                                                                    onChange={this.onChangeContentsText} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Form.Group>
                                                                    <span style={{ fontSize: 12, color: "red", }}>
                                                                        {this.state.errMsgText}
                                                                    </span>
                                                                    <Button variant="success" size="sm" 
                                                                        style={{ width: 200, float: 'right'}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handleAddContentsFromText}>
                                                                        {showAddContentsSpinner(this.state.addContentBtnText)}
                                                                    </Button>
                                                                </Form>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <b style={{marginBottom: 5}}>Pull Data from Listings & Warehouses</b>
                                            <Card>
                                                <Card.Body>
                                                    <div style={{textAlign: "center"}}>
                                                        <Row>
                                                            <Col>
                                                                <Button variant="info" size="sm" 
                                                                    style={{ width: 300, marginBottom: 5 }}
                                                                    disabled={this.state.isProcessing}
                                                                    onClick={this.handlePullListings}>
                                                                    {showPullListingsSpinner(this.state.pullListingBtnText)}
                                                                </Button>
                                                                <br />
                                                                <span style={{ fontSize: 12}}>
                                                                    {showPullWhContentsSpinner(this.state.pullWhContentsBtnText)}
                                                                </span>
                                                                <br/>
                                                                <ButtonGroup aria-label="Basic example">
                                                                    <Button variant="secondary"
                                                                        style={{ marginTop: 5, width: 150, fontSize: 10 }}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={() => this.handlePullWhContents(OLDEST_LOCATION_FIRST)}>
                                                                        Oldest Location First
                                                                    </Button>
                                                                    <Button variant="secondary"
                                                                        style={{ marginTop: 5, width: 150, fontSize: 10 }}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={() => this.handlePullWhContents(LARGEST_LOCATION_FIRST)}>
                                                                        Largest Location First
                                                                    </Button>
                                                                </ButtonGroup>
                                                            <br/>
                                                                <Button variant="primary" size="sm" 
                                                                    style={{ marginTop: 20, width: 300 }}
                                                                    disabled={this.state.isProcessing}
                                                                    onClick={this.handleDownloadPullContent}>
                                                                    {showPullDownloadPCSpinner(this.state.downloadPCBtnText)}
                                                                </Button>
                                                                <br />
                                                                <Button variant="dark" size="sm" 
                                                                    style={{ marginTop: 20, width: 300 }}
                                                                    disabled={this.state.isProcessing}
                                                                    onClick={this.handleDownloadPullReport}>
                                                                    {showPullDownloadPRSpinner(this.state.downloadPRBtnText)}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br/>
                    <PullRequestContents locationId={this.state.pullRequest.id} isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

function showSpinner(btnText) {
    if (btnText === "Add Contents") {
        return <span><AddToPhotosIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullListingsSpinner(btnText) {
    if (btnText === "Pull Listing Data") {
        return <span><LowPriority/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullWhContentsSpinner(btnText) {
    if (btnText === PULL_WH_CONTENTS) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadPRSpinner(btnText) {
    if (btnText === DOWNLOAD_PULL_REPORT) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadPCSpinner(btnText) {
    if (btnText === DOWNLOAD_PULL_CONTENT) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default PullRequestDetails;
