import React, {Component} from "react";
import InboundFeeds from "./InboundFeeds";

class Feeds extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{marginLeft: '1%', marginRight: '1%'}}>
                <InboundFeeds id={this.props.id}/>
            </div>
        );
    }
}

export default Feeds;
