import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import Spinner from "react-bootstrap/Spinner";
import {Description} from "@material-ui/icons";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import {handleErr} from "../utils/MiscellaniousUtils";
import RecalculateQtyInLocation from "../warehouses/locations/RecalculateQtyInLocation";
import ResourceAPIs from "../utils/ResourceAPI";

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
        height: 440,
    },
});

const recalculateQtyLocationStyle = {
    float: 'right',
};

class WarehouseLocationsExporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            clients: [],
            isProcessing: false,
        };
    }

    getClients = () => {
        this.setState({
            isLoaded: false,
        });
        new ResourceAPIs().getClients()
            .then(
                (result) => {
                    this.setState({
                        clients: result.data,
                        isLoaded: true,
                    })
                })
            .catch(error => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            );
    };

    onClickExport = (clientId) => {
        this.setState({
            isProcessing: false,
        });
        new ResourceAPIs().exportWHLocationsByClientId(clientId)
            .then(res => {
                    this.setState({
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "clientId-" + clientId + "-warehouse-locations" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    componentDidMount() {
        this.getClients();
    }

    render() {
        const {classes} = this.props;

        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <h5>Warehouse Locations Exporter</h5>
                    <br/>
                    <RecalculateQtyInLocation styles={recalculateQtyLocationStyle}/>
                    <p>Recalculate Qty in Locations</p>
                    <br/>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Client ID</TableCell>
                                        <TableCell align="center">Client Name</TableCell>
                                        <TableCell align="center">Export</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.clients.map((client) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={client.id}>
                                                <TableCell align="center">
                                                    <a href={"/clients/" + client.id} target="_blank">
                                                        {client.id}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="left">{client.name}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton style={{padding: 6}}
                                                                disabled={this.state.isProcessing}
                                                                onClick={() => this.onClickExport(client.id)}
                                                                color="primary">
                                                        {showExportSpinner(this.state.isProcessing)}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            );
        }
    }
}

export function showExportSpinner(isProcessing) {
    if (isProcessing === false) {
        return <span><Description/></span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/></span>;
    }
}

export default withStyles(useStyles)(WarehouseLocationsExporter);