import React from 'react';
import PrintComponents from "react-print-components";
import './QrCodestyles.css';
import {Print} from "@material-ui/icons";
import QRCode from 'qrcode.react';
import {Col, Row} from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import {Default, Mobile} from "../../utils/MediaUtils";
import { IconButton } from '@material-ui/core';

export default (props) => {

    // width: 2.25" = 5.715 cm
    // height: 1.25" = 3.175 cm

    const value = "https://app.cogimetrics.com/wh-locations/" + props.locationId;

    return (
        <PrintComponents
            trigger={
                <span>
                    <Default>
                        <Button color="primary" style={{ marginLeft: 10, width: 120 }}>
                            <Print /> Print
                        </Button>
                    </Default>
                    <Mobile>
                        <IconButton>
                            <Print />
                        </IconButton>
                    </Mobile>
                </span>
            }>
            <div>
                <div className="barcode-text">
                    <Row>
                        <Col>Location #:<br/>{props.locationId}</Col>
                        <Col>{props.clientName} ({props.clientId})</Col>
                    </Row>
                    <h2>{props.location}</h2>
                </div>
                <QRCode value={value} size={80} style={{marginLeft: "120px", marginTop: "-10px"}}/>
            </div>
        </PrintComponents>
    );
};
