import axios from 'axios';
import {AuthorizationHeader} from "../user/services/AuthHeader";
import {HOST_NAME} from "../constants";
import AuthService from "../user/services/AuthService";

export default class ResourceAPI {

    getHTTPClient(isLogin) {
        let httpClient = axios.create({
            baseURL: HOST_NAME,
            headers: AuthorizationHeader()
        });

        httpClient.defaults.headers.post['Content-Type'] = 'application/json';

        httpClient.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response) {
                if (isLogin === undefined) {
                    if (error.response.status === 403) {
                        localStorage.removeItem('user');
                        window.location.href = "/";
                    }
                }
            }
            return Promise.reject(error);
        });

        return httpClient;
    }

    // Listings

    getSearchListings(filters) {
        return this.getHTTPClient().post("/search/listings", filters);
    }

    getSearchListingsToAddContents(filters) {
        return this.getHTTPClient().post("/search/listings-to-add-contents", filters);
    }

    addListing() {
        return this.getHTTPClient().post("/listings");
    }

    getSearchExportListings(filters) {
        return this.getHTTPClient().post("/export/search/listings", filters);
    }

    importListings(data) {
        return this.getHTTPClient().post("/import/listings", data);
    }

    exportListings() {
        return this.getHTTPClient().get("/export/listings");
    }

    getLastListingId() {
        return this.getHTTPClient().get("/listings/last-id");
    }

    getListingById(id) {
        return this.getHTTPClient().get("/listings?id=" + id);
    }

    updateListingById(id, listings) {
        return this.getHTTPClient().post("/update/listings?id=" + id, listings);
    }

    cloneListingById(id, listings) {
        return this.getHTTPClient().post("/listings/clone?id=" + id, listings);
    }

    deleteListingById(id) {
        return this.getHTTPClient().delete("/listings?id=" + id);
    }

    deleteListings(listings) {
        return this.getHTTPClient().post("/listings/selected", listings);
    }

    importAmazonListings(data) {
        return this.getHTTPClient().post("/import/amazon-listings", data);
    }

    importAmazonListingsAsTask(data) {
        return this.getHTTPClient().post("/worker/task/import-amazon-listings", data);
    }

    updateQtysInWh(storeId) {
        return this.getHTTPClient().post("/update/listings/qty-in-warehouse?store-id=" + storeId);
    }

    updateQtysInWhAsTask(storeId) {
        return this.getHTTPClient().post("/worker/task/listings-warehouse-contents-puller?store-id=" + storeId);
    }

    // Work orders

    getSearchWorkOrders(filters) {
        return this.getHTTPClient().post("/search/work-orders", filters);
    }

    addWorkOrder() {
        return this.getHTTPClient().put("/work-order");
    }

    updateWorkOrder(workOrder) {
        return this.getHTTPClient().post("/work-order", workOrder);
    }

    cloneWorkOrder(workOrderId) {
        return this.getHTTPClient().post("/work-order/clone?id=" + workOrderId);
    }

    getWorkOrderById(id) {
        return this.getHTTPClient().get("/work-order?id=" + id);
    }

    deleteWorkOrderById(id) {
        return this.getHTTPClient().delete("/work-order?id=" + id);
    }

    uploadWoFile(data, options) {
        return this.getHTTPClient().post("/work-order/files", data, options);
    }

    getWoRepoFiles(id) {
        return this.getHTTPClient().get("/work-order/files?id=" + id);
    }

    deleteWoRepoFile(id, filename) {
        return this.getHTTPClient().delete("/work-order/files?id=" + id + "&fileName=" + filename);
    }

    // Warehouse contents

    getSearchWHContents(filters) {
        return this.getHTTPClient().post("/search/warehouse-contents", filters);
    }

    updateWHContentQty(id, qty, lot, filters) {
        return this.getHTTPClient().post("/update/warehouse-content?id=" + id + "&qty=" + qty + "&lot=" + lot, filters);
    }

    getSearchExportWHContents(filters) {
        return this.getHTTPClient().post("/export/search/warehouse-contents", filters);
    }

    importWHContents(data) {
        return this.getHTTPClient().post("/import/warehouse-contents", data);
    }

    exportWHContents() {
        return this.getHTTPClient().get("/export/warehouse-contents");
    }

    // Pull Requests

    updatePullRequestContent(id, sku, qtyDesired, invoice) {
        return this.getHTTPClient().post("/update/pull-request-content?id=" + id
            + "&sku=" + sku
            + "&qty-desired=" + qtyDesired
            + "&invoice=" + invoice);
    }

    deletePullRequestContent(id) {
        return this.getHTTPClient().delete("/pull-request-content?id=" + id);
    }

    getPRContentsById(id) {
        return this.getHTTPClient().get("/pull-request-contents?pull-request-id=" + id);
    }

    updatePullRequestContentFields(id, clientId, pullName, status, storeName, storeId, planId, warehouse, invoice) {
        return this.getHTTPClient().post("/update/pull-request?id=" + id
            + "&client-id=" + clientId
            + "&pull-name=" + pullName
            + "&status=" + status
            + "&store-name=" + storeName
            + "&store-id=" + storeId
            + "&plan-id=" + planId
            + "&warehouse=" + warehouse
            + "&invoice=" + invoice);
    }

    importPRById(id, data) {
        return this.getHTTPClient().put("/import/pull-request-contents?pull-request-id=" + id, data);
    }

    addWarehousePullContents(id, contents) {
        return this.getHTTPClient().put("/pull-request/contents?id=" + id, contents);
    }

    pullListingDataById(id) {
        return this.getHTTPClient().post("/pull-listing-data?pull-request-id=" + id);
    }

    pullWHContentsById(id, pullWarehouseType) {
        return this.getHTTPClient().post("/pull-warehouse-contents?pull-request-id=" + id 
            + "&pull-wh-type=" + pullWarehouseType);
    }

    exportPRById(id) {
        return this.getHTTPClient().get("/export/pull-reports?pull-request-id=" + id);
    }

    exportPRContentsById(id) {
        return this.getHTTPClient().get("/export/pull-request-contents?pull-request-id=" + id);
    }

    deleteAllWHContentsById(id) {
        return this.getHTTPClient().delete("/all-warehouse-contents?location-id=" + id);
    }

    getPRById(id) {
        return this.getHTTPClient().get("/pull-request?id=" + id);
    }

    searchPR(filters) {
        return this.getHTTPClient().post("/search/pull-requests", filters);
    }

    searchUpdatePullRequestContentFields(id, clientId, pullName, status, storeName, storeId, planId, warehouse, invoice, filter) {
        return this.getHTTPClient().post("/update/pull-request?id=" + id
            + "&client-id=" + clientId
            + "&pull-name=" + pullName
            + "&status=" + status
            + "&store-name=" + storeName
            + "&store-id=" + storeId
            + "&plan-id=" + planId
            + "&warehouse=" + warehouse
            + "&invoice=" + invoice, filter);
    }

    addNewPR() {
        return this.getHTTPClient().post("/pull-request");
    }

    clonePullRequest(id) {
        return this.getHTTPClient().post("/pull-request/clone?id=" + id);
    }

    // Warehouse

    getAllWarehouses() {
        return this.getHTTPClient().get("/warehouses");
    }

    addWarehouse() {
        return this.getHTTPClient().put("/warehouse");
    }

    editWarehouse(id, name, orderEmail, priority) {
        return this.getHTTPClient().post("/warehouse?id=" + id
            + "&name=" + name
            + "&order-email=" + orderEmail
            + "&priority=" + priority);
    }

    getWarehouseById(id) {
        return this.getHTTPClient().get("/warehouse?id=" + id);
    }

    // Business reports

    exportBusinessReport() {
        return this.getHTTPClient().get("/export/business-reports");
    }

    importBusinessReport(data) {
        return this.getHTTPClient().post("/import/business-reports", data);
    }

    getSearchBusinessReports(filters) {
        return this.getHTTPClient().post("/search/business-reports", filters);
    }

    addBusinessReport() {
        return this.getHTTPClient().post("/business-report");
    }

    deleteBusinessReportAndContents(id) {
        return this.getHTTPClient().delete("/business-report-and-contents?id=" + id);
    }

    getBusinessContents(businessReportId) {
        return this.getHTTPClient().get("/business-report-contents?business-report-id=" + businessReportId);
    }

    updateBusinessReport(id, clientId, storeId, storeName, startDate, endDate, reportType, content) {
        return this.getHTTPClient().post(
            "/update/business-report?id=" + id
            + "&client-id=" + clientId
            + "&store-id=" + storeId
            + "&store-name=" + storeName
            + "&start-date=" + startDate
            + "&end-date=" + endDate
            + "&report-type=" + reportType,
            content);
    }

    addBusinessReportContents(id, data) {
        return this.getHTTPClient().put("/import/business-report-contents?business-report-id=" + id, data);
    }

    getBusinessReportById(id) {
        return this.getHTTPClient().get("/business-reports?id=" + id);
    }

    // Settlement reports

    getSettlementReportContents(settlementReportId) {
        return this.getHTTPClient().get("/settlement-report-contents?settlement-report-id=" + settlementReportId);
    }

    updateSettlementReport(id, clientId, storeId, storeName, startDate, endDate, content) {
        return this.getHTTPClient().post(
            "/update/settlement-report?id=" + id
            + "&client-id=" + clientId
            + "&store-id=" + storeId
            + "&store-name=" + storeName
            + "&start-date=" + startDate
            + "&end-date=" + endDate,
            content);
    }

    addSettlementReportContents(id, data) {
        return this.getHTTPClient().put("/import/settlement-report-contents?settlement-report-id=" + id, data);
    }

    getSettlementReportById(id) {
        return this.getHTTPClient().get("/settlement-reports?id=" + id);
    }

    exportSettlementReports() {
        return this.getHTTPClient().get("/export/settlement-reports");
    }

    exportSettlementReportContents(settlementReportId) {
        return this.getHTTPClient().get("/export/settlement-report-contents?settlement-report-id=" + settlementReportId);
    }

    importSettlementReports(data) {
        return this.getHTTPClient().post("/import/settlement-reports", data);
    }

    getSearchSettlementReports(filters) {
        return this.getHTTPClient().post("/search/settlement-reports", filters);
    }

    addSettlementReport() {
        return this.getHTTPClient().post("/settlement-report");
    }

    deleteSettlementReportAndContents(id) {
        return this.getHTTPClient().delete("/settlement-report-and-contents?id=" + id);
    }

    // Warehouse Locations

    exportWHLocation() {
        return this.getHTTPClient().get("/export/warehouse-locations");
    }

    importWHLocation(data) {
        return this.getHTTPClient().post("/import/warehouse-locations", data);
    }

    getSearchWHLocation(filters) {
        return this.getHTTPClient().post("/search/warehouse-locations", filters);
    }

    addNewWHLocation(location) {
        if (location !== undefined) {
            return this.getHTTPClient().post("/warehouse-location", location);
        } else {
            return this.getHTTPClient().post("/warehouse-location");
        }
    }

    getSearchExportWHLocations(filters) {
        return this.getHTTPClient().post("/export/search/warehouse-locations", filters);
    }

    updateWHLocation(location) {
        return this.getHTTPClient().post("/update/warehouse-location", location);
    }

    updateQtyWHLocationById(id) {
        return this.getHTTPClient().post("/update/warehouse-locations/qty-in-location?id=" + id);
    }

    updateWHContentsByLocationId(id, data) {
        return this.getHTTPClient().put("/warehouse-contents?location-id=" + id, data);
    }

    updateWHContentsByLocation(id, data) {
        return this.getHTTPClient().put("/warehouse-contents-location?location-id=" + id, data);
    }

    deleteWHContentsByLocationId(id) {
        return this.getHTTPClient().delete("/all-warehouse-contents?location-id=" + id);
    }

    deleteWHContentById(id) {
        return this.getHTTPClient().delete("/warehouse-content?id=" + id);
    }

    safeDeleteWHContents(clientId) {
        return this.getHTTPClient().delete("/warehouse-contents?client-id=" + clientId);
    }

    deleteWHContentsForLocation(locationIds) {
        return this.getHTTPClient().delete("/all-warehouse-contents-for-locations?location-ids=" + locationIds);
    }

    safeDeleteWHLocations(clientId) {
        return this.getHTTPClient().delete("/warehouse-locations?client-id=" + clientId);
    }

    updateWHLocationByQuery(id, locationSize, locationName, clientId, warehouseId, filters) {
        return this.getHTTPClient().post("/update/warehouse-locations?id=" + id
            + "&location-size=" + locationSize
            + "&location-name=" + locationName
            + "&client-id=" + clientId
            + "&warehouse-id=" + warehouseId,
            filters);
    }

    getWarehouseContentsByLocationId(id) {
        return this.getHTTPClient().get("/warehouse-contents?location-id=" + id);
    }

    getWarehouseLocationById(id) {
        return this.getHTTPClient().get("/warehouse-locations?id=" + id);
    }

    recalculateQtyInLocation() {
        return this.getHTTPClient().post("/update/warehouse-locations/qty-in-location");
    }

    // Users

    getUsers() {
        return this.getHTTPClient().get("/users");
    }

    getUserByName(name) {
        return this.getHTTPClient().get("/users?name=" + name);
    }

    updateUser(id, role, clientId) {
        return this.getHTTPClient().post("/users/update-role?id=" + id + "&role=" + role + "&client-id=" + clientId);
    }

    getClientId() {
        return this.getHTTPClient().post(HOST_NAME + "/users/client-id",
            {username: AuthService.getCurrentUser().User});
    }

    getClients() {
        return this.getHTTPClient().get("/clients");
    }

    // Authentication

    login(username, password) {
        return this.getHTTPClient(true).post("/login", {username, password});
    }

    loginErrorReason(username) {
        return this.getHTTPClient().post("/users/error-reason", {username});
    }

    register(username, email, password) {
        return this.getHTTPClient().post("/users/sign-up", {
            username,
            email,
            password
        });
    }

    userRole(username) {
        return this.getHTTPClient().post("/users/role", {
            username
        });
    }

    validate(id, token) {
        return this.getHTTPClient().post("/users/validate?id=" + id , token);
    }

    sendMailAgain(id) {
        return this.getHTTPClient().post("/users/send-mail?id=" + id);
    }

    forgotPassword(email) {
        return this.getHTTPClient().get("/users/forget-password?email=" + email);
    }

    resetPassword(email, newPassword, token) {
        return this.getHTTPClient().post("/users/reset-password?email=" + email + "&new-password=" + newPassword, token);
    }

    // File Repo

    getRepoFiles(location) {
        let url = "/file-repo/list";
        if (location && location.length > 0) {
            url += "?filePath=" + location;
        }
        return this.getHTTPClient().get(url);
    }

    createFolder(filePath) {
        return this.getHTTPClient().post("/file-repo/create-dir?filePath=" + filePath);
    }

    deleteFile(filePath) {
        return this.getHTTPClient().delete("/file-repo?filePath=" + filePath);
    }

    uploadFile(data, options) {
        return this.getHTTPClient().post("/file-repo/store", data, options);
    }

    // Inbound Feeds

    importIFContents(data) {
        return this.getHTTPClient().post("/import/inbound-feed-contents", data);
    }

    deleteIFContentsById(id) {
        return this.getHTTPClient().delete("/inbound-feed-content?id=" + id);
    }

    getIFContentsByIdAndSize(id, size) {
        return this.getHTTPClient().get("/inbound-feed-contents?inbound-feed-id=" + id + "&size=" + size);
    }

    exportIFContents(id) {
        return this.getHTTPClient().get("/export/inbound-feed-contents?inbound-feed-id=" + id);
    }

    updateInboundFeed(id, clientId, warehouseId, inboundFeedName, updateFrequency, feedType, modifierType,
                      modifier, content) {
        return this.getHTTPClient().post("/update/inbound-feed?id=" + id + "&client-id=" + clientId
            + "&warehouse-id=" + warehouseId + "&inbound-feed-name=" + inboundFeedName
            + "&update-frequency=" + updateFrequency + "&feed-type=" + feedType + "&modifier-type=" + modifierType
            + "&modifier=" + modifier, content);
    }

    executeInboundFeed(id) {
        return this.getHTTPClient().post("/execute/inbound-feed?id=" + id);
    }

    getInboundFeedsById(id) {
        return this.getHTTPClient().get("/inbound-feeds?id=" + id);
    }

    deleteAllIFContentsById(id) {
        return this.getHTTPClient().delete("/all-inbound-feed-contents?inbound-feed-id=" + id);
    }

    searchInboundFeeds(filters) {
        return this.getHTTPClient().post("/search/inbound-feeds", filters);
    }

    addInboundFeed() {
        return this.getHTTPClient().post("/inbound-feed");
    }

    // Outbound Feeds

    getOFContentsByIdAndSize(id, size) {
        return this.getHTTPClient().get("/outbound-feed-contents?outbound-feed-id=" + id + "&size=" + size);
    }

    addOFContentsForListings(id, content) {
        return this.getHTTPClient().put("/outbound-feed-contents/listings?outbound-feed-id=" + id, content);
    }

    addOFContents(id, content) {
        return this.getHTTPClient().put("/outbound-feed-contents?outbound-feed-id=" + id, content);
    }

    getOutboundFeedsById(id) {
        return this.getHTTPClient().get("/outbound-feeds?id=" + id);
    }

    updateOutboundFeed(id, content) {
        return this.getHTTPClient().post("/update/outbound-feeds?id=" + id, content);
    }

    handlePullListingsOFContents(id) {
        return this.getHTTPClient().post("/outbound-feed-contents/pull-listings?outbound-feed-id=" + id);
    }

    handleUpdateQuantitiesOFContents(id) {
        return this.getHTTPClient().post("/outbound-feed-contents/update-quantities?outbound-feed-id=" + id);
    }

    exportOFContents(id) {
        return this.getHTTPClient().get("/export/outbound-feed-contents?outbound-feed-id=" + id);
    }

    exportOutboundFeed(id) {
        return this.getHTTPClient().post("/outbound-feed/export?outbound-feed-id=" + id);
    }

    exportOutboundFeedReport(id) {
        return this.getHTTPClient().get("/outbound-feed-report/" + id);
    }

    addOutboundFeed() {
        return this.getHTTPClient().post("/outbound-feeds");
    }

    searchOutboundFeeds(filters) {
        return this.getHTTPClient().post("/search/outbound-feeds", filters);
    }

    // Purchase Orders

    addPOContents(id, content) {
        return this.getHTTPClient().put("/purchase-order-contents/listings?purchase-order-id=" + id, content);
    }

    addPOContentsTask(id, content) {
        return this.getHTTPClient().post("/worker/task/purchase-order-contents-inserter?purchase-order-id=" + id,
            content);
    }

    addPurchaseOrderContents(id, addContent) {
        return this.getHTTPClient().put("/purchase-order-contents?purchase-order-id=" + id, addContent);
    }

    searchPOContents(filters) {
        return this.getHTTPClient().post("/search/purchase-orders", filters);
    }

    deletePurchaseOrderById(id) {
        return this.getHTTPClient().delete("/purchase-order?id=" + id);
    }

    addPurchaseOrder() {
        return this.getHTTPClient().post("/purchase-order");
    }

    getPOContentsById(id) {
        return this.getHTTPClient().get("/purchase-order-contents?purchase-order-id=" + id);
    }

    updatePOContentsById(id, sku) {
        return this.getHTTPClient().post("/update/purchase-order-content?id=" + id + "&sku=" + sku);
    }

    deletePOContentById(id) {
        return this.getHTTPClient().delete("/purchase-order-content?id=" + id);
    }

    updatePurchaseOrder(purchaseOrder) {
        return this.getHTTPClient().post("/update/purchase-order", purchaseOrder);
    }

    addPOPullListings(id) {
        return this.getHTTPClient().post("/po-pull/listings?purchase-order-id=" + id);
    }

    addPOPullWhContents(id) {
        return this.getHTTPClient().post("/po-pull/warehouse-contents?purchase-order-id=" + id);
    }

    addPOPullInbFeedContents(id) {
        return this.getHTTPClient().post("/po-pull/inbound-feed-contents?purchase-order-id=" + id);
    }

    addPOPullFbaInventory(id) {
        return this.getHTTPClient().post("/po-pull/fba-inventory?purchase-order-id=" + id);
    }

    addPOPullSalesFromBR(id) {
        return this.getHTTPClient().post("/po-pull/sales-business-reports?purchase-order-id=" + id);
    }

    addPOMakeBuyRecommendation(id) {
        return this.getHTTPClient().post("/make-buy-recommendation?purchase-order-id=" + id);
    }

    exportPOPullReports(id) {
        return this.getHTTPClient().get("/export/po-pull-reports?purchase-order-id=" + id);
    }

    getPurchaseOrderById(id) {
        return this.getHTTPClient().get("/purchase-order?id=" + id);
    }

    addPOPullWhContentsAsATask(id) {
        return this.getHTTPClient().post("/worker/task/purchase-order-warehouse-contents-puller?purchase-order-id=" + id);
    }

    addPOPullInbFeedContentsAsATask(id) {
        return this.getHTTPClient().post("/worker/task/purchase-order-inbound-feed-contents-puller?purchase-order-id=" + id);
    }

    addPOPullListingAsATask(id) {
        return this.getHTTPClient().post("/worker/task/purchase-order-listing-puller?purchase-order-id=" + id);
    }

    addPOPullFBAInventoryAsATask(id) {
        return this.getHTTPClient().post("/worker/task/purchase-order-fba-inventory-puller?purchase-order-id=" + id);
    }

    addPOPullBRSalesAsATask(id) {
        return this.getHTTPClient().post("/worker/task/purchase-order-br-sales-puller?purchase-order-id=" + id);
    }

    addPOMakeBuyRecommendAsATask(id) {
        return this.getHTTPClient().post("/worker/task/purchase-order-buy-recommendation-maker?purchase-order-id=" + id);
    }

    addPurchaseOrderContentsAsATask(id, addContent) {
        return this.getHTTPClient().post("worker/task/manual-purchase-order-contents-inserter?purchase-order-id=" + id, addContent);
    }

    // Inventory Reports

    getInventoryReportContentsById(id) {
        return this.getHTTPClient().get("/inventory-report-contents?inventory-report-id=" + id);
    }

    updateInventoryReport(id, clientId, storeId, storeName, date, addContent) {
        return this.getHTTPClient().post("/update/inventory-report?id=" + id + "&client-id=" + clientId
            + "&store-id=" + storeId+ "&store-name=" + storeName + "&date=" + date, addContent);
    }

    importInventoryReportContentsById(id, data) {
        return this.getHTTPClient().put("/import/inventory-report-contents?inventory-report-id=" + id, data);
    }

    getInventoryReportsById(id) {
        return this.getHTTPClient().get("/inventory-reports?id=" + id);
    }

    searchInventory(filters) {
        return this.getHTTPClient().post("/search/inventory",filters);
    }

    addInventoryReport() {
        return this.getHTTPClient().post("/inventory-report");
    }

    deleteInventoryReportAndContents(id) {
        return this.getHTTPClient().delete("/inventory-report-and-contents?id=" + id);
    }

    //splits

    addSplit() {
        return this.getHTTPClient().post("/splits");
    }

    searchSplit(filters) {
        return this.getHTTPClient().post("/search/splits", filters);
    }

    importAmazonShipmentSplitContents(id, data) {
        return this.getHTTPClient().post("/import/amazon-shipment-split-contents?split-id=" + id, data);
    }

    getSplitContentsById(id) {
        return this.getHTTPClient().get("/splits/" + id + "/split-contents");
    }

    updateSplitContent(id, splitContent) {
        return this.getHTTPClient().put("/splits/" + id + "/split-content", splitContent);
    }

    importSplitContents(id, data) {
        return this.getHTTPClient().post("/import/split-contents?split-id=" + id, data);
    }

    getSplitById(id) {
        return this.getHTTPClient().get("/splits?id=" + id);
    }

    updateSplit(id, data) {
        return this.getHTTPClient().post("/update/splits?id=" + id, data);
    }

    exportSplitContents(splitId) {
        return this.getHTTPClient().get("/export/split-contents?split-id=" + splitId);
    }

    // Orders

    exportOrders() {
        return this.getHTTPClient().get("/export/orders");
    }

    // Stores

    getStores() {
        return this.getHTTPClient().get("/stores");
    }

    addStores() {
        return this.getHTTPClient().put("/stores");
    }

    updateStores(store) {
        return this.getHTTPClient().post("/stores", store);
    }

    getStore(id) {
        return this.getHTTPClient().get("/stores/get?id=" + id);
    }

    deleteStore(id) {
        return this.getHTTPClient().delete("/stores?id=" + id);
    }

    getDeletedStores() {
        return this.getHTTPClient().get("/stores/deleted");
    }

    importAmazonAllOrdersByStoreId(storeId, data) {
        return this.getHTTPClient().post("/import/amazon-all-orders?store-id=" + storeId, data);
    }

    importAmazonNewOrdersByStoreId(storeId, fulfilmentChannel, data) {
        return this.getHTTPClient().post("/import/amazon-new-orders?store-id=" + storeId
            + "&fulfilment-channel=" + fulfilmentChannel, data);
    }

    importAmazonUnshipOrdersByStoreId(storeId, fulfilmentChannel, orderStatus, data) {
        return this.getHTTPClient().post("/import/amazon-unship-orders?store-id=" + storeId
            + "&fulfilment-channel=" + fulfilmentChannel + "&order-status=" + orderStatus, data);
    }

    importShopifyOrdersByStoreId(storeId, data) {
        return this.getHTTPClient().post("/import/shopify-orders?store-id=" + storeId, data);
    }

    importMoneyDollyOrdersByStoreId(storeId, data) {
        return this.getHTTPClient().post("/import/money-dolly-orders?store-id=" + storeId, data);
    }

    // Order Pull Requests

    getOrderPRContentsById(OPRId) {
        return this.getHTTPClient().get("/order-pull-request-contents?order-pull-request-id=" + OPRId);
    }

    updateOrderPRContents(id, sku, qtyDesired, invoice) {
        return this.getHTTPClient().post("/update/order-pull-request-content?id=" + id + "&sku=" + sku
            + "&qty-desired=" + qtyDesired + "&invoice=" + invoice);
    }

    deleteOrderPRContentsById(contentId) {
        return this.getHTTPClient().delete("/order-pull-request-content?id=" + contentId);
    }

    searchOrderPRContents(filters) {
        return this.getHTTPClient().post("/search/order-pull-request-contents", filters);
    }

    searchExportOrderPRContents(filters) {
        return this.getHTTPClient().post("/export/search/order-pull-request-contents", filters);
    }

    updateOrderPRContentsByQuery(id, pullName, status, clientId) {
        console.log(clientId)
        return this.getHTTPClient().post("/update/order-pull-request?id=" + id
            + "&pull-name=" + pullName + "&status=" + status + "&clientId=" + clientId);
    }

    importOrderPRContents(id, data) {
        return this.getHTTPClient().put("/import/order-pull-request-contents?order-pull-request-id="
            + id, data);
    }

    handlePullListings(id) {
        return this.getHTTPClient().post("/pull-listing-data/order-pull-requests?order-pull-request-id="
            + id);
    }

    handlePullListingsAsATask(id) {
        return this.getHTTPClient().post("/worker/task/order-pull-request-listing-puller?order-pull-request-id=" + id);
    }

    handlePullWhContents(id) {
        return this.getHTTPClient().post("/pull-warehouse-order-contents?order-pull-request-id=" + id);
    }

    handlePullWhContentsAsATask(id) {
        return this.getHTTPClient().post("/worker/task/order-pull-request-warehouse-puller?order-pull-request-id=" + id);
    }

    handleDownloadPullReport(id) {
        return this.getHTTPClient().get("/export/order-pull-reports?order-pull-request-id=" + id);
    }

    handleDownloadPullContents(id) {
        return this.getHTTPClient().get("/export/order-pull-contents?order-pull-request-id=" + id);
    }

    handleDeleteAllContents(id) {
        return this.getHTTPClient().delete("/all-warehouse-contents?location-id=" + id);
    }

    getPullRequestById(id) {
        return this.getHTTPClient().get("/order-pull-request?id=" + id);
    }

    importUpdateOrderPRContents(data) {
        return this.getHTTPClient().put("/import/update/order-pull-request-contents", data);
    }

    searchOrderPRs(filters) {
        return this.getHTTPClient().post("/search/order-pull-requests", filters);
    }

    searchUpdateOrderPRContents(id, pullName, status, clientId) {
        return this.getHTTPClient().post("/update/order-pull-request?id=" + id
            + "&pull-name=" + pullName
            + "&status=" + status
            + "&clientId=" + clientId);
    }

    addOrderPullRequest() {
        return this.getHTTPClient().post("/order-pull-request");
    }

    // Wholesale orders

    searchWholesaleOrders(filters) {
        return this.getHTTPClient().post("/wholesale-orders/search", filters);
    }

    getWholesaleOrder(id) {
        return this.getHTTPClient().get("/wholesale-orders?id=" + id);
    }

    updateWholesaleOrder(order) {
        return this.getHTTPClient().post("/wholesale-orders", order);
    }

    addNewWholesaleOrder() {
        return this.getHTTPClient().put("/wholesale-orders");
    }

    importWholesaleOrderContents(id, data) {
        return this.getHTTPClient().put("/wholesale-order-contents/import?wholesale-order-id=" + id, data);
    }

    getWholesaleOrderContentsById(id) {
        return this.getHTTPClient().get("/wholesale-order-contents?wholesale-order-id=" + id);
    }

    updateWholesaleOrderContent(id, sku, qtyDesired, invoice) {
        return this.getHTTPClient().post("/wholesale-order-contents?id=" + id + "&sku=" + sku
            + "&qty-desired=" + qtyDesired + "&invoice=" + invoice);
    }

    deleteWholesaleOrderContent(contentId) {
        return this.getHTTPClient().delete("/wholesale-order-contents?id=" + contentId);
    }

    wholesaleOrderPullListings(id) {
        return this.getHTTPClient().post("/wholesale-order-contents/pull-listing-data?wholesale-order-id="
            + id);
    }

    wholesaleOrderPullWhContents(id) {
        return this.getHTTPClient().post("/wholesale-order-contents/pull-warehouse-order-contents?wholesale-order-id=" + id);
    }

    wholesaleDownloadPullContents(id) {
        return this.getHTTPClient().get("/export/wholesale-order-contents?wholesale-order-id=" + id);
    }

    wholesaleDownloadPullReport(id) {
        return this.getHTTPClient().get("/export/wholesale-order-reports?wholesale-order-id=" + id);
    }

    // Upc pulls

    searchUpcPulls(filters) {
        return this.getHTTPClient().post("/upc-pulls/search", filters);
    }

    getUpcPull(id) {
        return this.getHTTPClient().get("/upc-pulls?id=" + id);
    }

    addNewUpcPull() {
        return this.getHTTPClient().put("/upc-pulls");
    }

    updateUpcPull(pull) {
        return this.getHTTPClient().post("/upc-pulls", pull);
    }

    addUpcPullContents(id, contents) {
        return this.getHTTPClient().put("/upc-pulls/contents?id=" + id, contents);
    }

    getUpcPullContentsById(id) {
        return this.getHTTPClient().get("/upc-pulls/contents?id=" + id);
    }

    updateUpcPullContent(id, upc, qtyDesired) {
        return this.getHTTPClient().post("/upc-pulls/contents?id=" + id + "&upc=" + upc + "&qty-desired=" + qtyDesired);
    }

    deleteUpcPullContent(contentId) {
        return this.getHTTPClient().delete("/upc-pulls/contents?id=" + contentId);
    }

    upcPullContentsPullWhData(id) {
        return this.getHTTPClient().post("/upc-pulls/contents/pull-warehouse-data?upcPullId=" + id);
    }

    upcPullDownloadPullContents(id) {
        return this.getHTTPClient().get("/export/upc-pull-contents?upcPullId=" + id);
    }

    upcPullDownloadPullReport(id) {
        return this.getHTTPClient().get("/export/upc-pull-reports?upcPullId=" + id);
    }

    // Home

    getUnbilledOrderFulfilments() {
        return this.getHTTPClient().get("/unbilled-order-fulfilment");
    }

    exportUnbilledOrderFulfilments(clientId) {
        return this.getHTTPClient().get("/export/order-pr-content?client-id=" + clientId);
    }

    getUnbilledWarehousePulls() {
        return this.getHTTPClient().get("/pull-requests?unbilled-warehouse-pulls=true");
    }

    getUnbilledWorkOrder() {
        return this.getHTTPClient().get("/unbilled-work-orders");
    }

    getUnbilledWholesaleOrders() {
        return this.getHTTPClient().get("/wholesale-orders/unbilled");
    }

    getClients() {
        return this.getHTTPClient().get("/clients");
    }

    exportWHLocationsByClientId(clientId) {
        return this.getHTTPClient().get("/export/warehouse-locations?client-id=" + clientId);
    }
  
    // Clients

    getClientById(id) {
        return this.getHTTPClient().get("/clients/" + id + "/details");
    }

    updateClient(content) {
        return this.getHTTPClient().post("/clients", content);
    }

    getClientListings(id) {
        return this.getHTTPClient().post("/clients/" + id + "/listings");
    }

    importClientListings(id, asin, fnsku, upc, componentUpc, data) {
        return this.getHTTPClient().post("/clients/" + id + "/import/listings?asin=" + asin
            + "&fnsku=" + fnsku + "&upc=" + upc + "&componentUpc=" + componentUpc, data);
    }

    searchPRContents(filters) {
        return this.getHTTPClient().post("/search/pull-requests-contents", filters)
    }

    searchExportPRContents(filters) {
        return this.getHTTPClient().post("/export/search/pull-requests-contents", filters)
    }

    addClient() {
        return this.getHTTPClient().put("/clients");
    }

    // Tasks
    getTasks() {
        return this.getHTTPClient().get("/tasks");
    }

    taskImportIFContents(data) {
        return this.getHTTPClient().post("/worker/task/import-inbound-feed-contents", data);
    }

    taskUpdateOFQty(data) {
        return this.getHTTPClient().post("/worker/task/outbound-contents-qty-updater", data);
    }

    stopTask(id) {
        return this.getHTTPClient().post("/tasks?taskId=" + id);
    }

    startTask(id) {
        return this.getHTTPClient().post("/tasks/start?taskId=" + id);
    }

    // Scheduler
    getSchedule(type, id) {
        return this.getHTTPClient().get("/schedule?name=" + type + "&id="+ id);
    }

    getAllSchedules() {
        return this.getHTTPClient().get("/schedule/all");
    }

    postSchedule(type, id, data) {
        return this.getHTTPClient().post("/schedule?name=" + type + "&id="+ id, data);
    }  

    updateSchedule(scheduleId, type, id, data) {
        return this.getHTTPClient().put("/schedule/" + scheduleId + "?name=" + type + "&id="+ id, data);
    }

    deleteSchedule(scheduleId) {
        return this.getHTTPClient().delete("/schedule/" + scheduleId );
    }
}
