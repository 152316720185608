import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {SkipNext, SkipPrevious} from "@material-ui/icons";
import {getIdFromUrl, handleErr, showExportSpinner, showFilterSpinner} from "../utils/MiscellaniousUtils";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import {FILTER, SAVE, FILTERING, EXPORT, EXPORTING, strOptions, eqOptions, intOptions} from "../constants";
import ResourceAPIs from "../utils/ResourceAPI";


const filterBtn = {
    marginRight: 10,
    width: 120,
    marginTop: 10,
    marginBottom: 10
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class ClientWHLocations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            exportBtnText: EXPORT,
        };
    }

    onChangeLocationNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeLocationNameValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationName.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeLocationIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeLocationIdValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationId.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeWarehouseIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouseId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeWarehouseIdValue = (event) => {
        let newState = Object.assign({}, this.state);
        newState.filters.warehouseId.value = event.target.value;
        this.setState(newState);
    };


    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchWhLocations();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    }

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchWhLocations();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchWhLocations();
    };

    searchWhLocations() {
        if (this.validateInputs()) {
            this.setState({
                filterBtnText: FILTERING,
                isProcessing: true,
            });
            new ResourceAPIs().getSearchWHLocation(this.state.filters)
                .then(res => {
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: res.data,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: error,
                        });
                    });
        }
    }

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().getSearchExportWHLocations(this.state.filters)
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "warehouse-locations-search-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    validateInputs = () => {
        // validate warehouse
        if (parseInt(this.state.filters.warehouseId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = parseInt(this.state.filters.warehouseId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid warehouse ID"
            });
            return false;
        }

        // validate location id
        if (parseInt(this.state.filters.locationId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationId.value = parseInt(this.state.filters.locationId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid location ID"
            });
            return false;
        }

        return true;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: this.props.id,
                },
                size: 100,
                from: 0,
            }
        });
    };

    render() { 
        return ( 
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Location Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeLocationNameFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              value={this.state.filters.locationName.value}
                                                              onChange={this.onChangeLocationNameValue}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Location ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeLocationIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeLocationIdValue}
                                                              value={this.state.filters.locationId.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Warehouse ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={intOptions}
                                                                handleOnChange={this.onChangeWarehouseIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              onChange={this.onChangeWarehouseIdValue}
                                                              value={this.state.filters.warehouseId.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <p style={{fontSize: 12, marginTop: 17}}>Export Result</p>
                                            <Button variant="success" onClick={this.onClickExport}
                                                    disabled={this.state.isProcessing}>
                                                {showExportSpinner(this.state.exportBtnText)}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">Location ID</TableCell>
                                            <TableCell align="right">Location Name</TableCell>
                                            <TableCell align="right">Qty In Location</TableCell>
                                            <TableCell align="right">Warehouse ID</TableCell>
                                            <TableCell align="right">Location Size</TableCell>
                                            <TableCell align="right">Created At</TableCell>
                                            <TableCell align="right">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/wh-locations/" + row.id} target="_blank">
                                                        {row.id}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="right">{row.locationName}</TableCell>
                                                <TableCell align="right">{row.qtyInLocation}</TableCell>
                                                <TableCell align="right">{row.warehouseId}</TableCell>
                                                <TableCell align="right">{row.locationSize}</TableCell>
                                                <TableCell
                                                    align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        
        );
    }
}
 
export default ClientWHLocations;
