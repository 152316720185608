import React, {Component} from "react";
import OrdersImportTabs from "./OrdersImportTabs";
import OrdersExporter from "./OrdersExporter";


class Orders extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <h4>Orders Exporter</h4>
                <OrdersExporter/>
                <h4>Orders Importer</h4><br/>
                <OrdersImportTabs/>
            </div>
        );
    }
}

export default Orders;