import React, {Component} from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import PullRequestsSearch from "./PullRequestsSearch";
import {BULK_ACTIONS} from "../../constants";

class PullRequests extends Component {

    render() {
        return (
            <div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="search">Search Pull Requests</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="search">
                            <br/>
                            <PullRequestsSearch id={this.props.id}/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Pane eventKey="importAndExport">
                        <br/>
                        {/*<ContentsImporter/>*/}
                        <br/>
                        {/*<ContentsExporter/>*/}
                    </Tab.Pane>
                </Tab.Container>
            </div>
        );
    }
}

export default PullRequests;
