import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { format2NiceDate } from "../utils/DateUtils";
import { handleError } from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const STORES = "stores";
const STORE = "store";

class DeletedStores extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            results: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.fetchDeletedStores();
    }

    fetchDeletedStores() {
        new ResourceAPIs().getDeletedStores().then(response => {
            this.setState({
                results: response.data,
                isLoaded: true,
            });
        }).catch(error => {
            handleError(error);
            this.setState({
                results: error,
                isLoaded: true,
            });
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <br /><br />
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Type</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/" + STORE + "/" + row.id} target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="right">{row.clientId}</TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.type}</TableCell>
                                                <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DeletedStores;
