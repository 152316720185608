import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {ADD_CONTENTS, ADDING_CONTENTS, SAVE, SAVING, ADD_CONTENTS_TASK, ADDING_CONTENTS_TASK} from "../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../utils/DateUtils";
import {
    alertSuccess,
    createTypeOption,
    getIdFromUrl,
    getLabel,
    handleErr,
    handleError,
    isEmptyOrUndefined
} from "../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import {Clear} from "@material-ui/icons";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import PurchaseOrderContents from "./PurchaseOrderContents";
import Select from "react-select";
import POContentsAdder from "./POContentsAdder";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ResourceAPI from "../utils/ResourceAPI";
import {
    MAKE_BUY_RECOMMENDATION, 
    MAKE_BUY_RECOMMENDATION_TASK,
    MAKING_BUY_RECOMMENDATION, 
    MAKING_BUY_RECOMMENDATION_TASK,
    PULL_BR_SALES_TASK,
    PULL_FBA_INVENTORY,
    PULL_FBA_INVENTORY_TASK,
    PULL_LISTING_DATA,
    PULL_LISTING_TASK,
    PULL_SALES_FROM_BR,
    PULL_WH_CONTENTS,
    PULL_WH_CONTENTS_TASK,
    pullBtnStyle,
    PULLING_BR_SALES_TASK,
    PULLING_FBA_INVENTORY,
    PULLING_FBA_INVENTORY_TASK,
    PULLING_LISTING_DATA,
    PULLING_LISTING_TASK,
    PULLING_SALES_FROM_BR,
    PULLING_WH_CONTENTS_TASK,
    taskBtnStyle,
    PULL_FEED_CONTENTS,
    PULL_FEED_CONTENTS_TASK,
    PULLING_FEED_CONTENTS,
    PULLING_FEED_CONTENTS_TASK
} from "./POConstants";
import {
    showAddContentsSpinner,
    showMakeBuyRecSpinner,
    showPullInbFeedContentsSpinner,
    showPullFbaInventorySpinner,
    showPullListingsSpinner,
    showPullSalesFromBRSpinner,
    showPullWhContentsSpinner,
    showSavePRSpinner
} from "./POUtils";

class PurchaseOrderDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            purchaseOrder: {
                id: getIdFromUrl(),
                createdAt: "",
                updatedAt: "",
                clientId: "",
                storeId: "",
                warehouseId: "",
                supplier: "",
                storeName: "",
                comments: "",
                fbaInventoryId: "",
                businessReportId: "",
                inbFeedId: "",
                replenishmentId: "",
                status: "",
                errMsg: "",
            },
            addContent: {
                sku: "",
            },
            selectedFile: null,
            isLocationLoaded: false,
            isProcessing: false,
            addContentBtnText: "Add Contents",
            addContentTaskBtnText: "Add Contents Task",
            deleteContentBtnText: "Delete All Contents",
            editPullRequestBtnText: SAVE,
            pullListingBtnText: PULL_LISTING_DATA,
            pullListingAsTaskBtnText: PULL_LISTING_TASK,
            pullFbaInventoryBtnText: PULL_FBA_INVENTORY,
            pullWhContentsBtnText: PULL_WH_CONTENTS,
            pullInbFeedContentsBtnText: PULL_FEED_CONTENTS,
            pullInbFeedContentsAsATaskBtnText: PULL_FEED_CONTENTS_TASK,
            pullSalesFromBRBtnText: PULL_SALES_FROM_BR,
            makeBuyRecBtnText: MAKE_BUY_RECOMMENDATION,
            pullWhContentsAsATaskBtnText: PULL_WH_CONTENTS_TASK,
            pullFBAInventoryAsATaskBtnText:  PULL_FBA_INVENTORY_TASK,
            pullBRSalesAsATaskBtnText: PULL_BR_SALES_TASK,
            makeBuyRecAsATaskBtnText: MAKE_BUY_RECOMMENDATION_TASK,
            errMsg: "",
            childLoad: false,
            clientLabel : "",
            storeLabel : "",
            clients: [],
            storeIds: []
        };

        // Edit pull requests

        this.onChangeClientIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeWarehouseId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.warehouseId = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreIdValue = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.storeId = selected.value;
            newState.storeLabel = getLabel(this.state.storeIds, selected.value);
            this.setState(newState);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeSupplier = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.supplier = event.target.value;
            this.setState(newState);
        };

        this.onChangeComments = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.comments = event.target.value;
            this.setState(newState);
        };

        this.onChangeFbaInventoryId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.fbaInventoryId = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.status = event.target.value;
            this.setState(newState);
        };

        this.onChangeBusinessReportId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.businessReportId = event.target.value;
            this.setState(newState);
        };

        this.onChangeInbFeedId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.inbFeedId = event.target.value;
            this.setState(newState);
        }

        this.onChangeReplenishmentId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.purchaseOrder.replenishmentId = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentSku = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.sku = event.target.value;
            this.setState(newState);
        };

        // Add contents

        this.onChangeAddContentQty = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.qty = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentBarcodes = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.barcodes = event.target.value;
            this.setState(newState);
        };

        // passing from child
        this.chooseListing = (listingsArr) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.listingId = listingsArr.id;
            this.setState(newState);
        };

        this.handleEditPurchaseOrder = () => {
            if (this.validateEditPurchaseOrder()) {
                this.setState({
                    editPullRequestBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPI().updatePurchaseOrder(this.state.purchaseOrder)
                    .then(res => {
                            console.log(res);
                            this.setState({
                                editPullRequestBtnText: SAVE,
                                isProcessing: false,
                            });
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                editPullRequestBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.setChildLoad = (bool) => {
            this.setState({
                childLoad: bool,
            });
        };

        this.handleCancelEditLocation = () => {
            this.getPurchaseOrderById();
        };

        this.handleAddContents = () => {
            if (this.validateAddContentInputs()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });
                new ResourceAPI().addPurchaseOrderContents(getIdFromUrl(), this.state.addContent)
                    .then(res => {
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                childLoad: false,
                                addContent: {
                                    sku: "",
                                }
                            });
                            this.restAddContentFrom();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleAddContentsTask = () => {
            if (this.validateAddContentInputs()) {
                this.setState({
                    addContentTaskBtnText: ADDING_CONTENTS_TASK,
                    isProcessing: true,
                });
                new ResourceAPI().addPurchaseOrderContentsAsATask(getIdFromUrl(), this.state.addContent)
                    .then(res => {
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentTaskBtnText: ADD_CONTENTS_TASK,
                                isProcessing: false,
                                childLoad: false,
                                addContent: {
                                    sku: "",
                                }
                            });
                            this.restAddContentFrom();
                            alertSuccess("'Add PO Contents Manually' task added successfully!");
                        })
                    .catch(error => {
                            handleError(error);
                            this.setState({
                                addContentTaskBtnText: ADD_CONTENTS_TASK,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.validateAddContentInputs = () => {
            if (this.state.addContent.sku.trim() === "") {
                this.setState({
                    errMsg: "SKUs field cannot be empty!"
                });
                return false;
            }
            return true;
        };

        this.handlePullListings = () => {
            this.setState({
                pullListingBtnText: PULLING_LISTING_DATA,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullListings(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullListingBtnText: PULL_LISTING_DATA,
                            isProcessing: false,
                            childLoad: false,
                        });
                        this.getPurchaseOrderById();
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullListingBtnText: PULL_LISTING_DATA,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullWhContents = () => {
            this.setState({
                pullWhContentsBtnText: "Pulling Warehouse Contents",
                isProcessing: true,
            });
            new ResourceAPI().addPOPullWhContents(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullWhContentsBtnText: PULL_WH_CONTENTS,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullWhContentsBtnText: PULL_WH_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullInbFeedContents = () => {
            this.setState({
                pullInbFeedContentsBtnText: PULLING_FEED_CONTENTS,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullInbFeedContents(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullInbFeedContentsBtnText: PULL_FEED_CONTENTS,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullInbFeedContentsBtnText: PULL_FEED_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullFbaInventory = () => {
            this.setState({
                pullFbaInventoryBtnText: PULLING_FBA_INVENTORY,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullFbaInventory(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullFbaInventoryBtnText: PULL_FBA_INVENTORY,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullFbaInventoryBtnText: PULL_FBA_INVENTORY,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullSalesFromBR = () => {
            this.setState({
                pullSalesFromBRBtnText: PULLING_SALES_FROM_BR,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullSalesFromBR(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullSalesFromBRBtnText: PULL_SALES_FROM_BR,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullSalesFromBRBtnText: PULL_SALES_FROM_BR,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleMakeBuyRecommendation = () => {
            this.setState({
                makeBuyRecBtnText: MAKING_BUY_RECOMMENDATION,
                isProcessing: true,
            });
            new ResourceAPI().addPOMakeBuyRecommendation(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            makeBuyRecBtnText: MAKE_BUY_RECOMMENDATION,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            makeBuyRecBtnText: MAKE_BUY_RECOMMENDATION,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullWhContentsAsATask = () => {
            this.setState({
                pullWhContentsAsATaskBtnText: PULLING_WH_CONTENTS_TASK,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullWhContentsAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        pullWhContentsAsATaskBtnText: PULL_WH_CONTENTS_TASK,
                        isProcessing: false,
                        childLoad: false,
                    });
                    alertSuccess("'Pull Warehouse Contents' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullWhContentsAsATaskBtnText: PULL_WH_CONTENTS_TASK,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handlePullInbFeedContentsAsATask = () => {
            this.setState({
                pullInbFeedContentsAsATaskBtnText: PULLING_FEED_CONTENTS_TASK,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullInbFeedContentsAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        pullInbFeedContentsAsATaskBtnText: PULL_FEED_CONTENTS_TASK,
                        isProcessing: false,
                        childLoad: false,
                    });
                    alertSuccess("'Pull Inb Feed Contents' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullInbFeedContentsAsATaskBtnText: PULL_FEED_CONTENTS_TASK,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handlePullListingDataAsATask = () => {
            this.setState({
                pullListingAsTaskBtnText: PULLING_LISTING_TASK,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullListingAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        pullListingAsTaskBtnText: PULL_LISTING_TASK,
                        isProcessing: false,
                    });
                    alertSuccess("'Pull Listing data' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullListingAsTaskBtnText: PULL_LISTING_TASK,
                        isProcessing: false,
                    });
                });
        };

        this.handlePullFBAInventoryAsATask = () => {
            this.setState({
                pullFBAInventoryAsATaskBtnText: PULLING_FBA_INVENTORY_TASK,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullFBAInventoryAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        pullFBAInventoryAsATaskBtnText: PULL_FBA_INVENTORY_TASK,
                        isProcessing: false,
                    });
                    alertSuccess("'Pull FBA Inventory data' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullFBAInventoryAsATaskBtnText: PULL_FBA_INVENTORY_TASK,
                        isProcessing: false,
                    });
                });
        };

        this.handlePullBRSalesAsATask = () => {
            this.setState({
                pullBRSalesAsATaskBtnText: PULLING_BR_SALES_TASK,
                isProcessing: true,
            });
            new ResourceAPI().addPOPullBRSalesAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        pullBRSalesAsATaskBtnText: PULL_BR_SALES_TASK,
                        isProcessing: false,
                    });
                    alertSuccess("'Pull Sales from Business Reports' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullBRSalesAsATaskBtnText: PULL_BR_SALES_TASK,
                        isProcessing: false,
                    });
                });
        };

        this.handleMakeBuyRecommendAsATask = () => {
            this.setState({
                makeBuyRecAsATaskBtnText: MAKING_BUY_RECOMMENDATION_TASK,
                isProcessing: true,
            });
            new ResourceAPI().addPOMakeBuyRecommendAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        makeBuyRecAsATaskBtnText: MAKE_BUY_RECOMMENDATION_TASK,
                        isProcessing: false,
                    });
                    alertSuccess("'Made buy recommendation' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        makeBuyRecAsATaskBtnText: MAKE_BUY_RECOMMENDATION_TASK,
                        isProcessing: false,
                    });
                });
        };
    }

    validateAddContentsInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                errMsg: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                errMsg: "",
            });
        }
        return true;
    };

    validateEditPurchaseOrder = () => {
        // validate warehouse id
        if (!isEmptyOrUndefined(this.state.purchaseOrder.warehouseId)) {
            if (parseInt(this.state.purchaseOrder.warehouseId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.purchaseOrder.warehouseId = parseInt(this.state.purchaseOrder.warehouseId, 10);
                this.setState(stateCopy);
            } else {
                return this.setPurchaseOrderEditErr("Invalid Warehouse ID!");
            }
        } else {
            return this.setPurchaseOrderEditErr("Warehouse ID field is required!");
        }

        // validate fbaInventoryId
        if (!isEmptyOrUndefined(this.state.purchaseOrder.fbaInventoryId)) {
            if (parseInt(this.state.purchaseOrder.fbaInventoryId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.purchaseOrder.fbaInventoryId = parseInt(this.state.purchaseOrder.fbaInventoryId, 10);
                this.setState(stateCopy);
            } else {
                return this.setPurchaseOrderEditErr("Invalid FBA Inventory ID!");
            }
        } else {
            return this.setPurchaseOrderEditErr("FBA Inventory ID field is required!");
        }

        // validate businessReportId
        if (!isEmptyOrUndefined(this.state.purchaseOrder.businessReportId)) {
            if (parseInt(this.state.purchaseOrder.businessReportId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.purchaseOrder.businessReportId = parseInt(this.state.purchaseOrder.businessReportId, 10);
                this.setState(stateCopy);
            } else {
                return this.setPurchaseOrderEditErr("Invalid Business Report ID!");
            }
        } else {
            return this.setPurchaseOrderEditErr("Business Report ID field is required!");
        }

        // validate replenishmentId
        if (!isEmptyOrUndefined(this.state.purchaseOrder.replenishmentId)) {
            if (parseInt(this.state.purchaseOrder.replenishmentId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.purchaseOrder.replenishmentId = parseInt(this.state.purchaseOrder.replenishmentId, 10);
                this.setState(stateCopy);
            } else {
                return this.setPurchaseOrderEditErr("Invalid Replenishment Multiple!");
            }
        } else {
            return this.setPurchaseOrderEditErr("Replenishment Multiple field is required!");
        }

        return true;
    };

    setPurchaseOrderEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.purchaseOrder.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getPurchaseOrderById = () => {
        new ResourceAPI().getPurchaseOrderById(getIdFromUrl())
            .then(res => {
                    const result = res.data;
                    this.setState({
                        purchaseOrder: {
                            id: result.id,
                            createdAt: result.createdAt,
                            updatedAt: result.updatedAt,
                            clientId: result.clientId,
                            warehouseId: result.warehouseId,
                            storeId: result.storeId,
                            storeName: result.storeName,
                            supplier: result.supplier,
                            status: result.status,
                            fbaInventoryId: result.fbaInventoryId,
                            businessReportId: result.businessReportId,
                            replenishmentId: result.replenishmentId,
                            inbFeedId: result.inbFeedId,
                            comments: result.comments,
                            errMsg: ""
                        },
                        isLocationLoaded: true,
                        storeLabel : getLabel(this.state.storeIds, result.storeId),
                        clientLabel : getLabel(this.state.clients, result.clientId)
                    })
                })
            .catch(error => {
                    this.setState({
                        isLocationLoaded: false,
                        error
                    })
                }
            );
    };

    restAddContentFrom = () => {
        this.setState({
            addContent: {
                qty: "",
                barcodes: "",
            }
        });
    };

    getStores = () => {
        new ResourceAPI().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storeIds: storesIds,
                    });

                    this.getPurchaseOrderById();

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPI().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });

            this.getStores();

        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <div>
                        <Card border="secondary">
                            <Card.Header as="h5">
                                <span style={{marginTop: 60}}>Purchase Order Details</span>

                                <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.purchaseOrder.errMsg}
                                    </span>
                                    <Button variant="success" size="sm" style={{width: 100,}}
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleEditPurchaseOrder}>
                                        {showSavePRSpinner(this.state.editPullRequestBtnText)}
                                    </Button>
                                    <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                            onClick={this.handleCancelEditLocation}
                                            disabled={this.state.isProcessing}>
                                        <Clear/> Cancel
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.purchaseOrder.id}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Client ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <div>
                                                            {this.props.id === undefined && (
                                                                <Select
                                                                    value={createTypeOption(this.state.clientLabel)}
                                                                    onChange={(e) => this.onChangeClientIdValue(e)}
                                                                    options={this.state.clients}
                                                                />
                                                            )}
                                                            {this.props.id !== undefined && (
                                                                <Select
                                                                    value={createTypeOption(this.state.clientLabel)}
                                                                    onChange={(e) => this.onChangeClientIdValue(e)}
                                                                    options={this.state.clients}
                                                                    isDisabled={true}
                                                                />
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Warehouse ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.purchaseOrder.warehouseId}
                                                                      onChange={this.onChangeWarehouseId}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Comments</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text" size="sm" as="textarea" rows="5"
                                                                      value={this.state.purchaseOrder.comments}
                                                                      onChange={this.onChangeComments}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Store ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <div>
                                                            <Select
                                                                value={createTypeOption(this.state.storeLabel)}
                                                                onChange={(e) => this.onChangeStoreIdValue(e)}
                                                                options={this.state.storeIds}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Store Name</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text" size="sm"
                                                                      value={this.state.purchaseOrder.storeName}
                                                                      onChange={this.onChangeStoreName}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Supplier</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text" size="sm"
                                                                      value={this.state.purchaseOrder.supplier}
                                                                      onChange={this.onChangeSupplier}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Status</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text" size="sm"
                                                                      value={this.state.purchaseOrder.status}
                                                                      onChange={this.onChangeStatus}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.purchaseOrder.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.purchaseOrder.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>FBA Inventory ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.purchaseOrder.fbaInventoryId}
                                                                      onChange={this.onChangeFbaInventoryId}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Business Report ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.purchaseOrder.businessReportId}
                                                                      onChange={this.onChangeBusinessReportId}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Inb Feed ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.purchaseOrder.inbFeedId}
                                                                      onChange={this.onChangeInbFeedId}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Replenishment Multiple</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number" size="sm"
                                                                      value={this.state.purchaseOrder.replenishmentId}
                                                                      onChange={this.onChangeReplenishmentId}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br/>

                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="manual">
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="manual">Add PO Contents Manually</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="listings">Add PO Contents using Listings</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <br/>
                            <Tab.Content>
                                <Tab.Pane eventKey="manual">
                                    <Card>
                                        <Card.Body>
                                            <Form.Group as={Col} controlId="formGridState">
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>SKUs</Form.Label>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control as="textarea" rows="4"
                                                                          value={this.state.addContent.sku}
                                                                          onChange={this.onChangeAddContentSku}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form.Group>
                                            <div>
                                                <span style={{fontSize: 12, color: "red",}}>{this.state.errMsg}</span>
                                                <br/>
                                                <Row>
                                                    <Button variant="success" 
                                                        style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                                                        disabled={this.state.isProcessing}
                                                        onClick={this.handleAddContents}>
                                                        {showAddContentsSpinner(this.state.addContentBtnText)}
                                                    </Button>
                                                    <Button variant="warning" style={{ width: 200 }}
                                                        disabled={this.state.isProcessing}
                                                        onClick={this.handleAddContentsTask}>
                                                        {showAddContentsSpinner(this.state.addContentTaskBtnText)}
                                                    </Button>
                                                </Row>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="listings">
                                    <POContentsAdder childLoadSetter={this.setChildLoad} id={this.props.id}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    <br/>

                    <div>
                        <Row>
                            <Col xs={2}>
                                <Button variant="info" style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullListings}>
                                    {showPullListingsSpinner(this.state.pullListingBtnText)}
                                </Button>
                                <Button variant="info"
                                        style={taskBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullListingDataAsATask}>
                                    {showPullListingsSpinner(this.state.pullListingAsTaskBtnText)}
                                </Button>
                            </Col>
                            <Col xs={2}>
                                <Button variant="secondary"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullWhContents}>
                                    {showPullWhContentsSpinner(this.state.pullWhContentsBtnText)}
                                </Button>
                                <Button variant="secondary"
                                        style={taskBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullWhContentsAsATask}>
                                    {showPullWhContentsSpinner(this.state.pullWhContentsAsATaskBtnText)}
                                </Button>
                            </Col>
                            <Col xs={2}>
                                <Button variant="success"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullInbFeedContents}>
                                    {showPullInbFeedContentsSpinner(this.state.pullInbFeedContentsBtnText)}
                                </Button>
                                <Button variant="success"
                                        style={taskBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullInbFeedContentsAsATask}>
                                    {showPullInbFeedContentsSpinner(this.state.pullInbFeedContentsAsATaskBtnText)}
                                </Button>
                            </Col>
                            <Col xs={2}>
                                <Button variant="primary"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullFbaInventory}>
                                    {showPullFbaInventorySpinner(this.state.pullFbaInventoryBtnText)}
                                </Button>
                                <Button variant="primary"
                                        style={taskBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullFBAInventoryAsATask}>
                                    {showPullFbaInventorySpinner(this.state.pullFBAInventoryAsATaskBtnText)}
                                </Button>
                            </Col>
                            <Col xs={2}>
                                <Button variant="dark"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullSalesFromBR}>
                                    {showPullSalesFromBRSpinner(this.state.pullSalesFromBRBtnText)}
                                </Button>
                                <Button variant="dark"
                                        style={taskBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handlePullBRSalesAsATask}>
                                    {showPullSalesFromBRSpinner(this.state.pullBRSalesAsATaskBtnText)}
                                </Button>
                            </Col>
                            <Col xs={2}>
                                <Button variant="warning"
                                        style={pullBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleMakeBuyRecommendation}>
                                    {showMakeBuyRecSpinner(this.state.makeBuyRecBtnText)}
                                </Button>
                                <Button variant="warning"
                                        style={taskBtnStyle}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleMakeBuyRecommendAsATask}>
                                    {showMakeBuyRecSpinner(this.state.makeBuyRecAsATaskBtnText)}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                    <PurchaseOrderContents purchaseOrder={this.state.purchaseOrder} isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

export default PurchaseOrderDetails;
