import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import {TiArrowSync} from "react-icons/ti";
import * as Constants from '../../constants';
import {alertError, alertSuccessfullyUpdated} from "../../utils/MiscellaniousUtils";
import Spinner from "react-bootstrap/Spinner";
import ResourceAPIs from "../../utils/ResourceAPI";

class RecalculateQtyInLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            recalculateQtyBtnText: Constants.RECALCULATE,
            err: "",
        };
    }

    onClickHandler = () => {
        this.setState({
            recalculateQtyBtnText: Constants.RECALCULATING,
            isProcessing: true,
        });
        new ResourceAPIs().recalculateQtyInLocation()
            .then(res => {
                    alertSuccessfullyUpdated(res.data);
                    this.setState({
                        recalculateQtyBtnText: Constants.RECALCULATE,
                        isProcessing: false,
                        err: "",
                    });
                },
                (error) => {
                    console.log(error.response.status);
                    alertError(error.response.data);
                    this.setState({
                        recalculateQtyBtnText: Constants.RECALCULATE,
                        isProcessing: false,
                    })
                }
            );
    };

    render() {
        return (
            <div>
                {this.props.title &&
                    <h4>{this.props.title}</h4>
                }
                <Button style={this.props.styles} variant="success" onClick={this.onClickHandler}
                        disabled={this.state.isProcessing}>
                    {showRecalculateQtyLocationSpinner(this.state.recalculateQtyBtnText)}
                </Button>
                <p>{this.state.err}</p>
            </div>
        );
    }
}


function showRecalculateQtyLocationSpinner(btnText) {
    if (btnText === Constants.RECALCULATE) {
        return <span><TiArrowSync/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default RecalculateQtyInLocation;
