import React, {Component} from "react";
import {IMPORT, IMPORT_TASK, IMPORTING, IMPORTING_TASK, MAX_FILE_SIZE, MAX_FILE_SIZE_STRING} from '../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
    alertSuccess,
    handleError,
    handleFileSizeExceedsError,
    showImportTaskSpinner,
    showSpinner,
} from "../utils/MiscellaniousUtils";
import Select from "react-select";
import {createTypeOption} from "../utils/FormUtils";
import ResourceAPIs from "../utils/ResourceAPI";

const amazonNewDiv = {
    marginTop: '25px',
};

class AmazonListingsImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: "Import",
            importAsATaskBtnText: IMPORT_TASK,
            importStatus: "",
            clients: [],
            storesIds: [],
            clientId: {
                value: "",
                label: "",
            },
            storeId: {
                value: "",
                label: "",
            },
        };

        this.onChangeClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.clientId.value = selected.value;
            stateCopy.clientId.label = this.getClientLabel(selected.value);
            this.setState(stateCopy);
        };

        this.onChangeStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.storeId.value = selected.value;
            stateCopy.storeId.label = this.getStoreLabel(selected.value)
            this.setState(stateCopy);
        };

        this.getClientLabel = value => {
            let data = "";
            this.state.clients.forEach((client) => {
                if(client.value === value) {
                    data = client.label;
                }
            });
            return data;
        };

        this.getStoreLabel = (value) => {
            let data = "";
            this.state.storesIds.forEach((store) => {
                if(store.value === value) {
                    data = store.label;
                }
            });
            return data;
        };
    }

    onChangeHandler = event => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].size > MAX_FILE_SIZE * 10) {
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                })
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                })
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('client-id', this.state.clientId.value);
        data.append('store-id', this.state.storeId.value);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true,
            });

            new ResourceAPIs().importAmazonListings(data).then(res => {
                this.setState({
                    importBtnText: IMPORT,
                    isProcessing: false,
                });
                this.clearFrom();
                alertSuccess(res.data);
            }).catch(error => {
                console.log(error);
                handleError(error);
                this.setState({
                    importBtnText: IMPORT,
                    isProcessing: false,
                });
            });
        }
    };

    onClickAsATaskHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('clientId', this.state.clientId.value);
        data.append('storeId', this.state.storeId.value);

        if (this.validateInputs()) {
            this.setState({
                importAsATaskBtnText: IMPORTING_TASK,
                isProcessing: true,
            });

            new ResourceAPIs().importAmazonListingsAsTask(data).then(res => {
                this.setState({
                    importAsATaskBtnText: IMPORT_TASK,
                    isProcessing: false,
                });
                this.clearFrom();
                alertSuccess(res.data);
            }).catch(error => {
                console.log(error);
                handleError(error);
                this.setState({
                    importAsATaskBtnText: IMPORT_TASK,
                    isProcessing: false,
                });
            });
        }
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    componentDidMount() {
        this.getClients();
        this.getStores();
    }

    render() {
        return (
            <div>
                <h4>Amazon Listings Importer</h4>

                <div style={{marginTop: '25px', width: '500px'}}>
                    <Form.Label>Client ID</Form.Label>
                    <Select
                        value={createTypeOption(this.state.clientId.label)}
                        onChange={(e) => this.onChangeClientIdValue(e)}
                        options={this.state.clients}
                    />
                </div>

                <div style={{marginTop: '25px', width: '500px'}}>
                    <Form.Label>Store ID</Form.Label>
                    <Select
                        value={createTypeOption(this.state.storeId.label)}
                        onChange={(e) => this.onChangeStoreIdValue(e)}
                        options={this.state.storesIds}
                    />
                </div>

                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Select File</Form.Label>
                        <br/>
                        <input type="file" name="file" onChange={this.onChangeHandler}/>
                        <Form.Text className="text-muted">
                            {this.state.fileErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="success" onClick={this.onClickHandler}
                                        disabled={this.state.isProcessing}>
                                    {showSpinner(this.state.importBtnText)}
                                </Button>
                                <Button variant="warning" onClick={this.onClickAsATaskHandler}
                                        disabled={this.state.isProcessing}
                                        style={{marginLeft: 10}}>
                                    {showImportTaskSpinner(this.state.importAsATaskBtnText)}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default AmazonListingsImporter;
