import React, {Component} from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Tasks from "./Tasks";
import Schedules from "./Schedules";

class TasksHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: "",
        }
    }

    componentDidMount() {
        let url = window.location.href;
        this.setState({
            activeKey: url.substring(url.lastIndexOf('/') + 1)
        });
    }

    render() {
        return (
            <div style={{marginLeft: '1%', marginRight: '1%'}}>
                <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey}>
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link href="/tasks" eventKey="tasks">
                                Tasks
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/tasks/schedules" eventKey="schedules">
                                Schedules
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Router>
                        <Switch>
                            <Route exact path="/tasks">
                                <Tasks/>
                            </Route>
                            <Route exact path="/tasks/schedules">
                                <Schedules/>
                            </Route>
                        </Switch>
                    </Router>
                </Tab.Container>
            </div>
        );
    }
}

export default TasksHome;