import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getLabel, createTypeOption, handleErr, showDeleteSpinner } from "../../utils/MiscellaniousUtils";
import { DELETE, DELETING } from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import { Info } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ResourceAPIs from "../../utils/ResourceAPI";
import Select from "react-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal);

const amazonNewDiv = {
    marginTop: '25px',
};

class ContentsDeleterWithLocationIds extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locationIds: "",
            locationIdsErr: undefined,
            isProcessing: false,
            deleteBtnText: DELETE,
        };

        this.onChangeLocationIdsValue = (event) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.locationIds = event.target.value;
            stateCopy.locationIdsErr = undefined;
            this.setState(stateCopy);
        }
    }

    deleteContents = (index) => {
        if (!this.validateLocationIds()) {
            return;
        }
        MySwal.fire({
            title: 'You want to Delete all the contents for location IDs: <br/>' + this.state.locationIds + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete them!'
        }).then((result) => {
            if (result.value) {
                // Delete happens here
                this.setState({
                    deleteBtnText: DELETING,
                    isProcessing: true,
                });
                new ResourceAPIs().deleteWHContentsForLocation(this.state.locationIds)
                    .then(res => {
                        MySwal.fire(
                            'Deleted!',
                            'Contents for Locations Ids \'' + this.state.locationIds + '\' has been deleted.',
                            'success'
                        );
                        this.setState({
                            deleteBtnText: DELETE,
                            isProcessing: false,
                            locationIds: "",
                        });
                    })
                    .catch(error => {
                        handleErr(error);
                        this.setState({
                            deleteBtnText: DELETE,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        })
    };

    validateLocationIds() {
        if (!this.state.locationIds || this.state.locationIds === "") {
            this.setState({
                locationIdsErr: "Location IDs cannot be empty"
            })
            return false;
        }
        return true;
    }

    render() {
        return (
            <div>
                <h4>
                    Delete Contents by Location IDs
                    <Tooltip title="This will delete all the warehouse contents with the given location IDs,
                    locations IDs should be seperated with commas (,)"
                        placement="right">
                        <IconButton aria-label="delete" size="small">
                            <Info />
                        </IconButton>
                    </Tooltip>
                </h4>
                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Comma seperated Location IDs</Form.Label>
                        <br />
                        {this.props.clientId === undefined && (
                            <div>
                                <Form.Control type="text"
                                              value={this.state.locationIds}
                                              onChange={this.onChangeLocationIdsValue}
                                              placeholder="201,324,102"/>
                            </div>
                        )}
                        <Form.Text className="text-muted">
                            {this.state.locationIdsErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="danger" onClick={this.deleteContents}
                                    disabled={this.state.isProcessing}>
                                    {showDeleteSpinner(this.state.deleteBtnText)}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default ContentsDeleterWithLocationIds;
