import React, { Component } from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import {Base64} from 'js-base64';

import AuthService from "./services/AuthService";
import ResourceAPIs from "../utils/ResourceAPI";

class Validate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            token: "",
            loading: false,
            message: ""
        };
    }

    onChangeToken = (event) => {
        this.setState({
            token: event.target.value
        });
    }

    handleValidate = (e) => {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        if (this.validateInputs()) {
            AuthService.validateToken(this.state.id, this.state.token)
            .then(res => {
                window.location.href = "/";
            }).catch(error =>{
                this.setState({
                    message: "Invalid token",
                    loading: false
                });
            })
        } 
    }

    componentDidMount() {
        if (this.props.location.search) {
            this.setState({
                id : Base64.decode(
                    new URLSearchParams(this.props.location.search).get('number')
                )
            });
        } else {
            window.location.href = "/";
        }

    }

    validateInputs = () => {
        if (this.state.token === "") {
            this.setState({
                message: "Please enter the token",
                loading: false,
            });
            return false;
        }
        return true;
    };

    sendMail = () => {
        new ResourceAPIs().sendMailAgain(this.state.id)
            .then(res => {
                this.setState({
                    message: "Email is send. Check your mail",
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    message: "Internal server error please login shortly.",
                    loading: false,
                });
            });
    }

    render() {
        return (
            <center>
                <Tab.Container id="left-tabs-example" >
                    <Card style={{ width: '24rem' }}>
                        <Card.Body>
                            <h4>Enter OTP</h4>
                            <p>Check your email for OTP</p>

                            <Tab.Content>
                                <Form style={{ padding: 10 }}>
                                    <Form.Group controlId="formBasicPassword">
                                        <Row>
                                            <Form.Control type="text"
                                                className="form-control"
                                                name="token"
                                                value={this.state.token}
                                                onChange={this.onChangeToken} />
                                        </Row>
                                    </Form.Group>
                                    <Row>
                                        <button
                                            className="btn btn-success btn-block"
                                            disabled={this.state.loading}
                                            onClick={this.handleValidate}>
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm" />
                                            )}
                                            <span> Submit </span>
                                        </button>
                                    </Row>
                                        <br/>
                                            <button 
                                                type="button" 
                                                class="btn btn-outline-light" 
                                                style={{color: "#a1a1a1"}}
                                                onClick={this.sendMail}
                                            >
                                                Resend
                                            </button>
                                    <Row>
                                        
                                    </Row>

                                    <br />
                                    {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </Tab.Content>
                        </Card.Body>
                    </Card>
                </Tab.Container>
            </center >
        );
    }
}

export default Validate;