import React from 'react';
import PrintComponents from "react-print-components";
import {Print} from "@material-ui/icons";
import Button from "react-bootstrap/Button";
import {getName} from "../../utils/MiscellaniousUtils";
import {format2NiceDateOnly} from "../../utils/DateUtils";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";

export default (props) => {
    return (
        <PrintComponents
            trigger={
                <Button variant="warning"
                        style={{marginTop: 25, width: 300}}>
                    <Print/> Print Packing Slips
                </Button>
            }
        >
            <div>
                <div style={{float: 'right'}}>
                    <br/>
                    Order {props.order.purchaseOrder}
                    <br/>
                    {format2NiceDateOnly(props.order.createdAt)}
                </div>
                <h2>{getName(props.stores, props.order.storeId).toUpperCase()}</h2>
                <br/><br/>
                <h5>SHIP TO</h5>
                <p style={{whiteSpace: 'pre-line'}}>
                    {getAddress(props.order, props.clients)}
                </p>
                <br/>
                <br/>
                <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{width: "5%"}}>SKU</TableCell>
                            <TableCell align="center" style={{width: "20%"}}>Product Name</TableCell>
                            <TableCell align="center" style={{width: "5%"}}>Qty Pulled</TableCell>
                            <TableCell align="center" style={{width: "5%"}}>Units Ordered</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.contents.map((row, index) => (
                            <TableRow>
                                <TableCell align="left">{row.sku}</TableCell>
                                <TableCell align="left">{row.productName}</TableCell>
                                <TableCell align="right">{row.qtyPulled}</TableCell>
                                <TableCell align="right">{row.unitsOrdered}</TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </div>
        </PrintComponents>
    );
};

function getCityStateZipLine(order, SPACE) {
    let cityStateZip = "";
    if (order.shipCity !== undefined && order.shipCity !== "") {
        cityStateZip += order.shipCity + SPACE;
    }
    if (order.shipState !== undefined && order.shipState !== "") {
        cityStateZip += order.shipState + SPACE;
    }
    if (order.shipZip !== undefined && order.shipZip !== "") {
        cityStateZip += order.shipZip;
    }
    return cityStateZip;
}

function getAddress(order, clients) {
    let address = "";
    const NEW_LINE = "\n";
    const SPACE = " ";

    const clientName = getName(clients, order.clientId);
    if (clientName !== "") {
        address += clientName + NEW_LINE;
    }

    if (order.shipAddress1 !== undefined && order.shipAddress1 !== "") {
        address += order.shipAddress1 + NEW_LINE;
    }
    if (order.shipAddress2 !== undefined && order.shipAddress2 !== "") {
        address += order.shipAddress2 + NEW_LINE;
    }
    if (order.shipAddress3 !== undefined && order.shipAddress3 !== "") {
        address += order.shipAddress3 + NEW_LINE;
    }

    const cityStateZip = getCityStateZipLine(order, SPACE);
    if (cityStateZip !== "") {
        address += cityStateZip + NEW_LINE;
    }

    if (order.shipPhone !== undefined && order.shipPhone !== "") {
        address += order.shipPhone + NEW_LINE;
    }

    return address;
}
