import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
    alertSuccess,
    handleError,
} from "../utils/MiscellaniousUtils";
import Select from "react-select";
import {createTypeOption} from "../utils/FormUtils";
import ResourceAPIs from "../utils/ResourceAPI";
import {HomeWork} from "@material-ui/icons";
import Spinner from "react-bootstrap/Spinner";

const amazonNewDiv = {
    marginTop: '25px',
};

const UPDATE_QTY = "Update Qty";
const UPDATE_QTY_TASK = "Update Qty as Task";

function showUpdateQtySpinner(btnText) {
    if (btnText === UPDATE_QTY) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showUpdateQtyTaskSpinner(btnText) {
    if (btnText === UPDATE_QTY_TASK) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

class ListingWarehouseContentsPuller extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            updateQtyBtnText: UPDATE_QTY,
            updateQtyTaskBtnText: UPDATE_QTY_TASK,
            importStatus: "",
            clients: [],
            storesIds: [],
            storeId: {
                value: "",
                label: "",
            },
        };

        this.onChangeStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.storeId.value = selected.value;
            stateCopy.storeId.label = this.getStoreLabel(selected.value)
            this.setState(stateCopy);
        };

        this.getStoreLabel = (value) => {
            let data = "";
            this.state.storesIds.forEach((store) => {
                if(store.value === value) {
                    data = store.label;
                }
            });
            return data;
        };
    }

    onClickHandler = () => {
        if (this.validateInputs()) {
            this.setState({
                updateQtyBtnText: "Updating Qty...",
                isProcessing: true,
            });

            new ResourceAPIs().updateQtysInWh(this.state.storeId.value).then(res => {
                this.setState({
                    updateQtyBtnText: UPDATE_QTY,
                    isProcessing: false,
                });
                this.clearFrom();
                alertSuccess("Update Qty's in Warehouse completed successfully!");
            }).catch(error => {
                handleError(error);
                this.setState({
                    updateQtyBtnText: UPDATE_QTY,
                    isProcessing: false,
                });
            });
        }
    };

    onClickAsATaskHandler = () => {
        if (this.validateInputs()) {
            this.setState({
                updateQtyTaskBtnText: "Updating Qty as Task...",
                isProcessing: true,
            });

            new ResourceAPIs().updateQtysInWhAsTask(this.state.storeId.value).then(res => {
                this.setState({
                    updateQtyTaskBtnText: UPDATE_QTY_TASK,
                    isProcessing: false,
                });
                this.clearFrom();
                alertSuccess("'Listing Warehouse Contents Puller' task added successfully!");
            }).catch(error => {
                console.log(error);
                handleError(error);
                this.setState({
                    updateQtyTaskBtnText: UPDATE_QTY_TASK,
                    isProcessing: false,
                });
            });
        }
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    validateInputs = () => {
        if (this.state.storeId.value && this.state.storeId.value > 0) {
            return true;
        }
        return false;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    componentDidMount() {
        this.getStores();
    }

    render() {
        return (
            <div>
                <h4>Listing Warehouse Contents Puller</h4>
                <p>Update <code>qty-in-warehouse</code> field in Listings table</p>

                <div style={{marginTop: '25px', width: '500px'}}>
                    <Form.Label>Store ID</Form.Label>
                    <Select
                        value={createTypeOption(this.state.storeId.label)}
                        onChange={(e) => this.onChangeStoreIdValue(e)}
                        options={this.state.storesIds}
                    />
                </div>

                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="success" onClick={this.onClickHandler}
                                        disabled={this.state.isProcessing || !this.validateInputs()}>
                                    {showUpdateQtySpinner(this.state.updateQtyBtnText)}
                                </Button>
                                <Button variant="warning" onClick={this.onClickAsATaskHandler}
                                        disabled={this.state.isProcessing || !this.validateInputs()}
                                        style={{marginLeft: 10}}>
                                    {showUpdateQtyTaskSpinner(this.state.updateQtyTaskBtnText)}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default ListingWarehouseContentsPuller;
