import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ContentsImporter from "./ContentsImporter";
import ContentsExporter from "./ContentsExporter";
import SafeContentsDeleterByClient from "./SafeContentsDeleterByClient";
import ContentsSearch from "./ContentsSearch";
import { BULK_ACTIONS } from "../../constants";
import { Default, Mobile } from "../../utils/MediaUtils";
import { Col, Row } from "react-bootstrap";
import ContentsDeleterWithLocationIds from "./ContentsDeleterWithLocationIds";

class Contents extends Component {

    constructor(props) {
        super(props);
    }

    hasProps = () => {
        if (this.props.location.search) {
            const query = new URLSearchParams(this.props.location.search);
            const barcode = query.get('barcode');
            return <ContentsSearch barcode={barcode} />;
        } else {
            return <ContentsSearch />
        }
    };

    render() {
        return (
            <div>
                <Mobile>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="search">Search Contents</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="search">
                                        <br />
                                        {this.hasProps()}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Pane eventKey="importAndExport">
                                    <br />
                                    <ContentsImporter />
                                    <br />
                                    <ContentsExporter />
                                    <br />
                                    <SafeContentsDeleterByClient />
                                    <br/>
                                    <ContentsDeleterWithLocationIds/>
                                </Tab.Pane>
                            </Tab.Container>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Mobile>
                <Default>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="search">Search Contents</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="search">
                                <br />
                                {this.hasProps()}
                            </Tab.Pane>
                        </Tab.Content>
                        <Tab.Pane eventKey="importAndExport">
                            <br />
                            <ContentsImporter />
                            <br />
                            <ContentsExporter />
                            <br />
                            <SafeContentsDeleterByClient />
                            <br/>
                            <ContentsDeleterWithLocationIds/>
                        </Tab.Pane>
                    </Tab.Container>
                </Default>
            </div>
        );
    }
}

export default Contents;
