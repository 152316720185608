import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import {FaFileExport} from "react-icons/fa";
import fileDownload from 'js-file-download';
import {getFormattedDate} from "../../orders/OrdersExporter";
import {EXPORT} from "../../constants";
import {EXPORTING} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";


const exportBtn = {
    marginTop: '25px',
    marginBottom: '35px',
    marginLeft: '15px',
};

class LocationsExporter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            exportBtnText: EXPORT,
            err: "",
        };
    }

    onClickHandler = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().exportWHLocation()
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "wh-locations-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                });
    };

    render() {
        return (
            <div>
                <h4>Locations Exporter</h4>
                <Button style={exportBtn} variant="success" onClick={this.onClickHandler}
                        disabled={this.state.isProcessing}>
                    <FaFileExport/> Export
                </Button>
                <p>{this.state.err}</p>
            </div>
        );
    }
}

export default LocationsExporter;
