import React, {Component} from "react";
import {EXPORT, EXPORTING, DELETE_CONTENTS} from "../../constants";
import {format2NiceDate} from "../../utils/DateUtils";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import {getIdFromUrl, getShowingResultsPhrase, handleErr, showExportSpinner} from "../../utils/MiscellaniousUtils";
import {getFormattedDate} from "../../orders/OrdersExporter";
import IconButton from "@material-ui/core/IconButton";
import {Delete} from "@material-ui/icons";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import fileDownload from "js-file-download";
import Button from "react-bootstrap/Button";
import ResourceAPIs from "../../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const exportBtn = {
    float: "right",
};

class InboundFeedContents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parent: this.props.parent,
            contents: [],
            contentsCount: null,
            isLoaded: false,
            isProcessing: false,
            exportBtnText: EXPORT,
        };
    }

    deleteContent = (id) => {
        MySwal.fire({
            title: 'You want to Delete Inbound Feed Content with ID ' + id + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPIs().deleteIFContentsById(id)
                    .then(res => {
                            MySwal.fire(
                                'Deleted!',
                                'Content ' + id + ' has been deleted.',
                                'success'
                            );
                            this.getContents();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                deleteContentBtnText: DELETE_CONTENTS,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        })
    };

    getContents = () => {
        new ResourceAPIs().getIFContentsByIdAndSize(getIdFromUrl(), 10)
            .then(res => {
                    const result = res.data;
                    this.setState({
                        contents: result.contents,
                        contentsCount: result.count,
                        isLoaded: true,
                    });
                })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    };

    onExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().exportIFContents(getIdFromUrl())
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "inbound-feed-contents-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                    });
                    handleErr(error);
                });
    };

    componentDidMount() {
        this.getContents();
    }

    componentWillReceiveProps({someProp}) {
        if (this.props.isLoad) {
            this.getContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">
                            <span style={{marginTop: 20}}>Inbound Feed Contents</span>
                            <Button style={exportBtn} variant="success" onClick={this.onExport}
                                    disabled={this.state.isProcessing}>
                                {showExportSpinner(this.state.exportBtnText)}
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Content ID</TableCell>
                                            <TableCell align="center">Inbound Feed ID</TableCell>
                                            <TableCell align="center">Barcode</TableCell>
                                            <TableCell align="center">Qty</TableCell>
                                            <TableCell align="center">Modified Qty</TableCell>
                                            <TableCell align="center">Update Frequency</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                            <TableCell align="center">Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="right">{row.id}</TableCell>
                                                <TableCell align="right">{row.inboundFeedId}</TableCell>
                                                <TableCell align="right">{row.barcode}</TableCell>
                                                <TableCell align="right">{row.quantity}</TableCell>
                                                <TableCell align="right">{row.modifiedQty}</TableCell>
                                                <TableCell align="right">{row.updateFrequency}</TableCell>
                                                <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton color="secondary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing}
                                                                onClick={() => this.deleteContent(row.id)}>
                                                        <Delete/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br/>
                            <p style={{fontSize: 13, float: 'right'}}>
                                {getShowingResultsPhrase(10, this.state.contentsCount)}
                            </p>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default InboundFeedContents;
