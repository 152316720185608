import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ResourceAPIs from "../../utils/ResourceAPI";
import {getIdFromUrl, handleErr} from '../../utils/MiscellaniousUtils';
import {OUTBOUND_CONTENTS_QTY_UPDATER, SAVE, UPDATE} from '../../constants';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {ExpandMore, HomeWork} from "@material-ui/icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {AccordionActions, Badge} from "@material-ui/core";
import {convertToGMT, getTimeZoneOffSet} from "../../utils/DateUtils";

const MySwal = withReactContent(Swal);

class OutboundFeedScheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleTime: "",
            frequency: "none",
            hasScheduled: false,
            submitButton: SAVE,
            id: null
        };
    }

    componentDidMount() {
        this.getSchedule();
    }

    getSchedule = () => {
        new ResourceAPIs().getSchedule(OUTBOUND_CONTENTS_QTY_UPDATER, getIdFromUrl()).then(res => {
            this.setState({
                scheduleTime: getTimeZoneOffSet(res.data.time).substring(0, 16),
                frequency: res.data.frequency,
                hasScheduled: true,
                submitButton: UPDATE,
                id: res.data.id
            });
        }).catch(() => {
            this.setState({
                scheduleTime: "",
                frequency: "none",
                hasScheduled: false,
                submitButton: SAVE
            });
        });
    };

    onChangeValue = (event) => {
        this.setState({
            frequency: event.target.value
        });
    };

    onChangeScheduleTime = (event) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.scheduleTime = event.target.value;
        this.setState(stateCopy);
    };


    validate = () => {
        return (this.state.scheduleTime !== "" && this.state.value !== "none");
    };

    submit = () => {
        if (this.validate() && !this.state.hasScheduled) {
            new ResourceAPIs().postSchedule(OUTBOUND_CONTENTS_QTY_UPDATER, getIdFromUrl(),
                {scheduleTime: convertToGMT(this.state.scheduleTime), frequency: this.state.frequency}).then(res => {
                MySwal.fire(
                    'Created!',
                    'Schedule is created',
                    'success'
                );
                this.getSchedule();
            }).catch(err => {
                handleErr(err);
            });
        } else if (this.validate() && this.state.hasScheduled) {
            new ResourceAPIs().updateSchedule(this.state.id, OUTBOUND_CONTENTS_QTY_UPDATER, getIdFromUrl(),
                {scheduleTime: convertToGMT(this.state.scheduleTime), value: this.state.value}).then(res => {
                MySwal.fire(
                    'Updated!',
                    'Schedule is updated',
                    'success'
                );
                this.getSchedule();
            }).catch(err => {
                handleErr(err);
            });
        }
    };

    cancel = () => {
        this.getSchedule();
    };

    remove = () => {
        MySwal.fire({
            title: 'You want to Delete Schedule with outbound feed ID ' + getIdFromUrl() + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPIs().deleteSchedule(this.state.id).then(response => {
                    MySwal.fire(
                        'Deleted!',
                        'Schedule with outbound feed Id: ' + getIdFromUrl() + ' has been deleted.',
                        'success'
                    );
                    this.getSchedule();
                });
            }
        });
    };

    render() {
        return (
            <div>
                <Badge color="secondary" badgeContent="Scheduled Task" invisible={!this.state.scheduleTime}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                                color: '#fbfbfc',
                                backgroundColor: '#6c757d',
                                fontSize: 12,
                                minHeight: 40,
                                height: 40
                            }}>
                        <span>
                            <HomeWork/> Update Quantities as a Scheduled Task
                        </span>
                        </AccordionSummary>
                        <AccordionDetails style={{fontSize: 14}}>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Schedule Time</Form.Label>
                                        </Col>
                                        <Col>
                                            <input type="datetime-local"
                                                   onChange={this.onChangeScheduleTime}
                                                   value={this.state.scheduleTime}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Frequent</Form.Label>
                                        </Col>
                                        <Col>
                                            <select onChange={this.onChangeValue} value={this.state.frequency}>
                                                <option key={0} value="daily">Daily</option>
                                                <option key={1} value="weekly">Weekly</option>
                                                <option key={2} value="none">None</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </AccordionDetails>
                        <AccordionActions>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                                <Button onClick={this.submit} variant="success" size="sm">Submit</Button>
                                <Button variant="secondary" onClick={this.cancel} size="sm" style={{marginLeft: 10}}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={this.remove} size="sm"
                                        disabled={!this.state.hasScheduled} style={{marginLeft: 10}}>
                                    Remove
                                </Button>
                            </Col>
                        </AccordionActions>
                    </Accordion>
                </Badge>
            </div>
        );
    }
}

export default OutboundFeedScheduler;
