import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete, SkipNext, SkipPrevious} from "@material-ui/icons";
import {createTypeOption, getLabel, handleErr, showFilterSpinner} from "../utils/MiscellaniousUtils";
import {eqOptions, FILTER, FILTERING, SAVE, strOptions} from "../constants";
import Select from "react-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ResourceAPIs from "../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class BusinessReportsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                startDate: {
                    condition: "eq",
                    value: "",
                },
                endDate: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            storesIds: [],
            clients:[],
        };

        this.onChangeClientIdValue = (selected) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeStoreIdValue = selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = getLabel(this.state.storesIds, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        }

        this.onChangeStoreNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStartDateValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.startDate.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeEndDateValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.endDate.value = event.target.value;
            this.setState(stateCopy);
        };
    }

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        console.log(selectedValue);
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStartDateFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.startDate.condition = selectedValue;
        this.setState(stateCopy);
    };


    onChangeEndDateFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.endDate.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchBusinessReports();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchBusinessReports();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchBusinessReports();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                startDate: {
                    condition: "eq",
                    value: "",
                },
                endDate: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchBusinessReports() {
        
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });

        new ResourceAPIs().getSearchBusinessReports(this.state.filters).then(response => {
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: response.data,
                });
            }).catch(error =>{
                handleErr(error);
                this.setState({
                    filterBtnText: FILTER,
                    isProcessing: false,
                    results: error,
                });
            });
        
    }

    validateInputs = () => {
        // validate clientID
        if (parseInt(this.state.filters.clientId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = parseInt(this.state.filters.clientId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid client ID"
            });
            return false;
        }

        return true;
    };

    handleAddNew = () => {
        new ResourceAPIs().addBusinessReport().then(response => {
                window.open("/breports/" + response.data);
            }).catch(error => {
                handleErr(error);
            });
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getStores();
        this.getClients();
    }

    deleteBReportAndContents = (index) => {
        let id = this.state.results[index].id;
        MySwal.fire({
            title: 'You want to Delete Business Report & Contents with ID ' + id + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                // Delete happens here
                new ResourceAPIs().deleteBusinessReportAndContents(id).then(response => {
                        MySwal.fire(
                            'Deleted!',
                            'Business Report ' + id + ' has been deleted.',
                            'success'
                        );
                        this.searchBusinessReports();
                    }).catch(error => {
                        handleErr(error);
                        this.setState({
                            deleteContentBtnText: "Delete All Contents",
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        })
    };

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Client ID</Form.Label>
                                        {this.props.id === undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={eqOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Select
                                                        value={createTypeOption(this.state.filters.clientId.label)}
                                                        onChange={(e) => this.onChangeClientIdValue(e)}
                                                        options={this.state.clients}
                                                        styles={this.customStyles}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {this.props.id !== undefined && (
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeClientIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="number"
                                                              value={this.props.id}
                                                              onChange={this.onChangeClientIdValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              disabled={true}/>
                                            </Col>
                                        </Row>
                                        )}
                                        <Form.Label style={filterLabel}>Store ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeStoreIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Select
                                                    value={createTypeOption(this.state.filters.storeId.label)}
                                                    onChange={(e) => this.onChangeStoreIdValue(e)}
                                                    options={this.state.storesIds}
                                                    styles={this.customStyles}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Store Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStoreNameFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStoreNameValue}
                                                              value={this.state.filters.storeName.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Start Date</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStartDateFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStartDateValue}
                                                              value={this.state.filters.startDate.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>End Date</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeEndDateFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeEndDateValue}
                                                              value={this.state.filters.endDate.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <p style={{fontSize: 12, marginTop: 17}}>Add New Business Report</p>
                                            <Button variant="primary" onClick={this.handleAddNew}>
                                                <Add/> Add New
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">ID</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Store ID</TableCell>
                                            <TableCell align="left">Store Name</TableCell>
                                            <TableCell align="left">Start Date</TableCell>
                                            <TableCell align="left">End Date</TableCell>
                                            <TableCell align="left">Created At</TableCell>
                                            <TableCell align="left">Updated At</TableCell>
                                            <TableCell>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/breports/" + row.id} target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="center">{row.clientId}</TableCell>
                                                <TableCell align="center">{row.storeId}</TableCell>
                                                <TableCell align="left">{row.storeName}</TableCell>
                                                <TableCell align="left">{row.startDate}</TableCell>
                                                <TableCell align="left">{row.endDate}</TableCell>
                                                <TableCell align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                                <TableCell>
                                                    <IconButton color="secondary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing}
                                                                onClick={() => this.deleteBReportAndContents(index)}>
                                                        <Delete/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default BusinessReportsSearch;
