import React, {Component} from 'react';
import {ADMIN} from "./constants";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Orders from "./orders/Orders";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import WarehouseHome from "./warehouses/WarehouseHome";
import WHLocationDetails from "./warehouses/locations/WHLocationDetails";
import Listings from "./listings/Listings";
import BusinessReports from "./business-reports/BusinessReports";
import SettlementReports from "./settlement-reports/SettlementReports";
import PullRequestDetails from "./warehouses/pull/PullRequestDetails";
import OrderPullRequestDetails from "./orders/order-pull-requests/OrderPullRequestDetails";
import BusinessReportDetails from "./business-reports/BusinessReportDetails";
import SettlementReportDetails from "./settlement-reports/SettlementReportDetails";
import InventoryReports from "./inventory/InventoryReports";
import InventoryReportDetails from "./inventory/InventoryReportDetails";
import PurchaseOrder from "./purchase-orders/PurchaseOrder";
import PurchaseOrderDetails from "./purchase-orders/PurchaseOrderDetails";
import SearchClients from "./client/SearchClients";
import Client from "./client/Client";
import Feeds from "./feeds/inbound/Feeds";
import InboundFeedDetails from "./feeds/inbound/InboundFeedDetails";
import ListingDetails from './listings/ListingDetails';
import WarehouseDetails from "./warehouses/warehouses/WarehouseDetails";
import SplitDetails from "./splits/SplitDetails";
import AuthService from "./user/services/AuthService";
import Splits from "./splits/Splits";
import OutboundFeeds from "./feeds/outbound/OutboundFeeds";
import OutboundFeedDetails from "./feeds/outbound/OutboundFeedDetails";
import OutboundFeedReport from './feeds/outbound/OutboundFeedReport';
import {alertError, getBaseUrl, getValidateUrl, handleErr} from "./utils/MiscellaniousUtils";
import RedirectUrlService from "./user/services/RedirectUrlService";
import Users from "./user/user-mgt/Users";
import Home from "./home/Home";
import Login from "./user/Login";
import Validate from "./user/Validate";
import FileRepo from "./file-repo/FileRepo";
import ClientDetails from "./view-client/ClientDetails";
import ClientFileRepo from "./view-client/ClientFileRepo";
import WorkOrder from "./work-orders/WorkOrder";
import WorkOrderDetails from "./work-orders/WorkOrderDetails";
import StoresHome from "./stores/StoresHome";
import StoreDetails from "./stores/StoreDetails";
import ResourceAPIs from "./utils/ResourceAPI";
import OrderPullRequests from "./orders/order-pull-requests/OrderPullRequests";
import TasksHome from "./tasks/TasksHome";
import WholesaleOrdersSearch from "./orders/wholesale/WholesaleOrdersSearch";
import WholesaleOrderDetails from "./orders/wholesale/WholesaleOrderDetails";
import UpcPullDetails from "./warehouses/upc-pulls/UpcPullDetails";

class App extends Component {

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            currentUser: undefined,
            isAdmin: false,
            clientId: 0,
            isClientIdLoad: false,
            redirectUrl: undefined,
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            const role = AuthService.getCurrentUserRole();
            if (role) {
                this.setState({
                    currentUser: user,
                    isAdmin: role === ADMIN.value,
                }, () => {
                    if (this.state.currentUser && !this.state.isAdmin) {
                        this.getClientId();
                    }
                });
            } else {
                alertError("Unable to fetch User Role! Please contact Admin");
            }
        } else { // if user not logged in redirect to login page & set redirect url
            let url = window.location.href;
            if (url !== getBaseUrl() && !url.includes(getValidateUrl())) {
                RedirectUrlService.addRedirectUrl(url);
                window.location.href = "/";
            }
        }
    }

    getClientId = () => {
        new ResourceAPIs().getClientId()
            .then(res => {
                this.setState({
                    clientId: res.data,
                    isClientIdLoad: true,
                });
            })
            .catch(error => {
                    console.log(error);
                    handleErr(error);
                }
            );
    };

    logOut() {
        AuthService.logout();
    }

    render() {

        let appStyle = {
            whiteSpace: "pre-wrap",
        };

        if (!this.state.currentUser) {
            appStyle = {
                whiteSpace: "pre-wrap",
                backgroundImage: "url('/banner.jpg')",
                width: '100%',
                height: '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            };
        }

        return (
            <div style={appStyle}>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand href="/">COGIMETRICS</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">

                        {this.state.currentUser && this.state.isAdmin && (
                            <Nav className="mr-auto">
                                <NavDropdown title="Orders" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/order-pull-requests">Order Pull Requests</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/wholesale-orders">Wholesale Orders</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Manage Inventory" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/warehouses">Warehouses</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/inventory">FBA Inventory</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/splits">Splits</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/listings">Listings</Nav.Link>
                                <NavDropdown title="Reports" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/business-reports">Business Reports</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/settlement-reports">Settlement Reports</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/purchase-order">Purchase Orders</Nav.Link>
                                <NavDropdown title="Feeds" id="feeds-dropdown">
                                    <NavDropdown.Item href="/feeds">Inbound Feeds</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/outbound-feeds">Outbound Feeds</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/file-repo">File Repository</Nav.Link>
                                <Nav.Link href="/work-order">Work Orders</Nav.Link>
                                <NavDropdown title="Account" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/users">Users</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/clients">Clients</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/stores">Stores</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/tasks">Tasks</Nav.Link>
                            </Nav>
                        )}
                        {this.state.currentUser && !this.state.isAdmin && this.state.isClientIdLoad && (
                            <Nav className="mr-auto">
                                <NavDropdown title="Orders" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/order-pull-requests">Order Pull Requests</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/wholesale-orders">Wholesale Orders</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Manage Inventory" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/warehouses/pull-requests">Warehouses</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/inventory">FBA Inventory</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/splits">Splits</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/listings">Listings</Nav.Link>
                                <NavDropdown title="Reports" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/business-reports">Business Reports</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/settlement-reports">Settlement Reports</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/purchase-order">Purchase Orders</Nav.Link>
                                <NavDropdown title="Feeds" id="feeds-dropdown">
                                    <NavDropdown.Item href="/feeds">Inbound Feeds</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/outbound-feeds">Outbound Feeds</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/client/file-repo">File repository</Nav.Link>
                                <Nav.Link href="/work-order">Work Orders</Nav.Link>
                            </Nav>
                        )}

                        {this.state.currentUser && (
                            <Nav>
                                <NavDropdown title={this.state.currentUser.User} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/" onClick={this.logOut}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        )}
                    </Navbar.Collapse>
                </Navbar>
                <br/>
                <Router>
                    <div>
                        <Switch>
                            {this.state.currentUser && this.state.isAdmin && (
                                <Route exact path="/">
                                    <Home/>
                                </Route>
                            )}
                            {this.state.currentUser && !this.state.isAdmin && this.state.isClientIdLoad && (
                                <Route exact path="/">
                                    <ClientDetails id={this.state.clientId}/>
                                </Route>
                            )}
                            {this.state.currentUser === undefined && (
                                <Route exact path="/">
                                    <Login/>
                                </Route>
                            )}
                            <Route exact path="/validate"
                                render = {props => <Validate {...props}/>}>
                            </Route>
                            <Route path="/orders">
                                <Orders />
                            </Route>
                            <Route path="/file-repo"
                                   render = {props => <FileRepo {...props}/>}>
                            </Route>
                            <Route path="/order-pull-requests/:id">
                                <OrderPullRequestDetails/>
                            </Route>
                            <Route exact path="/clients">
                                <SearchClients/>
                            </Route>
                            <Route exact path="/users">
                                <Users/>
                            </Route>
                            <Route exact path="/clients/:id">
                                <Client/>
                            </Route>
                            <Route path="/clients/:ClientId/listings/:ListingId"
                                   render = {props => <ListingDetails {...props}/>}>
                            </Route>
                            {this.state.isClientIdLoad && (
                                <Route path="/client/details">
                                    <ClientDetails id={this.state.clientId}/>
                                </Route>
                            )}
                            {this.state.isClientIdLoad && (
                                <Route path="/client/file-repo">
                                    <ClientFileRepo id={this.state.clientId}/>
                                </Route>
                            )}
                            <Route path="/stores">
                                <StoresHome/>
                            </Route>
                            <Route path="/store/:id">
                                <StoreDetails/>
                            </Route>
                            {this.state.currentUser && this.state.isAdmin && (
                                <>
                                    <Route exact path="/listings"
                                        render = {props => <Listings {...props}/>}>
                                    </Route>
                                    <Route exact path="/listings/:id"
                                        render = {props => <ListingDetails {...props}/>}>
                                    </Route>
                                    <Route path="/work-order"
                                        render = {props => <WorkOrder {...props}/>}>
                                    </Route>
                                    <Route path="/work-orders/:id"
                                        render = {props => <WorkOrderDetails {...props}/>}>
                                    </Route>
                                    <Route path="/orders"
                                        render = {props => <Orders {...props}/>}>
                                    </Route>
                                    <Route path="/order-pull-requests"
                                           render = {props => <OrderPullRequests {...props}/>}>
                                    </Route>
                                    <Route exact path="/inventory"
                                        render = {props => <InventoryReports {...props}/>}>
                                    </Route>
                                    <Route exact path="/outbound-feeds"
                                        render = {props => <OutboundFeeds {...props}/>}>
                                    </Route>
                                    <Route path="/outbound-feeds/:id"
                                        render = {props => <OutboundFeedDetails {...props}/>}>
                                    </Route>
                                    <Route path="/outbound-feed-report/:id"
                                        render = {props => <OutboundFeedReport {...props}/>}>
                                    </Route>
                                    <Route path="/feeds"
                                        render = {props => <Feeds {...props}/>}>
                                    </Route>
                                    <Route path="/ifeeds/:id"
                                        render = {props => <InboundFeedDetails {...props}/>}>
                                    </Route>
                                    <Route path="/ireports/:id"
                                        render = {props => <InventoryReportDetails {...props}/>}>
                                    </Route>
                                    <Route exact path="/splits"
                                        render = {props => <Splits {...props}/>}>
                                    </Route>
                                    <Route path="/splits/:id"
                                        render = {props => <SplitDetails {...props}/>}>
                                    </Route>
                                    <Route path="/warehouses"
                                        render = {props => <WarehouseHome {...props}/>}>
                                    </Route>
                                    <Route path="/whs/:id"
                                        render = {props => <WarehouseDetails {...props}/>}>
                                    </Route>
                                    <Route path="/pull-requests/:id"
                                        render = {props => <PullRequestDetails {...props}/>}>
                                    </Route>
                                    <Route path="/wh-locations/:id"
                                        render = {props => <WHLocationDetails {...props}/>}>
                                    </Route>
                                    <Route path="/settlement-reports"
                                        render = {props => <SettlementReports {...props}/>}>
                                    </Route>
                                    <Route path="/sreports/:id"
                                        render = {props => <SettlementReportDetails {...props}/>}>
                                    </Route>
                                    <Route path="/business-reports"
                                        render = {props => <BusinessReports {...props}/>}>
                                    </Route>
                                    <Route path="/breports/:id"
                                        render = {props => <BusinessReportDetails {...props}/>}>
                                    </Route>
                                    <Route path="/purchase-order"
                                        render = {props => <PurchaseOrder {...props}/>}>
                                    </Route>
                                    <Route path="/purchase-orders/:id" 
                                        render = {props => <PurchaseOrderDetails {...props}/>}>
                                    </Route>
                                    <Route path="/tasks">
                                        <TasksHome/>
                                    </Route>
                                    <Route path="/wholesale-orders"
                                           render = {props => <WholesaleOrdersSearch {...props}/>}>
                                    </Route>
                                    <Route path="/wholesale-order/:id"
                                           render={props => <WholesaleOrderDetails {...props}/>}>
                                    </Route>
                                    <Route path="/upc-pull/:id">
                                        <UpcPullDetails/>
                                    </Route>
                                </>
                            )}
                            {this.state.currentUser && !this.state.isAdmin && this.state.isClientIdLoad && (
                                <>
                                    <Route exact path="/listings"
                                        render = {props => <Listings id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route exact path="/listings/:id"
                                        render = {props => <ListingDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/work-order"
                                        render = {props => <WorkOrder clientId={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/orders"
                                        render = {props => <Orders clientId={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/order-pull-requests"
                                           render = {props => <OrderPullRequests clientId={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/work-orders/:id"
                                        render = {props => <WorkOrderDetails clientId={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route exact path="/inventory"
                                        render = {props => <InventoryReports id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route exact path="/outbound-feeds"
                                        render = {props => <OutboundFeeds id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/outbound-feeds/:id"
                                        render = {props => <OutboundFeedDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/feeds"
                                        render = {props => <Feeds id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/ifeeds/:id"
                                        render = {props => <InboundFeedDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/ireports/:id"
                                        render = {props => <InventoryReportDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route exact path="/splits"
                                        render = {props => <Splits id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/splits/:id"
                                        render = {props => <SplitDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/warehouses"
                                        render = {props => <WarehouseHome id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/pull-requests/:id"
                                        render = {props => <PullRequestDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/wh-locations/:id"
                                        render = {props => <WHLocationDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/settlement-reports"
                                        render = {props => <SettlementReports id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/sreports/:id"
                                        render = {props => <SettlementReportDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/business-reports"
                                        render = {props => <BusinessReports id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/breports/:id"
                                        render = {props => <BusinessReportDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/purchase-order"
                                        render = {props => <PurchaseOrder id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/purchase-orders/:id" 
                                        render = {props => <PurchaseOrderDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/wholesale-orders"
                                           render = {props => <WholesaleOrdersSearch id={this.state.clientId} {...props}/>}>
                                    </Route>
                                    <Route path="/wholesale-order/:id"
                                           render={props => <WholesaleOrderDetails id={this.state.clientId} {...props}/>}>
                                    </Route>
                                </>
                            )}
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
