import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getLabel, createTypeOption, handleErr, showDeleteSpinner } from "../../utils/MiscellaniousUtils";
import { DELETE, DELETING } from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import { Info } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ResourceAPIs from "../../utils/ResourceAPI";
import Select from "react-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal);

const amazonNewDiv = {
    marginTop: '25px',
};

const clientSelect = {
}

class SafeContentsDeleterByClient extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clientId: {
                value: "",
                label: "",
            },
            fileErr: "",
            isProcessing: false,
            deleteBtnText: DELETE,
            importStatus: "",
        };

        this.onChangeClientIdValue = (selected) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.clientId.value = selected.value;
            stateCopy.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
        };
    }

    componentDidMount() {
        this.getClients();
    }

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    deleteContents = (index) => {
        MySwal.fire({
            title: 'You want to Delete all the contents has 0 qty for client: <br/>' + this.state.clientId.label + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete them!'
        }).then((result) => {
            if (result.value) {
                // Delete happens here
                this.setState({
                    deleteBtnText: DELETING,
                    isProcessing: true,
                });
                new ResourceAPIs().safeDeleteWHContents(this.state.clientId.value)
                    .then(res => {
                        MySwal.fire(
                            'Deleted!',
                            'Contents has 0 qty for the client \'' + this.state.clientId.label + '\' has been deleted.',
                            'success'
                        );
                        this.setState({
                            deleteBtnText: DELETE,
                            isProcessing: false,
                        });
                    })
                    .catch(error => {
                        handleErr(error);
                        this.setState({
                            deleteBtnText: DELETE,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        })
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    render() {
        return (
            <div>
                <h4>
                    Safe Delete Contents by Client
                    <Tooltip title="This will delete all the warehouse contents with 0 qty for the given client"
                        placement="right">
                        <IconButton aria-label="delete" size="small">
                            <Info />
                        </IconButton>
                    </Tooltip>
                </h4>
                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Client</Form.Label>
                        <br />
                        {this.props.clientId === undefined && (
                            <div>
                                <Select
                                    value={createTypeOption(this.state.clientId.label)}
                                    onChange={(e) => this.onChangeClientIdValue(e)}
                                    options={this.state.clients}
                                />
                            </div>
                        )}
                        {this.props.clientId !== undefined && (
                            <div>
                                <Form.Control size="sm" type="text"
                                    value={this.props.clientId}
                                    disabled={true} />
                            </div>
                        )}
                        <Form.Text className="text-muted">
                            {this.state.fileErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="danger" onClick={this.deleteContents}
                                    disabled={this.state.isProcessing}>
                                    {showDeleteSpinner(this.state.deleteBtnText)}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default SafeContentsDeleterByClient;
