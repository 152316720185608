import React, { Component } from 'react';
import * as Constants from '../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {SkipNext, SkipPrevious} from "@material-ui/icons";
import {getIdFromUrl, handleErr, showExportSpinner, showFilterSpinner} from "../utils/MiscellaniousUtils";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import {FILTERING} from "../constants";
import {FILTER} from "../constants";
import {EXPORTING} from "../constants";
import {EXPORT} from "../constants";
import {intOptions} from "../constants";
import {eqOptions} from "../constants";
import ResourceAPIs from "../utils/ResourceAPI";

const filterBtn = {
    marginRight: 10,
    width: 120,
    marginTop: 10,
    marginBottom: 10
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class ClientWHContents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                barcode: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: Constants.FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: Constants.SAVE,
            exportBtnText: Constants.EXPORT,
        };
    }

    onChangeWHFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouseId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeWHValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouseId.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeLocationIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeLocationIdValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationId.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeLocationNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeLocationNameValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.locationName.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeBarcodeFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.barcode.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeBarcodeValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.barcode.value = event.target.value;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchWhContents();
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchWhContents();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchWhContents();
    };

    searchWhContents() {
        if (this.validateInputs()) {
            this.setState({
                filterBtnText: FILTERING,
                isProcessing: true,
            });
            new ResourceAPIs().getSearchWHContents(this.state.filters)
                .then(res => {
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: res.data,
                        });
                    })
                .catch(error => {
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: error,
                        });
                    });
        }
    }

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().getSearchExportWHContents(this.state.filters)
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "warehouse-contents-search-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    validateInputs = () => {
        // validate warehouse
        if (parseInt(this.state.filters.warehouseId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.warehouseId.value = parseInt(this.state.filters.warehouseId.value, 10);
            this.setState(stateCopy);
            } else {
            this.setState({
                errMsg: "Invalid warehouse ID"
            });
            return false;
        }

        // validate location id
        if (parseInt(this.state.filters.locationId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.locationId.value = parseInt(this.state.filters.locationId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid location ID"
            });
            return false;
        }

        return true;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                warehouseId: {
                    condition: "eq",
                    value: "0",
                },
                locationId: {
                    condition: "eq",
                    value: "0",
                },
                locationName: {
                    condition: "eq",
                    value: "",
                },
                barcode: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                size: 100,
                from: 0,
            },
        });
    };

    render() { 
        return ( 
            <div>
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label style={filterLabel}>Warehouse</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={intOptions}
                                                            handleOnChange={this.onChangeWHFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="number"
                                                          value={this.state.filters.warehouseId.value}
                                                          onChange={this.onChangeWHValue}
                                                          onKeyPress={this.handleFilter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Location ID</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={intOptions}
                                                            handleOnChange={this.onChangeLocationIdFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="number"
                                                          onChange={this.onChangeLocationIdValue}
                                                          value={this.state.filters.locationId.value}
                                                          onKeyPress={this.handleFilter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label style={filterLabel}>Location Name</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={eqOptions}
                                                            handleOnChange={this.onChangeLocationNameFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeLocationNameValue}
                                                          value={this.state.filters.locationName.value}
                                                          onKeyPress={this.handleFilter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Barcode</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={eqOptions}
                                                            handleOnChange={this.onChangeBarcodeFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          value={this.state.filters.barcode.value}
                                                          onChange={this.onChangeBarcodeValue}
                                                          onKeyPress={this.handleFilter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Form.Text className="text-muted">
                                        {this.state.errMsg}
                                    </Form.Text>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                            <SkipPrevious/>
                                        </IconButton>
                                        <span style={resultsDescStyle}>{this.state.results.length} results
                                        (Page {(this.state.filters.from / 100) + 1})</span>
                                        <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                            <SkipNext/>
                                        </IconButton>
                                    </Row>
                                    <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                        <Button variant="success" style={filterBtn}
                                                onClick={this.handleFilter}
                                                disabled={this.state.isProcessing}>
                                            {showFilterSpinner(this.state.filterBtnText)}
                                        </Button>
                                        <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <p style={{fontSize: 12, marginTop: 17}}>Export Result</p>
                                        <Button variant="success" onClick={this.onClickExport}
                                                disabled={this.state.isProcessing}>
                                            {showExportSpinner(this.state.exportBtnText)}
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br/>
                </Col>
                <Col xs={12}>
                    {this.state.results.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="right">Location</TableCell>
                                        <TableCell align="right">Warehouse</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Barcode</TableCell>
                                        <TableCell align="right">Created At</TableCell>
                                        <TableCell align="right">Updated At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.results.map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="right">
                                                <a href={"/wh-locations/" + row.locationId}
                                                       target="_blank">{row.locationName}</a>
                                            </TableCell>
                                            <TableCell align="right">{row.warehouseId}</TableCell>
                                            <TableCell align="right">{row.quantity}</TableCell>
                                            <TableCell align="right">{row.barcode}</TableCell>
                                            <TableCell
                                                align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                            <TableCell align="right">
                                                {format2NiceDate(row.updatedAt)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : <p></p>
                    }
                </Col>
            </Row>
        </div>
    
        );
    }
}
 
export default ClientWHContents;
