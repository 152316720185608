import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Badge from "react-bootstrap/Badge";
import Select from "react-select";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Add, Clear, Save, SkipNext, SkipPrevious} from "@material-ui/icons";
import {eqOptions, FILTER, FILTERING, intOptions, SAVE, SAVING, strOptions} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";
import {alertError, createTypeOption, getLabel, handleErr, showFilterSpinner} from "../../utils/MiscellaniousUtils";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const locationNameInput = {
    width: 100,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

const barcodeBadge3 = {
    width: 50,
};

class PullRequestsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                pullName: {
                    condition: "eq",
                    value: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            storesIds: [],
            clients: [],
        };

        this.onChangePullNameValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.pullName.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreNameValue = (event) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeClientIdValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = getLabel(this.state.storesIds, selected.value)
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients ,selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangePullName = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].pullName = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStoreName = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].storeName = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStoreId = (selected, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].storeId = selected.value;
            stateCopy.results[index].storeLabel = getLabel(this.state.storesIds, selected.value);
            this.setState(stateCopy);
        };

        this.onChangePlanId = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].planId = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatus = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].status = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInvoice = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].invoice = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = false;
            stateCopy.results[index].pullName = this.state.results[index].untouchedPullName;
            stateCopy.results[index].storeName = this.state.results[index].untouchedStoreName;
            stateCopy.results[index].planId = this.state.results[index].untouchedPlanId;
            stateCopy.results[index].status = this.state.results[index].untouchedStatus;
            stateCopy.results[index].invoice = this.state.results[index].untouchedInvoice;
            stateCopy.results[index].storeId = this.state.results[index].untouchedStoreId;
            stateCopy.results[index].storeLabel = this.state.results[index].untouchedStoreLabel;
            this.setState(stateCopy);
        };
    }

    componentDidMount() {
        this.getStores();
        this.getClients();
    }

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    onChangePullNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.pullName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };


    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    addUntouchedValues = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedPullName = arr[i].pullName;
            arr[i].untouchedStoreName = arr[i].storeName;
            arr[i].untouchedPlanId = arr[i].planId;
            arr[i].untouchedStatus = arr[i].status;
            arr[i].untouchedInvoice = arr[i].invoice;
            arr[i].untouchedStoreId = arr[i].storeId;
            arr[i].storeLabel = getLabel(this.state.storesIds, arr[i].storeId);
            arr[i].untouchedStoreLabel = arr[i].storeLabel;
        }
        return arr;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                pullName: {
                    condition: "eq",
                    value: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchPullRequests() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchPR(this.state.filters)
            .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: this.addUntouchedValues(res.data),
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                });
    }

    saveQty = (index) => {
        this.setState({
            saveQtyBtnText: SAVING,
            isProcessing: true,
        });
        let content = this.state.results[index];
        console.log(content);
        let id = parseInt(content.id, 10);
        let pullName = content.pullName;
        let storeName = content.storeName;
        let planId = content.planId;
        let status = content.status;
        let invoice = content.invoice;
        let storeId = content.storeId;

        if (id > 0) {
            new ResourceAPIs().searchUpdatePullRequestContentFields(id, content.clientId, pullName, status,
                storeName, storeId, planId, content.warehouse, invoice, this.state.filters)
                .then(res => {
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.saveQtyBtnText = SAVE;
                        stateCopy.isProcessing = false;
                        stateCopy.results[index].edited = false;
                        stateCopy.results[index].untouchedPullName = this.state.results[index].pullName;
                        stateCopy.results[index].untouchedStoreName = this.state.results[index].storeName;
                        stateCopy.results[index].untouchedPlanId = this.state.results[index].planId;
                        stateCopy.results[index].untouchedStatus = this.state.results[index].status;
                        stateCopy.results[index].untouchedInvoice = this.state.results[index].invoice;
                        stateCopy.results[index].storeLabel = getLabel(this.state.storesIds, stateCopy.results[index].storeId);
                        stateCopy.results[index].untouchedStoreLabel = stateCopy.results[index].storeLabel;
                        this.setState(stateCopy);
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            saveQtyBtnText: SAVE,
                            isProcessing: false,
                        });
                    });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        }

        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    validateInputs = () => {
        // validate clientID
        if (parseInt(this.state.filters.clientId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = parseInt(this.state.filters.clientId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid client ID"
            });
            return false;
        }

        return true;
    };

    handleAddNew = () => {
        new ResourceAPIs().addNewPR()
            .then(res => {
                    window.open("/pull-requests/" + res.data);
                })
            .catch(error => {
                    handleErr(error);
                });
    };

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Pull Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangePullNameFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              value={this.state.filters.pullName.value}
                                                              onChange={this.onChangePullNameValue}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Store ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={eqOptions}
                                                                handleOnChange={this.onChangeStoreIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Select
                                                    value={createTypeOption(this.state.filters.storeId.label)}
                                                    onChange={(e) => this.onChangeStoreIdValue(e)}
                                                    options={this.state.storesIds}
                                                    styles={this.customStyles}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              value={this.state.filters.status.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                        {this.props.id === undefined && (
                                            <div>
                                            <Form.Label style={filterLabel}>Client ID</Form.Label>
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Select
                                                        value={createTypeOption(this.state.filters.clientId.label)}
                                                        onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                        options={this.state.clients}
                                                        styles={this.customStyles}
                                                        onKeyDown={this.handleFilterOnEnter}
                                                    />
                                                </Col>
                                            </Row>
                                            </div>
                                        )}
                                        {this.props.id !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={eqOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Form.Control size="sm" type="number"
                                                                      value={this.props.id}
                                                                      onChange={this.onChangeClientIdValue}
                                                                      onKeyDown={this.handleFilterOnEnter}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <p>Add New Pull Request</p>
                                            <Button variant="primary" onClick={this.handleAddNew}><Add/> Add
                                                New</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" width="5%">Pull ID</TableCell>
                                            <TableCell align="center" width="10%">Pull Name</TableCell>
                                            <TableCell align="center" width="15%">Store ID</TableCell>
                                            <TableCell align="center" width="10%">Plan ID</TableCell>
                                            <TableCell align="center" width="5%">WH</TableCell>
                                            <TableCell align="center" width="5%">Client</TableCell>
                                            <TableCell align="center" width="10%">Status</TableCell>
                                            <TableCell align="center" width="10%">Invoice</TableCell>
                                            <TableCell align="center" width="10%">Timestamps</TableCell>
                                            <TableCell align="center" width="5%">Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/pull-requests/" + row.id} target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={locationNameInput}
                                                                  value={row.pullName == null ? "" : row.pullName}
                                                                  onChange={(e) => this.onChangePullName(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Select
                                                        value={createTypeOption(row.storeLabel)}
                                                        onChange={(e) => this.onChangeStoreId(e, index)}
                                                        options={this.state.storesIds}
                                                        styles={this.customStyles}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={locationNameInput}
                                                                  value={row.planId == null ? "" : row.planId}
                                                                  onChange={(e) => this.onChangePlanId(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">{row.warehouse}</TableCell>
                                                <TableCell align="right">{row.clientId}</TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={locationNameInput}
                                                                  value={row.status == null ? "" : row.status}
                                                                  onChange={(e) => this.onChangeStatus(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={locationNameInput}
                                                                  value={row.invoice == null ? "" : row.invoice}
                                                                  onChange={(e) => this.onChangeInvoice(e, index)}/>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Badge variant="secondary" style={barcodeBadge3}>Created</Badge><br/>
                                                    {format2NiceDate(row.createdAt)}<br/>
                                                    <Badge variant="primary" style={barcodeBadge3}>Updated</Badge><br/>
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton color="primary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.saveQty(index)}>
                                                        <Save/>
                                                    </IconButton>
                                                    <IconButton color="default" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.cancelSaveQty(index)}>
                                                        <Clear/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PullRequestsSearch;
