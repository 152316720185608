import React, {Component} from "react";
import {format2NiceDate} from "../utils/DateUtils";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import {CSVLink} from "react-csv";
import {getFormattedDate} from "../orders/OrdersExporter";
import {FaFileExport} from "react-icons/fa";
import ResourceAPIS from "../utils/ResourceAPI";

const headers = [
    {label: "Business Report ID", key: "businessReportId"},
    {label: "Client ID", key: "clientId"},
    {label: "Store Name", key: "storeName"},
    {label: "Start Date", key: "startDate"},
    {label: "End Date", key: "endDate"},
    {label: "(Parent) ASIN", key: "parentAsin"},
    {label: "(Child) ASIN", key: "childAsin"},
    {label: "Title", key: "title"},
    {label: "Sessions", key: "sessions"},
    {label: "Session Percentage", key: "sessionPercentage"},
    {label: "Page Views", key: "pageViews"},
    {label: "Page Views Percentage", key: "pageViewsPercentage"},
    {label: "Buy Box Percentage", key: "buyBoxPercentage"},
    {label: "Units Ordered", key: "unitsOrdered"},
    {label: "Unit Session Percentage", key: "unitSessionsPercentage"},
    {label: "Ordered Product Sales", key: "orderedProductSales"},
    {label: "Total Order Items", key: "totalOrderItems"},
    {label: "Created At", key: "createdAt"},
    {label: "Updated At", key: "updatedAt"},
];

class BusinessReportContents extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            breport: this.props.breport,
            contents: [],
            isLoaded: false,
            isProcessing: false,
        };
    }

    getContents = () => {
        let url = window.location.href;
        let id = url.substring(url.lastIndexOf('/') + 1);
        new ResourceAPIS().getBusinessContents(id).then(response => {
                this.setState({
                    contents: this.addBReportData(response.data),
                    isLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                })
            });
    };

    addBReportData = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].businessReportId = this.state.breport.id;
            arr[i].clientId = this.state.breport.clientId;
            arr[i].storeName = this.state.breport.storeName;
            arr[i].startDate = this.state.breport.startDate;
            arr[i].endDate = this.state.breport.endDate;
        }
        return arr;
    };

    componentDidMount() {
        this.getContents();
    }

    componentWillReceiveProps({someProp}) {
        if (this.props.isLoad) {
            this.getContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">
                            <span style={{marginTop: 20}}>Business Report Contents</span>
                            <CSVLink data={this.state.contents}
                                     headers={headers}
                                     filename={"business-report-contents-export-" + getFormattedDate(new Date()) + ".csv"}
                                     className="btn btn-primary small"
                                     style={{float: "right"}}>
                                <FaFileExport/> Export
                            </CSVLink>
                        </Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Content ID</TableCell>
                                            <TableCell align="center">Parent ASIN</TableCell>
                                            <TableCell align="center">Child ASIN</TableCell>
                                            <TableCell align="center">Sessions</TableCell>
                                            <TableCell align="center">Units Ordered</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="right">{row.id}</TableCell>
                                                <TableCell align="right">{row.parentAsin}</TableCell>
                                                <TableCell align="right">{row.childAsin}</TableCell>
                                                <TableCell align="right">{row.sessions}</TableCell>
                                                <TableCell align="right">{row.unitsOrdered}</TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.createdAt)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default BusinessReportContents;
