import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {SkipNext, SkipPrevious} from "@material-ui/icons"
import WHFilterSelect from "../components/WHFilterSelect";
import {getIdFromUrl, handleErr, showExportSpinner, showFilterSpinner} from "../utils/MiscellaniousUtils";
import {EXPORT, FILTER, FILTERING, intOptions, strOptions, EXPORTING} from "../constants";
import {getFormattedDate} from "../orders/OrdersExporter";
import fileDownload from "js-file-download";
import ResourceAPIs from "../utils/ResourceAPI";


const filterBtn = {
    marginRight: 10,
    width: 120,
    marginTop: 10,
    marginBottom: 10
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};


class ClientPRContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                contentId: {
                    condition: "eq",
                    value: "",
                },
                pullRequestId: {
                    condition: "eq",
                    value: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                invoice: {
                    condition: "eq",
                    value: "",
                },
                warehouse: {
                    condition: "eq",
                    value: "",
                },
                quantityDesired: {
                    condition: "eq",
                    value: "",
                },
                quantityPulled: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            exportBtnText: EXPORT,
            errMsg: "",
            results: []
        };
    }

    onChangeContentIdValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.contentId.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeContentIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.contentId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangePullRequestIdValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.pullRequestId.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangePullRequestIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.pullRequestId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInvoiceValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.invoice.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeInvoiceFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.invoice.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeWarehouseValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouse.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeWarehouseFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.warehouse.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeQuantityDesiredValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.quantityDesired.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeQuantityDesiredFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.quantityDesired.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeQuantityPulledValue = event => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.quantityPulled.value = event.target.value;
        this.setState(stateCopy);
    };

    onChangeQuantityPulledFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.quantityPulled.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchPRContents();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    }

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchPRContents();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchPRContents();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: getIdFromUrl(),
                },
                contentId: {
                    condition: "eq",
                    value: "",
                },
                pullRequestId: {
                    condition: "eq",
                    value: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                invoice: {
                    condition: "eq",
                    value: "",
                },
                warehouse: {
                    condition: "eq",
                    value: "",
                },
                quantityDesired: {
                    condition: "eq",
                    value: "",
                },
                quantityPulled: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchPRContents() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchPRContents(this.state.filters)
            .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: res.data,
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                }   
            );
    }

    onClickExport = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().searchExportPRContents(this.state.filters)
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "pull-requests-contents-search-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                    handleErr(error);
                });
    };

    render() { 
        return (  
            <Row>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={4}>
                                <Form.Label style={filterLabel}>Contents ID</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={intOptions}
                                                        handleOnChange={this.onChangeContentIdFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangeContentIdValue}
                                                      value={this.state.filters.contentId.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                                <Form.Label style={filterLabel}>Pull Request ID</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={intOptions}
                                                        handleOnChange={this.onChangePullRequestIdFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangePullRequestIdValue}
                                                      value={this.state.filters.pullRequestId.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                                <Form.Label style={filterLabel}>Store Name</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={strOptions}
                                                        handleOnChange={this.onChangeStoreNameFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangeStoreNameValue}
                                                      value={this.state.filters.storeName.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                                <Form.Label style={filterLabel}>Invoice</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={strOptions}
                                                        handleOnChange={this.onChangeInvoiceFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangeInvoiceValue}
                                                      value={this.state.filters.invoice.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={4}>
                                <Form.Label style={filterLabel}>Warehouse</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={intOptions}
                                                        handleOnChange={this.onChangeWarehouseFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangeWarehouseValue}
                                                      value={this.state.filters.warehouse.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                                <Form.Label style={filterLabel}>Quantity Desired</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={intOptions}
                                                        handleOnChange={this.onChangeQuantityDesiredFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangeQuantityDesiredValue}
                                                      value={this.state.filters.quantityDesired.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                                <Form.Label style={filterLabel}>Quantity Pulled</Form.Label>
                                <Row>
                                    <Col style={dropdownCol}>
                                        <WHFilterSelect options={intOptions}
                                                        handleOnChange={this.onChangeQuantityPulledFilter}/>
                                    </Col>
                                    <Col style={inputCol}>
                                        <Form.Control size="sm" type="text"
                                                      onChange={this.onChangeQuantityPulledValue}
                                                      value={this.state.filters.quantityPulled.value}
                                                      onKeyDown={this.handleFilterOnEnter}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <Row style={{display: 'block', textAlign: 'center'}}>
                                    <Form.Text className="text-muted">
                                        {this.state.errMsg}
                                    </Form.Text>
                                </Row>
                                <Row style={{display: 'block', textAlign: 'center'}}>
                                    <IconButton onClick={this.handlePrev}
                                                disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                        <SkipPrevious/>
                                    </IconButton>
                                    <span style={resultsDescStyle}>{this.state.results.length} results
                                    (Page {(this.state.filters.from / 100) + 1})</span>
                                    <IconButton onClick={this.handleNext}
                                                disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                        <SkipNext/>
                                    </IconButton>
                                </Row>
                                <Row align="center" style={{display: 'block', textAlign: 'center' }}>
                                    <Button variant="success" style={filterBtn}
                                            onClick={this.handleFilter}
                                            disabled={this.state.isProcessing}>
                                        {showFilterSpinner(this.state.filterBtnText)}
                                    </Button>
                                    <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <Row style={{display: 'block', textAlign: 'center'}}>
                                    <p style={{fontSize: 12, marginTop: 17}}>Export Result</p>
                                    <Button variant="success" onClick={this.onClickExport}
                                            disabled={this.state.isProcessing}>
                                        {showExportSpinner(this.state.exportBtnText)}
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <br/>
            </Col>
            <Col xs={12}>
                {this.state.results.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">ID</TableCell>
                                    <TableCell align="right">Pull Request Id</TableCell>
                                    <TableCell align="right">Store Name</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Warehouse</TableCell>
                                    <TableCell align="right">SKU</TableCell>
                                    <TableCell align="right">ASIN</TableCell>
                                    <TableCell align="right">FNSKU</TableCell>
                                    <TableCell align="right">Quantity Desired</TableCell>
                                    <TableCell align="right">Quantity Pulled</TableCell>
                                    <TableCell align="right">Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.results.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" align="right">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="left">{row.pullRequestId}</TableCell>
                                        <TableCell align="left">{row.storeName}</TableCell>
                                        <TableCell align="left">{row.pullName}</TableCell>
                                        <TableCell align="left">{row.warehouse}</TableCell>
                                        <TableCell align="left">{row.sku}</TableCell>
                                        <TableCell align="right">{row.asin}</TableCell>
                                        <TableCell align="right">{row.fnsku}</TableCell>
                                        <TableCell align="right">{row.qtyDesired}</TableCell>
                                        <TableCell align="right">{row.qtyPulled}</TableCell>
                                        <TableCell align="right">{row.invoice}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <p></p>
                }
            </Col>
        </Row>
    
        );
    }
}
 
export default ClientPRContents;
