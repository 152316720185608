import React, { Component } from 'react';
import {
    getIdFromUrl,
    getErrorMessageFromError
} from "../../utils/MiscellaniousUtils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { Alert } from 'react-bootstrap';
import { getFormattedDate } from "../../orders/OrdersExporter";
import fileDownload from "js-file-download";
import ResourceAPIs from "../../utils/ResourceAPI";

class OutboundFeedReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            outboundFeed: {
                id: "",
                format: "",
            },
            isProcessing: false,
            statusText: "",
            isError: false,
        };
    }

    componentDidMount() {
        this.getOutboundFeedById();
    }

    getOutboundFeedById = () => {
        new ResourceAPIs().getOutboundFeedsById(getIdFromUrl())
            .then(res => {
                const result = res.data;
                this.setState({
                    outboundFeed: {
                        id: result.id,
                        format: result.format,
                    }
                }, () => this.handleDownloadOFeedReport());
            })
            .catch(error => {
                this.setState({
                    statusText: getErrorMessageFromError(error),
                    isProcessing: false,
                    isError: true,
                });
            }
            );
    };

    handleDownloadOFeedReport = () => {
        this.setState({
            statusText: "Downloading Outbound Feed Report with ID: " + getIdFromUrl(),
            isProcessing: true,
        });
        new ResourceAPIs().exportOutboundFeedReport(getIdFromUrl())
            .then(res => {
                const dd = getFormattedDate(new Date());
                console.log(res.headers);
                if (this.state.outboundFeed.format === ".csv") {
                    fileDownload(res.data, "outbound-feed" + dd + ".csv");
                } else {
                    fileDownload(res.data, "outbound-feed" + dd + ".txt");
                }
                this.setState({
                    statusText: "Downloaded Outbound Feed Report for feed ID: " + getIdFromUrl() + " successfully!",
                    isProcessing: false,
                });
            })
            .catch(error => {
                this.setState({
                    statusText: getErrorMessageFromError(error),
                    isProcessing: false,
                    isError: true,
                });
            }
            );
    };

    render() {
        return (
            <div>
                <Row>
                    <Col xs={1} sm={2} md={4}></Col>
                    <Col xs={12} sm={8} md={4}>
                        <center>
                            {this.state.isProcessing &&
                                <div>
                                    <Spinner as="span" animation="border" size="sm" role="status"
                                        aria-hidden="true" /> {this.state.statusText}
                                </div>
                            }
                            {!this.state.isProcessing && !this.state.isError &&
                                <div>
                                    <Alert variant="success">
                                        {this.state.statusText}
                                    </Alert>
                                </div>
                            }
                            {!this.state.isProcessing && this.state.isError &&
                                <div>
                                    <Alert variant="error">
                                        {this.state.statusText}
                                    </Alert>
                                </div>
                            }
                        </center>
                    </Col>
                    <Col xs={1} sm={2} md={4}></Col>
                </Row>
            </div>
        )
    }
}

export default OutboundFeedReport;
