import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Locations from "./locations/Locations";
import Contents from "./contents/Contents";
import PullRequests from "./pull/PullRequests";
import Warehouses from "./warehouses/Warehouses";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Default, Mobile } from "../utils/MediaUtils";
import UpcPulls from "./upc-pulls/UpcPulls";

class WarehouseHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: "",
        }
    }

    componentDidMount() {
        let url = window.location.href;
        this.setState({
            activeKey: url.substring(url.lastIndexOf('/') + 1)
        });
    }

    render() {
        return (
            <div style={{ marginLeft: '1%', marginRight: '1%' }}>
                <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey}>
                    <Row>
                        <Mobile>
                            <center>
                                <Nav style={{ fontSize: 14, display: 'flex', justifyContent: 'center' }}>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/pull-requests" eventKey="pull-requests">
                                            Pull Requests
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/upc-pulls" eventKey="upc-pulls">
                                            UPC Pulls
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        {this.props.id === undefined && (
                                            <Nav.Link href="/warehouses" eventKey="warehouses">
                                                Warehouses
                                            </Nav.Link>
                                        )}
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/locations" eventKey="locations">
                                            Locations
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/contents" eventKey="contents">
                                            Contents
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </center>
                            <Router>
                                <Switch>
                                    <Route exact path="/warehouses/pull-requests">
                                        <PullRequests id={this.props.id} />
                                    </Route>
                                    <Route exact path="/warehouses/upc-pulls">
                                        <UpcPulls id={this.props.id} />
                                    </Route>
                                    <Route exact path="/warehouses/locations">
                                        <Locations id={this.props.id} />
                                    </Route>
                                    <Route exact path="/warehouses/contents"
                                        render={props => <Contents id={this.props.id} {...props} />} />
                                    <Route path="/warehouses">
                                        <Warehouses id={this.props.id} />
                                    </Route>
                                </Switch>
                            </Router>
                        </Mobile>
                        <Default>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/pull-requests" eventKey="pull-requests">
                                            Pull Requests
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/upc-pulls" eventKey="upc-pulls">
                                            UPC Pulls
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        {this.props.id === undefined && (
                                            <Nav.Link href="/warehouses" eventKey="warehouses">
                                                Warehouses
                                            </Nav.Link>
                                        )}
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/locations" eventKey="locations">
                                            Warehouse Locations
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/warehouses/contents" eventKey="contents">
                                            Warehouse Contents
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Router>
                                    <Switch>
                                        <Route exact path="/warehouses/pull-requests">
                                            <PullRequests id={this.props.id} />
                                        </Route>
                                        <Route exact path="/warehouses/upc-pulls">
                                            <UpcPulls id={this.props.id} />
                                        </Route>
                                        <Route exact path="/warehouses/locations">
                                            <Locations id={this.props.id} />
                                        </Route>
                                        <Route exact path="/warehouses/contents"
                                            render={props => <Contents id={this.props.id} {...props} />} />
                                        <Route path="/warehouses">
                                            <Warehouses id={this.props.id} />
                                        </Route>
                                    </Switch>
                                </Router>
                            </Col>
                        </Default>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

export default WarehouseHome;