
const REDIRECT_URL = 'redirect-url';

/**
 * Manage redirect url when user goes to a url before login.
 */
class RedirectUrlService {

    addRedirectUrl(url) {
        this.removeRedirectUrl();
        localStorage.setItem(REDIRECT_URL, url);
    }

    removeRedirectUrl() {
        localStorage.removeItem(REDIRECT_URL);
    }

    getRedirectUrl() {
        return localStorage.getItem(REDIRECT_URL);
    }
}

export default new RedirectUrlService();
