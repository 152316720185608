// Backend host name
export const PROD_API_URL = "https://app.cogimetrics.com/api";
export const PROD_API_UNSECURE_URL = "http://167.71.99.90:9090";
export const LOCAL_API_URL = "http://localhost:9090"; // http://192.168.8.100:9090

export const HOST_NAME = PROD_API_URL;

export const INTERNAL_SERVER_ERROR = "Internal server error!";

export const MAX_FILE_SIZE = 100000000; // 100MB
export const MAX_FILE_SIZE_STRING = "100MB"; // 100MB

export const BULK_ACTIONS = "Bulk Actions";

export const FILTER = "Filter";
export const SAVE = "Save";
export const UPDATE = "Update";
export const EDIT = "Edit";
export const IMPORT = "Import";
export const IMPORT_TASK = "Add Import Task";
export const EXPORT = "Export";
export const ADD_CONTENTS = "Add Contents";
export const ADD_CONTENTS_TASK = "Add Contents Task";
export const DELETE_CONTENTS = "Delete All Contents";
export const CALCULATE = "Calculate";
export const RECALCULATE = "Recalculate";
export const FETCH = "Fetch";
export const BACK = "Back";
export const DELETE = "Delete";
export const CLONE = "Clone";
export const UPLOAD = "Upload";

export const FILTERING = "Filtering...";
export const SAVING = "Saving...";
export const UPDATING = "Updating...";
export const IMPORTING = "Importing...";
export const IMPORTING_TASK = "Adding Import Task...";
export const EXPORTING = "Exporting...";
export const ADDING_CONTENTS = "Add Contents...";
export const ADDING_CONTENTS_TASK = "Adding Contents Task...";
export const DELETING_CONTENTS = "Deleting All Contents";
export const CALCULATING = "Calculating...";
export const RECALCULATING = "Recalculating...";
export const FETCHING = "Fetching";
export const DELETING = "Deleting..."
export const CLONING = "Cloning...";
export const UPLOADING = "Uploading...";

export const UNDEFINED = "Undefined";

export const intOptions = [
    { value: "eq", label: "Equals" },
    { value: "gt", label: "Greater than" },
    { value: "gte", label: "Greater than or equal" },
    { value: "lt", label: "Less than" },
    { value: "lte", label: "Less than or equal" },
];

export const strOptions = [
    { value: "eq", label: "Equals" },
    { value: "con", label: "Contains" },
];

export const strWithNilOptions = [
    { value: "eq", label: "Equals" },
    { value: "con", label: "Contains" },
    { value: "nil", label: "Nil" },
];

export const eqOptions = [
    { value: "eq", label: "Equals" },
];

// User Roles

export const NO_ACCESS_OPTION = {value: "NO_ACCESS", label: "Access Denied"};
export const ADMIN = {value: "ADMIN", label: "Admin"};
export const CLIENT = {value: "CLIENT", label: "Client"};

export const userRoles = [
    NO_ACCESS_OPTION,
    ADMIN,
    CLIENT,
];

// Worker task names

export const OUTBOUND_CONTENTS_QTY_UPDATER = "outbound contents qty updater";

// Listing types

export const listingTypes = [
    { value: "simple", label: "simple" },
    { value: "complex", label: "complex" },
];
