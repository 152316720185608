import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Clear, Save} from "@material-ui/icons";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import {format2NiceDate} from "../utils/DateUtils";
import {HOST_NAME, SAVE, SAVING} from "../constants";
import Spinner from "react-bootstrap/Spinner";
import {handleError, setEmptyIfNull} from "../utils/MiscellaniousUtils";
import axios from "axios";
import AuthHeader from "../user/services/AuthHeader";

class ClientDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: {
                id: "",
                name: "",
                phone: "",
                email: "",
                orderFee: "",
                orderUnitFee: "",
                wholesaleFee: "",
                wholesaleUnitFee: "",
                amazonFee: "",
                amazonUnitFee: "",
                monthlyMinimum: "",
                manufacturingFee: "",
                manufacturingUnitFee: "",
                storageFeeSmall: "",
                storageFeeMedium: "",
                storageFeeLarge: "",
                storageFeePallet: "",
                labourRate: "",
                storeName: "",
                status: "",
                createdAt: "",
                updatedAt: "",
                errMsg: "",
            },
            isLoaded: false,
            isProcessing: false,
            errMsg: "",
        };
    }

    getClientById = () => {
        fetch(HOST_NAME + "/clients/" + this.props.id + "/details", AuthHeader())
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    this.setState({
                        client: {
                            id: result.id,
                            name: setEmptyIfNull(result.name),
                            phone:setEmptyIfNull(result.phone),
                            email: setEmptyIfNull(result.email),
                            orderFee: result.orderFee,
                            orderUnitFee: result.orderUnitFee,
                            wholesaleFee: result.wholesaleFee,
                            wholesaleUnitFee: result.wholesaleUnitFee,
                            amazonFee: result.amazonFee,
                            amazonUnitFee: result.amazonUnitFee,
                            monthlyMinimum: result.monthlyMinimum,
                            storageFeeSmall: result.storageFeeSmall,
                            storageFeeMedium: result.storageFeeMedium,
                            storageFeeLarge: result.storageFeeLarge,
                            storageFeePallet: result.storageFeePallet,
                            labourRate: result.labourRate,
                            manufacturingFee: result.manufacturingFee,
                            manufacturingUnitFee: result.manufacturingUnitFee,
                            storeName: setEmptyIfNull(result.storeName),
                            status: setEmptyIfNull(result.status),
                            createdAt: result.createdAt,
                            updatedAt: result.updatedAt,
                        },
                        isLoaded: true,
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            );
    };

    componentDidMount() {
        this.getClientById();
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <Row>
                        <Col xs={12}>
                            <Card border="secondary">
                                <Card.Header as="h5">
                                    <span style={{marginTop: 60}}>Client Details</span>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.id}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Name</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.name}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Phone</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.phone}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Email</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.email}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Store Name</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.storeName}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Status</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.status}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Order Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.orderFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Order Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.orderUnitFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Wholesale Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.wholesaleFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Wholesale Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.wholesaleUnitFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Manufacturing Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.manufacturingFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Manufacturing Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.manufacturingUnitFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Amazon Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.amazonFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Amazon Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.amazonUnitFee}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Monthly Minimum</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.monthlyMinimum}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Small</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.storageFeeSmall}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Medium</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.storageFeeMedium}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Large</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.storageFeeLarge}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Pallet</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.storageFeePallet}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Labour Rate</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Text>
                                                                {this.state.client.labourRate}
                                                            </Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Created At</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.client.createdAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Updated At</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.client.updatedAt)}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

function showEditSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default ClientDetails;