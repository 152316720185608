import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import Spinner from "react-bootstrap/Spinner";
import {ADD_CONTENTS, ADD_CONTENTS_TASK, SAVE} from "../constants";
import {GetApp, HomeWork, Inbox, LowPriority, MonetizationOn, Save, ShoppingCart, Note} from "@material-ui/icons";
import {
    DOWNLOAD,
    MAKE_BUY_RECOMMENDATION, MAKE_BUY_RECOMMENDATION_TASK,
    PULL_BR_SALES_TASK,
    PULL_FBA_INVENTORY, PULL_FBA_INVENTORY_TASK,
    PULL_FEED_CONTENTS,
    PULL_LISTING_DATA,
    PULL_LISTING_TASK, PULL_SALES_FROM_BR,
    PULL_WH_CONTENTS,
    PULL_WH_CONTENTS_TASK,
    PULL_FEED_CONTENTS_TASK
} from "./POConstants";
import React from "react";

export function showAddContentsSpinner(btnText) {
    if (btnText === ADD_CONTENTS || btnText === ADD_CONTENTS_TASK) {
        return <span><AddToPhotosIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showSavePRSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showPullListingsSpinner(btnText) {
    if (btnText === PULL_LISTING_DATA || btnText === PULL_LISTING_TASK) {
        return <span><LowPriority/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showPullWhContentsSpinner(btnText) {
    if (btnText === PULL_WH_CONTENTS || btnText === PULL_WH_CONTENTS_TASK) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showPullInbFeedContentsSpinner(btnText) {
    if (btnText === PULL_FEED_CONTENTS || btnText === PULL_FEED_CONTENTS_TASK) {
        return <span><Note/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showPullFbaInventorySpinner(btnText) {
    if (btnText === PULL_FBA_INVENTORY || btnText === PULL_FBA_INVENTORY_TASK) {
        return <span><Inbox/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showPullSalesFromBRSpinner(btnText) {
    if (btnText === PULL_SALES_FROM_BR || btnText === PULL_BR_SALES_TASK) {
        return <span><MonetizationOn/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showMakeBuyRecSpinner(btnText) {
    if (btnText === MAKE_BUY_RECOMMENDATION || btnText === MAKE_BUY_RECOMMENDATION_TASK) {
        return <span><ShoppingCart/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export function showPullDownloadPRSpinner(btnText) {
    if (btnText === DOWNLOAD) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}