export const PULL_LISTING_DATA = "Pull Listing Data";
export const PULLING_LISTING_DATA = "Pulling Listing Data";
export const PULL_WH_CONTENTS = "Pull Warehouse Contents";
export const PULL_FEED_CONTENTS = "Pull Inb Feed Contents";
export const PULLING_FEED_CONTENTS = "Pulling Inb Feed Contents";
export const DOWNLOAD = "Download";

export const PULL_FBA_INVENTORY = "Pull FBA Inventory";
export const PULLING_FBA_INVENTORY = "Pulling FBA Inventory";
export const PULL_SALES_FROM_BR = "Pull Business Report Sales";
export const PULLING_SALES_FROM_BR = "Pulling Sales";
export const MAKE_BUY_RECOMMENDATION = "Make Buy Recommendation";
export const MAKING_BUY_RECOMMENDATION = "Making Buy Recommendation";

export const PULL_WH_CONTENTS_TASK = "Pull WH Contents as a Task";
export const PULLING_WH_CONTENTS_TASK = "Pulling WH Contents as a Task";
export const PULL_FEED_CONTENTS_TASK = "Pull Feed Contents as a Task";
export const PULLING_FEED_CONTENTS_TASK = "Pulling Feed Contents as a Task";
export const PULL_LISTING_TASK = "Pull Listing Data as a Task";
export const PULLING_LISTING_TASK = "Pulling Listing Data as a Task";
export const PULL_FBA_INVENTORY_TASK = "Pull FBA Inventory as a Task";
export const PULLING_FBA_INVENTORY_TASK = "Pulling FBA Inventory as a Task";
export const PULL_BR_SALES_TASK = "Pull BR Sales as a Task";
export const PULLING_BR_SALES_TASK = "Pulling BR Sales as a Task";
export const MAKE_BUY_RECOMMENDATION_TASK = "Make Buy Recommend as a Task";
export const MAKING_BUY_RECOMMENDATION_TASK = "Making Buy Recommend as a Task";

// Styles

export const locationInput = {
    width: 300,
};

export const pullBtnStyle = {
    width: '100%',
    fontSize: 12,
};

export const taskBtnStyle = {
    width: '100%',
    fontSize: 11,
    marginTop: 12,
};