import React, {Component} from "react";
import UnbilledOrderFulfilment from "./UnbilledOrderFulfilment";
import Col from "react-bootstrap/Col";
import WarehouseLocationsExporter from "./WarehouseLocationsExporter";
import UnbilledWarehousePulls from "./UnbilledWarehousePulls";
import UnbilledWorkOrders from "./UnbilledWorkOrders";
import Row from "react-bootstrap/Row";
import UnbilledWholesaleOrders from "./UnbilledWholesaleOrders";

class Home extends Component {

    render() {
        return (
            <div>
                <div style={{marginLeft: '3%', marginRight: '3%'}}>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <UnbilledOrderFulfilment/>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                            <WarehouseLocationsExporter/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <UnbilledWarehousePulls/>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                            <UnbilledWorkOrders/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <UnbilledWholesaleOrders/>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Home;
