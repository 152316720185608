import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import {Add, Clear, Save, SkipNext, SkipPrevious} from "@material-ui/icons";
import {alertError, handleErr, showFilterSpinner, createTypeOption, getLabel} from "../../utils/MiscellaniousUtils";
import {FILTER, SAVE, FILTERING, SAVING, intOptions, eqOptions} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const locationNameInput = {
    width: 200,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

const strOptions = [
    {value: "eq", label: "Equals"},
    {value: "con", label: "Contains"},
];

class OrderPullRequestsSearch extends Component {

    constructor(props) {
        console.log(props);
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                pullName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            clients: [],
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangePullNameValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.pullName.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangePullName = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].pullName = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatus = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = true;
            stateCopy.results[index].status = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.results[index].edited = false;
            stateCopy.results[index].pullName = this.state.results[index].untouchedPullName;
            stateCopy.results[index].status = this.state.results[index].untouchedStatus;
            this.setState(stateCopy);
        };


        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };
    }

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangePullNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.pullName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchOrderPullRequests();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchOrderPullRequests();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchOrderPullRequests();
    };

    addUntouchedValues = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedPullName = arr[i].pullName;
            arr[i].untouchedStatus = arr[i].status;
        }
        return arr;
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                pullName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchOrderPullRequests() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchOrderPRs(this.state.filters)
            .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: this.addUntouchedValues(res.data),
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                });
    }

    saveQty = (index) => {
        this.setState({
            saveQtyBtnText: SAVING,
            isProcessing: true,
        });
        let content = this.state.results[index];
        let id = parseInt(content.id, 10);
        let pullName = content.pullName;
        let status = content.status;
        let clientId = content.clientId;

        if (id > 0) {
            new ResourceAPIs().searchUpdateOrderPRContents(id, pullName, status, clientId)
                .then(res => {
                        let stateCopy = Object.assign({}, this.state);
                        stateCopy.saveQtyBtnText = SAVE;
                        stateCopy.isProcessing = false;
                        stateCopy.results[index].edited = false;
                        stateCopy.results[index].untouchedPullName = this.state.results[index].pullName;
                        stateCopy.results[index].untouchedStatus = this.state.results[index].status;
                        this.setState(stateCopy);
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            saveQtyBtnText: SAVE,
                            isProcessing: false,
                        });
                    });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        }

        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    handleAddNew = () => {
        new ResourceAPIs().addOrderPullRequest()
            .then(res => {
                    window.open("/order-pull-requests/" + res.data);
                })
            .catch(error => {
                    handleErr(error);
                });
    };

    componentDidMount() {
        this.getClients();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card >
                            <Card.Body>
                                <Row>
                                    <Col xs={4}>
                                        {this.props.id === undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={intOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Select
                                                            value={createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.props.id !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={eqOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Form.Control size="sm" type="number"
                                                                      value={this.props.id}
                                                                      onChange={this.onChangeClientIdValue}
                                                                      onKeyDown={this.handleFilterOnEnter}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        <Form.Label style={filterLabel}>Pull Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangePullNameFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                            value={this.state.filters.pullName.value}
                                                            onChange={this.onChangePullNameValue}
                                                            onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              value={this.state.filters.status.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <p>Add New Pull Request</p>
                                            <Button variant="primary" onClick={this.handleAddNew}><Add/> Add
                                                New</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Pull ID</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Pull Name</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                            <TableCell>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/order-pull-requests/" + row.id}
                                                       target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.clientId}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={locationNameInput}
                                                                  value={row.pullName == null ? "" : row.pullName}
                                                                  onChange={(e) => this.onChangePullName(e, index)}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Form.Control size="sm" type="text" style={locationNameInput}
                                                                  value={row.status == null ? "" : row.status}
                                                                  onChange={(e) => this.onChangeStatus(e, index)}/>
                                                </TableCell>
                                                <TableCell
                                                    align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="right">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton color="primary" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.saveQty(index)}>
                                                        <Save/>
                                                    </IconButton>
                                                    <IconButton color="default" style={{padding: 6}}
                                                                disabled={this.state.isProcessing || !row.edited}
                                                                onClick={() => this.cancelSaveQty(index)}>
                                                        <Clear/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default OrderPullRequestsSearch;
