import React, {Component} from "react";
import ListingsExporter from "./BusinessReportsExporter";
import ListingsImporter from "./BusinessReportsImporter";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import BusinessReportsSearch from "./BusinessReportsSearch";
import {BULK_ACTIONS} from "../constants";


class BusinessReports extends Component {

    constructor(props) {
        console.log(props);
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="search">Search Business Reports</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="search">
                            <br/>
                            <BusinessReportsSearch id={this.props.id}/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Pane eventKey="importAndExport">
                        <br/>
                        <ListingsImporter/>
                        <br/>
                        <ListingsExporter/>
                    </Tab.Pane>
                </Tab.Container>
            </div>
        );
    }
}

export default BusinessReports;
