import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {ADD_CONTENTS, ADDING_CONTENTS, SAVE, SAVING} from "../../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../../utils/DateUtils";
import {
    createTypeOption,
    getIdFromUrl,
    getLabel,
    handleErr,
    handleError,
    setEmptyIfNull,
    showAddContentsSpinner,
    showSaveSpinner
} from "../../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import {Clear, GetApp, HomeWork} from "@material-ui/icons";
import fileDownload from "js-file-download";
import Select from "react-select";
import ResourceAPIs from "../../utils/ResourceAPI";
import {getFormattedDate} from "../../orders/OrdersExporter";
import UpcPullContents from "./UpcPullContents";

const PULL_WH_CONTENTS = "Pull Warehouse Contents";
const DOWNLOAD_PULL_REPORT = "Download Pull Report";
const DOWNLOAD_PULL_CONTENTS = "Download Pull Contents";

class UpcPullDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            upcPull: {
                id: "",
                clientId: "",
                warehouseId: "",
                createdAt: "",
                updatedAt: "",
                errMsg: "",
            },
            clientLabel: "",
            warehouseLabel: "",
            isOrderLoaded: false,
            isProcessing: false,
            editUpcPullBtnText: SAVE,
            pullWhContentsBtnText: PULL_WH_CONTENTS,
            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
            downloadPCBtnText: DOWNLOAD_PULL_CONTENTS,
            addContentBtnText: ADD_CONTENTS,
            errMsg: "",
            childLoad: false,
            contents: [],
            clients: [],
            warehouses: [],
            contentsText: "",
        };

        // Edit upcPull details

        this.onChangeClientId = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.upcPull.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeWarehouseId = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.upcPull.warehouseId = selected.value;
            newState.warehouseLabel = getLabel(this.state.warehouses, selected.value);
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.upcPull.status = event.target.value;
            this.setState(newState);
        };

        this.onChangeContentsText = (event) => {
            let newState = Object.assign({}, this.state);
            newState.contentsText = event.target.value;
            this.setState(newState);
        };

        this.handleEditUpcPull = () => {
            this.setState({
                editUpcPullBtnText: SAVING,
                isProcessing: true,
            });
            new ResourceAPIs().updateUpcPull(this.state.upcPull)
                .then(res => {
                    this.setState({
                        editUpcPullBtnText: SAVE,
                        isProcessing: false,
                    });
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        editUpcPullBtnText: SAVE,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handleCancelEditUpcPull = () => {
            this.getUpcPullById();
        };

        this.handleAddContents = () => {
            if (this.validateAddContentsInputs()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });
                new ResourceAPIs().addUpcPullContents(getIdFromUrl(), {contents: this.state.contentsText})
                    .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            addContentBtnText: ADD_CONTENTS,
                            contentsText: "",
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        handleError(error);
                        this.setState({
                            addContentBtnText: ADD_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };

        this.handlePullWhContents = () => {
            this.setState({
                pullWhContentsBtnText: "Pulling Warehouse Contents",
                isProcessing: true,
            });
            new ResourceAPIs().upcPullContentsPullWhData(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        pullWhContentsBtnText: PULL_WH_CONTENTS,
                        isProcessing: false,
                        childLoad: false,
                    });
                    this.getUpcPullById();
                })
                .catch(error => {
                    handleError(error);
                    this.setState({
                        pullWhContentsBtnText: PULL_WH_CONTENTS,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handleDownloadPullReport = () => {
            this.setState({
                downloadPRBtnText: "Downloading Pull Report",
                isProcessing: true,
            });
            new ResourceAPIs().upcPullDownloadPullReport(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        downloadPRBtnText: DOWNLOAD_PULL_REPORT,
                        isProcessing: false,
                        childLoad: false,
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "upc-pull-report-" + dd + ".csv");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        downloadPRBtnText: DOWNLOAD_PULL_REPORT,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handleDownloadPullContents = () => {
            this.setState({
                downloadPCBtnText: "Downloading Pull Contents",
                isProcessing: true,
            });
            new ResourceAPIs().upcPullDownloadPullContents(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        downloadPCBtnText: DOWNLOAD_PULL_CONTENTS,
                        isProcessing: false,
                        childLoad: false,
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "upc-pull-contents-" + dd + ".csv");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        downloadPCBtnText: DOWNLOAD_PULL_CONTENTS,
                        isProcessing: false,
                        results: error,
                    });
                });
        };
    }

    getWholesaleOrderContents = () => {
        new ResourceAPIs().getWholesaleOrderContentsById(getIdFromUrl())
            .then(res => {
                const result = res.data;
                this.setState({
                    contents: result,
                    isLoaded: true,
                });
            })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return {value: client.id, label: client.id + " - " + client.name, name: client.name}
            });
            this.setState({
                clients: clients,
            }, () => {
                this.getWarehouses();
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    getWarehouses = () => {
        new ResourceAPIs().getAllWarehouses().then(res => {
                const warehouses = res.data.map(wh => {
                    return {
                        value: wh.id,
                        label: wh.name !== null ? wh.id + " - " + wh.name : wh.id + "-",
                        name: wh.name
                    }
                });
                this.setState({
                    warehouses: warehouses,
                });
                this.getUpcPullById();
            },
            (error) => {
                console.log(error);
                this.setState({
                    results: error,
                });
            });
    };

    validateAddContentsInputs = () => {
        if (this.state.contentsText === undefined || this.state.contentsText === "") {
            this.setState({
                errMsg: "Pls add contents you want to add!",
            });
            return false;
        } else {
            this.setState({
                errMsg: "",
            });
        }
        return true;
    };

    getUpcPullById = () => {
        new ResourceAPIs().getUpcPull(getIdFromUrl())
            .then(res => {
                const result = res.data;
                this.setState({
                    upcPull: {
                        id: result.id,
                        clientId: result.clientId,
                        warehouseId: result.warehouseId,
                        status: result.status,
                        createdAt: result.createdAt,
                        updatedAt: result.updatedAt,
                        errMsg: ""
                    },
                    clientLabel: getLabel(this.state.clients, result.clientId),
                    warehouseLabel: getLabel(this.state.warehouses, result.warehouseId),
                    isOrderLoaded: true,
                });
            })
            .catch(error => {
                    this.setState({
                        isOrderLoaded: false,
                        error
                    });
                }
            );
    };

    componentDidMount() {
        this.getClients();
        this.getWholesaleOrderContents();
    }

    render() {
        if (!this.state.isOrderLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '2%', marginRight: '2%'}}>
                    <Row>
                        <Col xs={7}>
                            <Card border="secondary" style={{height: 250}}>
                                <Card.Header as="h5">
                                    <span style={{marginTop: 60}}>UPC Pull Details</span>

                                    <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.upcPull.errMsg}
                                    </span>
                                        <Button variant="success" size="sm" style={{width: 100,}}
                                                disabled={this.state.isProcessing}
                                                onClick={this.handleEditUpcPull}>
                                            {showSaveSpinner(this.state.editUpcPullBtnText)}
                                        </Button>
                                        <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                                onClick={this.handleCancelEditUpcPull}
                                                disabled={this.state.isProcessing}>
                                            <Clear/> Cancel
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.upcPull.id}
                                                                          disabled={true}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Status</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.upcPull.status}
                                                                          onChange={this.onChangeStatus}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Created At</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.upcPull.createdAt)}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Client</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientId(e)}
                                                                options={this.state.clients}
                                                                styles={this.customStyles}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Warehouse</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Select
                                                                value={createTypeOption(this.state.warehouseLabel)}
                                                                onChange={(e) => this.onChangeWarehouseId(e)}
                                                                options={this.state.warehouses}
                                                                styles={this.customStyles}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Updated At</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.upcPull.updatedAt)}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={5}>
                            <Card border="secondary" style={{height: 250}}>
                                <Card.Header as="h5">
                                    <span style={{marginTop: 60}}>Add UPC Pull Contents</span>

                                    <div style={{float: "right"}}>
                                        <Button variant="success" size="sm" style={{width: 200,}}
                                                disabled={this.state.isProcessing}
                                                onClick={this.handleAddContents}>
                                            {showAddContentsSpinner(this.state.addContentBtnText)}
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Group controlId="contents">
                                            <Row>
                                                <Col>
                                                    <Form.Control type="text" size="sm" as="textarea"
                                                                  rows="5"
                                                                  value={setEmptyIfNull(this.state.contentsText)}
                                                                  onChange={this.onChangeContentsText}/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <span style={{fontSize: 12, color: "red",}}>
                                            {this.state.errMsg}
                                        </span>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br/><br/>
                    <Row style={{alignContent: 'center'}}>
                        <Button variant="secondary"
                                style={{width: 300, marginLeft: 20}}
                                disabled={this.state.isProcessing}
                                onClick={this.handlePullWhContents}>
                            {showPullWhContentsSpinner(this.state.pullWhContentsBtnText)}
                        </Button>
                        <Button variant="primary"
                                style={{width: 300, marginLeft: 20}}
                                disabled={this.state.isProcessing}
                                onClick={this.handleDownloadPullContents}>
                            {showPullDownloadPCSpinner(this.state.downloadPCBtnText)}
                        </Button>
                        <Button variant="dark"
                                style={{width: 300, marginLeft: 20}}
                                disabled={this.state.isProcessing}
                                onClick={this.handleDownloadPullReport}>
                            {showPullDownloadPRSpinner(this.state.downloadPRBtnText)}
                        </Button>
                    </Row>
                    <br/>
                    <UpcPullContents upcPullId={this.state.upcPull.id} isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

function showPullWhContentsSpinner(btnText) {
    if (btnText === PULL_WH_CONTENTS) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadPRSpinner(btnText) {
    if (btnText === DOWNLOAD_PULL_REPORT) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadPCSpinner(btnText) {
    if (btnText === DOWNLOAD_PULL_CONTENTS) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default UpcPullDetails;
