import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {SAVE, SAVING} from "../../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../../utils/DateUtils";
import {getIdFromUrl, handleErr, setEmptyIfNull, showSaveSpinner} from "../../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import {Clear} from "@material-ui/icons";
import ResourceAPIs from "../../utils/ResourceAPI";
import { Default, Mobile } from "../../utils/MediaUtils";

const locationInput = {
    width: 300,
};

class WarehouseDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            name: "",
            orderEmail: "",
            priority: "",
            createdAt: "",
            updatedAt: "",
            isLoaded: false,
            isProcessing: false,
            editBtnText: SAVE,
            errMsg: "",
        };

        // Edit warehouse

        this.onChangeName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.name = event.target.value;
            this.setState(newState);
        };

        this.onChangeOrderEmail = (event) => {
            let newState = Object.assign({}, this.state);
            newState.orderEmail = event.target.value;
            this.setState(newState);
        };

        this.onChangePriority = (event) => {
            let newState = Object.assign({}, this.state);
            newState.priority = event.target.value;
            this.setState(newState);
        };

        this.handleEdit = () => {
            if (this.validateEditWarehouse()) {
                this.setState({
                    editBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPIs().editWarehouse(getIdFromUrl(), this.state.name, this.state.orderEmail, this.state.priority)
                    .then(res => {
                            this.setState({
                                editBtnText: SAVE,
                                isProcessing: false,
                            });
                        })
                    .catch(error => {
                            console.log(error);
                            handleErr(error);
                            this.setState({
                                editBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleCancelEditLocation = () => {
            this.getWarehouseById();
        };
    }

    validateEditWarehouse = () => {
        // validate name
        if (this.state.name === "") {
            return this.setLocationEditErr("Invalid Name");
        }

        // validate order email
        if (this.state.orderEmail === "") {
            return this.setLocationEditErr("Invalid Order Email");
        }

        // validate priority
        if (this.state.priority === "") {
            return this.setLocationEditErr("Invalid Priority");
        }

        return true;
    };

    setLocationEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getWarehouseById = () => {
        new ResourceAPIs().getWarehouseById(getIdFromUrl())
            .then(res => {
                    const result = res.data
                    this.setState({
                        id: result.id,
                        name: setEmptyIfNull(result.name),
                        orderEmail: setEmptyIfNull(result.orderEmail),
                        priority: setEmptyIfNull(result.priority),
                        createdAt: result.createdAt,
                        updatedAt: result.updatedAt,
                        isLoaded: true,
                    })
                })
            .catch(error => {
                    console.log(error)
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            );
    };

    componentDidMount() {
        this.getWarehouseById();
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    {this.props.id === undefined && (
                    <Row>
                        <Col>
                            <Card border="secondary">
                                <Card.Header as="h5">Warehouse Details</Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col lg={6} md={6} xs={12}>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>ID</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="number" size="sm" style={locationInput}
                                                                          value={this.state.id}
                                                                          disabled={true}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Name</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" size="sm" style={locationInput}
                                                                          value={this.state.name}
                                                                          onChange={this.onChangeName}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Order Email</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" size="sm" style={locationInput}
                                                                          value={this.state.orderEmail}
                                                                          onChange={this.onChangeOrderEmail}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Priority</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" size="sm" style={locationInput}
                                                                          value={this.state.priority}
                                                                          onChange={this.onChangePriority}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Created At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.createdAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Updated At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.updatedAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                    <Mobile>
                                                        <div style={{ textAlign: "center", color: 'red', fontSize: 12 }}>
                                                            {this.state.errMsg}
                                                            <br /><br />
                                                            <Button variant="success" style={{ width: 120 }}
                                                                disabled={this.state.isProcessing}
                                                                onClick={this.handleEdit}>
                                                                {showSaveSpinner(this.state.editBtnText)}
                                                            </Button>
                                                            <Button variant="secondary" style={{ marginLeft: 10, width: 120 }}
                                                                onClick={this.handleCancelEditLocation}
                                                                disabled={this.state.isProcessing}>
                                                                <Clear /> Cancel
                                                            </Button>
                                                        </div>
                                                    </Mobile>
                                                    <Default>
                                                        <div style={{ textAlign: "center", color: 'red', fontSize: 12 }}>
                                                            {this.state.errMsg}
                                                            <br /><br />
                                                            <Button variant="success" style={{ width: 200 }}
                                                                disabled={this.state.isProcessing}
                                                                onClick={this.handleEdit}>
                                                                {showSaveSpinner(this.state.editBtnText)}
                                                            </Button>
                                                            <Button variant="secondary" style={{ marginLeft: 10, width: 200 }}
                                                                onClick={this.handleCancelEditLocation}
                                                                disabled={this.state.isProcessing}>
                                                                <Clear /> Cancel
                                                            </Button>
                                                        </div>
                                                    </Default>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    )}
                    <br/>
                </div>
            );
        }
    }
}

export default WarehouseDetails;
