import React, {Component} from 'react';
import {alertError, getIdFromUrl, handleErr, handleError, showExportSpinner} from '../utils/MiscellaniousUtils';
import Table from "@material-ui/core/Table";
import Form from "react-bootstrap/Form";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import {ArrowUpward, ArrowDownward, Save, Clear} from "@material-ui/icons";
import Card from "react-bootstrap/Card";
import Paper from "@material-ui/core/Paper/Paper";
import Badge from "react-bootstrap/Badge";
import {getFormattedDate} from "../orders/OrdersExporter";
import {format2NiceDate} from "../utils/DateUtils";
import ReactHtmlParser from 'react-html-parser';
import {hotkeys} from 'react-keyboard-shortcuts';
import ResourceAPIs from "../utils/ResourceAPI";

import BarcodeGenerator from './BarcodeGenerator';
import Button from "react-bootstrap/Button";
import {EXPORT, EXPORTING} from "../constants";
import fileDownload from "js-file-download";


const barcodeBadge3 = {
    width: 50,
};

class SplitContents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contents: [],
            searchedContents: [],
            isLoaded: false,
            search: "",
            tempSearch: "",
            exportBtnText: EXPORT,
            isProcessing: false,
        };
    }

    onChangeSearchValue = (event) => {
        let newState = Object.assign({}, this.state);
        newState.tempSearch = event.target.value;
        this.setState(newState);
    };

    onKeyDown = async (event) => {
        if (event.keyCode === 13) {
            event.target.select();
            let newState = Object.assign({}, this.state);
            newState.search = event.target.value;
            await this.setState(newState);
            this.filterContents(this.state.search);
        }
    };

    filterContents = (search) => {
        let listOfContents = this.state.contents;
        let newListOfContents = [];
        listOfContents.forEach((content) => {
            let hasFinding = false;
            let contentCopy = {...content};
            for (const key in content) {
                if (content[key] !== null && !(key === "createdAt" || key === "updatedAt")) {
                    let stringVal = content[key].toString();
                    if (stringVal.includes(search.toString())) {
                        hasFinding = true;
                        contentCopy[key] = contentCopy[key].toString().replace(search,
                            `<span style="background-color: #FFFF00"><b>` + search + `</b></span>`);

                    }
                }
            }
            if (hasFinding) {
                newListOfContents.push(contentCopy);
            }
        });
        this.setState({
            searchedContents: newListOfContents,
        });
    };

    getSplitContents = () => {
        new ResourceAPIs().getSplitContentsById(getIdFromUrl())
            .then(result => {
                let splitContents = this.addUntouchedValues(result.data);
                splitContents.map((content) => {
                    content.addToValue = 0;
                    let createdAt = content.createdAt;
                    let updatedAt = content.updatedAt;
                    content.qtyStillNeeded = content.qtyInShipment - content.qtyCheckedIn;
                    delete content.createdAt;
                    delete content.updatedAt;
                    content.createdAt = createdAt;
                    content.updatedAt = updatedAt;
                });
                this.setState({
                    contents: result.data,
                    isLoaded: true,
                });
            })
            .catch(error => {
                handleError(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    updateSplitContents = (splitContent) => {
        new ResourceAPIs().updateSplitContent(getIdFromUrl(), splitContent)
            .catch(error => {
                handleErr(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            });
    };

    getBgColor = (listingFnsku, fnsku) => {
        if (listingFnsku !== null) {
            if (listingFnsku.toLowerCase().charAt(0) === 'x') {
                return {backgroundColor: '#f5a2a2'};
            }
        }
        if (fnsku !== null) {
            if (fnsku.toLowerCase().charAt(0) === 'x') {
                return {backgroundColor: '#f5a2a2'};
            }
        }
        return {};
    };

    addUntouchedValues = (arr) => {
        for (const content of arr) {
            content.untouchedQtyCheckedIn = content.qtyCheckedIn;
        }
        return arr;
    };

    saveContent = (index) => {
        this.setState({
            isProcessing: true,
        });

        let content = undefined;
        if (this.state.search === "") {
            content = this.state.contents[index];
        } else {
            content = this.state.searchedContents[index];
        }
        let id = parseInt(content.id, 10);

        if (id > 0) {
            new ResourceAPIs().updateSplitContent(getIdFromUrl(), content)
                .then(res => {
                    let stateCopy = Object.assign({}, this.state);
                    if (this.state.search === "") {
                        stateCopy.isProcessing = false;
                        stateCopy.contents[index].edited = false;
                        stateCopy.contents[index].qtyStillNeeded =
                            stateCopy.contents[index].qtyInShipment - stateCopy.contents[index].qtyCheckedIn;
                        stateCopy.contents[index].untouchedQtyCheckedIn = content.qtyCheckedIn;
                        this.setState(stateCopy);
                    } else {
                        stateCopy.isProcessing = false;
                        stateCopy.searchedContents[index].edited = false;
                        stateCopy.searchedContents[index].qtyStillNeeded =
                            stateCopy.searchedContents[index].qtyInShipment - 
                            stateCopy.searchedContents[index].qtyCheckedIn;
                        stateCopy.searchedContents[index].untouchedQtyCheckedIn = content.qtyCheckedIn;
                        this.setState(stateCopy);
                    }
                }, (error) => {
                    this.setState({
                        isProcessing: false,
                    });
                    handleError(error);
                });
        } else {
            alertError("invalid id: " + id);
        }
        this.setState({
            isProcessing: false,
        });
    };

    cancelSaveContent = (index) => {
        let stateCopy = Object.assign({}, this.state);
        if (this.state.search === "") {
            stateCopy.contents[index].edited = false;
            stateCopy.contents[index].qtyCheckedIn = this.state.contents[index].untouchedQtyCheckedIn;
            this.setState(stateCopy);
        } else {
            stateCopy.searchedContents[index].edited = false;
            stateCopy.searchedContents[index].qtyCheckedIn = this.state.searchedContents[index].untouchedQtyCheckedIn;
            this.setState(stateCopy);
        }
    };

    incrementQtyCheckedIn = (index) => {
        let stateCopy = Object.assign({}, this.state);
        if (this.state.search === "") {
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].qtyCheckedIn = this.state.contents[index].qtyCheckedIn + 1;
            this.setState(stateCopy);
        } else {
            stateCopy.searchedContents[index].edited = true;
            stateCopy.searchedContents[index].qtyCheckedIn = this.state.searchedContents[index].qtyCheckedIn + 1;
            this.setState(stateCopy);
        }
    }

    decrementQtyCheckedIn = (index) => {
        let stateCopy = Object.assign({}, this.state);
        if (this.state.search === "") {
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].qtyCheckedIn = this.state.contents[index].qtyCheckedIn - 1;
            this.setState(stateCopy);
        } else {
            stateCopy.searchedContents[index].edited = true;
            stateCopy.searchedContents[index].qtyCheckedIn = this.state.searchedContents[index].qtyCheckedIn - 1;
            this.setState(stateCopy);
        }
    }

    getTableData = () => {
        let itemArray = [];
        if (this.state.search === "") {
            itemArray = this.state.contents;
        } else {
            itemArray = this.state.searchedContents;
        }
        return (
            <TableBody>
                {itemArray.map((row, index) => (
                    <TableRow key={row.id} style={this.getBgColor(row.listingFnsku, row.fnsku)}>
                        <TableCell align="center">{ReactHtmlParser(row.id)}</TableCell>
                        <TableCell align="center">{ReactHtmlParser(row.shipmentName)}</TableCell>
                        <TableCell align="center">{ReactHtmlParser(row.sku)}</TableCell>
                        <TableCell align="left">
                            <Badge variant="secondary"
                                   style={barcodeBadge3}>ASIN</Badge> {ReactHtmlParser(row.listingAsin)}<br/>
                            <Badge variant="primary"
                                   style={barcodeBadge3}>FNSKU</Badge> {ReactHtmlParser(row.listingFnsku)}<br/>
                            <Badge variant="success"
                                   style={barcodeBadge3}>UPC</Badge> {ReactHtmlParser(row.listingUpc)}<br/>
                            <Badge variant="info"
                                   style={barcodeBadge3}>Co UPC</Badge> {ReactHtmlParser(row.listingComponentUpc)}
                        </TableCell>
                        <TableCell align="left">
                            <Badge variant="warning"
                                   style={barcodeBadge3}>Ext ID</Badge> {ReactHtmlParser(row.externalId)}<br/>
                            <Badge variant="secondary"
                                   style={barcodeBadge3}>ASIN</Badge> {ReactHtmlParser(row.asin)}<br/>
                            <Badge variant="primary" style={barcodeBadge3}>FNSKU</Badge> {ReactHtmlParser(row.fnsku)}
                        </TableCell>
                        <TableCell align="center">{ReactHtmlParser(row.qtyInShipment.toString())}</TableCell>
                        <TableCell align="center">
                            {ReactHtmlParser(row.qtyCheckedIn.toString())}
                            <IconButton color="primary" style={{padding: 6}}
                                        disabled={this.state.isProcessing}
                                        onClick={() => this.incrementQtyCheckedIn(index)}>
                                <ArrowUpward/>
                            </IconButton>
                            <IconButton color="primary" style={{padding: 6}}
                                        disabled={this.state.isProcessing}
                                        onClick={() => this.decrementQtyCheckedIn(index)}>
                                <ArrowDownward/>
                            </IconButton>
                        </TableCell>

                        <TableCell align="center" style={{paddingTop:'30px'}}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control type="number"
                                              autoComplete="off"
                                              onChange={event => this.changeAddTo(event, index)}
                                              value={row.addToValue}
                                              size="sm"
                                              maxLength={2}
                                              ref={input => this[`input-${index}`] = input}
                                              onKeyDown={(event) => this.keyPress(event, index)}
                                />
                            </Form.Group>
                        </TableCell>
                        <TableCell align="center">{ReactHtmlParser(row.qtyStillNeeded.toString())}</TableCell>
                        <TableCell align="left">
                            <Badge variant="secondary" style={barcodeBadge3}>Created</Badge><br/>
                            {format2NiceDate(row.createdAt)}<br/>
                            <Badge variant="primary" style={barcodeBadge3}>Updated</Badge><br/>
                            {format2NiceDate(row.updatedAt)}
                        </TableCell>
                        <TableCell align="left">
                            <BarcodeGenerator fnsku={row.fnsku} sku={row.sku} title={row.title}/>
                            <IconButton color="primary" style={{padding: 6}}
                                        disabled={this.state.isProcessing || !row.edited}
                                        onClick={() => this.saveContent(index)}>
                                <Save/>
                            </IconButton>
                            <IconButton color="default" style={{padding: 6}}
                                        disabled={this.state.isProcessing || !row.edited}
                                        onClick={() => this.cancelSaveContent(index)}>
                                <Clear/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    changeAddTo = (event, index) => {
        let newState = Object.assign({}, this.state);
        if ((event.target.value < 100 && event.target.value >= 0)) {
            if (this.state.search === "") {
                newState.contents[index].addToValue = event.target.value;
            } else {
                newState.searchedContents[index].addToValue = event.target.value;
                newState.contents.map((value, contentIndex) => {
                    if (newState.searchedContents[index].id === value.id) {
                        newState.contents[contentIndex].addToValue = event.target.value;
                    }
                });
            }
            this.setState(newState);
        } else {
            alertError("'Add to Qty Checked In' only accepts 0 - 99");
        }
    };

    keyPress(event, index) {
        if (event.keyCode === 13) { 
            const addingQty = parseInt(event.target.value);
            let newState = Object.assign({}, this.state);
            if (this.state.search === "") {
                newState.contents[index].qtyCheckedIn = newState.contents[index].qtyCheckedIn + addingQty;
                newState.contents[index].qtyStillNeeded =
                    newState.contents[index].qtyInShipment - newState.contents[index].qtyCheckedIn;

                newState.contents[index].addToValue = 0;
                this.setState(newState, this.updateSplitContents(this.state.contents[index]));
            } else {
                newState.searchedContents[index].qtyCheckedIn =
                    newState.searchedContents[index].qtyCheckedIn + addingQty;
                newState.searchedContents[index].qtyStillNeeded =
                    newState.searchedContents[index].qtyInShipment - newState.searchedContents[index].qtyCheckedIn;
                newState.searchedContents[index].addToValue = 0;
                let newContentIndex = 0;

                // get relevant content from original `contents` array
                newState.contents.map((value, contentIndex) => {
                    if (newState.searchedContents[index].id === value.id) {
                        newState.contents[contentIndex].qtyCheckedIn = newState.searchedContents[index].qtyCheckedIn;
                        newState.contents[contentIndex].addToValue = 0;
                        newContentIndex = contentIndex;
                    }
                });
                this.setState(newState, this.updateSplitContents(this.state.contents[newContentIndex]));
            }
            event.target.value = 0;

        } else if (event.keyCode === 9) { // TAB key
            event.preventDefault();
        }

        if (event.keyCode === 9 || event.keyCode === 13) {
            event.preventDefault();
            let itemCount = 0;
            if (this.state.search === "") {
                itemCount = this.state.contents.length;
            } else {
                itemCount = this.state.searchedContents.length;
            }

            if (index >= itemCount - 1) {
                this[`searchField`].focus();
                this[`searchField`].select();
            } else {
                this[`input-${index + 1}`].focus();
                this[`input-${index + 1}`].select();
            }
        }
    }

    hot_keys = {
        'ctrl+s': {
            priority: 1,
            handler: (event) => {
                event.preventDefault();
                this[`searchField`].focus();
            },
        },
    };

    onExportHandler = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().exportSplitContents(getIdFromUrl()).then(response => {
            this.setState({
                exportBtnText: EXPORT,
                isProcessing: false,
                err: "",
            });
            const dd = getFormattedDate(new Date());
            fileDownload(response.data, "split-contents-export-" + dd + ".csv");
        }).catch(error => {
            this.setState({
                exportBtnText: EXPORT,
                isProcessing: false,
                err: error,
            });
            handleErr(error);
        });
    };

    componentDidMount() {
        this.getSplitContents();
    }


    componentDidUpdate(prevProps, preState, snapshot) {
        if (prevProps.isSplitContentsImported != this.props.isSplitContentsImported) {  
            this.getSplitContents();
        }
    }

    render() {
        return (
            <div>
                <Card border="secondary">
                    <Card.Header as="h5">
                        <span style={{marginTop: 20}}>Split Contents</span>
                        <Button style={{float: "right"}} variant="success" onClick={this.onExportHandler}
                                disabled={this.state.isProcessing}>
                            {showExportSpinner(this.state.exportBtnText)}
                        </Button>
                        <Form.Group controlId="formBasicPassword" style={{float: "right", marginRight: 10}}>
                            <Form.Control type="text"
                                          value={this.state.tempSearch}
                                          onChange={this.onChangeSearchValue}
                                          onKeyDown={this.onKeyDown}
                                          placeholder="Search... (ctrl + S)"
                                          ref={input => this[`searchField`] = input}
                            />
                        </Form.Group>
                    </Card.Header>
                    <Card.Body>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" width="5%">Content ID</TableCell>
                                        <TableCell align="center" width="10%">Shipment Name</TableCell>
                                        <TableCell align="center" width="10%">SKU</TableCell>
                                        <TableCell align="center" width="10%">Listing</TableCell>
                                        <TableCell align="center" width="10%">Split</TableCell>
                                        <TableCell align="center" width="5%">Qty In Shipment</TableCell>
                                        <TableCell align="center" width="10%">Qty Checked In</TableCell>
                                        <TableCell align="center" width="10%">Add to Qty Checked In</TableCell>
                                        <TableCell align="center" width="5%">Qty Still Needed</TableCell>
                                        <TableCell align="center" width="10%">Timestamps</TableCell>
                                        <TableCell align="center" width="5%">Controls</TableCell>
                                    </TableRow>
                                </TableHead>
                                {this.getTableData()}
                            </Table>
                        </TableContainer>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default hotkeys(SplitContents);
