export const YYYY_MM_DD = "yyyyMMdd";

export function isValidDate(dateString, format) {
    if (format === "yyyyMMdd") {
        let regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        let d = new Date(dateString);
        let dNum = d.getTime();
        if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0,10) === dateString;
    }
}

export function format2NiceDate(date) {
    let d = new Date(date);

    let yyyy = d.getFullYear();
    let month = getMonthName(d);
    let dd = d.getDate();

    let hh = formatNo2TwoDigits(d.getHours());
    let mm = formatNo2TwoDigits(d.getMinutes());
    let ss = formatNo2TwoDigits(d.getSeconds());

    return month + " " + dd + ", " + yyyy + " " + hh + ":" + mm + ":" + ss;
}

export function format2NiceDateOnly(date) {
    let d = new Date(date);

    let yyyy = d.getFullYear();
    let month = getFullMonthName(d);
    let dd = d.getDate();

    return month + " " + dd + ", " + yyyy;
}

function getMonthName(date) {
    let month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    return month[date.getMonth()];
}

function getFullMonthName(date) {
    let month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    return month[date.getMonth()];
}

function formatNo2TwoDigits(number) {
    return ("0" + number).slice(-2)
}

export function formatTaskTime(date) {
    if (date !== null) {
        const date1 = new Date();
        const date2 = new Date(date);
        const diffTime = Math.abs(date1 - date2);
        let seconds = Math.ceil(diffTime / (1000));
        if (seconds > 60) {
            let minutes = Math.ceil(seconds / (60));
            if (minutes > 60) {
                let hours = Math.ceil(minutes / (60));
                if (hours > 24) {
                    let days = Math.ceil(hours / (24));
                    if (days > 7) {
                        return format2NiceDate(date);
                    } else {
                        return days + " days ago"
                    }
                } else {
                    return hours + " hours ago"
                }
            } else {
                return minutes + " minutes ago"
            }
        } else {
            return seconds + " seconds ago"
        }
    } else {
        return null;
    }
}

// Convert time to GMT using time zone offset with UTC 0 time
export function convertToGMT(time) {
    return new Date(new Date(time)).toISOString().substring(0, 16);
}

// Get time zone offset compared to UTC 0 time, with local browser time
export function getTimeZoneOffSet(time) {
    return new Date(new Date(time).getTime() - (new Date(time).getTimezoneOffset() * 60000)).toISOString();
}
