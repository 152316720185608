import React, {Component} from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import InventoryReportsSearch from "./InventoryReportsSearch";


class InventoryReports extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="search">Search Inventory</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="search">
                            <br/>
                            <InventoryReportsSearch id={this.props.id}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }
}

export default InventoryReports;
