import React, {Component} from "react";
import {Base64} from 'js-base64';

import AuthService from "./services/AuthService";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import RedirectUrlService from "./services/RedirectUrlService";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Signup from "./Signup";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ResourceAPI from "../utils/ResourceAPI";
import {validEmail} from "../utils/TypeUtils";
import {IMPORT} from "../constants";
import {getErrorMessageFromError, handleError} from "../utils/MiscellaniousUtils";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeResetToken = this.onChangeResetToken.bind(this);

        this.state = {
            username: "",
            password: "",
            loading: false,
            message: "",
            showForgotPassword: false,
            showChangePassword: false,
            email: "",
            fpMessage: "",
            newPassword: "",
            confirmPassword: "",
            resetToken: "",
            modalBtnText: "Continue",
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangeNewPassword(e) {
        this.setState({
            newPassword: e.target.value
        });
    }

    onChangeConfirmPassword(e) {
        this.setState({
            confirmPassword: e.target.value
        });
    }

    onChangeResetToken(e) {
        this.setState({
            resetToken: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        if (this.validateInputs()) {
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                    // get user role
                    AuthService.userRole(this.state.username).then(
                        (res) => {
                            // check if there redirect url
                            if (RedirectUrlService.getRedirectUrl()) { // if available go to url
                                window.location.href = RedirectUrlService.getRedirectUrl();
                                RedirectUrlService.removeRedirectUrl();
                            } else { // else refresh page
                                if (AuthService.getCurrentUserRole() === "NOT_VALIDATED") {
                                    AuthService.getUserId().then( res => { 
                                        AuthService.logout()
                                        window.location.href = "/validate?number=" + Base64.encode(res.data);
                                    }).catch(() => {
                                        window.location.reload();
                                    });
                                } else if (AuthService.getCurrentUserRole() === "NO_ACCESS"){
                                    AuthService.logout();
                                    this.setState({
                                        message: "User not accepted by the admin",
                                        loading: false
                                    });
                                } else {
                                    window.location.reload();
                                }
                            }
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                loading: false,
                                message: resMessage
                            });
                        }
                    )
                },
                error => {
                    AuthService.loginErrorReason(this.state.username).then(
                        (res) => {
                            this.setState({
                                loading: false,
                                message: res
                            });
                        },
                        err => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            this.setState({
                                loading: false,
                                message: resMessage
                            });
                        }
                    )
                }
            );
        }
    }

    validateInputs = () => {
        if (this.state.username === "") {
            this.setState({
                message: "Username is required!",
                loading: false,
            });
            return false;
        }
        if (this.state.password === "") {
            this.setState({
                message: "Password is required!",
                loading: false,
            });
            return false;
        }
        return true;
    };

    handleClose = () => {
        this.setState({
            showForgotPassword: false
        })
    };

    handleShow = () => {
        this.setState({
            showForgotPassword: true
        })
    };

    onClickForgotPassword = () => {
        this.setState({
            fpMessage: "",
            loading: true
        });

        if (!this.state.showChangePassword) {
            // forgot password
            if (this.validateForgetPasswordInputs()) {
                new ResourceAPI().forgotPassword(this.state.email).then(() => {
                    this.setState({
                        loading: false,
                        modalBtnText: "Change",
                        fpMessage: "Pls check your email to get the reset token!"
                    });
                    this.handleShowChangePassword();
                }).catch(error => {
                    this.setState({
                        loading: false,
                        fpMessage: getErrorMessageFromError(error)
                    });
                });
            }
        } else {
            // reset password
            if (this.validateChangePasswordInputs()) {
                new ResourceAPI().resetPassword(this.state.email, this.state.newPassword, this.state.resetToken)
                    .then(() => {
                            this.setState({
                                loading: false,
                                modalBtnText: "Continue",
                                fpMessage: ""
                            });
                            this.handleClose();
                        }
                    ).catch(error => {
                    this.setState({
                        loading: false,
                        fpMessage: getErrorMessageFromError(error)
                    });
                });
            }
        }
    }

    validateForgetPasswordInputs = () => {
        if (this.state.email === "") {
            this.setState({
                fpMessage: "Email is required!",
                loading: false,
            });
            return false;
        }
        if (!validEmail(this.state.email)) {
            this.setState({
                fpMessage: "Email is invalid!",
                loading: false,
            });
            return false;
        }
        return true;
    };

    validateChangePasswordInputs = () => {
        if (this.state.email === "") {
            this.setState({
                fpMessage: "Email is required!",
                loading: false,
            });
            return false;
        }
        if (!validEmail(this.state.email)) {
            this.setState({
                fpMessage: "Email is invalid!",
                loading: false,
            });
            return false;
        }
        if (this.state.resetToken === "") {
            this.setState({
                fpMessage: "Reset token required!",
                loading: false,
            });
            return false;
        }
        if (this.state.newPassword === "") {
            this.setState({
                fpMessage: "New Password is required!",
                loading: false,
            });
            return false;
        }
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({
                fpMessage: "New Password & Confirm Password in not matching!",
                loading: false,
            });
        }
        return true;
    };

    handleCloseChangePassword = () => {
        this.setState({
            showChangePassword: false
        })
    };

    handleShowChangePassword = () => {
        this.setState({
            showChangePassword: true
        })
    };

    render() {
        return (
            <div>
                <div>
                    <br/><br/><br/>
                    <center>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="login">

                        <Card style={{width: '24rem'}}>
                            <Card.Body>
                                <Nav fill variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="login">Login</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="signup">Sign Up</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <br/>
                                <Tab.Content>
                                    <Tab.Pane eventKey="login">
                                        <Col>
                                            <Image src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" roundedCircle/>
                                        </Col>
                                        <Form style={{padding: 10}}>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Form.Label>Username</Form.Label>
                                                    <Form.Control type="text"
                                                                  className="form-control"
                                                                  name="username"
                                                                  value={this.state.username}
                                                                  onChange={this.onChangeUsername}/>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password"
                                                                  className="form-control"
                                                                  name="password"
                                                                  value={this.state.password}
                                                                  onChange={this.onChangePassword}/>
                                                </Row>
                                            </Form.Group>

                                            <br/>
                                            <Row>
                                                <button
                                                    className="btn btn-success btn-block"
                                                    disabled={this.state.loading}
                                                    onClick={this.handleLogin}>
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm"/>
                                                    )}
                                                    <span> Login</span>
                                                </button>
                                            </Row>

                                            <br/>
                                            {this.state.message && (
                                                <div className="form-group">
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.message}
                                                    </div>
                                                </div>
                                            )}

                                            <Button variant="link" size="small" onClick={this.handleShow}
                                                style={{fontSize: 11}}>
                                                Forgot Your Password?
                                            </Button>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="signup">
                                        <Signup/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card.Body>
                        </Card>
                        </Tab.Container>
                        {/*<span style={{color: 'red', fontSize: 12, fontFamily: 'verdana'}}>*/}
                        {/*    Created by <a href="http://innoxsol.com/" target="_blank" style={{color: 'red'}}>*/}
                        {/*    INNOX Solutions*/}
                        {/*</a>*/}
                        {/*</span>*/}
                    </center>
                </div>

                <Modal show={this.state.showForgotPassword} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Forgot Your Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{fontSize: 13}}>
                        <p>Having trouble logging in?</p>
                        <ul>
                            <li>Usernames are not in the form of email addresses</li>
                            <li>Passwords are case sensitive</li>
                        </ul>
                        <p>To reset your password, enter your email address used in the SignUp</p>
                        <Form.Group controlId="formEmail" style={{marginLeft: 15, marginRight: 15}}>
                            <Row>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text"
                                              className="form-control"
                                              name="email"
                                              value={this.state.email}
                                              onChange={this.onChangeEmail}/>
                            </Row>
                        </Form.Group>
                        {this.state.fpMessage && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {this.state.fpMessage}
                                </div>
                            </div>
                        )}
                        {this.state.showChangePassword && (
                            <div style={{marginLeft: 15, marginRight: 15}}>
                                <Form.Group controlId="formBasicPassword">
                                    <Row>
                                        <Form.Label>Reset Token</Form.Label>
                                        <Form.Control type="password"
                                                      className="form-control"
                                                      name="password"
                                                      value={this.state.resetToken}
                                                      onChange={this.onChangeResetToken}/>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Row>
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password"
                                                      className="form-control"
                                                      name="password"
                                                      value={this.state.newPassword}
                                                      onChange={this.onChangeNewPassword}/>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Row>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password"
                                                      className="form-control"
                                                      name="confirmPass"
                                                      value={this.state.confirmPassword}
                                                      onChange={this.onChangeConfirmPassword}/>
                                    </Row>
                                </Form.Group>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.onClickForgotPassword}>
                            {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"/>
                            )} {this.state.modalBtnText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}