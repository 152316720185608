import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {ADD_CONTENTS, ADDING_CONTENTS, SAVE, SAVING} from "../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate, isValidDate, YYYY_MM_DD} from "../utils/DateUtils";
import InventoryReportContents from "./InventoryReportContents";
import {createTypeOption, getIdFromUrl, getLabel, handleErr, showSaveSpinner} from "../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Clear, Info} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";
import ResourceAPIs from "../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const locationInput = {
    width: 300,
};

class InventoryReportDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            breport: {
                id: "",
                clientId: "",
                storeId: "",
                storeName: "",
                date: "",
                createdAt: "",
                updatedAt: "",
                errMsg: "",
            },
            clientLabel: "",
            storeLabel: "",
            selectedFile: null,
            isLocationLoaded: false,
            isProcessing: false,
            addContentBtnText: ADD_CONTENTS,
            deleteContentBtnText: "Delete All Contents",
            editLocationBtnText: SAVE,
            errMsg: "",
            childLoad: false,
            storesIds: [],
            clients: [],
        };

        // Edit breport

        this.onChangeClientId = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.breport.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.breport.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreId = selected => {
            let newState = Object.assign({}, this.state);
            newState.breport.storeId = selected.value;
            newState.storeLabel = getLabel(this.state.storesIds, selected.value);
            this.setState(newState);
        };

        this.onChangeDate = (event) => {
            let newState = Object.assign({}, this.state);
            newState.breport.date = event.target.value;
            this.setState(newState);
        };

        this.handleEditInvReport = () => {
            if (this.validateEditInvReport()) {
                this.setState({
                    editLocationBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPIs().updateInventoryReport(getIdFromUrl(), this.state.breport.clientId,
                    this.state.breport.storeId, this.state.breport.storeName, this.state.breport.date,
                    this.state.addContent)
                    .then(res => { 
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                editLocationBtnText: SAVE,
                                isProcessing: false,
                                childLoad: false,
                            }, () => {
                                this.getInventoryReportById();
                            });
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                editLocationBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleCancelEditLocation = () => {
            this.getInventoryReportById();
        };

        this.handleAddContent = () => {
            if (this.validateEditDetails()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });
                new ResourceAPIs().updateWHContentsByLocationId(getIdFromUrl(), this.state.addContent)
                    .then(res => { 
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: "Add Contents",
                                isProcessing: false,
                                childLoad: false,
                            });
                            this.resetAddContentFrom();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                addContentBtnText: "Add Contents",
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleDeleteAllContents = () => {
            MySwal.fire({
                title: 'You want to Delete all the contents of this location?',
                text: "You won't be able to revert this!",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        deleteContentBtnText: "Deleting All Contents",
                        isProcessing: true,
                    });
                    new ResourceAPIs().deleteWHContentsByLocationId(getIdFromUrl())
                        .then(res => { 
                                this.setState({
                                    childLoad: true,
                                });
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    childLoad: false,
                                });
                                MySwal.fire(
                                    'Deleted!',
                                    'All contents of this location has been deleted.',
                                    'success'
                                )
                            })
                        .catch(error => {
                                handleErr(error);
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    results: error,
                                });
                            });
                }
            });
        };

        this.onChangeSelectedFile = event => {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
            });
        };

        this.handleAddContents = () => {
            const data = new FormData();
            data.append('file', this.state.selectedFile);

            if (this.validateAddContentsInputs()) {
                this.setState({
                    addContentBtnText: "Adding Contents",
                    isProcessing: true,
                });
                new ResourceAPIs().importInventoryReportContentsById(getIdFromUrl(), data)
                    .then(res => { 
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: "Add Contents",
                                isProcessing: false,
                                childLoad: false,
                            });
                            this.resetAddContentFrom();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                addContentBtnText: "Add Contents",
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };
    }

    validateAddContentsInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                errMsg: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                errMsg: "",
            });
        }
        return true;
    };

    validateEditDetails = () => {
        if (parseInt(this.state.addContent.qty, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.addContent.qty = parseInt(this.state.addContent.qty, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid Quantity"
            });
            return false;
        }

        if (this.state.addContent.barcodes === "") {
            this.setState({
                errMsg: "Invalid Barcodes"
            });
            return false;
        }

        if (!isValidDate(this.state.startDate, YYYY_MM_DD)) {
            this.setState({
                errMsg: "Invalid Start Date, Correct format: yyyy-MM-dd"
            });
            return false;
        }

        if (!isValidDate(this.state.endDate, YYYY_MM_DD)) {
            this.setState({
                errMsg: "Invalid End Date, Correct format: yyyy-MM-dd"
            });
            return false;
        }

        return true;
    };

    validateEditInvReport = () => {
        // validate client id
        if (parseInt(this.state.breport.clientId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.breport.clientId = parseInt(this.state.breport.clientId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Client ID");
        }

        // validate store id
        if (parseInt(this.state.breport.storeId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.breport.storeId = parseInt(this.state.breport.storeId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Store ID");
        }

        // validate store name
        if (this.state.breport.storeName === "") {
            return this.setLocationEditErr("Invalid Location Name");
        }

        return true;
    };

    setLocationEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.breport.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getInventoryReportById = () => {
        new ResourceAPIs().getInventoryReportsById(getIdFromUrl())
            .then(res => {
                    const result = res.data;
                    this.setState({
                        breport: {
                            id: result.id,
                            clientId: result.clientId,
                            storeId: result.storeId,
                            storeName: result.storeName,
                            date: result.date,
                            createdAt: result.createdAt,
                            updatedAt: result.updatedAt,
                        },
                        isLocationLoaded: true,
                        clientLabel: getLabel(this.state.clients, result.clientId),
                        storeLabel: getLabel(this.state.storesIds, result.storeId)
                    })
                })
            .catch(error => {
                    this.setState({
                        isLocationLoaded: false,
                        error
                    })
                }
            );
    };

    resetAddContentFrom = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            selectedFile: null,
            theInputField: randomString
        });
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });
                    this.setState({
                        storesIds: storesIds,
                    }, () => {
                        this.getInventoryReportById();
                    });
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            }, () => {
                this.getStores();
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        // getClients will retrieve stores and inventory report data respectively
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <Row>
                        <Col xs={8}>
                            <Card border="secondary">
                                <Card.Header as="h5">Inventory Report Details</Card.Header>
                                <Card.Body style={{height: 285}}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>ID</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Form.Control type="number"
                                                                      value={this.state.breport.id}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>Client ID</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Select
                                                            value={createTypeOption(this.state.clientLabel)}
                                                            onChange={(e) => this.onChangeClientId(e)}
                                                            options={this.state.clients}
                                                            disabled={this.props.id !== undefined}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>
                                                            Date
                                                            <Tooltip title="Format: yyyy-MM-dd">
                                                                <IconButton aria-label="delete" size="small">
                                                                    <Info/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Form.Control type="text"
                                                                      value={this.state.breport.date != null ? this.state.breport.date : ""}
                                                                      onChange={this.onChangeDate}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>


                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>Store Name</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Form.Control type="text"
                                                                      value={this.state.breport.storeName}
                                                                      onChange={this.onChangeStoreName}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={3}>
                                                        <Form.Label>Store ID</Form.Label>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <Select
                                                            value={createTypeOption(this.state.storeLabel)}
                                                            onChange={(e) => this.onChangeStoreId(e)}
                                                            options={this.state.storesIds}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.breport.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.breport.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div style={{textAlign: "center",}}>
                                        <Form.Text className="text-muted">
                                            {this.state.breport.errMsg}
                                        </Form.Text>
                                        <br/>
                                        <Button variant="success" style={{width: 200}}
                                                disabled={this.state.isProcessing}
                                                onClick={this.handleEditInvReport}>
                                            {showSaveSpinner(this.state.editLocationBtnText)}
                                        </Button>
                                        <Button variant="secondary" style={{marginLeft: 10, width: 200}}
                                                onClick={this.handleCancelEditLocation}
                                                disabled={this.state.isProcessing}>
                                            <Clear/> Cancel
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Card border="secondary">
                                <Card.Header as="h5">Add Inventory Report Contents</Card.Header>
                                <Card.Body style={{height: 285}}>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <Form.Label>Select File</Form.Label>
                                                    <br/>
                                                    <input 
                                                        type="file" 
                                                        name="file"
                                                        onChange={this.onChangeSelectedFile}
                                                        key={this.state.theInputField}/>
                                                </Form.Group>
                                                <span style={{fontSize: 12, color: "red",}}>
                                                        {this.state.errMsg}
                                                    </span>
                                                <br/>
                                                <Button variant="success" style={{width: 200}}
                                                        disabled={this.state.isProcessing}
                                                        onClick={this.handleAddContents}>
                                                    {showSpinner(this.state.addContentBtnText)}
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <InventoryReportContents breport={this.state.breport} isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

function showSpinner(btnText) {
    if (btnText === "Add Contents") {
        return <span><AddToPhotosIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default InventoryReportDetails;
