import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import {PlaylistAdd} from "@material-ui/icons";
import {getIdFromUrl, handleErr, showFilterSpinner, alertSuccessfullyUpdated} from "../../utils/MiscellaniousUtils";
import {eqOptions, EXPORT, FILTER, FILTERING, intOptions, SAVE, strOptions} from "../../constants";
import Spinner from "react-bootstrap/Spinner";
import ResourceAPIs from "../../utils/ResourceAPI";
import withStyles from "@material-ui/core/styles/withStyles";


const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30,
};

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
        height: 440,
    },
});

const ADD_OF_CONTENTS = "Add OF Contents";
const ADDING_OF_CONTENTS = "Adding OF Contents...";

class OFContentsAdder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "0",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                supplier: {
                    condition: "eq",
                    value: "",
                },
                supplierGroup: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            exportBtnText: EXPORT,
            errMsg: "",
            results: [],
            resultsSize: 0,
            saveQtyBtnText: SAVE,
            addOFContentsBtnText: ADD_OF_CONTENTS,
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeBuyingModelValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.buyingModel.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeInventoryModelValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.inventoryModel.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeFulfilmentValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.fulfilment.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSupplierValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.supplier.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeSupplierGroupValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.supplierGroup.value = event.target.value;
            this.setState(stateCopy);
        };
    }

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        console.log(selectedValue);
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };


    onChangeBuyingModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.buyingModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeInventoryModelFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.inventoryModel.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeFulfilmentFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.fulfilment.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSupplierFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.supplier.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSupplierGroupFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.supplierGroup.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handleFilterOnEnter = (event) => {
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchPullRequests();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: "0",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                buyingModel: {
                    condition: "eq",
                    value: "",
                },
                inventoryModel: {
                    condition: "eq",
                    value: "",
                },
                fulfilment: {
                    condition: "eq",
                    value: "",
                },
                supplier: {
                    condition: "eq",
                    value: "",
                },
                supplierGroup: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchPullRequests() {
        if (this.validateInputs()) {
            this.setState({
                filterBtnText: FILTERING,
                isProcessing: true,
            });
            new ResourceAPIs().getSearchListingsToAddContents(this.state.filters)
                .then(res => { 
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: res.data.listings,
                            resultsSize: res.data.size
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            filterBtnText: FILTER,
                            isProcessing: false,
                            results: error,
                        });
                    });
        } else {
            console.log("invalid inputs");
        }
    }

    addOFContents = () => {
        this.setState({
            addOFContentsBtnText: ADDING_OF_CONTENTS,
            isProcessing: true,
        });
        new ResourceAPIs().addOFContentsForListings(getIdFromUrl(), this.state.filters)
            .then(res => {
                    this.props.childLoadSetter(true);
                    this.setState({
                        addOFContentsBtnText: ADD_OF_CONTENTS,
                        isProcessing: false,
                        addContent: {
                            sku: "",
                        }
                    });
                    this.props.childLoadSetter(false);
                    alertSuccessfullyUpdated("Outbound feed contents");
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        addOFContentsBtnText: ADD_OF_CONTENTS,
                        isProcessing: false,
                        results: error,
                    });
                });
    };

    validateInputs = () => {
        if (parseInt(this.state.filters.clientId.value, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = parseInt(this.state.filters.clientId.value, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid client ID"
            });
            return false;
        }

        return true;
    };

    render() {
        const {classes} = this.props;

        return (
            <Row>
                <Row style={{width: '100%'}}>
                    <Col xs={12} style={{width: '100%'}}>
                        <h6 style={{marginLeft: 10}}>Search Listings</h6>
                        <Card style={{marginLeft: '1%', marginRight: '1%', width: '98%',}}>
                            <Card.Body>
                                <Row xs={12}>
                                    <Col xs={5}>
                                        <Form.Label style={filterLabel}>Client ID</Form.Label>
                                                                               {this.props.id === undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Form.Control size="sm" type="number"
                                                                  value={this.state.filters.clientId.value}
                                                                  onKeyDown={this.handleFilterOnEnter}
                                                                  onChange={this.onChangeClientIdValue}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {this.props.id !== undefined && (
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={eqOptions}
                                                                    handleOnChange={this.onChangeClientIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Form.Control size="sm" type="number"
                                                                  value={this.props.id}
                                                                  onKeyDown={this.handleFilterOnEnter}
                                                                  onChange={this.onChangeClientIdValue}
                                                                  disabled={true}/>
                                                </Col>
                                            </Row>
                                        )}
                                        <Form.Label style={filterLabel}>Store Name</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStoreNameFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStoreNameValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.storeName.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.status.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Fulfilment</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeFulfilmentFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeFulfilmentValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.fulfilment.value}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label style={filterLabel}>Inventory model</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeInventoryModelFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeInventoryModelValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.inventoryModel.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Buying model</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeBuyingModelFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeBuyingModelValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.buyingModel.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Supplier</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeSupplierFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSupplierValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.supplier.value}/>
                                            </Col>
                                        </Row>
                                        <Form.Label style={filterLabel}>Supplier Group</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeSupplierGroupFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeSupplierGroupValue}
                                                              onKeyDown={this.handleFilterOnEnter}
                                                              value={this.state.filters.supplierGroup.value}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row align="center"
                                             style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary"
                                                    onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                        <br/>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <span style={resultsDescStyle}>
                                                Showing {this.state.results.length} of
                                                <b style={this.state.resultsSize > 3000 ? {'color': 'red'} : {}}> {this.state.resultsSize}</b> results
                                            </span>
                                        </Row>
                                        <br/>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="primary"
                                                    disabled={this.state.isProcessing
                                                    || this.state.resultsSize === 0
                                                    || this.state.resultsSize > 3000}
                                                    onClick={this.addOFContents}>
                                                {showAddOFContentsSpinner(this.state.addOFContentsBtnText)}
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <Paper className={classes.root}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">SKU</TableCell>
                                            <TableCell align="center">Client ID</TableCell>
                                            <TableCell align="center">Store Name</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Fulfilment</TableCell>
                                            <TableCell align="center">Inventory Model</TableCell>
                                            <TableCell align="center">Buying Model</TableCell>
                                            <TableCell align="center">Supplier</TableCell>
                                            <TableCell align="center">Supplier Group</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">{row.sku}</TableCell>
                                                <TableCell align="right">{row.clientId}</TableCell>
                                                <TableCell align="left">{row.storeName}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>
                                                <TableCell align="left">{row.fulfilment}</TableCell>
                                                <TableCell
                                                    align="left">{row.inventoryModel}</TableCell>
                                                <TableCell align="left">{row.buyingModel}</TableCell>
                                                <TableCell align="left">{row.supplier}</TableCell>
                                                <TableCell align="left">{row.supplierGroup}</TableCell>
                                                <TableCell
                                                    align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell
                                                    align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </Row>
        );
    }
}

function showAddOFContentsSpinner(btnText) {
    if (btnText === ADD_OF_CONTENTS) {
        return <span><PlaylistAdd/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default withStyles(useStyles)(OFContentsAdder);
