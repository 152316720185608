import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import TableContainer from "@material-ui/core/TableContainer";
import WHFilterSelect from "../components/WHFilterSelect";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import {Add, SkipNext, SkipPrevious} from "@material-ui/icons";
import Select from "react-select";
import {eqOptions, FILTER, FILTERING, intOptions, SAVE, strOptions} from "../constants";
import {createTypeOption, getLabel, handleErr, showFilterSpinner} from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

class SplitsSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                splitPlanName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            storesIds: [],
            results: [],
            saveQtyBtnText: SAVE,
        };

        this.onChangeClientIdValue = (selected) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeStoreNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStoreIdValue = selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = getLabel(this.state.storesIds, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeSplitPlanNameValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.splitPlanName.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };
    }

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeName.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeSplitPlanNameFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.splitPlanName.condition = selectedValue;
        this.setState(stateCopy);
    };


    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeName: {
                    condition: "eq",
                    value: "",
                },
                splitPlanName: {
                    condition: "eq",
                    value: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    handleAddNew = () => {
        new ResourceAPIs().addSplit()
            .then(res => {
                    window.open("/splits/" + res.data);
                })
            .catch(error => {
                    handleErr(error);
                });
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchSplits();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    }

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchSplits();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchSplits();
    };

    searchSplits() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchSplit(this.state.filters)
            .then(res => {
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: res.data,
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                });
    }

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                    const storesIds = res.data.map(store => {
                        return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                    });

                    this.setState({
                        storesIds: storesIds,
                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getStores();
        this.getClients();
    }

    render() { 
        return ( 
        <div>
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label style={filterLabel}>Store Name</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeStoreNameFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeStoreNameValue}
                                                          value={this.state.filters.storeName.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Store ID</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={eqOptions}
                                                            handleOnChange={this.onChangeStoreIdFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Select
                                                value={createTypeOption(this.state.filters.storeId.label)}
                                                onChange={(e) => this.onChangeStoreIdValue(e)}
                                                options={this.state.storesIds}
                                                styles={this.customStyles}
                                            />
                                        </Col>
                                    </Row>
                                    <Form.Label style={filterLabel}>Split Plan Name</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeSplitPlanNameFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeSplitPlanNameValue}
                                                          value={this.state.filters.splitPlanName.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    {this.props.id === undefined && (
                                    <div>
                                        <Form.Label style={filterLabel}>Client ID</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={intOptions}
                                                                handleOnChange={this.onChangeClientIdFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Select
                                                    value={createTypeOption(this.state.filters.clientId.label)}
                                                    onChange={(e) => this.onChangeClientIdValue(e)}
                                                    options={this.state.clients}
                                                    styles={this.customStyles}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    )}
                                    <Form.Label style={filterLabel}>Status</Form.Label>
                                    <Row>
                                        <Col style={dropdownCol}>
                                            <WHFilterSelect options={strOptions}
                                                            handleOnChange={this.onChangeStatusFilter}/>
                                        </Col>
                                        <Col style={inputCol}>
                                            <Form.Control size="sm" type="text"
                                                          onChange={this.onChangeStatusValue}
                                                          value={this.state.filters.status.value}
                                                          onKeyDown={this.handleFilterOnEnter}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <Form.Text className="text-muted">
                                            {this.state.errMsg}
                                        </Form.Text>
                                    </Row>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <IconButton onClick={this.handlePrev}
                                                    disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                            <SkipPrevious/>
                                        </IconButton>
                                        <span style={resultsDescStyle}>{this.state.results.length} results
                                        (Page {(this.state.filters.from / 100) + 1})</span>
                                        <IconButton onClick={this.handleNext}
                                                    disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                            <SkipNext/>
                                        </IconButton>
                                    </Row>
                                    <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                        <Button variant="success" style={filterBtn}
                                                onClick={this.handleFilter}
                                                disabled={this.state.isProcessing}>
                                            {showFilterSpinner(this.state.filterBtnText)}
                                        </Button>
                                        <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                    </Row>
                                </Col>
                                <Col xs={2}>
                                    <Row style={{display: 'block', textAlign: 'center'}}>
                                        <p style={{fontSize: 12, marginTop: 17}}>Add New Split</p>
                                        <Button variant="primary" onClick={this.handleAddNew}>
                                            <Add/> Add New
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br/>
                </Col>
                <Col xs={12}>
                    {this.state.results.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Client ID</TableCell>
                                        <TableCell align="center">Store ID</TableCell>
                                        <TableCell align="center">Store Name</TableCell>
                                        <TableCell align="center">Split Plan Name</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Created At</TableCell>
                                        <TableCell align="center">Updated At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.results.map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row" align="right">
                                                <a href={"/splits/" + row.id} target="_blank">{row.id}</a>
                                            </TableCell>
                                            <TableCell align="right">{row.clientId}</TableCell>
                                            <TableCell align="right">{row.storeId}</TableCell>
                                            <TableCell align="left">{row.storeName}</TableCell>
                                            <TableCell align="left">{row.splitPlanName}</TableCell>
                                            <TableCell align="left">{row.status}</TableCell>
                                            <TableCell align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                            <TableCell align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : <p></p>
                    }
                </Col>
            </Row>
        </div> 
        );
    }
}
 
export default SplitsSearch;
