import React, {Component} from "react";
import {MAX_FILE_SIZE, MAX_FILE_SIZE_STRING} from "../constants";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {ArrowBack, Folder, GetApp, InsertDriveFile} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {DELETE, DELETING, DIR, FILE, getDownloadUrl, getStaticUrl, showDocument, UPLOAD, UPLOADING} from "./Utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Checkbox from "@material-ui/core/Checkbox";
import {
    handleError,
    handleFileSizeExceedsError,
    handleNamedFileSizeExceedsError,
    showDeleteSpinner,
    showUploadSpinner
} from "../utils/MiscellaniousUtils";
import UploadProgress from "./UploadProgress";
import {HiClipboard} from "react-icons/hi";
import ResourceAPIs from "../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        placement: 'bottom',
    },
}))(Tooltip);

const displayNone = {
    display: "none",
};

const spanStyle = {
    marginLeft: "5px",
    marginRight: "70px",
};

const NO_FOLDER_CHOSEN = "No folder chosen";

class FileRepo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            selectedFiles: [],
            location: "",
            newDirectory: "",
            file: null,
            isLoaded: false,
            isProcessing: false,
            uploadBtnText: UPLOAD,
            uploadFolderBtnText: UPLOAD,
            selectedFile: null,
            selectedFilesInfo: [],
            isUploading: false,
            folderUploadText: NO_FOLDER_CHOSEN,
            deleteBtnText: DELETE,
        };
    }

    resetFolderUploadText = () => {
        this.setState({
            folderUploadText: NO_FOLDER_CHOSEN
        });
    };

    changeFolderUploadText = (text) => {
        this.setState({
            folderUploadText: text,
        });
    };

    closeIsUploading = () => {
        this.setState({
            isUploading: false,
        });
        this.clearFrom();
    };

    onChangeNewDirectory = (event) => {
        let newState = Object.assign({}, this.state);
        newState.newDirectory = event.target.value;
        this.setState(newState);
    };

    onChangeFile = event => {
        let numberOfFiles = event.target.files.length;
        let isError = false;
        let fileInfo = [];
        for (let fileNumber = 0; fileNumber < numberOfFiles; fileNumber++) {
            if (event.target.files[fileNumber] !== undefined) {
                let fileSize = event.target.files[fileNumber].size;
                if (fileSize > MAX_FILE_SIZE) {
                    handleNamedFileSizeExceedsError(event.target.files[fileNumber].name, MAX_FILE_SIZE_STRING);
                    isError = true;
                } else {
                    fileInfo.push({progress: 0, total: fileSize});
                }
            }
        }
        if (isError) {
            this.setState({
                selectedFile: null,
                selectedFilesInfo: [],
            });
        } else {
            this.setState({
                selectedFilesInfo: fileInfo,
                selectedFile: event.target.files,
            });
        }
    };

    onChangeFolder = event => {
        let fileSize = this.getFileCount(event.target.files);
        if (fileSize > MAX_FILE_SIZE) {
            handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
            this.changeFolderInputKey();
        } else {
            this.changeFolderUploadText(event.target.files.length + " files");
            const zip = require('jszip')();
            let files = event.target.files;

            for (let file = 0; file < event.target.files.length; file++) {
                let folderName;
                const folders = files[file].webkitRelativePath.split("/");

                for (let folder = 0; folder < folders.length - 1; folder++) {
                    if (folder === 0) {
                        folderName = zip.folder(folders[folder]);
                    } else {
                        folderName = folderName.folder(folders[folder]);
                    }
                }
                if (folderName) {
                    folderName.file(files[file].name, files[file]);
                }
            }

            zip.generateAsync({type: "blob"}).then(content => {
                let newState = Object.assign({}, this.state);
                newState.selectedFilesInfo.push({progress: 0, total: content.size});
                newState.selectedFile = [];
                newState.selectedFile.push(content);
                this.setState(newState);
            });
        }
    };

    getFileCount = (files) => {
        let size = 0;
        for (let file = 0; file < files.length; file++) {
            size = size + files[file].size;
        }
        return size;
    };

    onKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.createFolder(this.state.newDirectory);
        }
    };

    handleSelectAllClick = (event) => {
        let files = [];
        if (event.target.checked) {
            files = this.state.files;
        }

        this.setState({
            selectedFiles: files
        });
    };

    handleSelectClick = (event, row, index) => {
        let selectedFiles = this.state.selectedFiles;
        let files = this.state.files;
        if (event.target.checked) {
            selectedFiles.push(row);
        } else {
            if (index > -1) {
                selectedFiles.splice(index, 1);
            }
        }

        this.setState({
            selectedFiles: selectedFiles,
            files: files
        });
    };

    getFiles = (location) => {
        new ResourceAPIs().getRepoFiles(location).then(response => {
                let result = response.data;
                this.setState({
                    files: result,
                    isLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLoaded: false,
                    error
                });
            });
    };

    createFolder = (folder) => {
        if (folder === null || folder.trim().length === 0) {
            // MySwal.fire("Invalid Folder Name!", "Pls specify folder name you want to create", "error");
            return;
        }

        let filePath = "";
        if (this.state.location !== null && this.state.location.length > 0) {
            filePath = this.state.location + "/" + folder;
        } else {
            filePath = folder;
        }

        this.setState({
            isProcessing: true,
        });
        new ResourceAPIs().createFolder(filePath).then(response => {
                this.getFiles(this.state.location);
                this.setState({
                    isProcessing: false,
                    newDirectory: "",
                });
            }).catch(error => {
                handleError(error);
                this.setState({
                    isProcessing: false,
                });
            });
    };

    uploadFolder = () => {
        if (this.state.selectedFile == null) {
            MySwal.fire("Error", "Pls select the file you want to upload!", "error");
        } else {
            this.setState({
                uploadFolderBtnText: UPLOADING,
                isProcessing: true,
                isUploading: true,
            });

            const data = new FormData();
            data.append("file", this.state.selectedFile[0]);
            data.append("location", this.state.location);

            const options = {
                onUploadProgress: (ProgressEvent) => {
                    const {loaded, total} = ProgressEvent;
                    let newState = Object.assign({}, this.state);
                    newState.selectedFilesInfo[0].progress = loaded;
                    this.setState(newState);
                },
            };

            new ResourceAPIs().uploadFile(data, options).then(response => {
                    this.getFiles(this.state.location);
                    this.changeFolderInputKey();
                    this.resetFolderUploadText();
                }).catch(error => {
                    handleError(error);
                });
        }
        this.setState({
            uploadFolderBtnText: UPLOAD,
            isProcessing: false,
        });
    };

    uploadFile = () => {
        if (this.state.selectedFile == null) {
            MySwal.fire("Error", "Pls select the file you want to upload!", "error");
        } else {
            this.setState({
                uploadBtnText: UPLOADING,
                isProcessing: true,
                isUploading: true,
            });

            for (let file = 0; file < this.state.selectedFile.length; file++) {
                const data = new FormData();
                data.append("file", this.state.selectedFile[file]);
                data.append("location", this.state.location);

                const options = {
                    onUploadProgress: (ProgressEvent) => {
                        const {loaded, total} = ProgressEvent;
                        let newState = Object.assign({}, this.state);
                        newState.selectedFilesInfo[file].progress = loaded;
                        this.setState(newState);
                    },
                };
                new ResourceAPIs().uploadFile(data, options).then(response => {
                        if (file === (this.state.selectedFile.length - 1)) {
                            this.getFiles(this.state.location);
                            this.changeFileInputKey();
                        }
                    }).catch(error => {
                        handleError(error);
                    });
            }
            this.setState({
                uploadBtnText: UPLOAD,
                isProcessing: false,
            });
        }
    };

    deleteFile = () => {
        MySwal.fire({
            title: 'You want to Delete ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(result => {
            if (result.value) {
                this.setState({
                    deleteBtnText: DELETING,
                    isProcessing: true,
                });
                for (let file = 0; file < this.state.selectedFiles.length; file++) {
                    let filePath = this.state.selectedFiles[file].path;
                    new ResourceAPIs().deleteFile(filePath).then(response => {
                            this.getFiles(this.state.location);
                        }).catch(error => {
                            handleError(error);
                        });
                }
                this.setState({
                    deleteBtnText: DELETE,
                    isProcessing: false,
                    selectedFiles: [],
                });
            }
        });
    };

    changeFileInputKey = () => {
        let fileUploadKey = Math.random().toString(36);
        this.setState({
            theInputField: fileUploadKey,
        });
    };

    changeFolderInputKey = () => {
        let folderUploadKey = Math.random().toString(36);
        this.setState({
            folderUploadKey: folderUploadKey,
        });
    };

    clearFrom = () => {
        this.setState({
            selectedFilesInfo: [],
            selectedFile: null,
        });
    };

    gotoFolder = (folder) => {
        let location = this.state.location;
        if (location !== null && location.length > 0) {
            location = location + "/" + folder;
        } else {
            location = folder;
        }

        this.setState({
            location: location
        }, () => this.getFiles(this.state.location));
    };

    goBackFolder = () => {
        let location = this.state.location;
        if (location !== null && location.length > 0 && location.includes("/")) {
            location = location.substring(0, location.lastIndexOf("/"));
        } else {
            location = "";
        }

        this.setState({
            location: location
        }, () => this.getFiles(this.state.location));
    };

    componentDidMount() {
        let pathVariables = window.location.pathname.split('/');
        let location = "";
        pathVariables.forEach((pathVariable, index) => {
            if (!(index === 0 || index === 1 || index === pathVariables.length - 1)){
                location += pathVariable + "/" ;
            }
        });
        location = location.slice(0, -1);
        this.setState({
            location: location,
        }, () => this.getFiles(location));
    }

    copyToClipboard = (name, location) => {
        let url = getStaticUrl(name, location);
        const element = document.createElement('textarea');
        element.value = url;
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
    };

    render() {
        let numSelected = this.state.selectedFiles.length;
        let rowCount = this.state.files.length;

        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '5%', marginRight: '5%'}}>
                    <h1>File Repository</h1>
                    <br/>
                    <p>
                        <IconButton color="primary" style={{padding: 6}}
                                    onClick={this.goBackFolder} disabled={this.state.location.length <= 0}>
                            <ArrowBack/>
                        </IconButton>
                        /{this.state.location}
                    </p>
                    <br/>
                    <Row className="align-items-end">
                        <Col lg="4" sm="12" xs="12">
                            <span>Add Directory</span>
                            <Form.Group>
                                <Form.Control size="sm" type="text" placeholder="Enter Directory name -> Enter"
                                              value={this.state.newDirectory}
                                              onChange={this.onChangeNewDirectory}
                                              onKeyDown={this.onKeyDown}/>
                            </Form.Group>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <span>Upload File</span>
                            <br/>
                            <input
                                multiple
                                type="file"
                                name="file"
                                onClick={this.closeIsUploading}
                                onChange={this.onChangeFile}
                                key={this.state.theInputField}/>

                            <Button variant="success" size="sm" onClick={this.uploadFile}
                                    disabled={this.state.isProcessing}>
                                {showUploadSpinner(this.state.uploadBtnText)}
                            </Button>
                        </Col>
                        <Col lg="4" sm="6"  xs="6">
                            <span>Upload Folder</span>
                            <br/>
                            <input type="button" value="Upload Folder"
                                   onClick={() => document.getElementById('files').click()}/>
                            <span style={spanStyle}>{this.state.folderUploadText}</span>
                            <input
                                type="file"
                                name="files"
                                id="files"
                                style={displayNone}
                                directory=""
                                webkitdirectory=""
                                mozdirectory=""
                                onClick={this.closeIsUploading}
                                onChange={this.onChangeFolder}
                                key={this.state.folderUploadKey}/>
                            <Button variant="success" size="sm" onClick={this.uploadFolder}
                                    disabled={this.state.isProcessing}>
                                {showUploadSpinner(this.state.uploadFolderBtnText)}
                            </Button>
                        </Col>
                        <Col lg="1" sm="12"  xs="12">
                            <div className="float-right">
                                <Button variant="danger" size="sm" onClick={this.deleteFile}
                                        disabled={this.state.isProcessing || this.state.selectedFiles.length === 0}>
                                    {showDeleteSpinner(this.state.deleteBtnText)}
                                </Button>
                            </div>                            
                        </Col>
                    </Row>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" style={{width: "100%"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            onChange={this.handleSelectAllClick}
                                            inputProps={{'aria-label': 'select all desserts'}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">File size</TableCell>
                                    <TableCell align="center">Copy URL</TableCell>
                                    <TableCell align="center">Download</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.files.map((row, index) => {
                                    const isItemSelected = this.state.selectedFiles.includes(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow key={index}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                    onChange={(event) => this.handleSelectClick(event, row, index)}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.type === FILE && (
                                                    <div>
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    {showDocument(row.name, this.state.location)}
                                                                </React.Fragment>
                                                            }>
                                                            <IconButton color="default" style={{padding: 6}}>
                                                                <InsertDriveFile/>
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                        <a target="_blank"
                                                           href={getStaticUrl(row.name, this.state.location)}>
                                                            {row.name}
                                                        </a>
                                                    </div>
                                                )}
                                                {row.type === DIR && (
                                                    <div>
                                                        <IconButton color="primary" style={{padding: 6}}
                                                                    onClick={() => this.gotoFolder(row.name)}>
                                                            <Folder/>
                                                        </IconButton>
                                                        <a href="#" onClick={() => this.gotoFolder(row.name)}>{row.name}</a>
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="left">{row.type}</TableCell>
                                            <TableCell align="left">{row.size / 1000} KB</TableCell>
                                            <TableCell align="center">
                                                <IconButton color="default" style={{padding: 6}}
                                                            onClick={() => this.copyToClipboard(row.name, this.state.location)}>
                                                    <HiClipboard size={20}/>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.type === FILE && (
                                                <a href={getDownloadUrl(row.name, this.state.location)}>
                                                    <GetApp/>
                                                </a>
                                                )}
                                                {row.type === DIR && (
                                                    <GetApp color="disabled"/>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <UploadProgress isUploading={this.state.isUploading}
                                    selectedFiles={this.state.selectedFile}
                                    selectedFilesInfo={this.state.selectedFilesInfo}
                                    closeIsUploading={this.closeIsUploading}/>
                </div>
            );
        }
    }
}

export default FileRepo;
