import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { format2NiceDate } from "../../utils/DateUtils";
import { Add } from "@material-ui/icons";
import { handleErr } from "../../utils/MiscellaniousUtils";
import ResourceAPIs from "../../utils/ResourceAPI";
import { Default, Mobile } from "../../utils/MediaUtils";
import Badge from "react-bootstrap/Badge";

class WarehousesSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            results: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.fetchWarehouses();
    }

    fetchWarehouses() {
        new ResourceAPIs().getAllWarehouses()
            .then(res => {
                this.setState({
                    results: res.data,
                    isLoaded: true,
                });
            })
            .catch(error => {
                handleErr(error);
                this.setState({
                    results: error,
                    isLoaded: true,
                });
            });
    }

    handleAddNew = () => {
        new ResourceAPIs().addWarehouse()
            .then(res => {
                window.open("/whs/" + res.data);
            })
            .catch(error => {
                handleErr(error);
            });
    };

    render() {
        return (
            <div>
                <Mobile>
                    {this.props.id === undefined && (
                        <Row style={{ display: 'block', textAlign: 'center' }}>
                            <Button variant="primary" onClick={this.handleAddNew}><Add /> Add New Warehouse</Button>
                        </Row>
                    )}
                    <br />
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: "40%" }}>Warehouses</TableCell>
                                                <TableCell align="center" style={{ width: "60%" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row" align="left">
                                                        {this.props.id === undefined && (
                                                            <b><a href={"/whs/" + row.id} target="_blank">{row.id}</a></b>
                                                        )}
                                                        {this.props.id != undefined && (
                                                            <b>{row.id}</b>
                                                        )}
                                                        <br />
                                                        <Badge variant="secondary" style={{ width: 50 }}>Priority</Badge> {row.priority}<br />
                                                        <Badge variant="primary" style={{ width: 50 }}>Name</Badge> {row.name}<br />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Badge variant="success" style={{ width: 80 }}>Order Email</Badge> {row.orderEmail}<br />
                                                        <i style={{ fontSize: 9 }}>Created: {format2NiceDate(row.createdAt)}</i>
                                                        <br />
                                                        <i style={{ fontSize: 9 }}>Updated: {format2NiceDate(row.updatedAt)}</i>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Mobile>
                <Default>
                    {this.props.id === undefined && (
                        <Row style={{ display: 'block', textAlign: 'right', marginRight: '2%' }}>
                            <Button variant="primary" onClick={this.handleAddNew}><Add /> Add New Warehouse</Button>
                        </Row>
                    )}
                    <br />
                    <Row>
                        <Col xs={12}>
                            {this.state.results.length > 0 ?
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">Name</TableCell>
                                                <TableCell align="center">Order Email</TableCell>
                                                <TableCell align="center">Priority</TableCell>
                                                <TableCell align="center">Created At</TableCell>
                                                <TableCell align="center">Updated At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.results.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    {this.props.id === undefined && (
                                                        <TableCell component="th" scope="row" align="right">
                                                            <a href={"/whs/" + row.id} target="_blank">{row.id}</a>
                                                        </TableCell>
                                                    )}
                                                    {this.props.id != undefined && (
                                                        <TableCell component="th" scope="row" align="right">
                                                            {row.id}
                                                        </TableCell>
                                                    )}
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="left">{row.orderEmail}</TableCell>
                                                    <TableCell align="left">{row.priority}</TableCell>
                                                    <TableCell align="right">{format2NiceDate(row.createdAt)}</TableCell>
                                                    <TableCell align="right">{format2NiceDate(row.updatedAt)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p></p>
                            }
                        </Col>
                    </Row>
                </Default>
            </div>
        );
    }
}

export default WarehousesSearch;
