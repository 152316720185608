import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {SAVE, SAVING} from "../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../utils/DateUtils";
import {getIdFromUrl, handleErr, setEmptyIfNull, showSaveSpinner} from "../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import {Clear} from "@material-ui/icons";
import ResourceAPIs from "../utils/ResourceAPI";

const locationInput = {
    width: 300,
};

class StoreDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            name: "",
            clientId: "",
            type: "",
            createdAt: "",
            updatedAt: "",
            isLoaded: false,
            isProcessing: false,
            editBtnText: SAVE,
            errMsg: "",
        };

        // Edit warehouse

        this.onChangeName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.name = event.target.value;
            this.setState(newState);
        };

        this.onChangeClientId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.clientId = event.target.value;
            this.setState(newState);
        };

        this.onChangeType = (event) => {
            let newState = Object.assign({}, this.state);
            newState.type = event.target.value;
            this.setState(newState);
        };

        this.handleEdit = () => {
            if (this.validateEditStore()) {
                this.setState({
                    editBtnText: SAVING,
                    isProcessing: true,
                });

                const store = {
                    id: this.state.id,
                    name: this.state.name,
                    clientId: this.state.clientId,
                    type: this.state.type,
                };

                new ResourceAPIs().updateStores(store).then(response => {
                        this.setState({
                            editBtnText: SAVE,
                            isProcessing: false,
                        });
                    }).catch(error => {
                        handleErr(error);
                        this.setState({
                            editBtnText: SAVE,
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        };

        this.handleCancelEditLocation = () => {
            this.getStoreById();
        };
    }

    validateEditStore = () => {
        if (parseInt(this.state.clientId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.clientId = parseInt(this.state.clientId, 10);
            this.setState(stateCopy);
            return true;
        } else {
            this.setState({
                errMsg: "Invalid Client ID"
            });
            return false;
        }
    };

    getStoreById = () => {
        new ResourceAPIs().getStore(getIdFromUrl()).then(response => {
                let result = response.data;
                this.setState({
                    id: result.id,
                    clientId: result.clientId,
                    name: setEmptyIfNull(result.name),
                    type: setEmptyIfNull(result.type),
                    createdAt: result.createdAt,
                    updatedAt: result.updatedAt,
                    isLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLoaded: false,
                    error
                });
            });
    };

    componentDidMount() {
        this.getStoreById();
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '3%', marginRight: '3%'}}>
                    <Row>
                        <Col>
                            <Card border="secondary">
                                <Card.Header as="h5">Store Details</Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col lg={6} md={6} xs={12}>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>ID</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="number" size="sm" style={locationInput}
                                                                          value={this.state.id}
                                                                          disabled={true}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Client ID</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="number" size="sm" style={locationInput}
                                                                          value={this.state.clientId}
                                                                          onChange={this.onChangeClientId}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>Name</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" size="sm" style={locationInput}
                                                                          value={this.state.name}
                                                                          onChange={this.onChangeName}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label>type</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" size="sm" style={locationInput}
                                                                          value={this.state.type}
                                                                          onChange={this.onChangeType}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Created At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.createdAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Updated At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.updatedAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                <div style={{textAlign: "center", color: 'red', fontSize: 12}}>
                                                        {this.state.errMsg}
                                                    <br/><br/>
                                                    <Button variant="success" style={{width: 200}}
                                                            disabled={this.state.isProcessing}
                                                            onClick={this.handleEdit}>
                                                        {showSaveSpinner(this.state.editBtnText)}
                                                    </Button>
                                                    <Button variant="secondary" style={{marginLeft: 10, width: 200}}
                                                            onClick={this.handleCancelEditLocation}
                                                            disabled={this.state.isProcessing}>
                                                        <Clear/> Cancel
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                </div>
            );
        }
    }
}

export default StoreDetails;
