import React, {Component} from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import ResourceAPIs from "../utils/ResourceAPI";

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 455,
        height: 455,
    },
});

class UnbilledWorkOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            results: [],
        };
    }

    get = () => {
        new ResourceAPIs().getUnbilledWorkOrder()
            .then(res => {
                    const result = res.data;
                    this.setState({
                        result: result,
                        isLoaded: true,
                    })
                })
            .catch(error => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            );
    };

    componentDidMount() {
        this.get();
    }

    render() {
        const {classes} = this.props;

        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <h5>Unbilled Work Orders</h5>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{width: '15%'}}>Client ID</TableCell>
                                        <TableCell align="center" style={{width: '15%'}}>WO ID</TableCell>
                                        <TableCell align="center" style={{width: '45%'}}>Work Order Name</TableCell>
                                        <TableCell align="center" style={{width: '25%'}}>Store Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.result.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align="right">
                                                    <a href={"/clients/" + row.clientId} target="_blank">
                                                        {row.clientId}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <a href={"/work-orders/" + row.id} target="_blank">
                                                        {row.id}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="left">{row.title}</TableCell>
                                                <TableCell align="left">{row.storeName}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            );
        }
    }
}

export default withStyles(useStyles)(UnbilledWorkOrders);
