import React from 'react';
import {useBarcode} from '@createnextapp/react-barcode';
import PrintComponents from "react-print-components";
import './Barcodestyles.css';
import IconButton from "@material-ui/core/IconButton";
import {Print} from "@material-ui/icons";

export default (props) => {
    if (props.value === null || props.value === "") {
        return <br/>;
    } else {
        const {inputRef} = useBarcode({
            value: props.value,
            options: {
                format: 'CODE128B',
                background: '#ffffff',
                width: props.width,
                height: '50'
            }
        });

        // width: 2.25" = 5.715 cm
        // height: 1.25" = 3.175 cm
        return (
            <PrintComponents
                trigger={<IconButton aria-label="info" size="small" >
                    <Print/>
                </IconButton>}
            >
                <div>
                    <div className="barcode-text">
                        <br className="break-margin"/>
                        <b>New:</b> {props.SKU}
                        <br/>
                        {formatTitle(props.title)}
                    </div>
                    <svg className="barcode" ref={inputRef}/>
                </div>
            </PrintComponents>
        );
    }       
};

function formatTitle(title) {
    if (title !== null && title.length > 120) {
        title = title.substring(0, 119) + "..."
    }
    return title;
}
