import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import WHFilterSelect from "../../components/WHFilterSelect";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../../utils/DateUtils";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import {Add, SkipNext, SkipPrevious} from "@material-ui/icons";
import {createTypeOption, getLabel, handleErr, showFilterSpinner} from "../../utils/MiscellaniousUtils";
import {eqOptions, FILTER, FILTERING, intOptions, SAVE} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";

const filterBtn = {
    marginRight: 10,
    width: 120,
};

const filterLabel = {
    marginBottom: 0,
};

const resultsDescStyle = {
    fontSize: 12,
};

const dropdownCol = {
    paddingRight: 0
};

const inputCol = {
    paddingLeft: 0,
    paddingRight: 30
};

const strOptions = [
    {value: "eq", label: "Equals"},
    {value: "con", label: "Contains"},
];

class WholesaleOrdersSearch extends Component {

    constructor(props) {
        console.log(props);
        super(props);

        this.state = {
            filters: {
                clientId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                storeId: {
                    condition: "eq",
                    value: "",
                    label: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
            isProcessing: false,
            filterBtnText: FILTER,
            errMsg: "",
            results: [],
            saveQtyBtnText: SAVE,
            clients: [],
            stores: [],
        };

        this.onChangeClientIdValue = (event) => {
            let newState = Object.assign({}, this.state);
            newState.filters.clientId.value = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatusValue = event => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.status.value = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeCombinedClientIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.clientId.value = selected.value;
            stateCopy.filters.clientId.label = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };

        this.onChangeCombinedStoreIdValue = async selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.filters.storeId.value = selected.value;
            stateCopy.filters.storeId.label = getLabel(this.state.stores, selected.value);
            this.setState(stateCopy);
            this.handleFilter();
        };
    }

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });
            this.setState({
                clients: clients,
            });
            this.getStores();
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    getStores = () => {
        new ResourceAPIs().getStores().then(res => {
                const stores = res.data.map(store => {
                    return {value: store.id, label: store.id + " - " + store.name, name: store.name}
                });
                this.setState({
                    stores: stores,
                });
            },
            (error) => {
                console.log(error);
                this.setState({
                    results: error,
                });
            });
    };

    onChangeClientIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.clientId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStoreIdFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.storeId.condition = selectedValue;
        this.setState(stateCopy);
    };

    onChangeStatusFilter = selectedValue => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.status.condition = selectedValue;
        this.setState(stateCopy);
    };

    handleFilter = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = 0;
        this.setState(stateCopy);

        this.searchWholesaleOrders();
    };

    handleFilterOnEnter = (event) =>{
        if (event.keyCode === 13) {
            this.handleFilter();
        }
    };

    handleNext = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from + 100;
        this.setState(stateCopy);

        this.searchWholesaleOrders();
    };

    handlePrev = () => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.filters.from = this.state.filters.from - 100;
        this.setState(stateCopy);

        this.searchWholesaleOrders();
    };

    clearFilters = () => {
        this.setState({
            filters: {
                clientId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                storeId: {
                    condition: "eq",
                    value: this.props.id,
                    label: "",
                },
                status: {
                    condition: "eq",
                    value: "",
                },
                size: 100,
                from: 0,
            },
        });
    };

    searchWholesaleOrders() {
        this.setState({
            filterBtnText: FILTERING,
            isProcessing: true,
        });
        new ResourceAPIs().searchWholesaleOrders(this.state.filters)
            .then(res => {
                    this.setState({
                        results: res.data,
                        filterBtnText: FILTER,
                        isProcessing: false,
                    });
                })
            .catch(error => {
                    handleErr(error);
                    this.setState({
                        filterBtnText: FILTER,
                        isProcessing: false,
                        results: error,
                    });
                });
    }

    handleAddNew = () => {
        new ResourceAPIs().addNewWholesaleOrder()
            .then(res => {
                window.open("/wholesale-order/" + res.data);
            })
            .catch(error => {
                handleErr(error);
            });
    };

    componentDidMount() {
        this.getClients();
    }

    render() {
        return (
            <div style={{marginLeft: '2%', marginRight: '2%'}}>
                <Row>
                    <Col xs={12}>
                        <h4>Wholesale Orders</h4>
                        <br/>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={4}>
                                        {this.props.id === undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={intOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Select
                                                            value={createTypeOption(this.state.filters.clientId.label)}
                                                            onChange={(e) => this.onChangeCombinedClientIdValue(e)}
                                                            options={this.state.clients}
                                                            styles={this.customStyles}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        {this.props.id !== undefined && (
                                            <div>
                                                <Form.Label style={filterLabel}>Client</Form.Label>
                                                <Row>
                                                    <Col style={dropdownCol}>
                                                        <WHFilterSelect options={eqOptions}
                                                                        handleOnChange={this.onChangeClientIdFilter}/>
                                                    </Col>
                                                    <Col style={inputCol}>
                                                        <Form.Control size="sm" type="number"
                                                                      value={this.props.id}
                                                                      onChange={this.onChangeClientIdValue}
                                                                      onKeyDown={this.handleFilterOnEnter}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}

                                        <div>
                                            <Form.Label style={filterLabel}>Store</Form.Label>
                                            <Row>
                                                <Col style={dropdownCol}>
                                                    <WHFilterSelect options={intOptions}
                                                                    handleOnChange={this.onChangeStoreIdFilter}/>
                                                </Col>
                                                <Col style={inputCol}>
                                                    <Select
                                                        value={createTypeOption(this.state.filters.storeId.label)}
                                                        onChange={(e) => this.onChangeCombinedStoreIdValue(e)}
                                                        options={this.state.stores}
                                                        styles={this.customStyles}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label style={filterLabel}>Status</Form.Label>
                                        <Row>
                                            <Col style={dropdownCol}>
                                                <WHFilterSelect options={strOptions}
                                                                handleOnChange={this.onChangeStatusFilter}/>
                                            </Col>
                                            <Col style={inputCol}>
                                                <Form.Control size="sm" type="text"
                                                              onChange={this.onChangeStatusValue}
                                                              value={this.state.filters.status.value}
                                                              onKeyDown={this.handleFilterOnEnter}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <Form.Text className="text-muted">
                                                {this.state.errMsg}
                                            </Form.Text>
                                        </Row>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <IconButton onClick={this.handlePrev}
                                                        disabled={this.state.isProcessing || this.state.filters.from < 100}>
                                                <SkipPrevious/>
                                            </IconButton>
                                            <span style={resultsDescStyle}>{this.state.results.length} results
                                            (Page {(this.state.filters.from / 100) + 1})</span>
                                            <IconButton onClick={this.handleNext}
                                                        disabled={this.state.isProcessing || this.state.results.length !== 100}>
                                                <SkipNext/>
                                            </IconButton>
                                        </Row>
                                        <Row align="center" style={{display: 'block', textAlign: 'center'}}>
                                            <Button variant="success" style={filterBtn}
                                                    onClick={this.handleFilter}
                                                    disabled={this.state.isProcessing}>
                                                {showFilterSpinner(this.state.filterBtnText)}
                                            </Button>
                                            <Button variant="secondary" onClick={this.clearFilters}>Clear</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <Row style={{display: 'block', textAlign: 'center'}}>
                                            <p style={{fontSize: 12}}>Add New<br/>Wholesale Order</p>
                                            <Button variant="primary" onClick={this.handleAddNew}><Add/> Add
                                                New</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br/>
                    </Col>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">Client</TableCell>
                                            <TableCell align="center">Store</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                            {/*<TableCell>Controls</TableCell>*/}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">
                                                    <a href={"/wholesale-order/" + row.id}
                                                       target="_blank">{row.id}</a>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {getLabel(this.state.clients, row.clientId)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {getLabel(this.state.stores, row.storeId)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.status}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {format2NiceDate(row.createdAt)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {format2NiceDate(row.updatedAt)}
                                                </TableCell>
                                                {/*<TableCell>*/}
                                                {/*    <IconButton color="primary" style={{padding: 6}}*/}
                                                {/*                disabled={this.state.isProcessing || !row.edited}*/}
                                                {/*                onClick={() => this.saveQty(index)}>*/}
                                                {/*        <Save/>*/}
                                                {/*    </IconButton>*/}
                                                {/*    <IconButton color="default" style={{padding: 6}}*/}
                                                {/*                disabled={this.state.isProcessing || !row.edited}*/}
                                                {/*                onClick={() => this.cancelSaveQty(index)}>*/}
                                                {/*        <Clear/>*/}
                                                {/*    </IconButton>*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default WholesaleOrdersSearch;
