import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import * as Constants from "../../constants";
import { SAVE, SAVING, CALCULATE, CALCULATING } from "../../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { format2NiceDate } from "../../utils/DateUtils";
import WHLocationContents from "./WHLocationContents";
import {
    getIdFromUrl, 
    handleErr,
    getLabel, 
    createTypeOption
} from "../../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import Select from "react-select";
import {Add, Clear, Save, Autorenew} from "@material-ui/icons";
import {ADD_CONTENTS} from "../../constants";
import {ADDING_CONTENTS} from "../../constants";
import QrCodeGenerator from './QrCodeGenerator';
import ResourceAPIs from "../../utils/ResourceAPI";
import {Mobile, Default} from "../../utils/MediaUtils";
import IconButton from "@material-ui/core/IconButton";
import {Alert} from "react-bootstrap";

const MySwal = withReactContent(Swal);

const locationInput = {};

class WHLocationDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            location: {
                id: "",
                clientId: "",
                warehouseId: "",
                locationName: "",
                locationSize: "",
                createdAt: "",
                updatedAt: "",
                qtyInLocation: "",
                errMsg: "",
            },
            clientLabel: "",
            addContent: {
                qty: "",
                barcodes: "",
                lot: "",
            },
            isLocationLoaded: false,
            locationFetchError: undefined,
            isProcessing: false,
            addContentBtnText: ADD_CONTENTS,
            deleteContentBtnText: "Delete All Contents",
            editLocationBtnText: SAVE,
            calculateBtnText: CALCULATE,
            errMsg: "",
            childLoad: false,
            clientName: "",
            clients: [],
        };

        // Edit location

        this.onChangeClientId = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.location.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeWarehouseId = (event) => {
            let newState = Object.assign({}, this.state);
            newState.location.warehouseId = event.target.value;
            this.setState(newState);
        };

        this.onChangeLocationName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.location.locationName = event.target.value;
            this.setState(newState);
        };

        this.onChangeLocationSize = (event) => {
            let newState = Object.assign({}, this.state);
            newState.location.locationSize = event.target.value;
            this.setState(newState);
        };

        // Add contents

        this.onChangeAddContentQty = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.qty = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentLot = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.lot = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentBarcodes = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.barcodes = event.target.value;
            this.setState(newState);
        };

        this.handleEditLocation = () => {
            if (this.validateEditLocation()) {
                this.setState({
                    editLocationBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPIs().updateWHLocation(this.state.location)
                    .then(res => {
                            this.setState({
                                editLocationBtnText: SAVE,
                                isProcessing: false,
                            });
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                editLocationBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleCalculateLocation = () => {
            this.setState({
                calculateBtnText: CALCULATING,
                isProcessing: true,
            });

            new ResourceAPIs().updateQtyWHLocationById(getIdFromUrl())
                .then(res => {
                    this.getWHLocationById();
                    this.setState({
                        calculateBtnText: CALCULATE,
                        isProcessing: false,
                    });
                })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            calculateBtnText: CALCULATE,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };


        this.handleCancelEditLocation = () => {
            this.getWHLocationById();
        };

        this.handleAddContent = () => {
            if (this.validateAddContentInputs()) {
                this.setState({
                    addContentBtnText: ADDING_CONTENTS,
                    isProcessing: true,
                });

                new ResourceAPIs().updateWHContentsByLocation(getIdFromUrl(), this.state.addContent)
                    .then(res => { // then print response status
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                childLoad: false,
                            });
                            this.restAddContentFrom();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                addContentBtnText: ADD_CONTENTS,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleDeleteAllContents = () => {
            MySwal.fire({
                // title: 'Are you sure?',
                title: 'You want to Delete all the contents of this location?',
                text: "You won't be able to revert this!",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    // Delete happens here
                    this.setState({
                        deleteContentBtnText: "Deleting All Contents",
                        isProcessing: true,
                    });
                    new ResourceAPIs().deleteWHContentsByLocationId(getIdFromUrl())
                        .then(res => {
                                this.setState({
                                    childLoad: true,
                                });
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    childLoad: false,
                                });
                                MySwal.fire(
                                    'Deleted!',
                                    'All contents of this location has been deleted.',
                                    'success'
                                )
                            })
                        .catch(error => {
                                handleErr(error);
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    results: error,
                                });
                            });
                }
            });
        };
    }

    validateAddContentInputs = () => {
        // validate qty
        if (parseInt(this.state.addContent.qty, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.addContent.qty = parseInt(this.state.addContent.qty, 10);
            this.setState(stateCopy);
        } else {
            this.setState({
                errMsg: "Invalid Quantity"
            });
            return false;
        }

        // validate barcodes
        if (this.state.addContent.barcodes === "") {
            this.setState({
                errMsg: "Invalid Barcodes"
            });
            return false;
        }

        return true;
    };

    validateEditLocation = () => {
        // validate client id
        if (parseInt(this.state.location.clientId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.location.clientId = parseInt(this.state.location.clientId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Client ID");
        }

        // validate warehouse id
        if (parseInt(this.state.location.warehouseId, 10) >= 0) {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.location.warehouseId = parseInt(this.state.location.warehouseId, 10);
            this.setState(stateCopy);
        } else {
            return this.setLocationEditErr("Invalid Warehouse ID");
        }

        // validate location name
        if (this.state.location.locationName === "") {
            return this.setLocationEditErr("Invalid Location Name");
        }

        // validate location name
        if (this.state.location.locationSize === "") {
            return this.setLocationEditErr("Invalid Location Size");
        }

        return true;
    };

    setLocationEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.location.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getWHLocationById = () => {
        new ResourceAPIs().getWarehouseLocationById(getIdFromUrl())
            .then(res => {
                    const result = res.data
                    this.setState({
                        location: {
                            id: result.id,
                            clientId: result.clientId,
                            warehouseId: result.warehouseId,
                            locationName: result.locationName,
                            locationSize: result.locationSize,
                            createdAt: result.createdAt,
                            updatedAt: result.updatedAt,
                            qtyInLocation: result.qtyInLocation
                        },
                        isLocationLoaded: true,
                        clientLabel: getLabel(this.state.clients, result.clientId),
                    });

                    if (result.clientId > 0) {
                        fetch(Constants.HOST_NAME + '/clients/' + result.clientId + '/details')
                            .then(res => res.json())
                            .then(
                                (res) => {
                                    this.setState({
                                        clientName: res.name
                                    })
                                }, (error) => {
                                    this.setState({
                                        isLocationLoaded: false,
                                        error
                                    })
                                })
                    } else {
                        this.setState({
                            clientName: ""
                        })
                    }
                })
            .catch(error => {
                    if (error.response.status === 404) {
                        this.setState({
                            isLocationLoaded: true,
                            locationFetchError: error.response.data.message,
                            error
                        })
                    } else {
                        this.setState({
                            isLocationLoaded: false,
                            error
                        })
                    }
                }
            );
    };

    restAddContentFrom = () => {
        this.setState({
            addContent: {
                qty: "",
                barcodes: "",
                lot: "",
            }
        });
    };

    handleAddNew = () => {
        if (this.validateAddNew()) {
            new ResourceAPIs().addNewWHLocation(this.state.location)
                .then(res => {
                    window.open("/wh-locations/" + res.data);
                })
                .catch(error => {
                    handleErr(error);
                });
        }
    };

    validateAddNew = () => {
        if (this.state.location.clientId === 0) {
            MySwal.fire("Client id is missing", "Please add the client id to the location", "error");
            return false;
        } else if (this.state.location.warehouseId === 0) {
            MySwal.fire("Warehouse id is missing", "Please add the warehouse id to the location", "error");
            return false;
        } else if (this.state.location.locationName === null) {
            MySwal.fire("Location Name is missing", "Please add the location name to the location", "error");
            return false;
        } else {
            return true;
        }
    }


    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            }, () => {
                this.getWHLocationById();
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else if (this.state.locationFetchError) {
            return (
                <Alert variant='danger' style={{ marginLeft: '5%', marginRight: '5%' }}>
                    <Alert.Heading>
                    {this.state.locationFetchError + ': ' + getIdFromUrl()}
                    </Alert.Heading>
                    <hr/>
                    <p>Please check whether this warehouse location is already Deleted!</p>
                </Alert>
            );
        } else {
            return (
                <div style={{ marginLeft: '5%', marginRight: '5%' }}>
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <Card border="secondary">
                                <Card.Header as="h5">
                                    Location Details
                                    <Mobile>
                                        <IconButton color="primary"
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleEditLocation}>
                                            <Save/>
                                        </IconButton>
                                        <IconButton color="secondary"
                                            onClick={this.handleCancelEditLocation}
                                            disabled={this.state.isProcessing}>
                                            <Clear />
                                        </IconButton>
                                        <QrCodeGenerator location={this.state.location.locationName}
                                            locationId={this.state.location.id}
                                            clientId={this.state.location.clientId}
                                            clientName={this.state.clientName} />
                                    </Mobile>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Group controlId="formBasicPassword">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>ID</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Control type="number" size="sm" style={locationInput}
                                                                  value={this.state.location.id} disabled={true}/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Client ID</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    {this.props.id === undefined && (
                                                        <Select
                                                            value={createTypeOption(this.state.clientLabel)}
                                                            onChange={(e) => this.onChangeClientId(e)}
                                                            options={this.state.clients}
                                                            styles={locationInput}
                                                        />
                                                    )}
                                                    {this.props.id != undefined && (
                                                        <Form.Control type="number" size="sm" style={locationInput}
                                                                                    value={this.state.location.clientId}
                                                                                    onChange={this.onChangeClientId}
                                                                                    disabled={true}/>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Warehouse ID</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Control type="number" size="sm" style={locationInput}
                                                                  value={this.state.location.warehouseId}
                                                                  onChange={this.onChangeWarehouseId}/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Location Name</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Control type="text" size="sm" style={locationInput}
                                                                  value={this.state.location.locationName}
                                                                  onChange={this.onChangeLocationName}/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Location Size</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Form.Control type="text" size="sm" style={locationInput}
                                                                  value={this.state.location.locationSize}
                                                                  onChange={this.onChangeLocationSize}/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Row>
                                                <Col xs={4}>
                                                    <Form.Label>Qty In Location</Form.Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Control type="number" size="sm"
                                                                          style={{ width: 100 }}
                                                                          value={this.state.location.qtyInLocation}
                                                                          disabled={true} />
                                                        </Col>
                                                        <Col>
                                                            <Mobile>
                                                                <IconButton color="primary" style={{ padding: 6 }}
                                                                    disabled={this.state.isProcessing}
                                                                    onClick={this.handleCalculateLocation}>
                                                                    <Autorenew />
                                                                </IconButton>
                                                            </Mobile>
                                                            <Default>
                                                                <Button variant="success"
                                                                    style={{ width: 120, fontSize: 12 }}
                                                                    disabled={this.state.isProcessing}
                                                                    onClick={this.handleCalculateLocation}>
                                                                    {showCalculateLocationSpinner(this.state.calculateBtnText)}
                                                                </Button>
                                                            </Default>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Row>
                                            <Col xs={4}>
                                                <Form.Label>Created At</Form.Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Badge variant="secondary">
                                                    {format2NiceDate(this.state.location.createdAt)}</Badge>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Form.Label>Updated At</Form.Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Badge variant="secondary">
                                                    {format2NiceDate(this.state.location.updatedAt)}</Badge>
                                            </Col>
                                        </Row>
                                        <div style={{ textAlign: "center", }}>
                                            <Form.Text className="text-muted">
                                                {this.state.location.errMsg}
                                            </Form.Text>
                                            <br />
                                            <Default>
                                                <Button variant="success" style={{ width: 120 }}
                                                    disabled={this.state.isProcessing}
                                                    onClick={this.handleEditLocation}>
                                                    {showEditLocationSpinner(this.state.editLocationBtnText)}
                                                </Button>
                                                <Button variant="secondary" style={{ marginLeft: 10, width: 120 }}
                                                    onClick={this.handleCancelEditLocation}
                                                    disabled={this.state.isProcessing}>
                                                    <Clear /> Cancel
                                                </Button>
                                                <QrCodeGenerator location={this.state.location.locationName}
                                                    locationId={this.state.location.id}
                                                    clientId={this.state.location.clientId}
                                                    clientName={this.state.clientName} />
                                            </Default>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Mobile>
                                <br />
                            </Mobile>
                            <Card border="secondary">
                                <Card.Header as="h5">
                                    Add Location
                                    <Button style={{float:"right"}} onClick={this.handleAddNew}> 
                                        <Add/> Add New
                                    </Button>
                                </Card.Header>
                            </Card>
                            <br/>
                            <Card border="secondary">
                                <Card.Header as="h5">Add Contents</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Quantity</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number" size="sm" style={locationInput}
                                                                          value={this.state.addContent.qty}
                                                                          onChange={this.onChangeAddContentQty} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Lot</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text" size="sm" style={locationInput}
                                                                          value={this.state.addContent.lot}
                                                                          onChange={this.onChangeAddContentLot} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Barcodes</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control as="textarea" rows="6" style={locationInput}
                                                                          value={this.state.addContent.barcodes}
                                                                          onChange={this.onChangeAddContentBarcodes} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <div style={{ textAlign: "center"}}>
                                                    <Form.Text className="text-muted">
                                                        {this.state.errMsg}
                                                    </Form.Text>
                                                    <br />
                                                    <Default>
                                                        <Button variant="success" style={{ width: 160, fontSize: 11 }}
                                                            disabled={this.state.isProcessing}
                                                            onClick={this.handleAddContent}>
                                                            {showSpinner(this.state.addContentBtnText)}
                                                        </Button>
                                                        <Button variant="danger" style={{ marginLeft: 10, width: 160, fontSize: 11 }}
                                                            onClick={this.handleDeleteAllContents}
                                                            disabled={this.state.isProcessing}>
                                                            {showDeleteAllSpinner(this.state.deleteContentBtnText)}
                                                        </Button>
                                                    </Default>
                                                    <Mobile>
                                                        <Button variant="success" style={{ width: 140, fontSize: 9 }}
                                                            disabled={this.state.isProcessing}
                                                            onClick={this.handleAddContent}>
                                                            {showSpinner(this.state.addContentBtnText)}
                                                        </Button>
                                                        <br/>
                                                        <Button variant="danger" style={{ marginTop: 10, width: 140, fontSize: 9 }}
                                                            onClick={this.handleDeleteAllContents}
                                                            disabled={this.state.isProcessing}>
                                                            {showDeleteAllSpinner(this.state.deleteContentBtnText)}
                                                        </Button>
                                                    </Mobile>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <WHLocationContents locationId={this.state.location.id} isLoad={this.state.childLoad} />
                </div>
            );
        }
    }
}

function showSpinner(btnText) {
    if (btnText === ADD_CONTENTS) {
        return <span><AddToPhotosIcon /> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showDeleteAllSpinner(btnText) {
    if (btnText === "Delete All Contents") {
        return <span><DeleteForeverIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showEditLocationSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save /> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showCalculateLocationSpinner(btnText) {
    if (btnText === CALCULATE) {
        return <span>{btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default WHLocationDetails;
