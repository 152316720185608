import React, { Component } from "react";
import LocationsExporter from "./LocationsExporter";
import LocationsImporter from "./LocationsImporter";
import LocationsDeleter from "./LocationsDeleter";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import LocationsSearch from "./LocationsSearch";
import RecalculateQtyInLocation from "./RecalculateQtyInLocation";
import { BULK_ACTIONS } from "../../constants";
import { Default, Mobile } from "../../utils/MediaUtils";
import { Col, Row } from "react-bootstrap";

const recalculateQtyLocationStyle = {
    marginTop: '25px',
    marginBottom: '35px',
    marginLeft: '15px',
};

class Locations extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Mobile>
                    <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="search" 
                            style={{ marginTop: 35 }}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="search">Search Locations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="search">
                                    <br />
                                    <LocationsSearch id={this.props.id} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="importAndExport">
                                    <br />
                                    <LocationsImporter />
                                    <br />
                                    <LocationsExporter />
                                    <RecalculateQtyInLocation
                                        title={"Recalculate Quantities in Location"}
                                        styles={recalculateQtyLocationStyle} />
                                    <LocationsDeleter/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                    <Col xs={1}></Col>
                    </Row>
                </Mobile >
                <Default>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="search">Search Locations</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="search">
                                <br />
                                <LocationsSearch id={this.props.id} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="importAndExport">
                                <br />
                                <LocationsImporter />
                                <br />
                                <LocationsExporter />
                                <RecalculateQtyInLocation
                                    title={"Recalculate Quantities in Location"}
                                    styles={recalculateQtyLocationStyle} />
                                <LocationsDeleter/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Default>
            </div >
        );
    }
}

export default Locations;
