import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import {FaFileExport} from "react-icons/fa";
import fileDownload from 'js-file-download';
import {EXPORT, EXPORTING} from "../constants";
import ResourceAPIs from "../utils/ResourceAPI";


const exportBtn = {
    marginTop: '25px',
    marginBottom: '35px',
    marginLeft: '40px',
};

class OrdersExporter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            exportBtnText: EXPORT,
            err: "",
        };
    }

    onClickHandler = () => {
        this.setState({
            exportBtnText: EXPORTING,
            isProcessing: true,
        });
        new ResourceAPIs().exportOrders()
            .then(res => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: "",
                    });
                    const dd = getFormattedDate(new Date());
                    fileDownload(res.data, "orders-export-" + dd + ".csv");
                })
            .catch(error => {
                    this.setState({
                        exportBtnText: EXPORT,
                        isProcessing: false,
                        err: error,
                    });
                });
    };

    render() {
        return (
            <div>
                <Button style={exportBtn} variant="success" onClick={this.onClickHandler}
                        disabled={this.state.isProcessing}>
                    <FaFileExport/> Export
                </Button>
                <p>{this.state.err}</p>
            </div>
        );
    }
}

export function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    var hour = date.getHours().toString();

    var mins = date.getMinutes().toString();

    return month + '/' + day + '/' + year + "-" + hour + ":" + mins;
}

export default OrdersExporter;
