import React, {Component} from "react";
import axios from 'axios';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {alertError, alertSuccess, handleFileSizeExceedsError, showSpinner,} from "../utils/MiscellaniousUtils";
import AuthHeader from "../user/services/AuthHeader";
import {HOST_NAME, IMPORT, IMPORTING, INTERNAL_SERVER_ERROR, MAX_FILE_SIZE, MAX_FILE_SIZE_STRING} from "../constants";

const amazonNewDiv = {
    marginTop: '25px',
};

class ListingsImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: "Import",
            importStatus: "",
        };
    }

    onChangeHandler = event => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].size > MAX_FILE_SIZE) {
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                })
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                })
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true,
            });
            axios.post(HOST_NAME + "/import/listings", data, AuthHeader())
                .then(res => { // then print response status
                        // console.log(res);
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                        });
                        this.clearFrom();
                        alertSuccess(res.data);
                    },
                    (error) => {
                        console.log(error);
                        let err = INTERNAL_SERVER_ERROR;
                        if (error.response) {
                            err = error.response.data;
                        }
                        console.log(err);
                        alertError(err.error + " (" + err.message + ")");
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                        });
                    });
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    render() {
        return (
            <div>
                <h4>Listings Importer</h4>
                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Select File</Form.Label>
                        <br/>
                        <input type="file" name="file" onChange={this.onChangeHandler}/>
                        <Form.Text className="text-muted">
                            {this.state.fileErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="success" onClick={this.onClickHandler}
                                        disabled={this.state.isProcessing}>
                                    {showSpinner(this.state.importBtnText)}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default ListingsImporter;
