import React, {Component} from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {alertError, getIdFromUrl, handleErr} from "../../utils/MiscellaniousUtils";
import ResourceAPIs from "../../utils/ResourceAPI";
import {DELETE, SAVE} from "../../constants";
import {UpcPullContentsTableRow} from "./UpcPullContentsTableRow";

const MySwal = withReactContent(Swal);

class UpcPullContents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            upcPullId: this.props.upcPullId,
            contents: [],
            isLoaded: false,
            deleteBtnText: DELETE,
            isProcessing: false,
        };

        this.onChangeQtyDesired = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].qtyDesired = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeUpc = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].upc = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSaveQty = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = false;
            stateCopy.contents[index].qtyDesired = this.state.contents[index].untouchedQtyDesired;
            stateCopy.contents[index].upc = this.state.contents[index].untouchedUpc;
            this.setState(stateCopy);
        };
    }

    getUpcPullContents = () => {
        new ResourceAPIs().getUpcPullContentsById(getIdFromUrl())
            .then(res => {
                const result = res.data;
                this.setState({
                    contents: this.addUntouchedQty(result),
                    isLoaded: true,
                });
            })
            .catch(error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    };

    addUntouchedQty = (arr) => {
        for (const ele of arr) {
            // set empty for null
            if (ele.upc === null) {
                ele.upc = "";
            }

            ele.untouchedUpc = ele.upc;
            ele.untouchedQtyDesired = ele.qtyDesired;
        }
        return arr;
    };

    saveUpcPullContent = index => {
        this.setState({
            isProcessing: true,
        });
        let content = this.state.contents[index];
        let id = parseInt(content.id, 10);
        let upc = content.upc;
        let qtyDesired = parseInt(content.qtyDesired, 10);
        if (id > 0 && qtyDesired >= 0) {
            new ResourceAPIs().updateUpcPullContent(id, upc, qtyDesired)
                .then(res => {
                    let stateCopy = Object.assign({}, this.state);
                    stateCopy.isProcessing = false;
                    stateCopy.contents[index].edited = false;
                    stateCopy.contents[index].untouchedQtyDesired = this.state.contents[index].qtyDesired;
                    stateCopy.contents[index].untouchedUpc = this.state.contents[index].upc;
                    this.setState(stateCopy);
                })
                .catch(error => {
                    this.setState({
                        isProcessing: false,
                    });
                    handleErr(error);
                });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        } else {
            alertError("qty desired should be greater than or equal to 0");
        }
        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    deleteContent = (index) => {
        let contentId = this.state.contents[index].id;
        MySwal.fire({
            title: 'You want to Delete Content with ID ' + contentId + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                new ResourceAPIs().deleteUpcPullContent(contentId)
                    .then(res => {
                        MySwal.fire(
                            'Deleted!',
                            'Content ' + contentId + ' has been deleted.',
                            'success'
                        );
                        this.getUpcPullContents();
                    })
                    .catch(error => {
                        this.setState({
                            deleteContentBtnText: "Delete All Contents",
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        })
    };

    componentDidMount() {
        this.getUpcPullContents();
    }

    componentWillReceiveProps({someProp}) {
        if (this.props.isLoad) {
            this.getUpcPullContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Card border="secondary">
                        <Card.Header as="h5">UPC Pull Contents</Card.Header>
                        <Card.Body>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small" style={{width: "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{width: "5%"}}>ID</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>UPC</TableCell>
                                            <TableCell align="center" style={{width: "10%"}}>Units Ordered</TableCell>
                                            <TableCell align="center" style={{width: "10%"}}>Qty Desired</TableCell>
                                            <TableCell align="center" style={{width: "10%"}}>Qty Pulled</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>Created At</TableCell>
                                            <TableCell align="center" style={{width: "15%"}}>Updated At</TableCell>
                                            <TableCell style={{width: "10%"}}>Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.contents.map((row, index) => (
                                            <UpcPullContentsTableRow key={row.id}
                                                                     row={row}
                                                                     index={index}
                                                                     isProcessing={this.state.isProcessing}
                                                                     onChangeUpc={this.onChangeUpc}
                                                                     onChangeQtyDesired={this.onChangeQtyDesired}
                                                                     saveQty={this.saveUpcPullContent}
                                                                     cancelSaveQty={this.cancelSaveQty}
                                                                     deleteContent={this.deleteContent}
                                            />

                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

export default UpcPullContents;
