import React, {Component} from "react";
import * as Constants from '../../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {FaFileImport} from "react-icons/fa";
import {INTERNAL_SERVER_ERROR} from "../../constants";
import {alertError, handleFileSizeExceedsError} from "../../utils/MiscellaniousUtils";
import {IMPORT} from "../../constants";
import {IMPORTING} from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import {Info} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ResourceAPIs from "../../utils/ResourceAPI";

const amazonNewDiv = {
    marginTop: '25px',
};

class ContentsImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importStatus: "",
        };
    }

    componentDidMount() {
    }

    onChangeHandler = event => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].size > Constants.MAX_FILE_SIZE) {
                handleFileSizeExceedsError(Constants.MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                })
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                })
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true,
            });
            new ResourceAPIs().importWHContents(data)
                .then(res => {
                    this.setState({
                        importBtnText: IMPORT,
                        isProcessing: false,
                        importStatus: res.data,
                    });
                    this.clearFrom();
                }).catch(error => {
                    let err = INTERNAL_SERVER_ERROR;
                    if (error.response) {
                        err = error.response.data;
                    }
                    this.setState({
                        importBtnText: IMPORT,
                        isProcessing: false,
                    });
                    alertError(err)
                });
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    render() {
        return (
            <div>
                <h4>
                    Contents Importer
                    <Tooltip title="This import will ignore 'Location name'" placement="right">
                        <IconButton aria-label="delete" size="small">
                            <Info/>
                        </IconButton>
                    </Tooltip>
                </h4>
                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Select File</Form.Label>
                        <br/>
                        <input type="file" name="file" onChange={this.onChangeHandler}/>
                        <Form.Text className="text-muted">
                            {this.state.fileErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="success" onClick={this.onClickHandler}
                                        disabled={this.state.isProcessing}>
                                    <FaFileImport/> {this.state.importBtnText}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default ContentsImporter;
