import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import {
    CLONE, CLONING, SAVE, SAVING, UPLOAD, UPLOADING, DELETE, DELETING, MAX_FILE_SIZE, MAX_FILE_SIZE_STRING, HOST_NAME
} from "../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import { format2NiceDate } from "../utils/DateUtils";
import {
    createTypeOption, getIdFromUrl, getLabel, handleErr, handleError, isEmptyOrUndefined, setEmptyIfNull,
    showCloneSpinner, showUploadSpinner, showDeleteSpinner, handleNamedFileSizeExceedsError
} from "../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { Clear, Save, InsertDriveFile, GetApp } from "@material-ui/icons";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ResourceAPIs from "../utils/ResourceAPI";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UploadProgress from "../file-repo/UploadProgress";
import { showDocument, getStaticUrl, getDownloadUrl } from "../file-repo/Utils";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { HiClipboard } from "react-icons/hi";

const WORK_ORDER_FILES = "/work-order/files";

const locationInput = {
    width: 300,
};

const MySwal = withReactContent(Swal);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        placement: 'bottom',
    },
}))(Tooltip);

class WorkOrderDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workOrder: {
                id: "",
                createdAt: "",
                updatedAt: "",
                clientId: "",
                storeId: "",
                storeName: "",
                title: "",
                status: "",
                invoice: "",
                workInstructions: "",
            },
            errMsg: "",
            editPullRequestBtnText: SAVE,
            cloneWorkOrderBtnText: CLONE,
            uploadBtnText: UPLOAD,
            deleteBtnText: DELETE,
            files: [],
            selectedFiles: [],
            isProcessing: false,
            isUploading: false,
            selectedFile: null,
            selectedFilesInfo: [],
            clientLable: "",
            storeLabel: "",
            clients: [],
            storesIds: []
        };

        // Edit pull requests

        this.onChangeClientIdValue = (selected) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.workOrder.clientId = selected.value;
            stateCopy.clientLable = getLabel(this.state.clients, selected.value);
            this.setState(stateCopy);
        };

        this.onChangeStoreIdValue = selected => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.workOrder.storeId = selected.value;
            stateCopy.storeLabel = getLabel(this.state.storesIds, selected.value);
            this.setState(stateCopy);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.workOrder.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeTitle = (event) => {
            let newState = Object.assign({}, this.state);
            newState.workOrder.title = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.workOrder.status = event.target.value;
            this.setState(newState);
        };

        this.onChangeWorkInstructions = (event) => {
            let newState = Object.assign({}, this.state);
            newState.workOrder.workInstructions = event.target.value;
            this.setState(newState);
        };

        this.onChangeWorkInvoice = (event) => {
            let newState = Object.assign({}, this.state);
            newState.workOrder.invoice = event.target.value;
            this.setState(newState);
        };

        this.handleEditWorkOrder = () => {
            if (this.validateEditWorkOrder()) {
                this.setState({
                    editPullRequestBtnText: SAVING,
                    isProcessing: true,
                });
                new ResourceAPIs().updateWorkOrder(this.state.workOrder).then(response => {
                    this.setState({
                        editPullRequestBtnText: SAVE,
                        isProcessing: false,
                    });
                }).catch(error => {
                    handleErr(error);
                    this.setState({
                        editPullRequestBtnText: SAVE,
                        isProcessing: false,
                        results: error,
                    });
                });
            }
        };

        this.handleCloneWorkOrder = () => {
            if (this.validateEditWorkOrder()) {
                this.setState({
                    cloneWorkOrderBtnText: CLONING,
                    isProcessing: true,
                });
                new ResourceAPIs().cloneWorkOrder(getIdFromUrl()).then(response => {
                    window.open("/work-orders/" + response.data);
                    this.setState({
                        cloneWorkOrderBtnText: CLONE,
                        isProcessing: false,
                    });
                }).catch(error => {
                    handleError(error);
                    this.setState({
                        cloneWorkOrderBtnText: CLONE,
                        isProcessing: false,
                        results: error,
                    });
                });
            }
        };
    }

    validateEditWorkOrder = () => {
        // validate client id
        if (!isEmptyOrUndefined(this.state.workOrder.clientId)) {
            if (parseInt(this.state.workOrder.clientId, 10) >= 0) {
                let stateCopy = Object.assign({}, this.state);
                stateCopy.workOrder.clientId = parseInt(this.state.workOrder.clientId, 10);
                this.setState(stateCopy);
            } else {
                return this.setWorkOrderEditErr("Invalid Client ID!");
            }
        } else {
            return this.setWorkOrderEditErr("Client ID field is required!");
        }

        return true;
    };

    setWorkOrderEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getWorkOrderById = () => {
        new ResourceAPIs().getWorkOrderById(getIdFromUrl()).then(response => {
            let result = response.data;
            this.setState({
                workOrder: {
                    id: result.id,
                    createdAt: result.createdAt,
                    updatedAt: result.updatedAt,
                    clientId: result.clientId,
                    storeId: result.storeId,
                    storeName: result.storeName,
                    title: result.title,
                    status: result.status,
                    invoice: result.invoice,
                    workInstructions: result.workInstructions,
                    errMsg: ""
                },
                isLocationLoaded: true,
                clientLable: getLabel(this.state.clients, result.clientId),
                storeLabel: getLabel(this.state.storesIds, result.storeId)
            });

            this.getFiles();
        }).catch(error => {
            this.setState({
                isLocationLoaded: false,
                error
            })
        });
    };

    handleCancelEditWorkOrder = () => {
        this.getWorkOrderById();
    };

    getStores = () => {
        new ResourceAPIs().getStores()
            .then(res => {
                const storesIds = res.data.map(store => {
                    return { value: store.id, label: store.id + " - " + store.name, name: store.name }
                });

                this.setState({
                    storesIds: storesIds,
                });

                this.getWorkOrderById();

            },
                (error) => {
                    console.log(error);
                    this.setState({
                        results: error,
                    });
                });
    };

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            });

            this.getStores();

        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    }

    onChangeFile = event => {
        let numberOfFiles = event.target.files.length;
        let isError = false;
        let fileInfo = [];
        for (let fileNumber = 0; fileNumber < numberOfFiles; fileNumber++) {
            if (event.target.files[fileNumber] !== undefined) {
                let fileSize = event.target.files[fileNumber].size;
                if (fileSize > MAX_FILE_SIZE) {
                    handleNamedFileSizeExceedsError(event.target.files[fileNumber].name, MAX_FILE_SIZE_STRING);
                    isError = true;
                } else {
                    fileInfo.push({ progress: 0, total: fileSize });
                }
            }
        }
        if (isError) {
            this.setState({
                selectedFile: null,
                selectedFilesInfo: [],
            });
        } else {
            this.setState({
                selectedFilesInfo: fileInfo,
                selectedFile: event.target.files,
            });
        }
    };

    uploadFile = () => {
        if (this.state.selectedFile == null) {
            MySwal.fire("Error", "Pls select the file you want to upload!", "error");
        } else {
            this.setState({
                uploadBtnText: UPLOADING,
                isProcessing: true,
                isUploading: true,
            });

            for (let file = 0; file < this.state.selectedFile.length; file++) {
                const data = new FormData();
                data.append("file", this.state.selectedFile[file]);
                data.append("id", this.state.workOrder.id);

                const options = {
                    onUploadProgress: (ProgressEvent) => {
                        const { loaded, total } = ProgressEvent;
                        let newState = Object.assign({}, this.state);
                        newState.selectedFilesInfo[file].progress = loaded;
                        this.setState(newState);
                    },
                };
                new ResourceAPIs().uploadWoFile(data, options).then(response => {
                    if (file === (this.state.selectedFile.length - 1)) {
                        this.getFiles();
                        this.changeFileInputKey();
                    }
                }).catch(error => {
                    handleError(error);
                });
            }
            this.setState({
                uploadBtnText: UPLOAD,
                isProcessing: false,
            });
        }
    };

    getFiles = () => {
        new ResourceAPIs().getWoRepoFiles(this.state.workOrder.id).then(response => {
            let result = response.data;
            this.setState({
                files: result,
                isLoaded: true,
            });
        }).catch(error => {
            this.setState({
                isLoaded: false,
                error
            });
        });
    };

    deleteFile = () => {
        MySwal.fire({
            title: 'You want to Delete ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(result => {
            if (result.value) {
                this.setState({
                    deleteBtnText: DELETING,
                    isProcessing: true,
                });
                for (let file = 0; file < this.state.selectedFiles.length; file++) {
                    let filename = this.state.selectedFiles[file].name;
                    new ResourceAPIs().deleteWoRepoFile(this.state.workOrder.id, filename).then(response => {
                        this.getFiles();
                        }).catch(error => {
                            handleError(error);
                        });
                }
                this.setState({
                    deleteBtnText: DELETE,
                    isProcessing: false,
                    selectedFiles: [],
                });
            }
        });
    };

    handleSelectAllClick = (event) => {
        let files = [];
        if (event.target.checked) {
            files = this.state.files;
        }

        this.setState({
            selectedFiles: files
        });
    };

    handleSelectClick = (event, row, index) => {
        let selectedFiles = this.state.selectedFiles;
        let files = this.state.files;
        if (event.target.checked) {
            selectedFiles.push(row);
        } else {
            if (index > -1) {
                selectedFiles.splice(index, 1);
            }
        }

        this.setState({
            selectedFiles: selectedFiles,
            files: files
        });
    };

    copyToClipboard = (name, location) => {
        let url = HOST_NAME;
        if (location > 0) {
            url += "/" + location + "/" + name;
        } else {
            url += "/" + name;
        }
        const element = document.createElement('textarea');
        element.value = url;
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
    };

    closeIsUploading = () => {
        this.setState({
            isUploading: false,
        });
        this.clearForm();
    };

    clearForm = () => {
        this.setState({
            selectedFilesInfo: [],
            selectedFile: null,
        });
    };

    changeFileInputKey = () => {
        let fileUploadKey = Math.random().toString(36);
        this.setState({
            theInputField: fileUploadKey,
        });
    };

    getDownloadUrl = (file, currentLocation) => {
        let url = HOST_NAME + WORK_ORDER_FILES + "/download/";
        if (currentLocation !== null && currentLocation > 0) {
            url = url + currentLocation + "/" + file;
        } else {
            url = url + file;
        }
        return url;
    }

    componentDidMount() {
        this.getClients();
    }

    render() {
        let numSelected = this.state.selectedFiles.length;
        let rowCount = this.state.files.length;

        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{ marginLeft: '5%', marginRight: '5%' }}>
                    <div>
                        <Card border="secondary">
                            <Card.Header as="h5">
                                <span style={{ marginTop: 60 }}>Work Order Details</span>

                                <div style={{ float: "right" }}>
                                    <span style={{ fontSize: 12, color: "red", marginRight: 60, }}>
                                        {this.state.errMsg}
                                    </span>
                                    <Button variant="success" size="sm" style={{ width: 100, }}
                                        disabled={this.state.isProcessing}
                                        onClick={this.handleEditWorkOrder}>
                                        {showSavePRSpinner(this.state.editPullRequestBtnText)}
                                    </Button>
                                    <Button variant="secondary" size="sm" style={{ marginLeft: 10, width: 100, }}
                                        onClick={this.handleCancelEditWorkOrder}
                                        disabled={this.state.isProcessing}>
                                        <Clear /> Cancel
                                    </Button>
                                    <Button variant="primary" size="sm" style={{ marginLeft: 10, width: 100, }}
                                        onClick={this.handleCloneWorkOrder}
                                        disabled={this.state.isProcessing}>
                                        {showCloneSpinner(this.state.cloneWorkOrderBtnText)}
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>ID</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="number" size="sm" style={locationInput}
                                                            value={this.state.workOrder.id}
                                                            disabled={true} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            {this.props.clientId === undefined && (
                                                <div>
                                                    <Form.Group controlId="formBasicPassword">
                                                        <Row>
                                                            <Col>
                                                                <Form.Label>Client ID</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <div style={locationInput}>
                                                                    <Select
                                                                        value={createTypeOption(this.state.clientLable)}
                                                                        onChange={(e) => this.onChangeClientIdValue(e)}
                                                                        options={this.state.clients}
                                                                        styles={this.customStyles}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </div>
                                            )}
                                            {this.props.clientId !== undefined && (
                                                <div>
                                                    <Form.Group controlId="formBasicPassword">
                                                        <Row>
                                                            <Col>
                                                                <Form.Label>Client ID</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <div style={locationInput}>
                                                                    <Select
                                                                        value={createTypeOption(this.state.clientLable)}
                                                                        onChange={(e) => this.onChangeClientIdValue(e)}
                                                                        options={this.state.clients}
                                                                        styles={this.customStyles}
                                                                        isDisabled={true}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </div>
                                            )}
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Store ID</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <div style={locationInput}>
                                                            <Select
                                                                value={createTypeOption(this.state.storeLabel)}
                                                                onChange={(e) => this.onChangeStoreIdValue(e)}
                                                                options={this.state.storesIds}
                                                                styles={this.customStyles}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Store Name</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                            value={setEmptyIfNull(this.state.workOrder.storeName)}
                                                            onChange={this.onChangeStoreName} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Title</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                            value={setEmptyIfNull(this.state.workOrder.title)}
                                                            onChange={this.onChangeTitle} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Status</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                            value={setEmptyIfNull(this.state.workOrder.status)}
                                                            onChange={this.onChangeStatus} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Invoice</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" size="sm" style={locationInput}
                                                            value={setEmptyIfNull(this.state.workOrder.invoice)}
                                                            onChange={this.onChangeWorkInvoice} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.workOrder.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.workOrder.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br />

                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="manual">
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="manual">Work Instructions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="listings">Attach Files</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <br />
                            <Tab.Content>
                                <Tab.Pane eventKey="manual">
                                    <Card>
                                        <Card.Body>
                                            <Form.Group as={Col} controlId="formGridState">
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Form.Label>Enter </Form.Label>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control as="textarea" rows="12"
                                                                value={setEmptyIfNull(this.state.workOrder.workInstructions)}
                                                                onChange={this.onChangeWorkInstructions}
                                                                styles={{ whiteSpace: 'pre-wrap' }} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Form.Group>
                                            <div>
                                                <span style={{ fontSize: 12, color: "red", }}>{this.state.errMsg}</span>
                                                <br />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="listings">
                                    <Row>
                                        <Col>
                                            <span>Upload File</span>
                                            <br />
                                            <input
                                                multiple
                                                type="file"
                                                name="file"
                                                onClick={this.closeIsUploading}
                                                onChange={this.onChangeFile}
                                                key={this.state.theInputField} />
                                            <Button variant="success" size="sm" onClick={this.uploadFile}
                                                disabled={this.state.isProcessing}>
                                                {showUploadSpinner(this.state.uploadBtnText)}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <div className="float-right">
                                                <Button variant="danger" size="sm" onClick={this.deleteFile}
                                                    disabled={this.state.isProcessing || this.state.selectedFiles.length === 0}
                                                    style={{marginTop: 25}}>
                                                    {showDeleteSpinner(this.state.deleteBtnText)}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table" style={{ width: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                                            checked={rowCount > 0 && numSelected === rowCount}
                                                            onChange={this.handleSelectAllClick}
                                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">Name</TableCell>
                                                    <TableCell align="left">File size</TableCell>
                                                    {/* <TableCell align="center">Copy URL</TableCell> */}
                                                    <TableCell align="center">Download</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.files.map((row, index) => {
                                                    const isItemSelected = this.state.selectedFiles.includes(row);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    onChange={(event) => this.handleSelectClick(event, row, index)}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <div>
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                {showDocument(row.name, this.state.location)}
                                                                            </React.Fragment>
                                                                        }>
                                                                        <IconButton color="default" style={{ padding: 6 }}>
                                                                            <InsertDriveFile />
                                                                        </IconButton>
                                                                    </HtmlTooltip>
                                                                    {/* <a target="_blank"
                                                                        href={getStaticUrl(row.name, this.state.location)}> */}
                                                                    {row.name}
                                                                    {/* </a> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="left">{row.size / 1000} KB</TableCell>
                                                            {/* <TableCell align="center">
                                                                <IconButton color="default" style={{ padding: 6 }}
                                                                    onClick={() => this.copyToClipboard(row.name, this.state.workOrder.id)}>
                                                                    <HiClipboard size={20} />
                                                                </IconButton>
                                                            </TableCell> */}
                                                            <TableCell align="center">
                                                                <a href={this.getDownloadUrl(row.name, this.state.workOrder.id)}>
                                                                    <GetApp />
                                                                </a>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    <br />
                    <UploadProgress isUploading={this.state.isUploading}
                        selectedFiles={this.state.selectedFile}
                        selectedFilesInfo={this.state.selectedFilesInfo}
                        closeIsUploading={this.closeIsUploading} />
                </div>
            );
        }
    }
}

function showSavePRSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save /> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
            aria-hidden="true" /> {btnText}</span>;
    }
}

export default WorkOrderDetails;
