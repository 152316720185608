import React, {Component} from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExpandMore from '@material-ui/icons/ExpandMore';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const displayRightBottom = {
    position: 'fixed',
    bottom: '0',
    right: '0',
    width: '20%',
    backgroundColor: "#dbdbdb",
};

const rowStyle = {
    marginBottom: "2px",
};

class UploadProgress extends Component {
    state = {};

    render() {
        if (this.props.isUploading && this.props.selectedFiles != null) {

            if (this.props.selectedFiles[0].type === "application/zip") {
                let loaded = this.props.selectedFilesInfo[0].progress;
                let total = this.props.selectedFilesInfo[0].total;
                let percentage = Math.round((loaded / total) * 100);

                if (percentage > 100) {
                    percentage = 100;
                }
                return (
                    <Accordion style={displayRightBottom} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <span style={{color: '#32a2fe'}}>Progress</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <Row style={rowStyle}>
                                    <Col xs="10">
                                        <p>Folder</p>
                                    </Col>
                                    <Col xs="2">
                                        <CircularProgressbar value={percentage} text={`${percentage}%`}/>
                                    </Col>
                                </Row>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            } else {
                let data = Array.from(this.props.selectedFiles);

                return (
                    <Accordion style={displayRightBottom} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <span style={{color: '#32a2fe'}}>Progress</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {data.map((file, index) => {
                                    let loaded = this.props.selectedFilesInfo[index].progress;
                                    let total = this.props.selectedFilesInfo[index].total;
                                    let percentage = Math.round((loaded / total) * 100);
                                    if (percentage > 100) {
                                        percentage = 100;
                                    }
                                    return (
                                        <Row key={index} style={rowStyle}>
                                            <Col xs="10">
                                                <p>{file.name}</p>
                                            </Col>
                                            <Col xs="2">
                                                <CircularProgressbar value={percentage} text={`${percentage}%`}/>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            }
        } else {
            return <p></p>;
        }
    }
}

export default UploadProgress;
