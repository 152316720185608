import React, {Component} from "react";
import * as Constants from '../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
    alertSuccess, 
    handleError, 
    showSpinner, 
    handleFileSizeExceedsError,
} from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";
import {IMPORT} from "../constants";
import {IMPORTING} from "../constants";


const amazonNewDiv = {
    marginTop: '25px',
};

class BusinessReportsImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importStatus: "",
        };
    }

    onChangeHandler = event => {
        if( event.target.files[0] !== undefined){
            if(event.target.files[0].size > Constants.MAX_FILE_SIZE){
                handleFileSizeExceedsError(Constants.MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                })
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                })
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true,
            });
            new ResourceAPIs().importBusinessReport(data).then(response => {
                    this.setState({
                        importBtnText: IMPORT,
                        isProcessing: false,
                    });
                    this.clearFrom();
                    alertSuccess(response.data);
                }).catch(error => {
                    handleError(error);
                    this.setState({
                        importBtnText: IMPORT,
                        isProcessing: false,
                    });
                });
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            selectedFile: null,
            theInputField: randomString
        });
    };

    render() {
        return (
            <div>
                <h4>Business Reports Importer</h4>
                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Select File</Form.Label>
                        <br/>
                        <input 
                            type="file" 
                            name="file" 
                            onChange={this.onChangeHandler}
                            key={this.state.theInputField}/>
                            
                        <Form.Text className="text-muted">
                            {this.state.fileErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="success" onClick={this.onClickHandler}
                                        disabled={this.state.isProcessing}>
                                    {showSpinner(this.state.importBtnText)}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default BusinessReportsImporter;
