import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Clear, Save} from "@material-ui/icons";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import {format2NiceDate} from "../utils/DateUtils";
import {SAVE, SAVING} from "../constants";
import Spinner from "react-bootstrap/Spinner";
import {getIdFromUrl, handleError, setEmptyIfNull} from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";

class ClientDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: {
                id: "",
                name: "",
                phone: "",
                email: "",
                orderFee: "",
                orderUnitFee: "",
                wholesaleFee: "",
                wholesaleUnitFee: "",
                amazonFee: "",
                amazonUnitFee: "",
                monthlyMinimum: "",
                manufacturingFee: "",
                manufacturingUnitFee: "",
                storageFeeSmall: "",
                storageFeeMedium: "",
                storageFeeLarge: "",
                storageFeePallet: "",
                labourRate: "",
                storeName: "",
                status: "",
                createdAt: "",
                updatedAt: "",
                errMsg: "",
            },
            isLoaded: false,
            isProcessing: false,
            editBtnText: SAVE,
            errMsg: "",
        };

        this.onChangeName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.name = event.target.value;
            this.setState(newState);
        };

        this.onChangePhone = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.phone = event.target.value;
            this.setState(newState);
        };

        this.onChangeEmail = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.email = event.target.value;
            this.setState(newState);
        };

        this.onChangeOrderFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.orderFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeOrderUnitFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.orderUnitFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeWholesaleFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.wholesaleFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeWholesaleUnitFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.wholesaleUnitFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeAmazonFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.amazonFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeAmazonUnitFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.amazonUnitFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeManufacturingFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.manufacturingFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeManufacturingUnitFee = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.manufacturingUnitFee = event.target.value;
            this.setState(newState);
        };

        this.onChangeMonthlyMinimum = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.monthlyMinimum = event.target.value;
            this.setState(newState);
        };

        this.onChangeStorageFeeSmall = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.storageFeeSmall = event.target.value;
            this.setState(newState);
        };

        this.onChangeStorageFeeMedium = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.storageFeeMedium = event.target.value;
            this.setState(newState);
        };

        this.onChangeStorageFeeLarge = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.storageFeeLarge = event.target.value;
            this.setState(newState);
        };

        this.onChangeStorageFeePallet = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.storageFeePallet = event.target.value;
            this.setState(newState);
        };

        this.onChangeLabourRate = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.labourRate = event.target.value;
            this.setState(newState);
        };

        this.onChangeStoreName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.storeName = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.client.status = event.target.value;
            this.setState(newState);
        };
    }

    getClientById = () => {
        new ResourceAPIs().getClientById(getIdFromUrl())
            .then(res => {
                    const result = res.data;
                    this.setState({
                        client: {
                            id: result.id,
                            name: setEmptyIfNull(result.name),
                            phone:setEmptyIfNull(result.phone),
                            email: setEmptyIfNull(result.email),
                            orderFee: result.orderFee,
                            orderUnitFee: result.orderUnitFee,
                            wholesaleFee: result.wholesaleFee,
                            wholesaleUnitFee: result.wholesaleUnitFee,
                            amazonFee: result.amazonFee,
                            amazonUnitFee: result.amazonUnitFee,
                            monthlyMinimum: result.monthlyMinimum,
                            storageFeeSmall: result.storageFeeSmall,
                            storageFeeMedium: result.storageFeeMedium,
                            storageFeeLarge: result.storageFeeLarge,
                            storageFeePallet: result.storageFeePallet,
                            labourRate: result.labourRate,
                            manufacturingFee: result.manufacturingFee,
                            manufacturingUnitFee: result.manufacturingUnitFee,
                            storeName: setEmptyIfNull(result.storeName),
                            status: setEmptyIfNull(result.status),
                            createdAt: result.createdAt,
                            updatedAt: result.updatedAt,
                        },
                        isLoaded: true,
                    });
                })
            .catch(error => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            );
    };

    handleEditClient = () => {
        this.setState({
            editBtnText: SAVING,
            isProcessing: true,
        });
        new ResourceAPIs().updateClient(this.state.client)
            .then(res => {
                    this.setState({
                        editBtnText: SAVE,
                        isProcessing: false,
                    });
                })
            .catch(error => {
                    handleError(error);
                    this.setState({
                        editBtnText: SAVE,
                        isProcessing: false,
                    });
                });
    };

    componentDidMount() {
        this.getClientById();
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Row>
                        <Col xs={12}>
                            <Card border="secondary">
                                <Card.Header as="h5">
                                    <span style={{marginTop: 60}}>Client Details</span>

                                    <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.client.errMsg}
                                    </span>
                                        <Button variant="success" style={{width: 200}} size="sm"
                                                disabled={this.state.isProcessing}
                                                onClick={this.handleEditClient}>
                                            {showEditSpinner(this.state.editBtnText)}
                                        </Button>
                                        <Button variant="secondary" style={{marginLeft: 10, width: 200}} size="sm"
                                                onClick={this.handleCancelEditLocation}
                                                disabled={this.state.isProcessing}>
                                            <Clear/> Cancel
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>ID</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.id} disabled={true}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Name</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.client.name}
                                                                          onChange={this.onChangeName}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Phone</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.client.phone}
                                                                          onChange={this.onChangePhone}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Email</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.client.email}
                                                                          onChange={this.onChangeEmail}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Store Name</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.client.storeName}
                                                                          onChange={this.onChangeStoreName}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Status</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.client.status}
                                                                          onChange={this.onChangeStatus}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Order Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.orderFee}
                                                                          onChange={this.onChangeOrderFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Order Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.orderUnitFee}
                                                                          onChange={this.onChangeOrderUnitFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Wholesale Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.wholesaleFee}
                                                                          onChange={this.onChangeWholesaleFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Wholesale Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.wholesaleUnitFee}
                                                                          onChange={this.onChangeWholesaleUnitFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Manufacturing Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.manufacturingFee}
                                                                          onChange={this.onChangeManufacturingFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Manufacturing Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.manufacturingUnitFee}
                                                                          onChange={this.onChangeManufacturingUnitFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Amazon Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.amazonFee}
                                                                          onChange={this.onChangeAmazonFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Amazon Unit Fee</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.amazonUnitFee}
                                                                          onChange={this.onChangeAmazonUnitFee}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Monthly Minimum</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.monthlyMinimum}
                                                                          onChange={this.onChangeMonthlyMinimum}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Small</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.storageFeeSmall}
                                                                          onChange={this.onChangeStorageFeeSmall}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Medium</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.storageFeeMedium}
                                                                          onChange={this.onChangeStorageFeeMedium}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Large</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.storageFeeLarge}
                                                                          onChange={this.onChangeStorageFeeLarge}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Storage Fee Pallet</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="number"
                                                                          value={this.state.client.storageFeePallet}
                                                                          onChange={this.onChangeStorageFeePallet}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicPassword">
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Form.Label>Labour Rate</Form.Label>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Form.Control type="text"
                                                                          value={this.state.client.labourRate}
                                                                          onChange={this.onChangeLabourRate}/>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Created At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.client.createdAt)}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Updated At</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Badge variant="secondary">
                                                            {format2NiceDate(this.state.client.updatedAt)}</Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

function showEditSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default ClientDetails;
