import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {SAVE, SAVING, ADD_CONTENTS} from "../../constants";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {format2NiceDate} from "../../utils/DateUtils";
import {
    getIdFromUrl,
    handleErr,
    getLabel,
    createTypeOption,
    handleError,
    alertSuccess
} from "../../utils/MiscellaniousUtils";
import Button from "react-bootstrap/Button";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Clear, GetApp, HomeWork, LowPriority, Save} from "@material-ui/icons";
import OrderPullRequestContents from "./OrderPullRequestContents";
import {getFormattedDate} from "../OrdersExporter";
import fileDownload from "js-file-download";
import Select from "react-select";
import ResourceAPIs from "../../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const PULL_WH_CONTENTS = "Pull Warehouse Contents";
const PULL_WH_CONTENTS_AS_TASK = "Pull Warehouse Contents as Task";
const PULL_LISTINGS_DATA = "Pull Listing Data";
const PULL_LISTINGS_DATA_AS_TASK = "Pull Listing Data as Task";
const DOWNLOAD_PULL_REPORT = "Download Pull Report";
const DOWNLOAD_PULL_CONTENTS = "Download Pull Contents";

class OrderPullRequestDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pullRequest: {
                id: "",
                createdAt: "",
                updatedAt: "",
                pullName: "",
                status: "",
                clientId: "",
                errMsg: "",
            },
            clientLabel: "",
            addContent: {
                qty: "",
                barcodes: "",
            },
            selectedFile: null,
            isLocationLoaded: false,
            isProcessing: false,
            addContentBtnText: ADD_CONTENTS,
            deleteContentBtnText: "Delete All Contents",
            editPullRequestBtnText: SAVE,
            pullListingBtnText: PULL_LISTINGS_DATA,
            pullListingAsTaskBtnText: PULL_LISTINGS_DATA_AS_TASK,
            pullWhContentsBtnText: PULL_WH_CONTENTS,
            pullWhContentsAsTaskBtnText: PULL_WH_CONTENTS_AS_TASK,
            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
            downloadPCBtnText: DOWNLOAD_PULL_CONTENTS,
            errMsg: "",
            childLoad: false,
            clients: [],
        };

        // Edit pull requests

        this.onChangePullName = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.pullName = event.target.value;
            this.setState(newState);
        };

        this.onChangeStatus = (event) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.status = event.target.value;
            this.setState(newState);
        };

        this.onChangeClientId = (selected) => {
            let newState = Object.assign({}, this.state);
            newState.pullRequest.clientId = selected.value;
            newState.clientLabel = getLabel(this.state.clients, selected.value);
            this.setState(newState);
        };

        this.onChangeSelectedFile = event => {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
            });
        };

        // Add contents

        this.onChangeAddContentQty = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.qty = event.target.value;
            this.setState(newState);
        };

        this.onChangeAddContentBarcodes = (event) => {
            let newState = Object.assign({}, this.state);
            newState.addContent.barcodes = event.target.value;
            this.setState(newState);
        };

        this.handleEditOrderPullRequest = () => {
            if (this.validateEditOrderPullRequest()) {
                this.setState({
                    editPullRequestBtnText: SAVING,
                    isProcessing: true,
                });

                new ResourceAPIs().updateOrderPRContentsByQuery(getIdFromUrl(), this.state.pullRequest.pullName,
                    this.state.pullRequest.status, this.state.pullRequest.clientId)
                    .then(res => {
                            this.setState({
                                editPullRequestBtnText: SAVE,
                                isProcessing: false,
                            });
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                editPullRequestBtnText: SAVE,
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handleCancelEditLocation = () => {
            this.getPullRequestById();
        };

        this.handleAddContents = () => {
            const data = new FormData();
            data.append('file', this.state.selectedFile);

            if (this.validateAddContentsInputs()) {
                this.setState({
                    addContentBtnText: "Adding Contents",
                    isProcessing: true,
                });
                new ResourceAPIs().importOrderPRContents(getIdFromUrl(), data)
                    .then(res => {
                            this.setState({
                                childLoad: true,
                            });
                            this.setState({
                                addContentBtnText: "Add Contents",
                                isProcessing: false,
                                childLoad: false,
                            });
                            this.restAddContentFrom();
                        })
                    .catch(error => {
                            handleErr(error);
                            this.setState({
                                addContentBtnText: "Add Contents",
                                isProcessing: false,
                                results: error,
                            });
                        });
            }
        };

        this.handlePullListings = () => {
            this.setState({
                pullListingBtnText: "Pulling Listing Data",
                isProcessing: true,
            });
            new ResourceAPIs().handlePullListings(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullListingBtnText: PULL_LISTINGS_DATA,
                            isProcessing: false,
                            childLoad: false,
                        });
                        this.getPullRequestById();
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullListingBtnText: PULL_LISTINGS_DATA,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullListingsAsTask = () => {
            this.setState({
                pullListingAsTaskBtnText: "Pulling Listing Data As Task",
                isProcessing: true,
            });
            new ResourceAPIs().handlePullListingsAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        pullListingAsTaskBtnText: PULL_LISTINGS_DATA_AS_TASK,
                        isProcessing: false,
                        childLoad: false,
                    });
                    this.getPullRequestById();
                    alertSuccess("'Pull Listing Data' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullListingAsTaskBtnText: PULL_LISTINGS_DATA_AS_TASK,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handlePullWhContents = () => {
            this.setState({
                pullWhContentsBtnText: "Pulling Warehouse Contents",
                isProcessing: true,
            });
            new ResourceAPIs().handlePullWhContents(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            pullWhContentsBtnText: PULL_WH_CONTENTS,
                            isProcessing: false,
                            childLoad: false,
                        });
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            pullWhContentsBtnText: PULL_WH_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handlePullWhContentsAsTask = () => {
            this.setState({
                pullWhContentsAsTaskBtnText: "Pulling Warehouse Contents",
                isProcessing: true,
            });
            new ResourceAPIs().handlePullWhContentsAsATask(getIdFromUrl())
                .then(res => {
                    this.setState({
                        childLoad: true,
                    });
                    this.setState({
                        pullWhContentsAsTaskBtnText: PULL_WH_CONTENTS_AS_TASK,
                        isProcessing: false,
                        childLoad: false,
                    });
                    alertSuccess("'Pull Warehouse Contents' task added successfully!");
                })
                .catch(error => {
                    handleErr(error);
                    this.setState({
                        pullWhContentsAsTaskBtnText: PULL_WH_CONTENTS_AS_TASK,
                        isProcessing: false,
                        results: error,
                    });
                });
        };

        this.handleDownloadPullReport = () => {
            this.setState({
                downloadPRBtnText: "Downloading Pull Report",
                isProcessing: true,
            });
            new ResourceAPIs().handleDownloadPullReport(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
                            isProcessing: false,
                            childLoad: false,
                        });
                        const dd = getFormattedDate(new Date());
                        fileDownload(res.data, "order-pull-report-" + dd + ".csv");
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            downloadPRBtnText: DOWNLOAD_PULL_REPORT,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleDownloadPullContents = () => {
            this.setState({
                downloadPCBtnText: "Downloading Pull Contents",
                isProcessing: true,
            });
            new ResourceAPIs().handleDownloadPullContents(getIdFromUrl())
                .then(res => {
                        this.setState({
                            childLoad: true,
                        });
                        this.setState({
                            downloadPCBtnText: DOWNLOAD_PULL_CONTENTS,
                            isProcessing: false,
                            childLoad: false,
                        });
                        const dd = getFormattedDate(new Date());
                        fileDownload(res.data, "order-pull-contents-" + dd + ".csv");
                    })
                .catch(error => {
                        handleErr(error);
                        this.setState({
                            downloadPCBtnText: DOWNLOAD_PULL_CONTENTS,
                            isProcessing: false,
                            results: error,
                        });
                    });
        };

        this.handleDeleteAllContents = () => {
            MySwal.fire({
                title: 'You want to Delete all the contents of this location?',
                text: "You won't be able to revert this!",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        deleteContentBtnText: "Deleting All Contents",
                        isProcessing: true,
                    });
                    new ResourceAPIs().handleDeleteAllContents(getIdFromUrl())
                        .then(res => {
                                this.setState({
                                    childLoad: true,
                                });
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    childLoad: false,
                                });
                                MySwal.fire(
                                    'Deleted!',
                                    'All contents of this location has been deleted.',
                                    'success'
                                );
                            })
                        .catch(error => {
                                handleErr(error);
                                this.setState({
                                    deleteContentBtnText: "Delete All Contents",
                                    isProcessing: false,
                                    results: error,
                                });
                            });
                }
            });
        };
    }

    getClients = () => {
        new ResourceAPIs().getClients().then(res => {
            const clients = res.data.map(client => {
                return { value: client.id, label: client.id + " - " + client.name, name: client.name }
            });

            this.setState({
                clients: clients,
            }, () => {
                this.getPullRequestById();
            });
        }).catch(error => {
            this.setState({
                results: error,
            });
        });
    };

    validateAddContentsInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                errMsg: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                errMsg: "",
            });
        }
        return true;
    };

    validateEditOrderPullRequest = () => {
        // validate pull name
        if (this.state.pullRequest.pullName === "") {
            return this.setPullRequestEditErr("Invalid Pull Name");
        }
        // validate status
        if (this.state.pullRequest.status === "") {
            return this.setPullRequestEditErr("Invalid Status");
        }
        return true;
    };

    setPullRequestEditErr = (err) => {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.pullRequest.errMsg = err;
        this.setState(stateCopy);
        return false;
    };

    getPullRequestById = () => {
        new ResourceAPIs().getPullRequestById(getIdFromUrl())
            .then(res => {
                const result = res.data;
                this.setState({
                    pullRequest: {
                        id: result.id,
                        createdAt: result.createdAt,
                        updatedAt: result.updatedAt,
                        clientId: result.clientId,
                        pullName: result.pullName,
                        status: result.status,
                        storeName: result.storeName,
                        planId: result.planId,
                        warehouse: result.warehouse,
                        invoice: result.invoice,
                        errMsg: ""
                    },
                    isLocationLoaded: true,
                });
            }).catch(error => {
                this.setState({
                    isLocationLoaded: false,
                    error
                });
                handleError(error);
            }
        );
    };

    restAddContentFrom = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            addContent: {
                qty: "",
                barcodes: "",
                theInputField: randomString
            }
        });
    };

    componentDidMount() {
        this.getClients();
    }

    render() {
        if (!this.state.isLocationLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div style={{marginLeft: '2%', marginRight: '2%'}}>
                    <div>
                        <Card border="secondary">
                            <Card.Header as="h5">
                                <span style={{marginTop: 60}}>Order Pull Request Details</span>

                                <div style={{float: "right"}}>
                                    <span style={{fontSize: 12, color: "red", marginRight: 60,}}>
                                        {this.state.pullRequest.errMsg}
                                    </span>
                                    <Button variant="success" size="sm" style={{width: 100,}}
                                            disabled={this.state.isProcessing}
                                            onClick={this.handleEditOrderPullRequest}>
                                        {showSaveOrderPRSpinner(this.state.editPullRequestBtnText)}
                                    </Button>
                                    <Button variant="secondary" size="sm" style={{marginLeft: 10, width: 100,}}
                                            onClick={this.handleCancelEditLocation}
                                            disabled={this.state.isProcessing}>
                                        <Clear/> Cancel
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col xs={4}>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>ID</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="number"
                                                                      value={this.state.pullRequest.id}
                                                                      disabled={true}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Client</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        {this.props.id === undefined && (
                                                            <Select
                                                                value={createTypeOption(this.state.clientLabel)}
                                                                onChange={(e) => this.onChangeClientId(e)}
                                                                options={this.state.clients}
                                                                styles={this.customStyles}
                                                            />
                                                        )}
                                                        {this.props.id !== undefined && (
                                                            <Form.Control type="text"
                                                                          value={this.state.clientLabel}
                                                                          disabled={true}/>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Pull Name</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text"
                                                                      value={this.state.pullRequest.pullName}
                                                                      onChange={this.onChangePullName}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Status</Form.Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                        <Form.Control type="text"
                                                                      value={this.state.pullRequest.status}
                                                                      onChange={this.onChangeStatus}/>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={4}>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Created At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.pullRequest.createdAt)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Updated At</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Badge variant="secondary">
                                                        {format2NiceDate(this.state.pullRequest.updatedAt)}</Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br/>
                    <div>
                        <Card border="secondary">
                            <Card.Body style={{backgroundColor: "#FAFAFA"}}>
                                <Form>
                                    <Row>
                                        <Col xs={5}>
                                            <b style={{marginBottom: 5}}>Add Order Pull Request Contents</b>
                                            <Card>
                                                <Card.Body>
                                                    <Form.Group as={Col} controlId="formGridState">
                                                        <Form.Label>Select File</Form.Label>
                                                        <br/>
                                                        <input 
                                                            type="file" 
                                                            name="file"
                                                            onChange={this.onChangeSelectedFile}
                                                            key={this.state.theInputField}/>
                                                    </Form.Group>
                                                    <span style={{fontSize: 12, color: "red",}}>
                                                        {this.state.errMsg}
                                                    </span>
                                                    <br/>
                                                    <Button variant="success" style={{width: 200}}
                                                            disabled={this.state.isProcessing}
                                                            onClick={this.handleAddContents}>
                                                        {showSpinner(this.state.addContentBtnText)}
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={7}>
                                            <b style={{marginBottom: 5}}>Pull Data from Listings & Warehouses</b>
                                            <Card style={{minHeight: 182}}>
                                                <Card.Body>
                                                    <div style={{textAlign: "center"}}>
                                                        <Row>
                                                            <Col>
                                                                <Button variant="info"
                                                                        style={{marginTop: 10, width: 300}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handlePullListings}>
                                                                    {showPullListingsSpinner(this.state.pullListingBtnText)}
                                                                </Button>
                                                                <br/>
                                                                <Button variant="info"
                                                                        style={{marginTop: 25, width: 300}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handlePullListingsAsTask}>
                                                                    {showPullListingsSpinner(this.state.pullListingAsTaskBtnText)}
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button variant="secondary"
                                                                        style={{marginTop: 10, width: 300}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handlePullWhContents}>
                                                                    {showPullWhContentsSpinner(this.state.pullWhContentsBtnText)}
                                                                </Button>
                                                                <br/>
                                                                <Button variant="secondary"
                                                                        style={{marginTop: 25, width: 300}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handlePullWhContentsAsTask}>
                                                                    {showPullWhContentsSpinner(this.state.pullWhContentsAsTaskBtnText)}
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button variant="primary"
                                                                        style={{marginTop: 10, width: 300}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handleDownloadPullContents}>
                                                                    {showPullDownloadPCSpinner(this.state.downloadPCBtnText)}
                                                                </Button>
                                                                <br/>
                                                                <Button variant="dark"
                                                                        style={{marginTop: 25, width: 300}}
                                                                        disabled={this.state.isProcessing}
                                                                        onClick={this.handleDownloadPullReport}>
                                                                    {showPullDownloadPRSpinner(this.state.downloadPRBtnText)}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                    <br/>
                    <OrderPullRequestContents locationId={this.state.pullRequest.id} isLoad={this.state.childLoad}/>
                </div>
            );
        }
    }
}

function showSpinner(btnText) {
    if (btnText === "Add Contents") {
        return <span><AddToPhotosIcon/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showSaveOrderPRSpinner(btnText) {
    if (btnText === SAVE) {
        return <span><Save/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullListingsSpinner(btnText) {
    if (btnText === PULL_LISTINGS_DATA || btnText === PULL_LISTINGS_DATA_AS_TASK) {
        return <span><LowPriority/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullWhContentsSpinner(btnText) {
    if (btnText === PULL_WH_CONTENTS || btnText === PULL_WH_CONTENTS_AS_TASK) {
        return <span><HomeWork/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadPRSpinner(btnText) {
    if (btnText === DOWNLOAD_PULL_REPORT) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

function showPullDownloadPCSpinner(btnText) {
    if (btnText === DOWNLOAD_PULL_CONTENTS) {
        return <span><GetApp/> {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default OrderPullRequestDetails;
