import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {format2NiceDate} from "../utils/DateUtils";
import {Add} from "@material-ui/icons";
import Button from "react-bootstrap/Button";
import {handleErr} from "../utils/MiscellaniousUtils";
import ResourceAPIs from "../utils/ResourceAPI";

class SearchClients extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            errMsg: "",
            results: [],
        };
    }

    componentDidMount() {
        this.getClients();
    }


    getClients = () => {
        new ResourceAPIs().getClients()
            .then(res => {
                    this.setState({
                        isProcessing: false,
                        results: res.data,
                    });
                })
            .catch(error => {
                    this.setState({
                        isProcessing: false,
                        results: error,
                    });
                });
    };

    handleAddNew = () => {
        new ResourceAPIs().addClient()
            .then(res => {
                    window.open("/clients/" + res.data);
                })
            .catch(error => {
                    handleErr(error);
                });
    };

    render() {
        return (
            <div style={{width: '90%', marginLeft: '5%', marginRight: '5%'}}>
                <Row>
                    <Button variant="primary" onClick={this.handleAddNew}
                            style={{marginBottom: 20}}>
                        <Add/> Add New
                    </Button>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Phone</TableCell>
                                            <TableCell align="center">Email</TableCell>
                                            <TableCell align="center">Store Name</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    <a href={"/clients/" + row.id} target="_blank">
                                                        {row.id}
                                                    </a>
                                                </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.phone}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.storeName}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>
                                                <TableCell align="left">{format2NiceDate(row.createdAt)}</TableCell>
                                                <TableCell align="left">{format2NiceDate(row.updatedAt)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p></p>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SearchClients;
