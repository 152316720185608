import {HOST_NAME} from "../constants";
import React from "react";

export const FILE_REPO = "/file-repo";
export const DIR = "dir";
export const FILE = "file";
export const UPLOAD = "Upload";
export const DELETE = "Delete";
export const UPLOADING = "Uploading...";
export const DELETING = "Deleting...";


export function isImage(file) {
    const ext = file.split('.').pop().toLowerCase();
    if (ext !== null) {
        if (ext === "jpg" || ext === "jpeg" || ext === "png") {
            return true;
        }
    }
    return false;
}

export function getDownloadUrl(file, currentLocation) {
    let url = HOST_NAME + FILE_REPO + "/download/";
    if (currentLocation !== null && currentLocation.length > 0) {
        url = url + currentLocation + "/" + file;
    } else {
        url = url + file;
    }
    return url;
}

export function getStaticUrl(name, location) {
    let url = HOST_NAME;
    if (location === "") {
        url += "/" + name;
    } else {
        url += "/" + location + "/" + name;
    }
    return url;
}

export function showDocument(file, currentLocation) {
    if (isImage(file)) {
        return <img src={getDownloadUrl(file, currentLocation)} width="400" height="300"/>
    } else {
        return <span>Cannot display this file type</span>
    }
}
