import React, {Component} from "react";
import WarehousesSearch from "./WarehousesSearch";

class Warehouses extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                {this.props.id === undefined && (
                <WarehousesSearch id={this.props.id}/>
                )}
            </div>
        );
    }
}

export default Warehouses;
