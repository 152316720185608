import React, {Component} from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import AmazonNewOrders from "./importers/AmazonNewOrders";
import AmazonUnshippedOrders from "./importers/AmazonUnshippedOrders";
import AmazonAllOrders from "./importers/AmazonAllOrders";
import ShopifyOrders from "./importers/ShopifyOrders";
import MoneyDollyOrders from "./importers/MoneyDollyOrders";


class OrdersImportTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Tab.Container id="left-tabs-example" defaultActiveKey="amazon-new">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="amazon-new">Amazon New Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="amazon-unship">Amazon Unshipped Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="amazon-all">Amazon All Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="shopify">Shopify Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="money-dolly">Money Dolly Orders</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="amazon-new">
                        <AmazonNewOrders stores={this.state.stores}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="amazon-unship">
                        <AmazonUnshippedOrders/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="amazon-all">
                        <AmazonAllOrders/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="shopify">
                        <ShopifyOrders/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="money-dolly">
                        <MoneyDollyOrders/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        );
    }
}

export default OrdersImportTabs;
