import ResourceAPIs from "../../utils/ResourceAPI";

class AuthService {

    login(username, password) {
        return new ResourceAPIs().login(username, password).then(response => {
            if (response.data.Authorization) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    loginErrorReason(username) {
        return new ResourceAPIs().loginErrorReason(username).then(response => {
            return response.data;
        });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(username, email, password) {
        return new ResourceAPIs().register(username, email, password);
    }

    validateToken(userId, token) {
        return new ResourceAPIs().validate(userId, token);
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getCurrentUserRole() {
        return localStorage.getItem('role');
    }

    userRole(username) {
        return new ResourceAPIs().userRole(username).then(response => {
            if (response.data) {
                localStorage.setItem("role", response.data);
            }
            return response.data;
        });
    }

    getUserId() {
        return new ResourceAPIs().getUserByName(
            JSON.parse(localStorage.getItem('user')).User
        );
    }
}

export default new AuthService();
