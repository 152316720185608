import React, {Component} from "react";
import * as Constants from "../../constants";
import withStyles from "@material-ui/core/styles/withStyles";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
    alertSuccess,
    handleError,
    showSpinner,
    handleFileSizeExceedsError
} from "../../utils/MiscellaniousUtils";
import {IMPORT, IMPORTING} from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";

const useStyles = ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 80,
        height: 80,
    },
});

class OrderPullRequestImporter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importStatus: ""
        };
    }

    onChangeHandler = event => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].size > Constants.MAX_FILE_SIZE) {
                handleFileSizeExceedsError(Constants.MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                });
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                });
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append("file", this.state.selectedFile);

        if (this.validateInputs()) {
            this.setState({
                importBtnText: IMPORTING,
                isProcessing: true
            });
            new ResourceAPIs().importUpdateOrderPRContents(data)
                .then(res => {
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                            selectedFile: null,
                            loaded: null
                        });
                        this.clearFrom();
                        alertSuccess(res.data);
                    })
                .catch(error => {
                        handleError(error);
                        this.setState({
                            importBtnText: IMPORT,
                            isProcessing: false,
                            selectedFile: null,
                            loaded: null
                        });
                    }
                );
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!"
            });
            return false;
        } else {
            this.setState({
                fileErr: ""
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null
        });
    };

    render() {
        const {classes} = this.props;

        if (this.props.miniWidget) {
            return (
                <Row className={classes.container}>
                    <Col lg={4} md={4} xs={12}>
                        <p>Order Pull Request Importer</p>
                    </Col>
                    <Col lg={8} md={8} xs={12}>
                        <Row>
                            <Col>
                                <Form.Group as={Col} controlId="formGridState">
                                    <input type="file" name="file" onChange={this.onChangeHandler}/>
                                    <Form.Text className="text-muted">{this.state.fileErr}</Form.Text>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button
                                    style={{float:"right"}}
                                    variant="success"
                                    onClick={this.onClickHandler}
                                    disabled={this.state.isProcessing}
                                >
                                    {showSpinner(this.state.importBtnText)}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        } else {
            return (
                <div>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Select File</Form.Label>
                        <br/>
                        <input type="file" name="file" onChange={this.onChangeHandler}/>
                        <Form.Text className="text-muted">{this.state.fileErr}</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button
                                    variant="success"
                                    onClick={this.onClickHandler}
                                    disabled={this.state.isProcessing}
                                >
                                    {showSpinner(this.state.importBtnText)}
                                </Button>
                            </Col>
                            <Col>{this.state.importStatus}</Col>
                        </Row>
                    </Form.Group>
                </div>
            );
        }
    }
}

export default withStyles(useStyles)(OrderPullRequestImporter);
