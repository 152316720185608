import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell/TableCell";

class FieldsIncluder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [
                {included: false, tableField: 'Sku', outboundFieldName: '', order: 0},
                {included: false, tableField: 'Title', outboundFieldName: '', order: 0},
                {included: false, tableField: 'UPC', outboundFieldName: '', order: 0},
                {included: false, tableField: 'ASIN', outboundFieldName: '', order: 0},
                {included: false, tableField: 'Component-UPC', outboundFieldName: '', order: 0},
                {included: false, tableField: 'MPN', outboundFieldName: '', order: 0},
                {included: false, tableField: 'Price', outboundFieldName: '', order: 0},
                {included: false, tableField: 'Quantity', outboundFieldName: '', order: 0},
            ],
            isValid: true,
            errMsg: '',
        };

        this.changeIncluded = (event, index) => {
            let newState = Object.assign({}, this.state);
            newState.fields[index].included = event.target.checked;
            this.setState(newState);
            this.handleFieldsIncluder();
        };

        this.changeOutboundFieldName = (event, index) => {
            let newState = Object.assign({}, this.state);
            newState.fields[index].outboundFieldName = event.target.value;
            this.setState(newState);
            this.handleFieldsIncluder();
        };

        this.changeOrder = (event, index) => {
            let newState = Object.assign({}, this.state);
            newState.fields[index].order = event.target.value;
            this.setState(newState);
            this.handleFieldsIncluder();
        };

        this.handleFieldsIncluder = () => {
            this.props.fieldsIncluder(this.state.fields);
        }
    }

    componentDidMount() {
        if (this.props.fieldsIncluded !== null && this.props.fieldsIncluded !== '') {
            let newState = Object.assign({}, this.state);
            newState.fields = JSON.parse(this.props.fieldsIncluded);
            this.setState(newState);
        }
        this.handleFieldsIncluder();
    }

    render() {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="simple table" size="small" style={{width: "100%"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{width: "15%"}}>Included</TableCell>
                                <TableCell align="center" style={{width: "30%"}}>Table Field</TableCell>
                                <TableCell align="center" style={{width: "35%"}}>Outbound Field Name</TableCell>
                                <TableCell align="center" style={{width: "20%"}}>Order</TableCell>
                            </TableRow>
                        </TableHead>
                        <tbody>
                        {this.state.fields.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell align="center">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <Form.Check type="checkbox" label=""
                                                    checked={row.included}
                                                    onChange={(e) => this.changeIncluded(e, index)}/>
                                    </Form.Group>
                                </TableCell>
                                <TableCell align="center">{row.tableField}</TableCell>
                                <TableCell align="center">
                                    <Form.Control type="text" size="sm"
                                                  value={row.outboundFieldName}
                                                  onChange={(e) => this.changeOutboundFieldName(e, index)}/>
                                </TableCell>
                                <TableCell align="center">
                                    <Form.Control type="number" size="sm"
                                                  value={row.order}
                                                  onChange={(e) => this.changeOrder(e, index)}/>
                                </TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default FieldsIncluder;
