import React from "react";
import TableRow from "@material-ui/core/TableRow";
import {Box, Collapse, TableCell} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {RadioButtonUnchecked} from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Spinner from "react-bootstrap/Spinner";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {formatTaskTime} from "../utils/DateUtils";
import {getTaskData} from "./TaskUtils";
import Button from "react-bootstrap/Button";
import Highlight from "react-highlight.js";
import {AMAZON_LISTINGS_IMPORTER, INBOUND_CONTENTS_IMPORTER} from "./Constants";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export function TaskTableRow(props) {
    const {row, startTask, stopTask} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {getStatusIcon(row.status)}
                </TableCell>
                <TableCell component="th" scope="row" align="right">{row.id}</TableCell>
                <TableCell align="right">{row.userId}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{getTaskData(row.data, row.name)}</TableCell>
                <TableCell align="center">{getDuration(row.startedAt, row.endedAt, row.status)}</TableCell>
                <TableCell align="center">{formatTaskTime(row.createdAt)}</TableCell>
                <TableCell align="center">{getStartedTime(row.startedAt, row.status)}</TableCell>
                <TableCell align="center">{getEndedTime(row.endedAt, row.status)}</TableCell>
                <TableCell align="center">
                    <Button variant="outline-danger"
                            onClick={() => stopTask(row.id)}
                            disabled={isStopped(row.status)}>
                        Stop
                    </Button>{" "}
                    <Button variant="outline-info"
                            onClick={() => startTask(row.id)}
                            disabled={canStart(row.status, row.name)}>
                        Start
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Highlight laguage="json">
                                {JSON.stringify(JSON.parse(row.data), null, 4)}
                            </Highlight>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function getStatusIcon(status) {
    if (status === "New") {
        return <Tooltip title={status} placement="right"><RadioButtonUnchecked style={{color: 'grey'}}/></Tooltip>;
    } else if (status === "Completed") {
        return <Tooltip title={status} placement="right"><CheckCircleOutlineIcon style={{color: 'green'}}/></Tooltip>;
    } else if (status === "Processing") {
        return <Tooltip title={status} placement="right"><Spinner animation="border" variant="warning"
                                                                  size="sm"/></Tooltip>;
    } else if (status === "Failed") {
        return <Tooltip title={status} placement="right"><ErrorOutlineIcon color='secondary'/></Tooltip>;
    } else {
        return "Unsupported status";
    }
}

function isStopped(status) {
    return !(status === "New" || status === "Processing");
}

function canStart(status, name) {
    return !(status === "Failed"
        && name !== INBOUND_CONTENTS_IMPORTER
        && name !== AMAZON_LISTINGS_IMPORTER);
}

function getStartedTime(date, status) {
    if (status !== "New") {
        return formatTaskTime(date);
    }
    return "Not Started";
}

function getEndedTime(date, status) {
    if (status === "New") {
        return "Not Started";
    } else if (status === "Processing") {
        return "Processing";
    }
    return formatTaskTime(date);
}

function getDuration(start, end, status) {
    if (status === "New") {
        return "Not Started";
    } else if (status === "Processing") {
        return status;
    } else {
        if (start !== null) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const diffTime = Math.abs(startDate - endDate);

            let seconds = Math.ceil(diffTime / (1000));
            if (seconds > 60) {
                let minutes = Math.ceil(seconds / (60));
                return minutes + " minutes"
            } else {
                return seconds + " seconds"
            }
        } else {
            return null;
        }
    }
}
