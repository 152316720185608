import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import ResourceAPIs from "../utils/ResourceAPI";
import {format2NiceDate, formatTaskTime} from "../utils/DateUtils";

class Schedules extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProcessing: false,
            results: [],
            isLoaded: false,
            msg: "Loading"
        };
    }

    fetchSchedules() {
        new ResourceAPIs().getAllSchedules().then(response => {
            this.setState({
                results: response.data,
                isLoaded: true,
                msg: "",
            });
        }).catch(error => {
            this.stopIntervalTask();
            this.setState({
                results: error,
                isLoaded: false,
                msg: "[Error] Fetching schedules failed, Please contact admin",
            });
        });
    }

    tick() {
        this.fetchSchedules();
    }

    componentDidMount() {
        this.fetchSchedules();
        this.interval = setInterval(() => this.tick(), 30000);
    }

    stopIntervalTask = () => {
        clearInterval(this.interval);
    };

    render() {
        if (this.state.isLoaded) {
            return (
                <Row style={{marginTop: '20px'}}>
                    <Col xs={12}>
                        {this.state.results.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">User ID</TableCell>
                                            <TableCell align="center">Type</TableCell>
                                            <TableCell align="center">Data</TableCell>
                                            <TableCell align="center">Time</TableCell>
                                            <TableCell align="center">Frequency</TableCell>
                                            <TableCell align="center">Created At</TableCell>
                                            <TableCell align="center">Updated At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.results.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" align="right">{row.id}</TableCell>
                                                <TableCell align="right">{row.userId}</TableCell>
                                                <TableCell align="left">{row.type}</TableCell>
                                                <TableCell align="left">{row.data}</TableCell>
                                                <TableCell align="center">{format2NiceDate(row.time)}</TableCell>
                                                <TableCell align="left">{row.frequency}</TableCell>
                                                <TableCell align="center">{formatTaskTime(row.createdAt)}</TableCell>
                                                <TableCell align="center">{formatTaskTime(row.updatedAt)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <p>Loading</p>
                        }
                    </Col>
                </Row>
            );
        } else {
            return (
                <div>
                    <center>
                        <p style={{color: 'red'}}>{this.state.msg}</p>
                    </center>
                </div>
            );
        }

    }
}

export default Schedules;
