import {
    INBOUND_CONTENTS_IMPORTER,
    OUTBOUND_CONTENTS_QTY_UPDATER,
    PURCHASE_ORDER_CONTENTS_INSERTER,
    PURCHASE_ORDER_WH_CONTENTS_PULLER,
    PURCHASE_ORDER_LISTING_PULLER,
    PURCHASE_ORDER_FBA_INV_PULLER,
    PURCHASE_ORDER_BR_SALES_PULLER,
    PURCHASE_ORDER_BUY_RECOMMEND_MAKER,
    AMAZON_LISTINGS_IMPORTER,
    PURCHASE_ORDER_FEED_CONTENTS_PULLER,
    MANUAL_PURCHASE_ORDER_CONTENTS_INSERTER,
    LISTING_WH_CONTENTS_PULLER, ORDER_PULL_REQUEST_LISTINGS_PULLER, ORDER_PULL_REQUEST_WAREHOUSE_PULLER
} from "./Constants";
import React from "react";
import {Link} from "@material-ui/icons";


export function getTaskData(data, name) {
    let json = JSON.parse(data);
    if (name === INBOUND_CONTENTS_IMPORTER) {
        return <span>Inbound Feed ID:
            <a href={"/ifeeds/" + json.feedId} target="_blank">{json.feedId}</a>
        </span>;
    } else if (name === OUTBOUND_CONTENTS_QTY_UPDATER) {
        return <span>Outbound Feed ID:
            <a href={"/outbound-feeds/" + json.outboundFeedId} target="_blank"> {json.outboundFeedId}</a>
        </span>;
    } else if (name === PURCHASE_ORDER_CONTENTS_INSERTER ||
        name === MANUAL_PURCHASE_ORDER_CONTENTS_INSERTER) {
        return <span>Purchase Order ID:
            <a href={"/purchase-orders/" + json.purchaseOrderId} target="_blank"> {json.purchaseOrderId}</a>
        </span>;
    } else if (name === PURCHASE_ORDER_WH_CONTENTS_PULLER ||
        name === PURCHASE_ORDER_FEED_CONTENTS_PULLER ||
        name === PURCHASE_ORDER_LISTING_PULLER ||
        name === PURCHASE_ORDER_FBA_INV_PULLER ||
        name === PURCHASE_ORDER_BR_SALES_PULLER ||
        name === PURCHASE_ORDER_BUY_RECOMMEND_MAKER) {
        return <span>Purchase Order ID:
            <a href={"/purchase-orders/" + json.purchaseOrderId} target="_blank"> {json.purchaseOrderId}</a>
        </span>;
    } else if (name === AMAZON_LISTINGS_IMPORTER) {
        return <span>Listings:
            <a href={"/listings"} target="_blank"><Link/></a>
        </span>;
    } else if (name === LISTING_WH_CONTENTS_PULLER) {
        return <span>Store ID: {json.storeId}</span>;
    } else if (name === ORDER_PULL_REQUEST_LISTINGS_PULLER ||
        name === ORDER_PULL_REQUEST_WAREHOUSE_PULLER) {
        return <span>Order Pull Request ID:
            <a href={"/order-pull-requests/" + json.orderPullRequestId} target="_blank"> {json.orderPullRequestId}</a>
        </span>;
    } else {
        return "Undefined task";
    }
}