import React, {Component} from "react";
import * as Constants from "../constants";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import {handleError} from "../utils/MiscellaniousUtils";
import {FaFileImport} from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ResourceAPI from "../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const fetchButtonStyle = {
    width: 120,
};

class ListingLastId extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastIdOfListing: null,
            isProcessing: false,
            fetchLastIdTxt: Constants.FETCH
        };
    }

    onClickHandler = () => {

        this.setState({
            fetchLastIdTxt: Constants.FETCHING,
            isProcessing: true
        });
        new ResourceAPI().getLastListingId().then(response => {
                this.setState({
                    fetchLastIdTxt: Constants.FETCH,
                    isProcessing: false,
                    lastIdOfListing: response.data
                });
                MySwal.fire("The Last Listing ID", this.state.lastIdOfListing.toString(), "success");
            }).catch(error => {
                this.setState({
                    fetchLastIdTxt: Constants.FETCH,
                    isProcessing: false,
                });
                handleError(error);
            });
    };

    render() {
        return (
            <div>
                <Form.Group as={Col} controlId="formGridState">
                    <Row>
                        <Col>
                            <Button
                                variant="success"
                                onClick={this.onClickHandler}
                                disabled={this.state.isProcessing}
                                style={fetchButtonStyle}
                            >
                                {showFetchBtnSpinner(this.state.fetchLastIdTxt)}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </div>
        );
    }
}

export function showFetchBtnSpinner(btnText) {
    if (btnText === Constants.FETCH) {
        return <span><FaFileImport/>{"  "} {btnText}</span>;
    } else {
        return <span><Spinner as="span" animation="border" size="sm" role="status"
                              aria-hidden="true"/> {btnText}</span>;
    }
}

export default ListingLastId;
