import React, { Component } from "react";
import { format2NiceDate } from "../../utils/DateUtils";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "react-bootstrap/Card";
import { Clear, Delete, Save } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { alertError, getIdFromUrl, handleErr } from "../../utils/MiscellaniousUtils";
import Form from "react-bootstrap/Form";
import { SAVE } from "../../constants";
import ResourceAPIs from "../../utils/ResourceAPI";
import { Mobile, Default } from "../../utils/MediaUtils";
import Badge from "react-bootstrap/Badge";

const MySwal = withReactContent(Swal);

class WHLocationContents extends Component {

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            locationId: this.props.locationId,
            contents: [],
            isLoaded: false,
            deleteBtnText: "Delete",
            isProcessing: false,
        };

        this.onChangeQty = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].quantity = event.target.value;
            this.setState(stateCopy);
        };

        this.onChangeLot = (event, index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = true;
            stateCopy.contents[index].lot = event.target.value;
            this.setState(stateCopy);
        };

        this.cancelSave = (index) => {
            let stateCopy = Object.assign({}, this.state);
            stateCopy.contents[index].edited = false;
            stateCopy.contents[index].quantity = this.state.contents[index].untouchedQty;
            stateCopy.contents[index].lot = this.state.contents[index].untouchedLot;
            this.setState(stateCopy);
        };
    }

    getWHContents = () => {
        new ResourceAPIs().getWarehouseContentsByLocationId(getIdFromUrl())
            .then(res => {
                const result = res.data
                this.setState({
                    contents: this.addUntouchedQty(result),
                    isLoaded: true,
                })
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error
                })
            }
            );
    };

    addUntouchedQty = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].untouchedQty = arr[i].quantity;
            arr[i].untouchedLot = arr[i].lot;
        }
        return arr;
    };

    save = (index) => {
        this.setState({
            isProcessing: true,
        });
        let content = this.state.contents[index];
        let id = parseInt(content.id, 10);
        let qty = parseInt(content.quantity, 10);
        let lot = content.lot;
        if (id > 0) {
            new ResourceAPIs().updateWHContentQty(id, qty, lot, {})
                .then(res => {
                    let stateCopy = Object.assign({}, this.state);
                    stateCopy.isProcessing = false;
                    stateCopy.contents[index].edited = false;
                    stateCopy.contents[index].untouchedQty = this.state.contents[index].quantity;
                    stateCopy.contents[index].untouchedLot = this.state.contents[index].lot;
                    this.setState(stateCopy);
                })
                .catch(error => {
                    this.setState({
                        isProcessing: false,
                    });
                    handleErr(error);
                });
        } else if (id <= 0) {
            alertError("invalid id: " + id);
        }
        this.setState({
            saveQtyBtnText: SAVE,
            isProcessing: false,
        });
    };

    deleteContent = (index) => {
        let contentId = this.state.contents[index].id;
        MySwal.fire({
            title: 'You want to Delete Content with ID ' + contentId + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                // Delete happens here
                new ResourceAPIs().deleteWHContentById(contentId)
                    .then(res => {
                        MySwal.fire(
                            'Deleted!',
                            'Content ' + contentId + ' has been deleted.',
                            'success'
                        );
                        this.getWHContents();
                    })
                    .catch(error => {
                        handleErr(error);
                        this.setState({
                            deleteContentBtnText: "Delete All Contents",
                            isProcessing: false,
                            results: error,
                        });
                    });
            }
        })
    };

    componentDidMount() {
        this.getWHContents();
    }

    componentWillReceiveProps({ someProp }) {
        // console.log(this.props);
        if (this.props.isLoad) {
            this.getWHContents();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <Default>
                        <Card border="secondary">
                            <Card.Header as="h5">Location Contents</Card.Header>
                            <Card.Body>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '5%' }}>Content ID</TableCell>
                                                <TableCell align="center" style={{ width: '5%' }}>Client ID</TableCell>
                                                <TableCell align="center" style={{ width: '5%' }}>Warehouse ID</TableCell>
                                                <TableCell align="center" style={{ width: '13%' }}>Location Name</TableCell>
                                                <TableCell align="center" style={{ width: '13%' }}>Barcode</TableCell>
                                                <TableCell align="center" style={{ width: '13%' }}>Lot</TableCell>
                                                <TableCell align="center" style={{ width: '15%' }}>Quantity</TableCell>
                                                <TableCell align="center" style={{ width: '14%' }}>Created At</TableCell>
                                                <TableCell align="center" style={{ width: '14%' }}>Updated At</TableCell>
                                                <TableCell style={{ width: '14%' }}>Controls</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.contents.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell align="right">{row.id}</TableCell>
                                                    <TableCell align="right">{row.clientId}</TableCell>
                                                    <TableCell align="right">{row.warehouseId}</TableCell>
                                                    <TableCell align="left">{row.locationName}</TableCell>
                                                    <TableCell align="left">{row.barcode}</TableCell>
                                                    <TableCell align="left">
                                                        <Form.Control size="sm" type="text" style={{ width: 100, }}
                                                            value={row.lot}
                                                            onChange={(e) => this.onChangeLot(e, index)} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Form.Control size="sm" type="number" style={{ width: 100, }}
                                                            value={row.quantity}
                                                            onChange={(e) => this.onChangeQty(e, index)} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {format2NiceDate(row.createdAt)}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {format2NiceDate(row.updatedAt)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton color="primary" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            onClick={() => this.save(index)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton color="default" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || !row.edited}
                                                            onClick={() => this.cancelSave(index)}>
                                                            <Clear />
                                                        </IconButton>
                                                        <IconButton color="secondary" style={{ padding: 6 }}
                                                            disabled={this.state.isProcessing || row.edited}
                                                            onClick={() => this.deleteContent(index)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card.Body>
                        </Card>
                    </Default>
                    <Mobile>
                        <h5>Location Contents</h5>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{ width: '10%' }}>ID</TableCell>
                                <TableCell align="center" style={{ width: '13%' }}>Lot/Qty</TableCell>
                                <TableCell style={{ width: '14%' }}>Controls</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.contents.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left"><b>{row.id}</b><br />
                                        <Badge variant="primary" style={{ width: 50 }}>Client</Badge> {row.clientId}<br />
                                        <Badge variant="secondary" style={{ width: 50 }}>WH</Badge> {row.warehouseId}<br />
                                        <Badge variant="warning" style={{ width: 50 }}>Name</Badge> {row.locationName}<br />
                                        <Badge variant="success" style={{ width: 50 }}>Barcode</Badge> {row.barcode}<br />
                                        <i style={{ fontSize: 9 }}>Created: {format2NiceDate(row.createdAt)}</i>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Badge variant="primary" style={{ width: 50 }}>Lot</Badge>
                                        <Form.Control size="sm" type="text" style={{ width: 100, }}
                                            value={row.lot}
                                            onChange={(e) => this.onChangeLot(e, index)} />
                                        <Badge variant="secondary" style={{ width: 50 }}>Qty</Badge>
                                        <Form.Control size="sm" type="number" style={{ width: 100, }}
                                            value={row.quantity}
                                            onChange={(e) => this.onChangeQty(e, index)} />
                                        <i style={{ fontSize: 9 }}>Updated: {format2NiceDate(row.updatedAt)}</i>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton color="primary" style={{ padding: 6 }}
                                            disabled={this.state.isProcessing || !row.edited}
                                            onClick={() => this.save(index)}>
                                            <Save />
                                        </IconButton>
                                        <IconButton color="default" style={{ padding: 6 }}
                                            disabled={this.state.isProcessing || !row.edited}
                                            onClick={() => this.cancelSave(index)}>
                                            <Clear />
                                        </IconButton>
                                        <IconButton color="secondary" style={{ padding: 6 }}
                                            disabled={this.state.isProcessing || row.edited}
                                            onClick={() => this.deleteContent(index)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Mobile>
                </div>
            );
        }
    }
}

export default WHLocationContents;
