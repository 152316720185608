import React, {Component} from "react";
import {MAX_FILE_SIZE, MAX_FILE_SIZE_STRING, IMPORT, IMPORTING} from '../constants';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
    alertError,
    alertSuccess,
    showSpinner,
    handleFileSizeExceedsError,
    getIdFromUrl,
} from "../utils/MiscellaniousUtils";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ResourceAPIs from "../utils/ResourceAPI";

const MySwal = withReactContent(Swal);

const amazonNewDiv = {
    marginTop: '0px',
};

class ClientListingsImporter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileErr: "",
            isProcessing: false,
            importBtnText: IMPORT,
            importStatus: "",
            asin: false,
            fnsku: false,
            upc: false,
            componentUpc: false,
        };
    }

    onChangeHandler = event => {
        if( event.target.files[0] !== undefined){
            if(event.target.files[0].size > MAX_FILE_SIZE){
                handleFileSizeExceedsError(MAX_FILE_SIZE_STRING);
                this.setState({
                    selectedFile: null,
                    asin: false,
                    fnsku: false,
                    upc: false,
                    componentUpc: false,
                });
            } else {
                this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                    asin: false,
                    fnsku: false,
                    upc: false,
                    componentUpc: false,
                });
            }
        }
    };

    onClickHandler = () => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        if(this.validateInputs()){
            MySwal.fire({
                title: 'Importing Listing data',
                icon: "warning",
                html: '<h5>In order to protect your data, It is recommended that you ONLY import the fields you are changing.</h5>' + 
                      '<h6>By default, this import will NOT update ASIN, FNSKU, UPC, and Component UPC even if included in the import ' + 
                      'file. If your file contains these fields and you wish to update these fields, please select this check box.</h6>' + 
                      '<p style="text-align:left; float:left; margin-right:20"><input type="checkbox" id="asin"/> ASIN  </p>' + " " +
                      '<p style="text-align:left; float:left; margin-right:20"><input type="checkbox" id="upc"/> UPC  </p>' +
                      '<p style="text-align:left; float:left; margin-right:20"><input type="checkbox" id="component-upc"/> component-UPC  </p>' +
                      '<p style="text-align:left; float:left; margin-right:20"><input type="checkbox" id="fnsku"/> FNSKU  </p>',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
                preConfirm: () => {
                  var asin = Swal.getPopup().querySelector('#asin').checked
                  var upc = Swal.getPopup().querySelector('#upc').checked
                  var componentUpc = Swal.getPopup().querySelector('#component-upc').checked
                  var fnsku = Swal.getPopup().querySelector('#fnsku').checked
      
                  return {asin: asin, upc: upc, componentUpc: componentUpc, fnsku: fnsku}
                }
              }).then(async (result) => {
                  if(result.dismiss === undefined){
                      await Swal.fire({
                            title: "Import Listings",
                            icon: "warning",
                            text: "Are you sure?",
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            showCancelButton: true,
                            cancelButtonColor: "#FF0000",
                      }).then(res => {
                          if(res.value){
                              this.setState({
                                asin: result.value.asin,
                                fnsku: result.value.fnsku,
                                upc: result.value.upc,
                                componentUpc: result.value.componentUpc,
                              });
                              this.setState({
                                importBtnText: IMPORTING,
                                isProcessing: true,
                              });
                              new ResourceAPIs().importClientListings(getIdFromUrl(), this.state.asin, this.state.fnsku,
                                  this.state.upc, this.state.componentUpc, data)
                                .then(res => {
                                        this.setState({
                                            importBtnText: IMPORT,
                                            isProcessing: false,
                                        });
                                        this.clearFrom();
                                        alertSuccess(res.data);
                                    })
                                .catch(error => {
                                        let err = "Internal server error!";
                                        if (error.response) {
                                            err = error.response.data;
                                        }
                                        alertError(err.error + " (" + err.message + ")");
                                        this.setState({
                                            importBtnText: IMPORT,
                                            isProcessing: false,
                                        });
                                    });
                          }
                      })
                  }
            })
        }
    };

    validateInputs = () => {
        if (this.state.selectedFile == null) {
            this.setState({
                fileErr: "Pls select the file you want to upload!",
            });
            return false;
        } else {
            this.setState({
                fileErr: "",
            });
        }
        return true;
    };

    clearFrom = () => {
        this.setState({
            selectedFile: null,
        });
    };

    render() {
        return (
            <div>
                <div style={amazonNewDiv}>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Select File</Form.Label>
                        <br/>
                        <input type="file" name="file" onChange={this.onChangeHandler}/>
                        <Form.Text className="text-muted">
                            {this.state.fileErr}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                        <Row>
                            <Col>
                                <Button variant="success" onClick={this.onClickHandler}
                                        disabled={this.state.isProcessing}>
                                    {showSpinner(this.state.importBtnText)}
                                </Button>
                            </Col>
                            <Col>
                                {this.state.importStatus}
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

export default ClientListingsImporter;
