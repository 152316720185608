import React from 'react';
import {useBarcode} from '@createnextapp/react-barcode';
import PrintComponents from "react-print-components";
import './Barcodestyles.css';
import {Print} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

export default (props) => {

    let sku = props.sku.replace(/<\/?[^>]+(>|$)/g, "");
    let fnsku = props.fnsku.replace(/<\/?[^>]+(>|$)/g, "");
    let title = props.title.replace(/<\/?[^>]+(>|$)/g, "");

    const {inputRef} = useBarcode({
        value: fnsku,
        options: {
            format: 'CODE128B',
            background: '#ffffff',
            width: '2',
            height: '50'
        }
    });

    // width: 2.25" = 5.715 cm
    // height: 1.25" = 3.175 cm

    return (
        <PrintComponents
            trigger={
                <IconButton aria-label="print">
                    <Print/>
                </IconButton>
            }>
            <div>
                <div className="barcode-text">
                    <br className="break-margin"/>
                    <b>New:</b> {sku}
                    <br/>
                    {formatTitle(title)}
                </div>
                <svg className="barcode" ref={inputRef}/>
            </div>
        </PrintComponents>
    );
};

function formatTitle(title) {
    if (title !== null && title.length > 120) {
        title = title.substring(0, 119) + "..."
    }
    return title;
}
