import React, {Component} from "react";
import Exporter from "./SettlementReportsExporter";
import Importer from "./SettlementReportsImporter";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SettlementReportsSearch from "./SettlementReportsSearch";
import {BULK_ACTIONS} from "../constants";


class SettlementReports extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{marginLeft: '5%', marginRight: '5%'}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="search">Search Settlement Reports</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="importAndExport">{BULK_ACTIONS}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="search">
                            <br/>
                            <SettlementReportsSearch id = {this.props.id}/>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Pane eventKey="importAndExport">
                        <br/>
                        <Importer/>
                        <br/>
                        <Exporter/>
                    </Tab.Pane>
                </Tab.Container>
            </div>
        );
    }
}

export default SettlementReports;
